import {
	Divider,
	Group,
	Input,
	Modal,
	Stack,
	createStyles,
} from '@mantine/core';
import { useApiListTeams } from '@repo/api-codegen';
import MultiSelector from '@repo/common/components/MultiSelector/MultiSelector';
import { Button, Text } from '@repo/foundations';
import { map, noop } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type { ChangeEventHandler } from 'react';
import { useCallback, useContext, useState } from 'react';
import {
	invalidateSearchViews,
	useAuthUser,
	useCreateSearchView,
	useUpdateSearchView,
	useWorkspace,
} from '../../../../api';
import { trackEvent } from '../../../../utils/analytics';
import AddFilterMenu from '../AddFilterMenu';
import type { SearchView } from '../FilterCarousel.constants';
import SelectedFilters from '../SelectedFilters';
import { SearchFilterStoreContext } from '../store';
import { parseSearchFiltersToView } from './utils';

interface IEditViewMenuCardProps {
	opened: boolean;
	view?: SearchView;
	onClose: () => void;
}

const useStyles = createStyles((theme) => ({
	modalBody: {
		padding: 0,
	},
	wrapper: {
		padding: theme.spacing.lg,
		paddingTop: 0,
		gap: theme.spacing.lg,
	},
	sectionWrapper: {
		gap: theme.spacing.xs,
	},
	footerWrapper: {
		padding: `${theme.spacing.md} ${theme.spacing.lg}`,
		justifyContent: 'flex-end',
	},
	label: {
		fontWeight: theme.other.typography.weight.semibold,
		color: theme.other.getColor('text/primary/default'),
	},
	subLabel: {
		fontWeight: theme.other.typography.weight.regular,
		color: theme.other.getColor('text/secondary/default'),
	},
}));

function EditViewMenuCard({ opened, view, onClose }: IEditViewMenuCardProps) {
	const { classes } = useStyles();
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();
	const searchFilterStore = useContext(SearchFilterStoreContext);

	const [newLabel, setNewLabel] = useState<string>(
		view?.label || 'Untitled View'
	);

	const handleLabelChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		setNewLabel(e.target.value);
	};

	const titleDisplay = searchFilterStore.selectedView
		? 'Edit view'
		: 'New view';

	const { data: teams } = useApiListTeams(
		{},
		{
			suspense: false,
			select: (res) => res.results,
		}
	);

	const teamOptions = map(teams, (team) => ({
		label: team.name,
		value: team.id,
		icon: team.icon,
		navigateTo: '/teams',
		onClick: noop,
	}));

	const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>(
		view?.teams || []
	);

	const handleChange = useCallback((value: (string | boolean)[]) => {
		setSelectedTeamIds(value as string[]);
	}, []);

	const { isLoading: isCreating, mutateAsync: createSearchView } =
		useCreateSearchView({
			options: {
				onSuccess: () => {
					invalidateSearchViews(1, {});
					trackEvent('search/views/create', {}, user, workspace);
				},
			},
		});

	const { isLoading: isUpdating, mutateAsync: updateSearchView } =
		useUpdateSearchView({
			options: {
				onSuccess: () => {
					invalidateSearchViews(1, {});
					trackEvent('search/views/update', {}, user, workspace);
				},
			},
			disableOptimisticUpdate: true,
			disableInvalidation: true,
		});

	const handleSave = async () => {
		if (!view) {
			await createSearchView({
				data: {
					name: newLabel,
					selected_filters: parseSearchFiltersToView(
						searchFilterStore.searchFilters
					),
					teams: selectedTeamIds,
				},
			});
			searchFilterStore.resetSelectedFilters();
			searchFilterStore.setSelectedView(undefined);
		} else {
			await updateSearchView({
				data: {
					id: view.value,
					name: newLabel,
					selected_filters: parseSearchFiltersToView(
						searchFilterStore.searchFilters
					),
					teams: selectedTeamIds,
				},
			});
			searchFilterStore.resetSelectedFilters();
			searchFilterStore.setSelectedView(undefined);
		}
		onClose();
	};

	const handleCancel = () => {
		if (searchFilterStore.selectedView) {
			// Discard changes to view
			searchFilterStore.setSelectedFiltersFromView(
				searchFilterStore.selectedView
			);
		} else {
			// Reset filters
			searchFilterStore.resetSelectedFilters();
		}
		onClose();
	};

	return (
		<Modal
			classNames={{
				body: classes.modalBody,
			}}
			opened={opened}
			onClose={handleCancel}
			title={titleDisplay}
			closeOnClickOutside
		>
			<Stack className={classes.wrapper}>
				<Stack className={classes.sectionWrapper}>
					<Text className={classes.label} size="sm">
						View name
					</Text>
					<Input
						placeholder={view?.label || 'Untitled View'}
						value={newLabel}
						onChange={handleLabelChange}
					/>
				</Stack>
				<Stack className={classes.sectionWrapper}>
					<Text className={classes.label} size="sm">
						Filters
					</Text>
					<Group spacing="xs">
						<SelectedFilters standalone />
						<AddFilterMenu standalone />
					</Group>
				</Stack>
				<Stack className={classes.sectionWrapper}>
					<Stack spacing={0}>
						<Text className={classes.label} size="sm">
							Team visibility
						</Text>
						<Text className={classes.subLabel} size="xs">
							Add teams to allow other members in your workspace to use this
							view
						</Text>
					</Stack>
					<MultiSelector
						options={teamOptions}
						property="team"
						iconType="emoji"
						isViewerUser={false}
						readOnly={false}
						initialSelected={selectedTeamIds}
						permittedId=""
						isMenuItemBadge={false}
						variant="default"
						onChange={handleChange}
					/>
				</Stack>
			</Stack>
			<Divider />
			<Group className={classes.footerWrapper}>
				{searchFilterStore.selectedView?.label === view?.label ? (
					<Button
						variant="default"
						onClick={() => {
							searchFilterStore.setSelectedView(undefined);
							handleCancel();
						}}
					>
						Disable view
					</Button>
				) : (
					<Button variant="default" onClick={handleCancel}>
						{view ? 'Discard view changes' : 'Cancel'}
					</Button>
				)}

				<Button
					variant="primary"
					onClick={handleSave}
					loading={isCreating || isUpdating}
				>
					{view ? 'Update view' : 'Create view'}
				</Button>
			</Group>
		</Modal>
	);
}

export default observer(EditViewMenuCard);
