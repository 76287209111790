import { makeAutoObservable } from 'mobx';

export class FormStore {
	submissionUnderReview: string | undefined = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	setSubmissionUnderReview = (submissionId: string | undefined) => {
		this.submissionUnderReview = submissionId;
	};
}
