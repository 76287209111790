import { showNotification } from '@mantine/notifications';
import { Icon, IconNames, Text } from '@repo/foundations';
import { filter } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type {
	Automation,
	DefaultContext,
	ISecodaEntity,
	UpdateRequestParams,
} from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { UpdateModelHook } from '../../api/factories/types';
import {
	useAutomationRun,
	useDeleteAutomation,
	useUpdateAutomation,
} from '../../api/hooks/automation';
import { automationQueryKeyFactory } from '../../api/hooks/automation/constants';
import { getCadenceDisplayLabel } from '../../components/Automation/AutomationCard/AutomationTriggerCard/constants';
import type { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import { useDeleteManyAction } from '../../components/TableV2/BaseModel.hooks';
import { useGenericColumns } from '../../components/TableV2/SecodaEntity.hooks';
import { ROW_HEIGHT } from '../../components/TableV2/TableV2.styles';
import {
	DateTimeRender,
	ReadOnlyBadgeRender,
} from '../../components/TableV2/render';
import { EntityType } from '../../lib/types';

export function useColumns(): ExtendedDataTableColumn<Automation>[] {
	const genericColumns = useGenericColumns({
		useUpdate: useUpdateAutomation as unknown as UpdateModelHook<
			ISecodaEntity,
			UpdateRequestParams<ISecodaEntity>,
			DefaultContext<ISecodaEntity>,
			unknown
		>,
	});

	const columns = useMemo(
		() => [
			{
				accessor: 'title',
				title: 'Name',
				width: 150,
				render: (record: Automation) => (
					<Text
						size="sm"
						sx={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							lineHeight: `${ROW_HEIGHT}px`,
						}}
						color="text/primary/default"
						fw={600}
					>
						{record?.icon && `${record?.icon} `}
						{record?.title ?? 'Untitled'}
					</Text>
				),
			},

			{
				accessor: 'schedule',
				title: 'Schedule',
				width: 150,
				render: (record: Automation) => {
					if (!record.schedule) {
						return '';
					}
					return getCadenceDisplayLabel(record.schedule);
				},
			},
			{
				accessor: 'status',
				title: 'Status',
				width: 150,
				render: (record: Automation) => (
					<ReadOnlyBadgeRender
						record={record}
						field="status"
						options={[
							{
								color: 'green',
								label: 'On',
								option: 'enabled',
							},
							{
								color: 'gray',
								label: 'Off',
								option: 'disabled',
							},
						]}
						nilOption={{
							color: 'gray',
							label: 'Off',
							option: 'disabled',
						}}
					/>
				),
			},
			...(filter(genericColumns, (column) =>
				['description'].includes(column.accessor)
			) as ExtendedDataTableColumn<Automation>[]),
			{
				accessor: 'next_run_at',
				title: 'Next run',
				width: 150,
				render: (record: Automation) => (
					<DateTimeRender record={record} field="next_run_at" />
				),
			},

			{
				accessor: 'last_run_at',
				title: 'Last run',
				width: 150,
				render: (record: Automation) => (
					<DateTimeRender record={record} field="last_run_at" />
				),
			},
		],
		[genericColumns]
	);

	return columns;
}

export const useActions = (): ICommandListItem<Automation>[] => {
	const { mutateAsync: runAutomation } = useAutomationRun();
	const { action: deleteManyAction } = useDeleteManyAction({
		name: 'automation',
		queryKeyFactory: automationQueryKeyFactory,
		useDelete: useDeleteAutomation,
	});

	const handleRunAutomations = useCallback(
		async (entities: Automation[]) => {
			let automationIds =
				entities
					?.filter(({ is_enabled: isEnabled }) => isEnabled)
					.map(({ id }) => id) ?? [];

			showNotification({
				title: 'Automations running',
				message: 'All the selected automations have been triggered',
				color: 'green',
				icon: <Icon name="check" />,
			});

			// Create a promise array of all the automations that are being run.
			await Promise.all(
				automationIds.map((automationId) =>
					runAutomation({
						data: {
							automationId,
						},
					})
				)
			);
		},
		[runAutomation]
	);

	const actions = useMemo(
		() => [
			{
				id: 'actions::run',
				title: 'Run automation',
				name: 'Run',
				iconName: 'refresh' as IconNames,
				hotkey: '/mp',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: handleRunAutomations,
			},
			deleteManyAction,
		],
		[deleteManyAction, handleRunAutomations]
	);

	return actions;
};
