import { createStyles, Divider, Stack } from '@mantine/core';
import { useDebounceFn } from 'ahooks';
import { useCallback, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import {
	useAuthUser,
	useDictionaryTerm,
	useUpdateDictionaryTerm,
	useWorkspace,
} from '../../api';
import { AIAssistantSidebar } from '../../components/AIAssistant/AIAssistantSidebar/AIAssistantSidebar';
import Documentation from '../../components/Documentation';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import { EntityPageDescription } from '../../components/EntityPageLayout';
import EntityPageActions from '../../components/EntityPageLayout/EntityPageActions';
import EntityPageNavBar from '../../components/EntityPageLayout/EntityPageNavBar';
import EntityPageSidebar from '../../components/EntityPageLayout/EntityPageSidebar';
import EntityPageTitle from '../../components/EntityPageLayout/EntityPageTitle';
import EntityPageToggles from '../../components/EntityPageLayout/EntityPageToggles';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import { useFullWidthToggle } from '../../hooks/useFullWidthToggle';
import { DictionaryTerm } from '../../lib/models';
import { EntityType } from '../../lib/types';
import { trackEvent } from '../../utils/analytics';
import { isViewerOfEntity } from '../../utils/authorization/roles';
import { useRedirectURLIncludingTitle } from '../../utils/hook/useRedirectURLIncludingTitle';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';

const useStyles = createStyles(
	(theme, { shouldSetFullWidth }: { shouldSetFullWidth: boolean }) => ({
		wrapper: {
			margin: '0 auto',
			width: '100%',
			minWidth: '640px',
			maxWidth: shouldSetFullWidth ? '100%' : '640px',
			fontSize: theme.fontSizes.sm,
		},
	})
);

export interface IDictionaryTermPageProps {
	id?: string;
}

function DictionaryTermPage({ id: propsId }: IDictionaryTermPageProps) {
	const paramsId = useParamsIdSuffixUuid();
	const id = propsId || paramsId;

	const { workspace } = useWorkspace();
	const { user } = useAuthUser();
	const { shouldSetFullWidth } = useFullWidthToggle(id);
	const { classes } = useStyles({ shouldSetFullWidth });

	const { data: dictionaryTerm } = useDictionaryTerm({
		id,
		options: {
			useErrorBoundary: true,
			onSuccess: () => {
				trackEvent('dictionary_term/open', {}, user, workspace);
			},
			cacheTime: 1000,
			suspense: true,
		},
	});

	useRedirectURLIncludingTitle(dictionaryTerm);

	const { mutateAsync } = useUpdateDictionaryTerm({});

	const { run: updateDictionaryTerm } = useDebounceFn(
		async (key: string, value: DjangoValueType) => {
			await mutateAsync({ data: { id, [key]: value } });
		},
		{ wait: 500 }
	);

	const handleEntityChange = useCallback(
		(key: string) =>
			(value: DjangoValueType, saveRemotely = true) => {
				updateDictionaryTerm(key, value);
				trackEvent(`dictionary_term/${key}/update`, { id }, user, workspace);
			},
		[id, updateDictionaryTerm, user, workspace]
	);

	const dictionaryTermModel = useMemo(() => {
		if (!dictionaryTerm) {
			return null;
		}
		return new DictionaryTerm(dictionaryTerm);
	}, [dictionaryTerm]);

	if (!dictionaryTerm || !dictionaryTermModel) {
		return null;
	}

	const readOnly = isViewerOfEntity(user, dictionaryTerm);

	return (
		<PageLayoutOuterWrapper key={dictionaryTerm.id}>
			<Helmet>
				<title>{dictionaryTerm?.title ?? 'Dictionary Term'}</title>
			</Helmet>
			<PageLayoutWrapper name="dictionary-term">
				<PageLayoutContentWrapper name="dictionary-term">
					{dictionaryTerm && (
						<EntityPageNavBar
							entity={dictionaryTerm}
							actions={<EntityPageActions entity={dictionaryTerm} />}
							toggles={<EntityPageToggles entity={dictionaryTerm} />}
						/>
					)}
					<PageLayoutContent>
						<Stack className={classes.wrapper} spacing={0}>
							<EntityPageTitle
								icon={null}
								placeholder="Untitled document"
								entity={dictionaryTerm}
								isReadOnly={readOnly}
								onChange={handleEntityChange('title')}
							/>
							<EntityPageDescription
								entityId={dictionaryTerm.id}
								description={dictionaryTerm?.description ?? ''}
								readOnly={readOnly}
								onChange={handleEntityChange('description')}
								integrationId={dictionaryTerm.integration}
							/>
							<Divider />
							<div role="definition">
								<Documentation entity={dictionaryTermModel} />
							</div>
						</Stack>
					</PageLayoutContent>
				</PageLayoutContentWrapper>
				<EntityPageSidebar
					entity={dictionaryTerm}
					updateEntity={updateDictionaryTerm}
					{...getEntityPageSidebarProps(EntityType.document)}
				/>
				<AIAssistantSidebar />
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}

export default DictionaryTermPage;
