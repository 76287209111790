import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { GLOSSARY_TERMS_NAMESPACE } from '../../api';
import { authHeaders } from '../../api/common';
import { SecodaEntity } from './entity';

export class GlossaryTerm extends SecodaEntity {
	static CLASS_NAME = 'GlossaryTerm';

	constructor(obj: Partial<GlossaryTerm>) {
		// @ts-expect-error TS(2345): Argument of type 'Partial<DictionaryTerm>' is not ... Remove this comment to see the full error message
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as never)[key] = cloneDeep((obj as never)[key]);
		});
	}

	static get namespace() {
		return GLOSSARY_TERMS_NAMESPACE;
	}

	static get fields() {
		return SecodaEntity.fields.concat([]);
	}

	async execute() {
		return axios.post(`${this.apiPath}execute/`, {}, authHeaders());
	}
}
