import { useDebounceFn } from 'ahooks';
import { lowerCase } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type React from 'react';
import type { JSX } from 'react';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useAuthUser } from '../../api';
import type { EntityTabsStore } from '../../pages/TableEntityPage/TableEntityTabs/TableEntityTabsStore';
import { trackEvent } from '../../utils/analytics';
import { isViewerOfEntity } from '../../utils/authorization/roles';
import { useRedirectURLIncludingTitle } from '../../utils/hook/useRedirectURLIncludingTitle';
import { AIAssistantSidebar } from '../AIAssistant/AIAssistantSidebar/AIAssistantSidebar';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../PageLayout';
import EntityPageActions from './EntityPageActions';
import EntityPageDescription from './EntityPageDescription';
import EntityPageNavBar from './EntityPageNavBar';
import type { IEntityPageSidebarProps } from './EntityPageSidebar';
import EntityPageSidebar from './EntityPageSidebar';
import EntityPageTitle from './EntityPageTitle';
import EntityPageToggles from './EntityPageToggles';

export interface IEntityPageLayoutProps extends IEntityPageSidebarProps {
	name: string;
	children: React.ReactNode;
	icon?: React.ReactNode;
	classNames?: Partial<Record<'contentWrapper' | 'content', string>>;
	isReadOnlyTitle?: boolean;
	entityTabsStore?: EntityTabsStore;
	withActions?: boolean;
	withSidebarToggles?: boolean;
	withDescription?: boolean;
	withEntityByteSize?: boolean;
	withEntityRowCount?: boolean;
	withMetricSection?: JSX.Element;
	withPinnedToggle?: boolean;
	withDiscussions?: boolean;
	withCustomPublishElement?: JSX.Element;
}

function EntityPageLayout({
	name,
	entity,
	updateEntity,
	children,
	icon,
	classNames,
	isReadOnlyTitle = false,
	entityTabsStore,
	withActions = true,
	withSidebarToggles = true,
	withDiscussions,
	withFrequentUsers,
	withDescription,
	withVerifiedSelector,
	withGovernanceSelector,
	withCollectionSelector,
	withTagSelector,
	withOwnerSelector,
	withRelatedResourceSelector,
	withCustomPropertyEditors,
	withEntityPopularity,
	withEntityRowCount,
	withEntityByteSize,
	withPinnedToggle,
	withCustomPublishElement,
	withTeamSelector,
	withMetricSection,
	withCollectionParentSelector: withParentSelector,
	// Question-specific properties:
	withAssignedToSelector = false,
	withStatusSelector = false,
	withPrioritySelector = false,
	withSource,
}: IEntityPageLayoutProps) {
	const { user, workspace } = useAuthUser();
	const isViewerUser = isViewerOfEntity(user, entity);
	const isReadOnlyTitleProp = isReadOnlyTitle || isViewerUser;

	useRedirectURLIncludingTitle(entity);

	const { run: updateEntityDebounce } = useDebounceFn(
		(key, value, saveRemotely) => {
			updateEntity(key, value, saveRemotely);
		},
		{ wait: 150 }
	);

	const handleEntityChange = useCallback(
		(key: string) =>
			(value: unknown, saveRemotely = true) => {
				updateEntityDebounce(key, value, saveRemotely);
				trackEvent(
					`${entity.entity_type}/${key}/update`,
					{ id: entity.id },
					user,
					workspace
				);
			},
		[entity.entity_type, entity.id, updateEntityDebounce, user, workspace]
	);

	const creationQuery = entityTabsStore?.creationQuery?.sql ?? '';

	return (
		<PageLayoutOuterWrapper>
			<Helmet>
				<title>{entity.title || name}</title>
			</Helmet>
			<PageLayoutWrapper name="entity-page" key={entity.id}>
				<PageLayoutContentWrapper name="entity-page">
					{entity && (
						<EntityPageNavBar
							entity={entity}
							actions={
								withActions ? (
									<EntityPageActions
										entity={entity}
										withPinnedToggle={withPinnedToggle}
										creationQuery={creationQuery}
									/>
								) : undefined
							}
							toggles={
								withSidebarToggles ? (
									<EntityPageToggles
										entity={entity}
										withDiscussions={withDiscussions}
									/>
								) : undefined
							}
						/>
					)}
					<PageLayoutContent className={classNames?.content}>
						<EntityPageTitle
							icon={icon}
							placeholder={`Untitled ${lowerCase(name)}`}
							entity={entity}
							isReadOnly={isReadOnlyTitleProp}
							onChange={handleEntityChange('title')}
						/>
						{withDescription && (
							<EntityPageDescription
								entityId={entity.id}
								description={entity?.description ?? ''}
								readOnly={isViewerUser}
								onChange={handleEntityChange('description')}
								integrationId={entity.integration}
							/>
						)}
						{children}
					</PageLayoutContent>
				</PageLayoutContentWrapper>

				<EntityPageSidebar
					entity={entity}
					updateEntity={updateEntity}
					withFrequentUsers={withFrequentUsers}
					withVerifiedSelector={withVerifiedSelector}
					withGovernanceSelector={withGovernanceSelector}
					withCollectionSelector={withCollectionSelector}
					withTagSelector={withTagSelector}
					withOwnerSelector={withOwnerSelector}
					withRelatedResourceSelector={withRelatedResourceSelector}
					withCustomPropertyEditors={withCustomPropertyEditors}
					withEntityPopularity={withEntityPopularity}
					withEntityByteSize={withEntityByteSize}
					withEntityRowCount={withEntityRowCount}
					withTeamSelector={withTeamSelector}
					withAssignedToSelector={withAssignedToSelector}
					withStatusSelector={withStatusSelector}
					withPrioritySelector={withPrioritySelector}
					withSource={withSource}
					withMetricSection={withMetricSection}
					withCustomPublishElement={withCustomPublishElement}
					withCollectionParentSelector={withParentSelector}
				/>
				<AIAssistantSidebar />
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}

export default observer(EntityPageLayout);
