import { useState } from 'react';
import { useAutomationJobList } from '../../api/hooks/automationJob';
import { AutomationJob } from '../../api/types/models/automation/automationJob';
import { TableV2 } from '../TableV2';
import { AutomationJobDrawer } from './AutomationJobDrawer';
import { useColumns } from './hooks';
interface AutomationHistoryTableProps {
	automationId: string;
}

export function AutomationHistoryTable({
	automationId,
}: AutomationHistoryTableProps) {
	const [jobDetailShown, setJobDetailShown] = useState(false);
	const [currentAutomationJob, setCurrentAutomationJob] =
		useState<AutomationJob | null>(null);
	const columns = useColumns();

	return (
		<>
			<TableV2
				onRowClick={(automationJob: AutomationJob) => {
					setJobDetailShown(true);
					setCurrentAutomationJob(automationJob);
				}}
				columns={columns}
				usePaginationList={useAutomationJobList}
				defaultRequiredSearchParams={{
					automation: automationId,
				}}
				columnVisibility={{
					catalogType: 'automation_jobs',
					catalogServerType: 'automation_jobs',
				}}
				withInteractiveHeader={false}
				withFilters={false}
			/>
			{currentAutomationJob && (
				<AutomationJobDrawer
					automationJob={currentAutomationJob}
					opened={jobDetailShown}
					onClose={() => {
						setJobDetailShown(false);
						setCurrentAutomationJob(null);
					}}
				/>
			)}
		</>
	);
}
