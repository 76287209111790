/* eslint-disable react-hooks/rules-of-hooks */
import { Divider, Input, Stack, useMantineTheme } from '@mantine/core';
import { isEmpty, map, size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import type {
	FilterMenuItem,
	FilterMenuOption,
} from '../FilterCarousel.constants';
import FilterDropdownHeader from './FilterDropdownHeader';
import FilterDropdownItem from './FilterDropdownItem';
import { filterByTerm, filterForUnselected } from './utils';

interface IFilterDropdownProps {
	filterMenuItem: FilterMenuItem;
	handleIsIncludeChange: (value: boolean) => void;
	onClose?: () => void;
}

function FilterDropdown({
	filterMenuItem,
	handleIsIncludeChange,
	onClose,
}: IFilterDropdownProps) {
	const itemContent = (index: number, option: FilterMenuOption) => (
		<FilterDropdownItem
			key={index}
			filterOption={option}
			filterMenuItem={filterMenuItem}
		/>
	);
	const { selectedOptions, options } = filterMenuItem;
	const [term, setTerm] = useState('');

	const selectedFilteredOptions = useMemo(
		() => filterByTerm(selectedOptions, term),
		[selectedOptions, term]
	);

	const unselectedFilteredOptions = useMemo(
		() => filterByTerm(filterForUnselected(options, selectedOptions), term),
		[options, selectedOptions, term]
	);

	const theme = useMantineTheme();

	return (
		<Stack spacing={0}>
			<Stack spacing={0} px={theme.spacing.xs}>
				<FilterDropdownHeader
					filterOption={filterMenuItem}
					state={filterMenuItem.isInclude}
					onIsIncludeChange={handleIsIncludeChange}
					onDeleteFilter={onClose}
					withIsNotMenu={filterMenuItem.withIsNotMenu}
				/>
				<Input
					autoFocus
					height={32}
					mb={10}
					value={term}
					placeholder={`Search ${filterMenuItem.label}`}
					onChange={(e) => setTerm(e.currentTarget.value)}
				/>
			</Stack>
			{(!isEmpty(selectedFilteredOptions) ||
				!isEmpty(unselectedFilteredOptions)) && (
				<>
					{map(selectedFilteredOptions, (option, index) => (
						<FilterDropdownItem
							key={index}
							filterOption={option}
							filterMenuItem={filterMenuItem}
						/>
					))}
					{!isEmpty(selectedFilteredOptions) &&
						!isEmpty(unselectedFilteredOptions) && <Divider my={10} />}
					{!isEmpty(unselectedFilteredOptions) && (
						<Virtuoso
							style={{
								height:
									size(unselectedFilteredOptions) > 8
										? 256
										: size(unselectedFilteredOptions) * 32,
							}}
							data={unselectedFilteredOptions}
							totalCount={size(unselectedFilteredOptions)}
							itemContent={itemContent}
						/>
					)}
				</>
			)}
		</Stack>
	);
}

export default observer(FilterDropdown);
