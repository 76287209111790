import { useDebouncedValue } from '@mantine/hooks';
import type { Filter } from '@repo/api-codegen';
import produce from 'immer';
import { useMemo } from 'react';
import type { ISecodaEntity } from '../api';
import { useCatalogList } from '../api/hooks/resourceCatalog';
import type { EntityType } from '../lib/types';

interface IUseCatalogSearchParams<T> {
	term: string;
	teamID?: string;
	types?: EntityType[];
	wait?: number;
	select?: (results: ISecodaEntity[]) => T[];
}

export default function useCatalogSearch<T = ISecodaEntity>({
	term,
	teamID,
	types,
	wait = 500,
	select,
}: IUseCatalogSearchParams<T>) {
	const [debouncedTerm] = useDebouncedValue(term, wait);

	const filters = useMemo(() => {
		const catalogFilter: {
			teams?: string;
			filter: Filter;
		} = {
			filter: {
				operator: 'and',
				operands: [],
			},
		};

		return produce(catalogFilter, (draft) => {
			if (teamID) {
				draft.teams = teamID;
			}

			if (draft.filter.operator === 'and') {
				if (!draft.filter.operands) {
					draft.filter.operands = [];
				}

				if (debouncedTerm) {
					draft.filter.operands.push({
						operands: [],
						operator: 'contains',
						field: 'title',
						value: debouncedTerm,
					});
				} else {
					draft.filter.operands = draft.filter.operands.filter(
						(operand) => operand.operator !== 'contains'
					);
				}

				if (types) {
					draft.filter.operands.push({
						operands: [],
						operator: 'in',
						field: 'entity_type',
						value: types,
					});
				}
			}
		});
	}, [debouncedTerm, teamID, types]);

	const { data, isLoading } = useCatalogList({
		filters,
		options: {
			select: ({ results }) => (select ? select(results) : (results as T[])),
		},
	});

	return {
		data,
		isLoading,
	};
}
