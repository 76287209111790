import { Group, Stack, useMantineTheme } from '@mantine/core';
import { Text } from '@repo/foundations';

interface IInputWrapperProps {
	label: string;
	isOptional?: boolean;
	isLabelHidden?: boolean;
	isChildrenHidden?: boolean;
	children: React.ReactNode;
}

export default function FormInputWrapper({
	label,
	isOptional = false,
	isLabelHidden = false,
	isChildrenHidden = false,
	children,
}: IInputWrapperProps) {
	const theme = useMantineTheme();

	if (isLabelHidden && isChildrenHidden) {
		return null;
	}

	return (
		<Stack spacing={theme.spacing['3xs']}>
			{!isLabelHidden && (
				<Group spacing={theme.spacing['3xs']}>
					<Text size="sm" weight="semibold">
						{label}
					</Text>
					{isOptional && (
						<Text size="sm" weight="semibold" color="text/secondary/default">
							(Optional)
						</Text>
					)}
				</Group>
			)}
			{!isChildrenHidden && children}
		</Stack>
	);
}
