import { SelectItem, Stack } from '@mantine/core';
import { Title, Text, MultiSelect } from '@repo/foundations';
import { IUser, IUserGroup, useAuthUser, useUserGroupList } from '../../../../api';
import { useExtendedUserList } from '../../../../api/hooks/user/useExtendedUserList';
import { useMemo, useState } from 'react';
import { getDisplayName } from '../../../../utils/userUtils';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { UserAvatar } from '../../../UserAvatar';

type UserItem = SelectItem & {
	group: 'Users';
	user: IUser;
};

type GroupItem = SelectItem & {
	group: 'User Groups';
	userGroup: IUserGroup;
};


type UserOrGroupItem = UserItem | GroupItem;

function isUserItem(item: UserOrGroupItem): item is UserItem {
	return item.group === 'Users';
}


function ManageStack() {
	const { user: currentUser } = useAuthUser();
	const { activeUsers } = useExtendedUserList({});
	const { data: userGroups } = useUserGroupList({
		options: {
			select: (data) => data.results,
		}
	});

	const [owners, setOwners] = useState<string[]>([`user:${currentUser.id}`]);
	const [subscribers, setSubscribers] = useState<string[]>([]);
	const [extraSubscribers, setExtraSubscribers] = useState<UserOrGroupItem[]>([]);

	const ownersData = useMemo(() => {
		const userItems: UserOrGroupItem[] = activeUsers?.map((u) => ({
			value: `user:${u.id}`,
			label: getDisplayName(u),
			group: 'Users',
			user: u,
		})) || [];

		const groupItems: UserOrGroupItem[] = userGroups?.map((g) => ({
			value: `userGroup:${g.id}`,
			label: g.name,
			group: 'User Groups',
			userGroup: g,
		})) || [];

		return [...userItems, ...groupItems];
	}, [activeUsers, userGroups]);

	const subscribersData = useMemo(() => {
		const userItems: UserOrGroupItem[] = activeUsers?.map((u) => ({
			value: `user:${u.id}`,
			label: getDisplayName(u),
			group: 'Users',
			user: u,
		})) || [];

		const groupItems: UserOrGroupItem[] = userGroups?.map((g) => ({
			value: `userGroup:${g.id}`,
			label: g.name,
			group: 'User Groups',
			userGroup: g,
		})) || [];

		return [...userItems, ...groupItems, ...extraSubscribers];
	}, [activeUsers, userGroups, extraSubscribers]);

	return (
		<Stack p={0} spacing="md">
			<Title size="md">
				Manage
			</Title>
			<Stack p={0} spacing="sm">
				<MultiSelect<UserOrGroupItem>
					label="Owners"
					data={ownersData}
					value={owners}
					setValue={setOwners}
					renderIcon={(item: UserOrGroupItem) =>
						isUserItem(item) ? (
						<UserAvatar user={item.user} size="xs" />
						) : (
							<IconWrapper>
								<Text size="sm">{item.userGroup.icon}</Text>
							</IconWrapper>
						)}
					renderLabel={(item: UserOrGroupItem) => item.label}
					optional={false}
				/>
				<MultiSelect<UserOrGroupItem>
					label="Subscribers"
					data={subscribersData}
					value={subscribers}
					setValue={setSubscribers}
					renderIcon={(item: UserOrGroupItem) =>
						isUserItem(item) ? (
						<UserAvatar user={item.user} size="xs" />
						) : (
							<IconWrapper>
								<Text size="sm">{item.userGroup.icon}</Text>
							</IconWrapper>
						)}
					renderLabel={(item: UserOrGroupItem) => item.label}
					optional
					creatable
					getCreateLabel={(query) => subscribersData?.filter((value) => value.label?.includes(query)).length > 0 ? '' : `Add "${query}"`}
					onCreate={(query) => {
						// TODO[tan]: Hook this up with the proper API call to make "email" subscibers
						const item = { value: query, label: query, group: 'Users', user: { id: query, email: query, name: query } } as unknown as UserItem;
						setSubscribers([...subscribers, item.value]);
						setExtraSubscribers([...extraSubscribers, item]);
						return item;
					}}
				/>
			</Stack>
		</Stack>
	)
}

export default ManageStack;
