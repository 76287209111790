import {
	ActionIcon,
	Box,
	createStyles,
	Group,
	Stack,
	Tooltip,
} from '@mantine/core';
import { useDisclosure, useScrollIntoView } from '@mantine/hooks';
import { AvatarSkeleton, Icon, TextSkeleton } from '@repo/foundations';
import { isEqual } from 'lodash-es';
import type { RefObject } from 'react';
import { memo, useEffect } from 'react';
import CopyButton from '../../../../../../packages/foundations/components/Buttons/CopyButton';
import { useUser, type IEmbeddedPrompt } from '../../../api';
import { useAIFeedback } from '../../../api/hooks/ai/useAIFeedback';
import { useStreamingContent } from '../../../hooks/useStreamingContent';
import { AIAvatar } from '../../AIAvatar';
import { RichEditor } from '../../RichEditor';
import { UserAvatar } from '../../UserAvatar';
import { sanitizePromptMessage } from '../utils';
import { AISteps } from './AISteps';
import { MessageAIError } from './MessageAIError';
import { MessageAILoading } from './MessageAILoading';
import { MessageBody } from './MessageBody';

const useStyles = createStyles((theme) => ({
	markdown: {
		color: theme.other.getColor('text/primary/default'),
		fontSize: theme.fontSizes.md,
		fontWeight: theme.other.typography.weight.regular,
		lineHeight: theme.other.typography.lineHeight.text.md,
	},
	richEditorContainer: {
		'.markdown-body': {
			color: theme.other.getColor('text/primary/default'),
			fontSize: theme.fontSizes.md,
			fontWeight: theme.other.typography.weight.bold,
			lineHeight: theme.other.typography.lineHeight.text.md,
		},
	},
	toolbar: {
		marginLeft: `-${theme.spacing['3xs']}`,
	},
}));

type MessageGroupProps = {
	message: IEmbeddedPrompt;
	scrollAreaRef?: RefObject<HTMLDivElement>;
	onRetry?: () => void;
};

function MessageGroupInternal({
	message,
	scrollAreaRef,
	onRetry,
}: MessageGroupProps) {
	const { data: user } = useUser({ id: message.user_id || '' });
	const { classes } = useStyles();

	const { showPositiveFeedbackModal, showNegativeFeedbackModal } =
		useAIFeedback({
			id: message.id,
		});
	const { targetRef, scrollableRef, scrollIntoView } = useScrollIntoView<
		HTMLDivElement,
		HTMLDivElement
	>({ duration: 200 });

	useEffect(() => {
		if (scrollAreaRef?.current) {
			scrollableRef.current = scrollAreaRef.current;
		}
	}, [scrollAreaRef, scrollableRef]);

	const [showSteps, { toggle: toggleSteps }] = useDisclosure(false);

	useEffect(() => {
		if (showSteps) {
			scrollIntoView({ alignment: 'end' });
		}
	}, [showSteps, scrollIntoView]);

	const messageResponse = useStreamingContent(
		message.status === 'running',
		message.response?.content ?? ''
	);
	const shouldDisplayLoading =
		message.status === 'pending' ||
		(message.status === 'running' && messageResponse.length === 0);
	const shouldDisplayFailure = message.status === 'failed';
	const shouldDisplayAnswerToolbar = message.status === 'completed';
	const hasSteps = (message.response?.steps?.length ?? 0) > 0;

	return (
		<Stack spacing="xl" pb="xl">
			<MessageBody
				avatar={
					user ? (
						<UserAvatar user={user} size="md" />
					) : (
						<AvatarSkeleton size="md" />
					)
				}
				author={
					user ? user.display_name : <TextSkeleton width={200} size="md" />
				}
			>
				<RichEditor readOnly value={sanitizePromptMessage(message.prompt)} />
			</MessageBody>
			<MessageBody
				avatar={
					<AIAvatar size="md" speed={shouldDisplayLoading ? 'slow' : 'none'} />
				}
				author={message.persona?.name || 'Secoda AI'}
			>
				{shouldDisplayLoading && (
					<MessageAILoading steps={message.response?.steps ?? []} />
				)}
				{shouldDisplayFailure && <MessageAIError onRetry={onRetry} />}
				{!shouldDisplayLoading && !shouldDisplayFailure && (
					<Box className={classes.richEditorContainer}>
						<RichEditor readOnly value={messageResponse} />
					</Box>
				)}
				{shouldDisplayAnswerToolbar && (
					<Group spacing={0} className={classes.toolbar}>
						<CopyButton value={message.response?.content || ''} color="gray" />
						<Group spacing={0} noWrap>
							<Tooltip label="Good response">
								<ActionIcon size="xs" onClick={showPositiveFeedbackModal}>
									<Icon name="thumbUp" />
								</ActionIcon>
							</Tooltip>
							<Tooltip label="Bad response">
								<ActionIcon size="xs" onClick={showNegativeFeedbackModal}>
									<Icon name="thumbDown" />
								</ActionIcon>
							</Tooltip>
						</Group>
						{hasSteps && (
							<Tooltip label="Show steps">
								<ActionIcon
									size="xs"
									onClick={toggleSteps}
									variant={showSteps ? 'filled' : 'subtle'}
								>
									<Icon name="listSearch" />
								</ActionIcon>
							</Tooltip>
						)}
					</Group>
				)}
				{showSteps && <AISteps ref={targetRef} prompt={message} />}
			</MessageBody>
		</Stack>
	);
}

export const MessageGroup = memo(MessageGroupInternal, (prevProps, nextProps) =>
	isEqual(prevProps.message, nextProps.message)
);
