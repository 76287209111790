import { Breadcrumbs, Skeleton, useMantineTheme } from '@mantine/core';

interface BreadcrumbsSkeletonProps {}

export function BreadcrumbsSkeleton({}: BreadcrumbsSkeletonProps) {
	const theme = useMantineTheme();

	return (
		<Breadcrumbs>
			<Skeleton width={theme.other.space[20]} height={theme.other.space[5]} />
			<Skeleton width={theme.other.space[20]} height={theme.other.space[5]} />
		</Breadcrumbs>
	);
}
