import {
	Box,
	createStyles,
	Divider,
	Drawer,
	Group,
	Image,
	Input,
	Stack,
	Textarea,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { queryKeyFn, useUpdatePersona } from '@repo/api-codegen';
import { Button, Text } from '@repo/foundations';
import { useDebounceFn } from 'ahooks';
import React, { useCallback, useState } from 'react';
import { queryClient } from '../../../api';
import { ImageUpload } from '../../ImageUpload/ImageUpload';
import { MultiTeamsSelector } from '../../MultiTeamsSelector/MultiTeamsSelector.tsx';
import { AIPersonasFilters } from './AIPersonasFilters';
import { useAIPersonaImage } from './hooks';

interface AIPersonaCardProps {
	id?: string | undefined | null;
	name?: string | undefined | null;
	description?: string | undefined | null;
	avatarURL?: string | undefined | null;
	teams?: string[] | undefined | null;
	customInstructions?: string | undefined | null;
	onDelete: (id: string) => void;
}

const useStyles = createStyles((theme) => ({
	card: {
		padding: theme.spacing.md,
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		background: theme.other.getColor('surface/primary/default'),
		borderRadius: theme.radius.md,
		gap: theme.spacing.md,
		textAlign: 'left',
		cursor: 'pointer',

		'&:hover': {
			background: theme.other.getColor('surface/primary/hover'),
		},

		['&:active']: {
			background: theme.other.getColor('surface/primary/active'),
		},
	},
}));

export function AIPersonaCard({
	id,
	name,
	description,
	avatarURL,
	teams,
	customInstructions,
	onDelete,
}: AIPersonaCardProps) {
	const { classes } = useStyles();

	const [isOpen, { open, close }] = useDisclosure();
	const [personaName, setPersonaName] = useState(name || '');
	const [personaDescription, setPersonaDescription] = useState(
		description || ''
	);
	const [personaCustomInstructions, setPersonaCustomInstructions] = useState(
		customInstructions || ''
	);
	const [personaAvatarUrl, setPersonaAvatarUrl] = useState(avatarURL || '');
	const [personaTeams, setPersonaTeams] = useState<string[]>(teams || []);

	const { mutateAsync: updatePersona } = useUpdatePersona({
		onSuccess: () => {
			queryClient.invalidateQueries(
				queryKeyFn({
					path: '/ai/personas/',
					operationId: 'getPersonas',
					variables: {}, // Ensure these match what you pass to useGetPersonas
				})
			);
		},
	});
	const { mutateAsync: uploadImage } = useAIPersonaImage();

	const handleUpdatePersonaDebounced = useDebounceFn(
		() => {
			updatePersona({
				body: {
					name: personaName,
					description: personaDescription,
					custom_instructions: personaCustomInstructions,
					teams: personaTeams,
				},
				pathParams: {
					personaId: id || '',
				},
			});
		},
		{ wait: 500 }
	);

	const onNameChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setPersonaName(event.target.value);
			handleUpdatePersonaDebounced.run();
		},
		[handleUpdatePersonaDebounced]
	);

	const onDescriptionChange = useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement>) => {
			setPersonaDescription(event.target.value);
			handleUpdatePersonaDebounced.run();
		},
		[handleUpdatePersonaDebounced]
	);

	const onCustomInstructionsChange = useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement>) => {
			setPersonaCustomInstructions(event.target.value);
			handleUpdatePersonaDebounced.run();
		},
		[handleUpdatePersonaDebounced]
	);

	const onUpload = useCallback(
		async (file: File) => {
			const resp = await uploadImage({
				file: file,
				aiPersonaId: id || '',
			});
			setPersonaAvatarUrl((resp.avatar_url as unknown as string) || '');
		},
		[id, uploadImage]
	);

	const onTeamsChange = useCallback(
		(teams: string[]) => {
			setPersonaTeams(teams);
			handleUpdatePersonaDebounced.run();
		},
		[handleUpdatePersonaDebounced]
	);

	return (
		<>
			<Drawer
				position="right"
				opened={isOpen}
				onClose={close}
				title={`Edit ${personaName || 'New Persona'}`}
				size="xl"
			>
				<Stack spacing="sm">
					<ImageUpload
						callback={onUpload}
						label="Icon"
						renderAvatar
						placeholder={personaAvatarUrl || '/images/logos/cfsecoda.webp'}
					/>
					<Input
						name="Name"
						placeholder="Name your Persona"
						value={personaName}
						onChange={onNameChange}
					/>
					<Textarea
						name="Description"
						onChange={onDescriptionChange}
						placeholder="Add a short description of what your Persona does"
						value={personaDescription}
						minRows={3}
						autosize
					/>

					<Divider />
					<Stack spacing={0}>
						<Text weight="semibold" size="md">
							Custom instructions
						</Text>
						<Text size="sm" color="text/secondary/default">
							Provide custom instructions for the AI to follow when using this
							persona. These will be appended to the global instructions.
						</Text>
					</Stack>
					<Textarea
						name="Custom instructions"
						onChange={onCustomInstructionsChange}
						placeholder="Custom instructions"
						value={personaCustomInstructions}
						minRows={3}
						autosize
					/>

					<Divider />
					<AIPersonasFilters personaId={id || ''} />
					<Divider />

					<Stack spacing={0}>
						<Text weight="semibold" size="md">
							Teams
						</Text>
						<Text size="sm" color="text/secondary/default">
							Only members of these teams will be able to use this persona
							(except for Admins who can use all personas).
						</Text>
					</Stack>
					<MultiTeamsSelector value={personaTeams} setValue={onTeamsChange} />
					<Divider />

					<Text weight="semibold" size="md">
						Delete
					</Text>
					<Text size="sm" color="text/secondary/default">
						Deleting a persona is irreversible. This action will remove the
						persona and all associated data.
					</Text>
					<Group>
						<Button
							onClick={() => onDelete(id || '')}
							variant="primary"
							tone="critical"
						>
							Delete Persona
						</Button>
					</Group>
				</Stack>
			</Drawer>
			<Box component="button" className={classes.card} onClick={open}>
				<Group noWrap align="flex-start" spacing="xs">
					<Image
						src={personaAvatarUrl || '/images/logos/cfsecoda.webp'}
						width={24}
						height={24}
						radius="xs"
					/>
					<Stack spacing="xs">
						<Text size="md" weight="semibold">
							{personaName || 'New Persona'}
						</Text>
						<Text size="sm">{personaDescription}</Text>
					</Stack>
				</Group>
			</Box>
		</>
	);
}
