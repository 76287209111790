import { Stack } from '@mantine/core';
import { clone, isNil, map, size } from 'lodash-es';
import type { FormFieldValue } from '../../../../../api';
import FormFieldSelectOption from './FormFieldSelectOption';

interface IFormFieldSelectOptionsProps {
	options?: FormFieldValue[];
	createOption: () => void;
	updateOptions: (options: FormFieldValue[]) => void;
}

export default function FormFieldSelectOptions({
	options,
	createOption,
	updateOptions,
}: IFormFieldSelectOptionsProps) {
	if (isNil(options)) {
		createOption();
	}

	const handleUpdateOption = (index: number, value: string) => {
		if (isNil(options) || index < 0 || index >= options.length) return;

		const newOptions = clone(options);
		newOptions[index].value = value;
		updateOptions(newOptions);
	};

	const handleMoveOption = (index: number, direction: 'UP' | 'DOWN') => {
		if (isNil(options) || index < 0 || index >= options.length) return;

		const newOptions = clone(options);
		const newIndex = direction === 'UP' ? index - 1 : index + 1;

		if (newIndex >= 0 && newIndex < newOptions.length) {
			[newOptions[index], newOptions[newIndex]] = [
				newOptions[newIndex],
				newOptions[index],
			];
			updateOptions(newOptions);
		}
	};

	const handleDeleteOption = (index: number) => {
		if (isNil(options) || index < 0 || index >= options.length) return;

		const newOptions = clone(options);
		newOptions.splice(index, 1);
		updateOptions(newOptions);
	};

	return (
		<Stack spacing="sm">
			{map(options, (option, index) => (
				<FormFieldSelectOption
					key={index}
					index={index}
					option={option}
					numOptions={size(options)}
					updateOption={handleUpdateOption}
					moveOption={handleMoveOption}
					deleteOption={handleDeleteOption}
				/>
			))}
		</Stack>
	);
}
