import type { MantineTheme } from '@mantine/core';
import {
	CopyButton,
	createStyles,
	Group,
	Textarea,
	Tooltip,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { Badge, Text } from '@repo/foundations';
import { typography } from '@repo/theme/primitives';
import { getColor } from '@repo/theme/utils';
import { truncate } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type { ChangeEvent } from 'react';
import { ISecodaEntity, useAuthUser } from '../../../api';
import type { SecodaEntity } from '../../../lib/models';
import { getEntityDisplayTitle } from '../../../lib/utils/entity';
import { isViewerOfEntity } from '../../../utils/authorization/roles';

export interface IEntityPageTitleProps {
	placeholder: string;
	icon: React.ReactNode;
	entity:
		| SecodaEntity
		| ISecodaEntity
		| { title: string; title_cased?: string };
	isReadOnly: boolean;
	onChange: (title: string) => void;
	shortenTitle?: boolean;
	size?: keyof typeof typography.title;
}

const useStyles = createStyles(
	(theme: MantineTheme, size: keyof typeof typography.title) => ({
		wrapper: {
			display: 'flex',
			width: '100%',
			cursor: 'pointer',
		},
		textAreaRoot: {
			display: 'flex',
			flexGrow: '1 !important' as any,
		},
		textAreaWrapper: {
			width: '100%',
		},
		textAreaInput: {
			fontSize: typography.text[size],
			fontWeight: typography.weight.bold,
			overflowY: 'hidden',

			'&:focus': {
				boxShadow: 'none',
				borderColor: 'transparent',
				backgroundColor: 'transparent',
			},

			'&:disabled': {
				opacity: 1,
				backgroundColor: getColor('fill/transparent/default'),
				color: getColor('text/primary/default'),
				cursor: 'text',
			},
		},
	})
);

function EntityPageTitle({
	placeholder = 'Untitled dictionary term',
	icon,
	entity,
	isReadOnly,
	onChange,
	shortenTitle = false,
	size = 'xxl',
}: IEntityPageTitleProps) {
	const { classes } = useStyles(size);
	const { user, isViewerOrGuestUser } = useAuthUser();
	const readOnlyUser =
		'id' in entity && entity.id
			? isViewerOfEntity(user, entity)
			: isViewerOrGuestUser;

	const [title, setTitle] = useInputState(entity.title);

	const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = event.currentTarget;
		setTitle(value);
		onChange(value);
	};

	let casedTitle = getEntityDisplayTitle(entity);
	if (shortenTitle) {
		casedTitle = truncate(casedTitle, { length: 20 });
	}

	const copiedText = `"${casedTitle}" copied to clipboard`;

	return (
		<CopyButton value={casedTitle} timeout={2000}>
			{({ copied, copy }) => (
				<Tooltip
					label={<Text size="xs">{copied ? copiedText : casedTitle}</Text>}
					disabled={!isReadOnly}
					openDelay={1000}
					position="top-start"
					offset={-10}
				>
					<Group
						className={classes.wrapper}
						spacing={8}
						onClick={copy}
						align="baseline"
					>
						{icon}
						<Textarea
							classNames={{
								root: classes.textAreaRoot,
								wrapper: classes.textAreaWrapper,
								input: classes.textAreaInput,
							}}
							data-testid="input-title"
							value={isReadOnly ? casedTitle : title}
							onChange={handleChange}
							placeholder={placeholder}
							disabled={isReadOnly}
							variant="unstyled"
							autosize
						/>
						{readOnlyUser && <Badge variant="default">Read Only</Badge>}
					</Group>
				</Tooltip>
			)}
		</CopyButton>
	);
}

export default observer(EntityPageTitle);
