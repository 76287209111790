import { EntityType } from '../../../lib/types';

export const ENTITY_PAGE_PATHS = [
	'/database',
	'/schema',
	'/table',
	'/column',
	'/dashboard',
	'/chart',
	'/event',
	'/job',
	'/collections',
	'/metrics',
	'/dictionary',
	'/docs',
	'/questions',
	'/monitor',
	'/user',
	'/glossary',
];

export const TEAM_HOME_PAGE_PATTERN =
	/^\/teams\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}(\/)?$/;

export const TEAM_CATALOG_PAGE_PATHS = [
	{ name: 'Catalog', path: 'catalog', pattern: /^\/teams\/[^/]+\/catalog$/ },
	{
		name: 'Collections',
		path: 'collections',
		pattern: /^\/teams\/[^/]+\/collections$/,
		entityType: EntityType.collection,
	},
	{
		name: 'Metrics',
		path: 'metrics',
		pattern: /^\/teams\/[^/]+\/metrics$/,
		entityType: EntityType.metric,
	},
	{
		name: 'Dictionary Terms',
		path: 'dictionary',
		pattern: /^\/teams\/[^/]+\/dictionary$/,
		entityType: EntityType.dictionary_term,
	},
	{
		name: 'Documents',
		path: 'docs',
		pattern: /^\/teams\/[^/]+\/docs$/,
		entityType: EntityType.document,
	},
	{
		name: 'Glossary',
		path: 'glossary',
		pattern: /^\/teams\/[^/]+\/glossary$/,
		entityType: EntityType.glossary_term,
	},
	{
		name: 'Questions',
		path: 'questions',
		pattern: /^\/teams\/[^/]+\/questions$/,
		entityType: EntityType.question,
	},
];

export const ANALYTICS_PAGE_PATTERN =
	/^\/analytics\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}(\/)?$/;
