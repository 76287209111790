import { Center, Stack } from '@mantine/core';
import { Button } from '@repo/foundations';
import { map, size } from 'lodash-es';
import type { IForm, IFormField } from '../../../../api';
import {
	useCreateFormField,
	useDeleteFormField,
	useUpdateFormField,
	useUpdateFormOrder,
} from '../../../../api/hooks/forms';
import { FormQuestion } from './FormQuestion/FormQuestion';

interface IQuestionsTabProps {
	form: IForm;
}

export default function QuestionsTab({ form }: IQuestionsTabProps) {
	const { fields } = form;

	const { mutate: createFormField } = useCreateFormField();
	const { mutate: updateFormField } = useUpdateFormField();
	const { mutate: deleteFormField } = useDeleteFormField();
	const { mutate: updateFormOrder } = useUpdateFormOrder();

	const handleCreateField = () => {
		createFormField({
			formId: form.id as string,
			field: {
				input_type: 'text',
			},
		});
	};

	const handleUpdateField = (field: IFormField) => {
		updateFormField({
			formId: form.id as string,
			field,
		});
	};

	const handleMoveField = (fieldID: string, direction: 'UP' | 'DOWN') => {
		const fieldIds = map(fields, 'id');
		const index = fieldIds.indexOf(fieldID);

		if (index < 0) return;
		if (direction === 'UP' && index === 0) return;
		if (direction === 'DOWN' && index === size(fieldIds) - 1) return;

		const newIndex = direction === 'UP' ? index - 1 : index + 1;

		const [removedFieldID] = fieldIds.splice(index, 1);
		fieldIds.splice(newIndex, 0, removedFieldID);

		updateFormOrder({
			formId: form.id as string,
			order: fieldIds as string[],
		});
	};

	const handleDeleteField = (fieldID: string) => {
		deleteFormField({
			formId: form.id as string,
			fieldId: fieldID,
		});
	};

	return (
		<Stack>
			{map(fields, (field: IFormField) => (
				<FormQuestion
					field={field}
					numFields={size(fields)}
					handleUpdateField={handleUpdateField}
					handleMoveField={handleMoveField}
					handleDeleteField={handleDeleteField}
				/>
			))}
			<Center>
				<Button w="fit-content" leftIconName="plus" onClick={handleCreateField}>
					Add field
				</Button>
			</Center>
		</Stack>
	);
}
