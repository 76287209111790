import { Divider, Group, GroupProps } from '@mantine/core';
import {
	OPERATORS_CONFIG,
	SORT_OPTIONS,
} from '@repo/common/components/Filter/constants';
import { TopLevelOperatorToggle } from '@repo/common/components/Filter/TopLevelOperatorToggle';
import type { FilterOptionType } from '@repo/common/components/Filter/types';
import { SortMenu } from '@repo/common/components/SortMenu/SortMenu';
import { Button } from '@repo/foundations';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { AddFilter, Filter } from '../../../../components/Filter';

import { FILTER_OPTIONS_CONFIG } from '../../../../components/Filter/constants';
import { FilterViews } from '../../../../components/Filter/Views/FilterViews';
import { useFilterBar } from './useFilterBar';

export interface FilterBarProps {
	fixedFilterOptions?: FilterOptionType[];
	showSort?: boolean;
	showFilterViews?: boolean;
	onOpenFilterViewModal?: () => void;
	showTopLevelOperator?: boolean;
	py?: GroupProps['py'];
}

export const FilterBar = observer(
	({
		fixedFilterOptions,
		showSort = true,
		showFilterViews,
		showTopLevelOperator,
		onOpenFilterViewModal,
		py = 'md',
	}: FilterBarProps) => {
		const { store, values } = useFilterBar({ fixedFilterOptions });

		return (
			<Group position="apart" spacing="md" noWrap align="baseline">
				<Group spacing="xs" py={py}>
					{showSort && (
						<SortMenu
							onChange={store.setSort}
							options={Object.values(SORT_OPTIONS)}
							value={store.sort}
						/>
					)}
					{showFilterViews && (
						<>
							<FilterViews onChange={store.setFilterView} value={store.view} />
							{onOpenFilterViewModal && (
								<Button onClick={onOpenFilterViewModal} size="sm">
									Save view
								</Button>
							)}
							<Divider mx="2xs" orientation="vertical" />
						</>
					)}
					{values.map((value, idx) => (
						<Filter
							// eslint-disable-next-line react/no-array-index-key
							key={`filter-${idx}}`}
							value={toJS(value)}
							filterOption={FILTER_OPTIONS_CONFIG[value.filterType]}
							onChange={store.onChangeValue(idx)}
							onClear={store.onClearValue(idx)}
							showDetailedLabel
							operatorConfig={
								OPERATORS_CONFIG[
									FILTER_OPTIONS_CONFIG[value.filterType].filterDropdownConfig
										.dropdownType
								]
							}
						/>
					))}
					<AddFilter
						options={toJS(store.filterOptions)}
						onAddFilter={store.onAddValue}
					/>
				</Group>
				{showTopLevelOperator && (
					<TopLevelOperatorToggle
						value={store.topLevelOperator}
						onChange={store.setTopLevelOperator}
					/>
				)}
			</Group>
		);
	}
);
