import { createStyles, Loader, Tabs } from '@mantine/core';

import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';
import {
	IQuery,
	type ISecodaEntity,
	useAuthUser,
	useIntegration,
	useIntegrationSpec,
} from '../../../api';
import Documentation from '../../../components/Documentation';
import EntityDiscussions from '../../../components/EntityDiscussions';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { LineageGraphWrapper } from '../../../components/LineageGraph/LineageGraph';
import UrlPreview from '../../../components/Preview/UrlPreview';
import { QueryList } from '../../../components/QueryList/QueryList';
import type { TabItem } from '../../../components/TabsList';
import TabsList from '../../../components/TabsList';
import { trackEvent } from '../../../utils/analytics';
import { useTabNavigate } from '../../../utils/hook/useTabNavigate';
import { withParanthesis } from '../../../utils/shared.utils';
import ChartsTab from './ChartsTab';
import type { DashboardEntityTabsStore } from './DashboardEntityTabsStore';

const useStyles = createStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	tab: {
		paddingLeft: theme.spacing.sm,
		paddingRight: theme.spacing.sm,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

export interface IDashboardEntityTabsProps {
	dashboard: ISecodaEntity;
	dashboardEntityTabsStore: DashboardEntityTabsStore;
	tab?: string;
}

function TabLoader() {
	return <Loader size={12} />;
}

function DashboardEntityTabs({
	dashboard,
	dashboardEntityTabsStore: store,
	tab,
}: IDashboardEntityTabsProps) {
	const { classes } = useStyles();
	const { user, workspace } = useAuthUser();

	const isLineageDisabled = store.isLineageLoading;

	const discussionsCount = withParanthesis(store.discussionCount);

	const queriesActive = (store.queryCount ?? 0) > 0;
	const showQueriesTab = store.isQueryLoading || queriesActive;

	const { data: integration } = useIntegration({
		id: dashboard.integration ?? '',
	});
	const integrationSpec = useIntegrationSpec(dashboard.integration ?? '');

	const showPreviewTab =
		integrationSpec &&
		integrationSpec.type === 'builtin' &&
		integrationSpec.value.previewSupport;

	const isPreviewDisabled = false;

	const documentationFirst = (dashboard.definition?.length || 0) > 2;

	const getDefaultTab = () => {
		if (showPreviewTab) {
			return 'preview';
		}
		if (documentationFirst) {
			return 'documentation';
		}
		return 'lineage';
	};

	const defaultTab = getDefaultTab();
	const currentTab = tab ?? defaultTab;

	const navigateToTab = useTabNavigate();

	const handleTabChange = (updatedTab: string) => {
		if (updatedTab === 'lineage') {
			trackEvent('dashboard/lineage/open', {}, user, workspace);
		}
		navigateToTab(updatedTab);
	};

	const tabsList: TabItem[] = [
		{
			value: 'preview',
			label: 'Preview',
			disabled: isPreviewDisabled,
			hidden: !showPreviewTab,
		},
		{
			value: 'lineage',
			label: 'Lineage',
			icon: store.isLineageLoading && <TabLoader />,
			disabled: isLineageDisabled,
		},
		{
			value: 'charts',
			label: 'Charts',
		},
		{
			value: 'queries',
			label: 'Queries',
			icon: store.isQueryLoading && <TabLoader />,
			hidden: !showQueriesTab,
		},
		{
			value: 'discussions',
			label: `Questions ${discussionsCount}`,
			icon: store.isDiscussionLoading && <TabLoader />,
		},
		{
			value: 'documentation',
			label: 'Documentation',
		},
	];

	return (
		<Tabs
			color="dark"
			classNames={{
				root: classes.root,
				tab: classes.tab,
				panel: classes.panel,
			}}
			value={currentTab}
			onTabChange={handleTabChange}
		>
			<TabsList tabs={tabsList} />

			{showPreviewTab && (
				<Tabs.Panel value="preview" pt="sm">
					<ErrorBoundary>
						<Suspense fallback={<Loader />}>
							<UrlPreview
								integration={integration?.type as string}
								entity={dashboard}
							/>
						</Suspense>
					</ErrorBoundary>
				</Tabs.Panel>
			)}

			<Tabs.Panel value="lineage" pt="sm">
				<LineageGraphWrapper
					id={dashboard.id}
					type={dashboard.native_type}
					store={store}
				/>
			</Tabs.Panel>

			<Tabs.Panel value="charts" pt="sm">
				<ChartsTab id={dashboard.id} store={store} />
			</Tabs.Panel>

			<Tabs.Panel value="queries" pt="sm">
				<ErrorBoundary onError={() => store.setQueryCount(0)}>
					<Suspense fallback={<Loader />}>
						<QueryList
							entity={
								dashboard as unknown as ISecodaEntity & {
									creation_query?: IQuery;
								}
							}
							store={store}
						/>
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="discussions" pt="sm">
				<ErrorBoundary onError={() => store.setDiscussionCount(0)}>
					<Suspense fallback={<Loader />}>
						<EntityDiscussions entity={dashboard} store={store} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="documentation" pt="sm">
				<Documentation entity={dashboard} />
			</Tabs.Panel>
		</Tabs>
	);
}

export default observer(DashboardEntityTabs);
