import { Stack } from '@mantine/core';

import { sortBy } from 'lodash-es';
import { Helmet } from 'react-helmet';
import { Navigate, useParams } from 'react-router';
import type { IForm } from '../../api';
import { useAuthUser } from '../../api';
import { useForm } from '../../api/hooks/forms';

import { FormPageNavBar } from '../../components/Forms';
import { FormStoreProvider } from '../../components/Forms/context';
import { FormPageBody } from '../../components/Forms/FormPageBody';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';

export interface FormPageProps {
	id?: string;
}

function FormPage({ id: propsId }: FormPageProps) {
	const { id: paramsID, tab } = useParams();
	const id = propsId || paramsID;

	const { workspace } = useAuthUser();

	const { data: form, isLoading } = useForm(id || '', {
		enabled: workspace.forms_enabled,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		select: (data: IForm) => ({
			...data,
			fields: sortBy(data.fields, 'order'),
		}),
	});

	if (!workspace.forms_enabled) {
		return <Navigate to="/" />;
	}

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	return (
		<FormStoreProvider>
			<Helmet>
				<title>{form?.title ?? 'Form'}</title>
			</Helmet>
			<Stack spacing={0}>
				<FormPageNavBar form={form} />
				<FormPageBody form={form} tab={tab} />
			</Stack>
		</FormStoreProvider>
	);
}

export default FormPage;
