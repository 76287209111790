import { useDebouncedValue } from '@mantine/hooks';
import {
	IS_NOT_SET_FILTER_ITEM,
	IS_SET_FILTER_ITEM,
} from '@repo/common/components/Filter/constants';
import type {
	FilterDropdownConfigList,
	FilterItem,
	FilterValue,
} from '@repo/common/components/Filter/types';
import { useMemo, useState } from 'react';
import { useFilterItems } from '../useFilterItems';
import { getValueAsArray } from '../utils';

interface UseFilterDropdownListProps {
	getItems: FilterDropdownConfigList['getItems'];
	getItemsById?: FilterDropdownConfigList['getItemsById'];
	value?: FilterValue;
	hasIsNotSetOption?: boolean;
	hasIsSetOption?: boolean;
}

export function useFilterDropdownList({
	getItems,
	getItemsById,
	value,
	hasIsNotSetOption,
	hasIsSetOption,
}: UseFilterDropdownListProps) {
	const [searchTerm, setSearchTerm] = useState('');
	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200);
	const { error, loading, items } = useFilterItems({
		getItems,
		searchTerm: debouncedSearchTerm,
		getItemsById,
		value,
	});
	const [debouncedLoading] = useDebouncedValue(loading, 300);

	const { selected, unselected } = useMemo(() => {
		const values = value ? getValueAsArray(value) : [];
		const { selectedItems, unselectedItems } = items.reduce<{
			selectedItems: FilterItem[];
			unselectedItems: FilterItem[];
		}>(
			(acc, item) => {
				if (values.includes(item.value)) {
					acc.selectedItems.push(item);
				} else {
					acc.unselectedItems.push(item);
				}
				return acc;
			},
			{ selectedItems: [], unselectedItems: [] }
		);

		return {
			selected: [
				...(hasIsSetOption && value?.isSetApplied ? [IS_SET_FILTER_ITEM] : []),
				...(hasIsNotSetOption && value?.isNotSetApplied
					? [IS_NOT_SET_FILTER_ITEM]
					: []),
				...selectedItems,
			],
			unselected: [
				...(hasIsSetOption && !value?.isSetApplied ? [IS_SET_FILTER_ITEM] : []),
				...(hasIsNotSetOption && !value?.isNotSetApplied
					? [IS_NOT_SET_FILTER_ITEM]
					: []),
				...unselectedItems,
			],
		};
	}, [hasIsNotSetOption, hasIsSetOption, items, value]);

	return {
		loading: debouncedLoading,
		error,
		selected,
		unselected,
		searchTerm,
		setSearchTerm,
	};
}
