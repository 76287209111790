import { Skeleton } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useDebounceFn } from 'ahooks';
import axios from 'axios';
import { isNil } from 'lodash-es';
import { memo, Ref, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { api } from '../../../network';
import { authHeaders } from '../../api/common';
import { v4 } from '../../utils/uuid/v4';
import { debounceLocalDefinitionSnapshot } from '../Documentation/utils';
import type { IProseMirrorEditorProps } from '../Editor/outline/src';
import { uploadImagePrivate } from '../ImageUpload/ImageUpload.helpers';

import { useAuthUser, useWorkspace } from '../../api';
import { trackEvent } from '../../utils/analytics';
import { useFeatureFlags } from '../../utils/featureFlags';
import { useEditorClickLink } from '../../utils/hook/useEditorClickLink';
import { RichMarkdownEditor } from '../Editor/outline/src';
import MultiplayerEditor from '../Editor/outline/src/components/MultiplayerEditor';
import embeds from '../Editor/outline/src/embeds';

async function putFile(file: File) {
	const id = v4();
	const presign = await axios.get(
		`${api()}file/presign_upload/?path=attachments&unique_file_key=${id}`,
		authHeaders()
	);
	const formFields: Record<string, string> = presign.data.fields;
	const { url } = presign.data;
	const formData = new FormData();

	Object.entries(formFields).forEach(([key, value]) => {
		formData.append(key, value);
	});
	// File field must go last.
	// `https://stackoverflow.com/questions/15234496/upload-directly-to-amazon-s3-using-ajax-returning-error-bucket-post-must-contai`
	formData.append('file', file);
	await axios.post(url, formData);
	return id;
}

export const uploadFile = async (file: File, isImage: boolean) => {
	if (file.size < 30e6) {
		if (isImage) {
			const result = await uploadImagePrivate(file);
			return result;
		}
		const id = await putFile(file);
		const regex = /[\s,:;/\\]+/g;
		// We must be careful not to remove this endpoint. The regex replaces brackets because it breaks the markdown parser.
		const path = `file/presign_download/?path=attachments&unique_file_key=${id}&original_name=${file.name.replace(
			regex,
			''
		)}`;
		return `/download?path=${btoa(path)}`;
	}
	// eslint-disable-next-line no-alert
	showNotification({ message: 'Error! The maximum file size is 10 MB...' });
	throw new Error('maximum file size is 10 MB');
};

export const DISABLE_EXTENSIONS_FOR_CREATION_MODAL_EDITOR: IProseMirrorEditorProps['disableExtensions'] =
	['chart_block', 'query_block'];

export const DISABLE_EXTENSIONS_FOR_LIMITED_EDITOR: IProseMirrorEditorProps['disableExtensions'] =
	[
		'container_notice',
		'chart_block',
		'query_block',
		'table',
		'td',
		'th',
		'tr',
		'holy',
		'page',
		'embed',
		'image',
		'hr',
		'attachment',
		'comment',
		'blockmenu',
		'find-and-replace',
	];
export const DISABLE_INPUT_EXTENSIONS_FOR_LIMITED_EDITOR: IProseMirrorEditorProps['disableInputExtensions'] =
	['code_block', 'code_fence'];

export interface IRichEditorProps
	extends Pick<
		IProseMirrorEditorProps,
		'disableExtensions' | 'template' | 'outlineThemeOverride'
	> {
	readOnly: boolean;
	dataTestId?: string;
	version?: number;
	placeholder?: string;
	autoFocus?: boolean;
	initialValue?: string;
	disableTopGap?: boolean;
	onChangeCallback?: (value?: string, saveRemotely?: boolean) => void;
	id?: string;
	multiplayerUserHistory?: string[];
	onFocus?: VoidFunction;
	onBlur?: VoidFunction;
	limited?: boolean;
	// eslint-disable-next-line react/no-unused-prop-types
	multiplayer?: boolean;
	className?: string;
	proseMirrorRef?: Ref<RichMarkdownEditor>;
	focusedCommentID?: string;
	onCreatePlaceholderComment?: (selectedText: string) => void;
	onClickComment?: (commentID: string) => void;
	singleLineEditor?: boolean;
	showMentionMenuButton?: boolean;
	enableScrollToHash?: boolean;
	value?: string;
	disableResourceLinking?: boolean;
	disableChangeCallbackDebounce?: boolean;
	onChangeCallbackDebounceWait?: number;
}

// eslint-disable-next-line react/display-name
export const RichEditor = memo((props: IRichEditorProps) => {
	const {
		id,
		version,
		dataTestId,
		onChangeCallback,
		autoFocus,
		initialValue,
		disableTopGap,
		readOnly,
		placeholder,
		multiplayerUserHistory,
		onFocus,
		onBlur,
		disableExtensions,
		limited = false,
		className = 'rich-text-editor',
		proseMirrorRef = null,
		focusedCommentID,
		onCreatePlaceholderComment,
		onClickComment,
		singleLineEditor,
		showMentionMenuButton,
		enableScrollToHash = false,
		template,
		value,
		disableResourceLinking = false,
		disableChangeCallbackDebounce = false,
		outlineThemeOverride,
		onChangeCallbackDebounceWait = 150,
	} = props;

	if (value && (onChangeCallback || !readOnly)) {
		throw new Error(
			'RichEditor: value prop is only supported in readOnly mode'
		);
	}

	// Disabled until multiplayer disconnection issues are resolved.
	// Refer to this ticket to revert: `carter/eng-1277-disable-multiplayer`
	const isMultiplayer = false;

	const location = useLocation();
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();

	const initialScrollTo: string | undefined = enableScrollToHash
		? location.hash
		: undefined;

	const [scrollTo] = useState(initialScrollTo);

	const [initialDocumentation, setInitialDocumentation] = useState<
		string | undefined
	>(initialValue);

	const { editorAiSummaryBlock } = useFeatureFlags();

	const disableExtensionsWithFeatureFlag = useMemo(() => {
		const extensions =
			(limited ? DISABLE_EXTENSIONS_FOR_LIMITED_EDITOR : disableExtensions) ??
			[];
		if (!editorAiSummaryBlock) {
			extensions.push('ai_summary');
		}
		return extensions;
	}, [disableExtensions, editorAiSummaryBlock, limited]);

	const disableInputExtensions = limited
		? DISABLE_INPUT_EXTENSIONS_FOR_LIMITED_EDITOR
		: [];

	// Propagate the initial value back. This is important because if you edit and then immediately save a response,
	// it needs an initial value, otherwise it saves ''.
	useEffect(() => {
		const initialState = initialValue ?? '';
		setInitialDocumentation(initialState);

		if (onChangeCallback) {
			// Second parameter is false because we don't want to save remotely on first load.
			onChangeCallback(initialState, false);
		}
		// Do not add `onChangeCallback` to the deps list because it will cause an infinite loop.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValue]);

	let debounceChangeWait = onChangeCallback
		? onChangeCallbackDebounceWait
		: 4000;
	if (disableChangeCallbackDebounce) {
		debounceChangeWait = 0;
	}

	const { run: handleChange, flush: flushDebouncedChange } = useDebounceFn(
		(changedValue) => {
			if (id) {
				debounceLocalDefinitionSnapshot(id, changedValue());
			}

			if (!isMultiplayer) {
				if (onChangeCallback) {
					// If initial value, this is a managed editor.
					onChangeCallback(changedValue());
				}
			}
		},
		// Wait shorter if updates are an API call.
		{ wait: debounceChangeWait }
	);

	const onShowToast = useCallback(
		(message: string) =>
			showNotification({
				message: `${message}`,
			}),
		[]
	);

	const onClickLink = useEditorClickLink();

	const onTrackEvent = useCallback(
		(eventName: string, properties: Record<string, string> = {}) => {
			if (eventName) {
				trackEvent(eventName, properties, user, workspace);
			}
		},
		[user, workspace]
	);

	if (isMultiplayer && !version) {
		throw new Error('Version is required when multiplayer is true.');
	}

	// We must always have documentation,
	// even if it is empty string.
	// We must always have isMultiplayer eventually not equal null
	// because it is set to true or false when the socket connects
	// or has an error, respectively.
	if (isNil(initialDocumentation) || isNil(isMultiplayer)) {
		return <Skeleton height={768} mt={12} radius="sm" />;
	}

	const commonProps: IProseMirrorEditorProps = {
		id,
		defaultValue: initialDocumentation,
		dataTestId: dataTestId ?? 'rich-text-editor',
		embeds,
		readOnly,
		autoFocus,
		readOnlyWriteCheckboxes: true,
		onChange: handleChange,
		focusedCommentID,
		disableTopGap,
		uploadFile,
		placeholder: placeholder ?? 'Type "/" for commands...',
		onShowToast,
		onClickLink,
		onUnmount: flushDebouncedChange,
		onFocus,
		onBlur,
		onCreatePlaceholderComment,
		onClickComment,
		className,
		disableExtensions: disableExtensionsWithFeatureFlag,
		disableInputExtensions,
		template,
		singleLineEditor,
		showMentionMenuButton,
		scrollTo,
		value,
		disableResourceLinking,
		onTrackEvent,
		outlineThemeOverride,
	};

	if (isMultiplayer) {
		return (
			<MultiplayerEditor
				{...commonProps}
				version={version}
				multiplayerUserHistory={multiplayerUserHistory}
				proseMirrorRef={proseMirrorRef}
				defaultValue={undefined}
			/>
		);
	}

	return <RichMarkdownEditor {...commonProps} ref={proseMirrorRef} />;
});
