import { CATALOG_COLUMN_MAPPING } from '../../components/CatalogView/constants';

/**
 * Reset all the list caches for *all* of the models.
 */
export function resetAllListCache() {
	Object.values(CATALOG_COLUMN_MAPPING).forEach(({ server }) => {
		server.invalidateCache();
	});
}
