import {
	Group,
	Stack,
	Tooltip,
	createStyles,
	useMantineTheme,
} from '@mantine/core';
import { Button, IconButton } from '@repo/foundations';
import { useBoolean, useKeyPress } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { removeTrailingWhitespaces } from '../../../components/Editor/outline/src/utils/removeTrailingWhitespaces';
import { RichEditor } from '../../../components/RichEditor';
import { useForceReRender } from '../../../utils/hook/useForceReRender';
import { getModKey } from '../../../utils/keyboard';
import { useCommentStoreContext } from '../context';

const useStyles = createStyles((theme) => ({
	commentForm: {
		position: 'relative',
		backgroundColor: theme.other.getColor('surface/app/default'),
		border: `1px solid ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.sm,
		fontSize: theme.fontSizes.sm,

		// Artificial padding to create another line in the editor "box"
		// The actions are positioned absolutely at the bottom right
		paddingBottom: theme.other.space[8],
		marginBottom: theme.spacing['2xs'],
	},
	editorContainer: {
		position: 'relative',
		overflowY: 'scroll',
		'::-webkit-scrollbar': {
			display: 'none',
		},
		paddingTop: theme.spacing['2xs'],
		paddingLeft: theme.spacing['2xs'],
	},
	actionGroup: {
		position: 'absolute',
		right: theme.spacing['2xs'],
		bottom: theme.spacing['2xs'],
	},
	actionGroupBackground: {
		backgroundColor: theme.other.getColor('fill/brand/default'),
		'&:disabled': {
			backgroundColor: theme.other.getColor('fill/brand/disabled'),
		},
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/brand/default'),
		},
	},
}));

type ICommentFormProps = {
	dataTestId?: string;
	autoFocus?: boolean;
	isEditExisting?: boolean;
	placeholder?: string;
	definition?: string;
	onSubmit: (commentDefinition: string) => void;
	onCancel: () => void;
};

function CommentForm({
	dataTestId,
	autoFocus = false,
	isEditExisting = false,
	placeholder = 'Leave a comment...',
	definition = '',
	onSubmit,
	onCancel,
}: ICommentFormProps) {
	const theme = useMantineTheme();
	const ref = useRef<HTMLDivElement>(null);
	const [value, setValue] = useState<string>(definition);
	const [valid, setValid] = useBoolean(false);
	const { uploadingEditorID, setUploadingEditorID } = useCommentStoreContext();

	// Disable form to avoid multiple submission
	const [enabled, setEnabled] = useState<boolean>(true);
	const { key: richEditorKey, forceUpdate } = useForceReRender();
	const { classes } = useStyles();

	const handleCommentChange = (newValue: string | undefined) => {
		if (newValue && removeTrailingWhitespaces(newValue).length > 0) {
			setValue(newValue);
			setValid.setTrue();
		} else {
			setValid.setFalse();
		}
	};

	const handleSubmitComment = async () => {
		if (
			removeTrailingWhitespaces(value) === '' ||
			!enabled ||
			uploadingEditorID === richEditorKey
		) {
			return;
		}
		setEnabled(false);
		setUploadingEditorID(richEditorKey);
		await onSubmit(value);
		setValue('');
		forceUpdate();
		setEnabled(true);
	};

	const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		e.stopPropagation();
		onCancel();
	};

	useKeyPress(['meta.Enter'], handleSubmitComment, { exactMatch: true });
	useKeyPress('esc', () => onCancel());

	const actions = isEditExisting ? (
		<Group className={classes.actionGroup} spacing="xs">
			<Button size="sm" onClick={handleCancel}>
				Cancel
			</Button>
			<Button
				data-testid={`${dataTestId}-submit`}
				variant="primary"
				size="sm"
				disabled={!valid}
				onClick={handleSubmitComment}
				loading={richEditorKey === uploadingEditorID}
			>
				Save
			</Button>
		</Group>
	) : (
		<Tooltip label={`${getModKey()} + Enter to submit`}>
			<IconButton
				aria-label="Submit comment"
				data-testid={`${dataTestId}-submit`}
				className={`${classes.actionGroup} ${classes.actionGroupBackground}`}
				disabled={!valid}
				loading={richEditorKey === uploadingEditorID}
				size="sm"
				onClick={handleSubmitComment}
				iconName="arrowUp"
				variant="primary"
			/>
		</Tooltip>
	);

	return (
		<Stack className={classes.commentForm}>
			<Stack className={classes.editorContainer} ref={ref}>
				<RichEditor
					dataTestId={dataTestId}
					key={richEditorKey}
					autoFocus={autoFocus}
					placeholder={placeholder}
					readOnly={false}
					onChangeCallback={handleCommentChange}
					initialValue={value}
					disableTopGap
					limited
					outlineThemeOverride={{
						fontSize: theme.fontSizes.sm,
					}}
				/>
			</Stack>
			{actions}
		</Stack>
	);
}

export default observer(CommentForm);
