import { Badge, BadgeVariant } from '@repo/foundations';

interface ISubmissionStatusBadgeProps {
	status?: 'DRAFT' | 'PENDING' | 'APPROVED' | 'REJECTED' | 'UNKNOWN';
}

export default function SubmissionStatusBadge({
	status,
}: ISubmissionStatusBadgeProps) {
	const STATUS_TO_VARIANT_MAP: Record<
		NonNullable<ISubmissionStatusBadgeProps['status']>,
		BadgeVariant
	> = {
		DRAFT: 'default',
		PENDING: 'info',
		APPROVED: 'success',
		REJECTED: 'critical',
		UNKNOWN: 'default',
	};

	return (
		<Badge variant={status ? STATUS_TO_VARIANT_MAP[status] : 'default'}>
			{status}
		</Badge>
	);
}
