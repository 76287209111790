import { Box, Group, Modal, ScrollArea, Stack } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { Button, Text, Title } from '@repo/foundations';
import { isNil, map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import type { IUser } from '../../../../../api';
import { useAuthUser } from '../../../../../api';
import {
	useApproveFormSubmission,
	useFormSubmission,
	useRejectFormSubmission,
} from '../../../../../api/hooks/forms';
import { UserAvatar } from '../../../../UserAvatar';
import { useFormStore } from '../../../context';
import { FormField } from '../../PreviewTab/FormField';
import AdminRightSection from './AdminRightSection';
import { useStyles } from './ReviewSubmissionModal.styles';
import VisitorRightSection from './VisitorRightSection';

function ReviewSubmissionModal() {
	const [review, setReview] = useState('approve');
	const [comment, setComment] = useInputState('');

	const { isAdminUser } = useAuthUser();

	const { classes, theme } = useStyles();

	const { submissionUnderReview, setSubmissionUnderReview } = useFormStore();

	const { data: submission } = useFormSubmission(submissionUnderReview ?? '', {
		enabled: !isNil(submissionUnderReview),
	});

	const { mutate: approveFormSubmission } = useApproveFormSubmission();
	const { mutate: rejectFormSubmission } = useRejectFormSubmission();

	if (isNil(submission)) {
		return null;
	}

	const handleOnClose = () => {
		setSubmissionUnderReview(undefined);
	};

	const handleSubmit = () => {
		if (review === 'approve') {
			approveFormSubmission({
				submissionId: submission.id as string,
			});
		} else {
			rejectFormSubmission({
				submissionId: submission.id as string,
				comment,
			});
		}

		setSubmissionUnderReview(undefined);
	};

	return (
		<Modal.Root
			classNames={{
				header: classes.header,
				body: classes.body,
			}}
			opened={!isNil(submission)}
			onClose={handleOnClose}
			size={theme.other.width.xxl}
			radius="lg"
			centered
		>
			<Modal.Overlay />
			<Modal.Content>
				<Modal.Header>
					<Modal.Title>
						<Title size="md">{`Review 📝 ${submission.form?.title} submission`}</Title>
					</Modal.Title>
					<Modal.CloseButton />
				</Modal.Header>
				<Modal.Body>
					<Group className={classes.wrapper}>
						<Stack className={classes.leftSection}>
							<Group spacing="xs">
								<UserAvatar size="lg" user={submission.user as IUser} />
								<Stack spacing={0}>
									<Text
										size="sm"
										weight="semibold"
										lh={1.25}
									>{`${submission?.user?.first_name} ${submission?.user?.last_name}`}</Text>
									<Text size="xs" color="text/secondary/default" lh={1}>
										{`Submitted ${
											submission?.updated_at
												? new Date(submission.updated_at).toLocaleString()
												: ''
										}`}
									</Text>
								</Stack>
							</Group>
							{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
							{/* @ts-ignore poorly typed component by Mantine */}
							<ScrollArea.Autosize mah={420} offsetScrollbars>
								<Stack>
									{map(submission.form?.fields, (field) => (
										<FormField
											key={field.id}
											field={field}
											submission={submission}
											isReadOnly
										/>
									))}
								</Stack>
							</ScrollArea.Autosize>
						</Stack>
						<Box className={classes.rightSection}>
							{isAdminUser && (
								<AdminRightSection
									review={review}
									setReview={setReview}
									comment={comment}
									setComment={setComment}
								/>
							)}
							{!isAdminUser && <VisitorRightSection submission={submission} />}
						</Box>
					</Group>
					<Group className={classes.footer}>
						{isAdminUser && (
							<>
								<Button variant="primary" size="md" onClick={handleSubmit}>
									Submit
								</Button>
								<Button variant="default" size="md" onClick={handleOnClose}>
									Cancel
								</Button>
							</>
						)}
						{!isAdminUser && (
							<Button variant="primary" size="md" onClick={handleOnClose}>
								Close
							</Button>
						)}
					</Group>
				</Modal.Body>
			</Modal.Content>
		</Modal.Root>
	);
}

export default observer(ReviewSubmissionModal);
