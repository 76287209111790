import { Group, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Button } from '@repo/foundations';
import { Navigate } from 'react-router';
import { useAuthUser } from '../../api';
import { useCreateForm, useFormsList } from '../../api/hooks/forms';
import { EmptyState } from '../../components/EmptyState';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import FormList from './FormList';

function FormListPage() {
	const { classes, theme } = useStyles();
	const { workspace } = useAuthUser();

	const { data: forms, isLoading } = useFormsList({});

	const { mutateAsync: createForm } = useCreateForm();

	const navigate = useNavigate();

	const handleCreateForm = async () => {
		const form = await createForm({
			form: {
				title: undefined,
			},
		});
		navigate(`/forms/${form.id}`);
	};

	if (!workspace.forms_enabled) {
		return <Navigate to="/" />;
	}

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	const actions = (
		<Group>
			<Button
				component="a"
				target="_blank"
				rel="noreferrer noopener"
				href="https://docs.secoda.co/features/forms"
				leftIconName="externalLink"
			>
				Learn more
			</Button>
			<Button
				size="md"
				onClick={handleCreateForm}
				variant="primary"
				color="blue"
				aria-label="Create form"
			>
				Create form
			</Button>
		</Group>
	);

	if (forms && forms.length === 0) {
		return (
			<EmptyState
				className={classes.emptySpace}
				imgSrc="/images/empty-state/forms.svg"
				imgWidth={theme.other.space[40]}
				title="Streamline your data governance"
				description="Create customizable forms for data access requests, approval workflows, compliance tracking, and more."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={actions}
			/>
		);
	}

	return (
		<Stack className={classes.wrapper}>
			<FormList />
		</Stack>
	);
}

export default FormListPage;
