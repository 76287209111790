import { useDebouncedValue, useInputState } from '@mantine/hooks';
import { useEffect } from 'react';
import type { FormFieldValue } from '../../../../../api';
import { TextInputWithActions } from './TextInputWithActions';

interface IFormFieldSelectOptionProps {
	index: number;
	option: FormFieldValue;
	numOptions: number;
	updateOption: (index: number, value: string) => void;
	moveOption: (index: number, direction: 'UP' | 'DOWN') => void;
	deleteOption: (index: number) => void;
}

export default function FormFieldSelectOption({
	index,
	option,
	numOptions,
	updateOption,
	moveOption,
	deleteOption,
}: IFormFieldSelectOptionProps) {
	const [value, setValue] = useInputState(option.value);
	const [debounceValue] = useDebouncedValue(value, 500);

	useEffect(() => {
		if (value !== option.value) {
			setValue(option.value);
		}
	}, [option.value]);

	useEffect(() => {
		if (debounceValue !== option.value) {
			updateOption(index, value);
		}
	}, [debounceValue]);

	return (
		<TextInputWithActions
			value={value}
			actions={[
				{
					icon: 'arrowUp',
					disabled: index === 0,
					onClick: () => {
						moveOption(index, 'UP');
					},
				},
				{
					icon: 'arrowDown',
					disabled: index === numOptions - 1,
					onClick: () => {
						moveOption(index, 'DOWN');
					},
				},
				{
					icon: 'trash',
					onClick: () => {
						deleteOption(index);
					},
				},
			]}
			onChange={setValue}
		/>
	);
}
