/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Menu } from '@mantine/core';
import { IconButton } from '@repo/foundations';
import { useState } from 'react';

type IInlineEmojiPickerProps = {
	onClick: ({ native }: { native: string }) => void;
	onOpenChange?: (open: boolean) => void;
};

// eslint-disable-next-line react/display-name
export function InlineEmojiPicker({
	onClick,
	onOpenChange = () => {},
}: IInlineEmojiPickerProps) {
	const [open, setOpen] = useState(false);

	const handleSetOpen = (newOpen: boolean) => {
		setOpen(newOpen);
		onOpenChange(newOpen);
	};

	const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		// We have to stop propagation to avoid `onClickOutside` from being called.
		event.preventDefault();
		event.stopPropagation();
		handleSetOpen(!open);
	};

	const handleEmojiSelect = (emoji: { native: string }) => {
		onClick({ native: emoji.native });
		setOpen(false);
	};

	return (
		<Menu
			position="bottom-end"
			opened={open}
			onChange={setOpen}
			closeOnClickOutside
			closeOnItemClick
			withinPortal
		>
			<Menu.Target>
				<IconButton
					variant="tertiary"
					iconName="moodPlus"
					onClick={handleClick}
					tooltip="Add reaction"
				/>
			</Menu.Target>
			<Menu.Dropdown>
				<Picker
					data={data}
					autoFocus
					onClickOutside={() => handleSetOpen(false)}
					onEmojiSelect={handleEmojiSelect}
					previewPosition="none"
				/>
			</Menu.Dropdown>
		</Menu>
	);
}
