import type { ColorScheme } from '@mantine/core';
import { ColorSchemeProvider, MantineProvider, Tooltip } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { ApiConfigProvider } from '@repo/api-codegen/api/codegen/apiConfigContext';
import { SecodaTheme } from '@repo/theme/mantine/secodaTheme';
import { QueryClientProvider } from '@tanstack/react-query';
import 'focus-visible/dist/focus-visible';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { queryClient } from './api';
import { TableV2Spotlight } from './components/Spotlight/components/TableV2Spotlight';
import ReloadPrompt from './ReloadPrompt';
import { trackEvent } from './utils/analytics';
import { FeatureFlagsProvider } from './utils/featureFlags';
import { LaunchDarklyProvider } from './utils/featureFlags/LaunchDarklyProvider';

export function Providers({ children }: { children: React.ReactNode }) {
	const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
		key: 'mantine-color-scheme',
		defaultValue: 'light',
		getInitialValueInEffect: true,
	});

	const toggleColorScheme = (value?: ColorScheme) =>
		setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

	useEffect(() => {
		// Add any theme properties here so they can be seen in mixpanel.
		trackEvent('theme', { colorScheme });
	}, [colorScheme]);

	return (
		<DndProvider backend={HTML5Backend}>
			<ColorSchemeProvider
				colorScheme={colorScheme}
				toggleColorScheme={toggleColorScheme}
			>
				<MantineProvider
					theme={{ ...SecodaTheme, colorScheme }}
					withNormalizeCSS
					withGlobalStyles
				>
					<ReloadPrompt />
					<Tooltip.Group openDelay={1000} closeDelay={200}>
						<QueryClientProvider client={queryClient}>
							<ApiConfigProvider>
								<LaunchDarklyProvider>
									<FeatureFlagsProvider>
										<TableV2Spotlight>{children}</TableV2Spotlight>
									</FeatureFlagsProvider>
								</LaunchDarklyProvider>
							</ApiConfigProvider>
						</QueryClientProvider>
					</Tooltip.Group>
				</MantineProvider>
			</ColorSchemeProvider>
		</DndProvider>
	);
}
