import { Skeleton } from '@mantine/core';
import { Filter } from '@repo/api-codegen';
import { Suspense } from 'react';
import { CatalogServerType } from '../../../components/CatalogView/types';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { EntityType } from '../../../lib/types';
import { CatalogTable } from '../../TeamCatalogPage/CatalogTable';
import { useColumns } from '../../TeamCatalogPage/TeamCatalogPage.hooks';

interface IProfileTableProps {
	filters: { operands: Filter[] };
}

function ProfileTable({ filters }: IProfileTableProps) {
	const columns = useColumns();

	return (
		<ErrorBoundary>
			<Suspense fallback={<Skeleton h="70vh" w="100%" />}>
				<CatalogTable
					columns={columns.filter(
						(column) =>
							!['integration', 'parent', 'external_usage'].includes(
								column.accessor
							)
					)}
					columnVisibility={{
						catalogType: EntityType.all,
						catalogServerType: 'secoda_entity' as CatalogServerType,
					}}
					withSavedViews={false}
					defaultRequiredSearchParams={{
						grab_all_entity_types: true,
					}}
					defaultRequiredCatalogFilters={filters}
				/>
			</Suspense>
		</ErrorBoundary>
	);
}

export default ProfileTable;
