import type { IForm } from '../../../api';
import { useAuthUser } from '../../../api';
import { useUpdateForm } from '../../../api/hooks/forms';
import EmojiIconSelector from '../../EmojiIconSelector';
import { EntityPageDescription } from '../../EntityPageLayout';
import EntityPageTitle from '../../EntityPageLayout/EntityPageTitle/EntityPageTitle';

interface IFormPageHeaderProps {
	form: IForm;
}

export function FormPageHeader({ form }: IFormPageHeaderProps) {
	const { isAdminUser } = useAuthUser();

	const { mutateAsync: updateForm } = useUpdateForm();

	const formTitle = {
		title: form.title ?? '',
	};

	const handleUpdateTitle = (title: string) => {
		if (form.id) {
			updateForm({
				form: {
					id: form.id,
					title,
				},
			});
		}
	};

	const handleUpdateDescription = (description: string) => {
		if (form.id) {
			updateForm({
				form: {
					id: form.id,
					description,
				},
			});
		}
	};

	const handleIconChange = async (emoji: string) => {
		if (form?.id) {
			await updateForm({
				form: {
					id: form.id,
					icon: emoji,
				},
			});
		}
	};

	const icon = (
		<EmojiIconSelector
			icon={form.icon ?? '⚡'}
			title="form"
			onChange={handleIconChange}
			defaultEmoji="⚡"
		/>
	);

	return (
		<>
			<EntityPageTitle
				entity={formTitle}
				onChange={handleUpdateTitle}
				placeholder="Untitled"
				isReadOnly={!isAdminUser}
				icon={icon}
			/>
			<EntityPageDescription
				onChange={handleUpdateDescription}
				description={form?.description ?? ''}
				readOnly={!isAdminUser}
			/>
		</>
	);
}
