import { createStyles, Flex, Stack, Stepper } from '@mantine/core';
import { Button, Icon, IconButton } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { IntegrationTitle } from '../components/IntegrationTitle';
import IntegrationGuide from './IntegrationGuide';
import {
	ConnectionStep,
	MappingStep,
	SelectStep,
	SyncStep,
} from './StepComponents';
import useCreateIntegrationConnection, {
	IntegrationSteps,
} from './useCreateIntegrationConnection';

const useStyles = createStyles((theme) => ({
	wrapper: {
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		gap: 0,
	},
	header: {
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		padding: `${theme.spacing.sm} ${theme.spacing['3xl']}`,
	},
	content: {
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		height: 'calc(100vh - 52px)',
	},
	integrationWrapper: {
		alignItems: 'flex-start',
		justifyContent: 'center',
		flexGrow: 1,
		height: '100%',
		overflowY: 'auto',
		padding: `0 ${theme.spacing.xl} ${theme.spacing.xl} ${theme.spacing.xl}`,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.other.getColor('border/secondary/default'),
	},
	integrationContent: {
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		height: '100%',
	},
	steps: {
		flexShrink: 1,
		width: `calc(${theme.other.width.md}px + (2 * ${theme.spacing['3xl']}))`,
		padding: `${theme.spacing.lg} ${theme.spacing['3xl']}`, // The horizontal padding is to hide the box shadow of the content
		backgroundColor: theme.other.getColor('surface/primary/default'),
		zIndex: 10,
	},
	step: {
		'.mantine-Stepper-stepLabel': {
			fontSize: theme.other.typography.text.sm,
			fontWeight: theme.other.typography.weight.semibold,
			color: theme.other.getColor('text/primary/default'),
		},
		'&:not([data-completed])': {
			'.mantine-Stepper-stepLabel': {
				color: theme.other.getColor('text/secondary/default'),
			},
		},
	},
	stepperContent: {
		flexGrow: 1,
		width: theme.other.width.md,
		paddingTop: theme.spacing.lg,
		marginTop: theme.spacing['5xl'],
	},
	stepIcon: {
		border: 'none',
		'&[data-completed=true]': {
			backgroundColor: theme.other.getColor('surface/success/default'),
			svg: { stroke: theme.other.getColor('icon/success/default') },
		},
		'&[data-progress=true]': {
			backgroundColor: theme.other.getColor('fill/brand/default'),
			fontSize: theme.other.typography.text.sm,
			fontWeight: theme.other.typography.weight.semibold,
			color: theme.other.getColor('text/brand-on-fill/default'),
			svg: {
				stroke: theme.other.getColor('icon/primary/default'),
			},
		},
	},
	stepBody: {
		marginLeft: theme.spacing.xs,
	},
	separator: {
		backgroundColor: theme.other.getColor('border/secondary/default'),
		height: 1,
	},
	separatorActive: {
		backgroundColor: theme.other.getColor('border/secondary/default'),
		height: 1,
	},
}));

function CreateIntegrationConnection() {
	const {
		integrationSpec,
		createdIntegration,
		setCreatedIntegration,
		currentStep,
		prevStep,
		nextStep,
		handleClose,
		stepNames,
	} = useCreateIntegrationConnection();

	const { classes, theme } = useStyles();

	const hasDocumentation =
		integrationSpec.type === 'builtin' &&
		integrationSpec.value.documentationMarkdown &&
		currentStep === 0;

	if (isNil(integrationSpec.value)) {
		return null;
	}

	return (
		<Flex className={classes.wrapper}>
			<Flex className={classes.header}>
				<Button
					variant="tertiary"
					leftIconName="chevronLeft"
					style={{
						visibility: currentStep === 0 ? 'hidden' : 'visible',
					}}
					onClick={prevStep}
					disabled={currentStep === 0}
				>
					Back
				</Button>
				<IntegrationTitle
					integrationSpec={integrationSpec}
					title={
						integrationSpec.type === 'builtin'
							? `Connecting ${integrationSpec.value.name}`
							: `Connecting ${integrationSpec.value.versions[0]?.name}`
					}
				/>
				<IconButton variant="tertiary" iconName="x" onClick={handleClose} />
			</Flex>
			<Flex className={classes.content}>
				<Flex className={classes.integrationWrapper}>
					<Flex className={classes.integrationContent}>
						<Stepper
							classNames={{
								root: classes.root,
								steps: classes.steps,
								step: classes.step,
								stepIcon: classes.stepIcon,
								stepBody: classes.stepBody,
								separator: classes.separator,
								separatorActive: classes.separatorActive,
								content: classes.stepperContent,
							}}
							active={currentStep}
							completedIcon={<Icon name="check" />}
							iconSize={theme.other.iconSize.lg}
							contentPadding="xl"
						>
							{stepNames.map((step) => (
								<Stepper.Step key={step} label={step}>
									<Stack spacing="2xl" pb="lg">
										{step === IntegrationSteps.CONNECTION && (
											<ConnectionStep
												integrationSpec={integrationSpec}
												integration={createdIntegration}
												setIntegration={setCreatedIntegration}
												nextStep={nextStep}
											/>
										)}
										{step === IntegrationSteps.IMPORT && createdIntegration && (
											<SelectStep
												integration={createdIntegration}
												integrationSpec={integrationSpec}
												nextStep={nextStep}
											/>
										)}
										{step === IntegrationSteps.MAPPING &&
											createdIntegration && (
												<MappingStep
													integration={createdIntegration}
													nextStep={nextStep}
												/>
											)}
										{step === IntegrationSteps.SYNC && createdIntegration && (
											<SyncStep
												integrationSpec={integrationSpec}
												integration={createdIntegration}
											/>
										)}
									</Stack>
								</Stepper.Step>
							))}
						</Stepper>
					</Flex>
				</Flex>
				{hasDocumentation && (
					<Flex h="calc(100vh - 52px)">
						<IntegrationGuide
							type={integrationSpec.value.type}
							markdownURL={integrationSpec.value.documentationMarkdown}
						/>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
}

export default CreateIntegrationConnection;
