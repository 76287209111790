import { createStyles, Group, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Text } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useUser } from '../../api';
import type { Question } from '../../lib/models';
import { UserAvatar } from '../UserAvatar';
import QuestionUpvoteIndicator from './List/QuestionUpvoteIndicator';
import { embeddedQuestionsStore } from './store';

const useStyles = createStyles((theme) => ({
	questionStack: {
		borderRadius: 4,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.colors.gray[3],
		padding: 12,
		cursor: 'pointer',
	},
	titleBox: {
		display: 'flex',
		alignItems: 'flex-end',
	},
}));

interface IEmbeddedQuestionsListProps {
	questions: Question[];
}

function EmbeddedQuestion({ question }: { question: Question }) {
	const { data: user } = useUser({
		id: question?.owners[0],
		options: {
			enabled: !isNil(question?.owners[0]),
		},
	});

	return (
		<Group>
			<QuestionUpvoteIndicator question={question} />
			<Stack spacing={0}>
				<Text>{question.title}</Text>
				<Group>
					{user && <UserAvatar size="xxs" user={user} />}
					<Text size="xs">{user?.display_name ?? ''}</Text>
					<Text size="xs">{moment(question.created_at).fromNow()}</Text>
				</Group>
			</Stack>
		</Group>
	);
}

function EmbeddedQuestionsList({ questions }: IEmbeddedQuestionsListProps) {
	const { classes } = useStyles();

	const navigate = useNavigate();

	const handleQuestionClick = (id: string) => {
		embeddedQuestionsStore.setOpen(false);
		navigate(`/questions/${id}`);
	};

	return (
		<Stack>
			{questions.map((question) => (
				<Stack
					key={question.id}
					className={classes.questionStack}
					onClick={() => handleQuestionClick(question.id)}
				>
					<EmbeddedQuestion question={question} />
				</Stack>
			))}
		</Stack>
	);
}

export default observer(EmbeddedQuestionsList);
