/* eslint-disable no-console */
import { Dialog, Group, createStyles } from '@mantine/core';
import { Button, IconButton, Text } from '@repo/foundations';
import { useCallback, useState } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

const useStyles = createStyles((theme) => ({
	dialog: {
		width: 'max-content',
		borderRadius: theme.radius.lg,
		backgroundColor: theme.other.getColor('surface/inverse/default'),
		padding: `${theme.spacing.xs} ${theme.spacing.lg} !important`,
		minHeight: '0 !important',
	},
	group: {
		backgroundColor: 'transparent',
	},
	text: {
		color: theme.other.getColor('text/inverse/default'),
	},
	button: {
		'& .mantine-Button-label': {
			color: theme.other.getColor('text/inverse/default'),
		},
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/inverse/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/inverse/active'),
		},
	},
	iconButton: {
		color: theme.other.getColor('text/inverse/default'),
		'& .mantine-IconButton-icon': {
			color: theme.other.getColor('text/inverse/default'),
		},
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/inverse/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/inverse/active'),
		},
	},
}));

function ReloadPrompt() {
	const [loading, setLoading] = useState(false);
	const { classes } = useStyles();

	const {
		needRefresh: [needRefresh, setNeedRefresh],
		updateServiceWorker,
	} = useRegisterSW({
		onRegistered(r) {
			console.info('SW Registered: ' + r);
		},
		onRegisterError(error) {
			console.error('SW registration error', error);
		},
	});

	const close = useCallback(() => {
		setNeedRefresh(false);
	}, [setNeedRefresh]);

	return (
		<Dialog
			opened={needRefresh}
			onClose={close}
			classNames={{ root: classes.dialog }}
		>
			<Group spacing="md" position="apart" className={classes.group}>
				<Text size="sm" className={classes.text}>
					New version available
				</Text>
				<Group spacing="xs">
					<Button
						loading={loading}
						disabled={loading}
						variant="tertiary"
						onClick={() => {
							setLoading(true);
							updateServiceWorker(true);
						}}
						className={classes.button}
					>
						Reload
					</Button>
					<IconButton
						size="sm"
						variant="tertiary"
						onClick={close}
						iconName="x"
						className={classes.iconButton}
					/>
				</Group>
			</Group>
		</Dialog>
	);
}

export default ReloadPrompt;
