import type { CSSObject } from '@mantine/core';
import { createStyles, NumberInput, Textarea, TextInput } from '@mantine/core';
import { useDebouncedValue, useInputState } from '@mantine/hooks';
import { getColor } from '@repo/theme/utils';
import { useEffect } from 'react';
import type { FormFieldValues } from '../../../../../api';

interface IFormTextField {
	initialValue?: string;
	type: 'text' | 'number' | 'multiline-text';
	updateValue: (values: FormFieldValues) => void;
	isReadOnly: boolean;
}

const useStyles = createStyles((theme) => ({
	root: {
		flexGrow: '1 !important' as CSSObject['flexGrow'],
	},
	input: {
		padding: `${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing.sm}`,
		border: `0.5px solid ${getColor('border/secondary/default')}`,
		borderRadius: theme.radius.sm,
		backgroundColor: getColor('surface/input/default'),
		'&:hover': {
			backgroundColor: getColor('surface/input/hover'),
		},
		'&:focus': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
		'&:active': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
	},
}));

export default function FormTextField({
	initialValue = '',
	type,
	updateValue,
	isReadOnly,
}: IFormTextField) {
	const [value, setValue] = useInputState(initialValue);
	const [debouncedValue] = useDebouncedValue(value, 500);

	const { classes } = useStyles();

	useEffect(() => {
		if (initialValue !== value) {
			setValue(initialValue);
		}
	}, [initialValue]);

	useEffect(() => {
		if (debouncedValue !== initialValue) {
			updateValue({ values: [{ value }] });
		}
	}, [debouncedValue]);

	const isNumeric = (str: string) =>
		!Number.isNaN(str) && !Number.isNaN(parseFloat(str));

	if (type === 'number') {
		return (
			<NumberInput
				classNames={{
					root: classes.root,
					input: classes.input,
				}}
				value={isNumeric(value) ? parseInt(value, 10) : ''}
				onChange={(num: number) => setValue(num.toString())}
				disabled={isReadOnly}
			/>
		);
	}

	if (type === 'multiline-text') {
		return (
			<Textarea
				classNames={{
					root: classes.root,
					input: classes.input,
				}}
				value={value}
				onChange={setValue}
				autosize
				minRows={3}
				disabled={isReadOnly}
			/>
		);
	}

	return (
		<TextInput
			classNames={{
				root: classes.root,
				input: classes.input,
			}}
			value={value}
			onChange={setValue}
			disabled={isReadOnly}
		/>
	);
}
