import { DEFAULT_THEME } from '@mantine/styles';

export function hashCode(str: string): number {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
}

export function pickColorType(str?: string, disabled?: boolean | null): string {
	const keys = ['blue', 'cyan', 'lime', 'violet', 'grape', 'green'];

	if (disabled) {
		return 'gray';
	}

	if (!str) {
		return 'blue';
	}

	return keys[Math.abs(hashCode(str)) % keys.length];
}

/**
 * Picks a color based on a string.
 * @param {string} [str] - The string to determine the color.
 * @returns {string} - The picked color.
 */
export function pickColor(str?: string, level?: number): string {
	return DEFAULT_THEME.colors[pickColorType(str)][level || 4];
}
