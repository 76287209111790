import {
	Center,
	createStyles,
	Group,
	Image,
	Stack,
	TextInput,
} from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Button, Icon, Text } from '@repo/foundations';
import { isNil } from 'lodash-es';
import type React from 'react';
import { useState } from 'react';
import { anonymousId } from '../../components/Auth/AuthStepEmailComponent.constants';
import { APIKey } from '../../lib/models';
import { IS_LOCAL_OR_DEVELOPMENT } from '../../utils/envs';
import { sendChromeExtensionMessage } from '../../utils/extension';

const useStyles = createStyles(() => ({
	wrapper: {
		width: '100%',
		height: '100vh',
	},
}));

function ChromeExtensionPage() {
	const [extensionId, setExtensionId] = useState('');
	const navigate = useNavigate();

	const { classes } = useStyles();
	const handleAuthenticate = async () => {
		const response = (await APIKey.create({
			key: '',
			is_chrome_extension_key: true, // indicates that the key is used by the Chrome Extension
		})) as APIKey;

		if (!isNil(response.key)) {
			sendChromeExtensionMessage(
				{
					type: 'token',
					token: response.key,
					anonymousId: anonymousId(),
				},
				{
					title: 'Successfully authenticated',
					message: 'You can now use Secoda Chrome Extension',
				},
				{
					title: 'Something went wrong while authenticating',
					message: 'If the issue persists contact customer support',
				}
			);
			navigate('/');
		}
	};

	const handleExtensionIdChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		localStorage.setItem('extensionId', event.currentTarget.value);
		setExtensionId(event.currentTarget.value);
	};

	return (
		<Center className={classes.wrapper}>
			<Stack spacing="lg" w={480} align="center">
				<Group spacing="xs">
					<Image
						src="/images/logos/cfsecoda.webp"
						width={36}
						height={36}
						radius="sm"
					/>
					<Icon name="arrowsRightLeft" />
					<Image src="/images/logos/chrome.svg" width={36} height={36} />
				</Group>
				<Stack spacing="sm">
					<Text
						size="xl"
						weight="bold"
						lh="28px"
						color="text/primary/default"
						align="center"
					>
						Authenticate Chrome Extension
					</Text>
					<Text
						size="md"
						weight="regular"
						lh="24px"
						color="text/secondary/default"
						align="center"
					>
						Access metadata, ask AI-powered questions, and search your company
						data from anywhere in Chrome.
					</Text>
				</Stack>
				<Stack spacing="sm" w={200}>
					{IS_LOCAL_OR_DEVELOPMENT && (
						<TextInput
							value={extensionId}
							onChange={handleExtensionIdChange}
							placeholder="Enter Chrome Extension ID"
						/>
					)}
					<Button size="lg" onClick={handleAuthenticate}>
						Authenticate
					</Button>
				</Stack>
			</Stack>
		</Center>
	);
}

export default ChromeExtensionPage;
