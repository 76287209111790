import {
	Button,
	Checkbox,
	Group,
	Select,
	Stack,
	TextInput,
} from '@mantine/core';
import { Text } from '@repo/foundations';
import { useState } from 'react';
import { EntityType } from '../../lib/types';

export function PropertyForm({
	fields,
	onCancel,
	onConfirm,
	existingLabels,
	initialValues = { label: '', type: '', visibility: [] },
}: {
	fields: Array<'label' | 'type' | 'visibility'>;
	onCancel: () => void;
	onConfirm: (label: string, type: string, visibility: EntityType[]) => void;
	existingLabels: string[];
	initialValues?: { label: string; type: string; visibility: EntityType[] };
}) {
	const [label, setLabel] = useState(initialValues.label);
	const [type, setType] = useState(initialValues.type);
	const [visibility, setVisibility] = useState<EntityType[]>(
		initialValues.visibility
	);
	const [labelError, setLabelError] = useState('');

	const handleConfirm = () => {
		if (existingLabels.includes(label) && label !== initialValues.label) {
			setLabelError('A column with this name already exists');
		} else if (!/^[a-zA-Z]+$/.test(label)) {
			setLabelError('Label must contain only alphabetical characters');
		} else {
			onConfirm(label, type, visibility);
		}
	};

	return (
		<Stack>
			{fields.includes('label') && (
				<TextInput
					label="Label"
					value={label}
					onChange={(event) => {
						setLabel(event.currentTarget.value);
						setLabelError('');
					}}
					error={labelError}
				/>
			)}
			{fields.includes('type') && (
				<Select
					label="Type"
					data={[
						{ value: 'string', label: 'String' },
						// { value: 'number', label: 'Number' },
						// { value: 'boolean', label: 'Boolean' },
						// { value: 'date', label: 'Date' },
					]}
					value={'string'}
					disabled={true}
					onChange={(value) => setType(value || '')}
				/>
			)}
			{fields.includes('visibility') && (
				<>
					<Text weight="bold">Visibility</Text>
					<Text size="xs" mb="xs" color="text/secondary/default">
						The property will be applied to all resources selected below
					</Text>
					<Checkbox
						label="Catalog"
						checked={visibility.includes(EntityType.all)}
						onChange={(event) => {
							if (event.currentTarget.checked) {
								setVisibility([...visibility, EntityType.all]);
							} else {
								setVisibility(visibility.filter((v) => v !== EntityType.all));
							}
						}}
					/>
					<Checkbox
						label="Tables"
						checked={visibility.includes(EntityType.table)}
						onChange={(event) => {
							if (event.currentTarget.checked) {
								setVisibility([...visibility, EntityType.table]);
							} else {
								setVisibility(visibility.filter((v) => v !== EntityType.table));
							}
						}}
					/>
					<Checkbox
						label="Columns"
						checked={visibility.includes(EntityType.column)}
						onChange={(event) => {
							if (event.currentTarget.checked) {
								setVisibility([...visibility, EntityType.column]);
							} else {
								setVisibility(
									visibility.filter((v) => v !== EntityType.column)
								);
							}
						}}
					/>
					<Checkbox
						label="Collections"
						checked={visibility.includes(EntityType.collection)}
						onChange={(event) => {
							if (event.currentTarget.checked) {
								setVisibility([...visibility, EntityType.collection]);
							} else {
								setVisibility(
									visibility.filter((v) => v !== EntityType.collection)
								);
							}
						}}
					/>
					<Checkbox
						label="Glossary terms"
						checked={visibility.includes(EntityType.dictionary_term)}
						onChange={(event) => {
							if (event.currentTarget.checked) {
								setVisibility([...visibility, EntityType.dictionary_term]);
							} else {
								setVisibility(
									visibility.filter((v) => v !== EntityType.dictionary_term)
								);
							}
						}}
					/>
					<Checkbox
						label="Documents"
						checked={visibility.includes(EntityType.document)}
						onChange={(event) => {
							if (event.currentTarget.checked) {
								setVisibility([...visibility, EntityType.document]);
							} else {
								setVisibility(
									visibility.filter((v) => v !== EntityType.document)
								);
							}
						}}
					/>
					<Checkbox
						label="Questions"
						checked={visibility.includes(EntityType.question)}
						onChange={(event) => {
							if (event.currentTarget.checked) {
								setVisibility([...visibility, EntityType.question]);
							} else {
								setVisibility(
									visibility.filter((v) => v !== EntityType.question)
								);
							}
						}}
					/>
				</>
			)}
			<Group position="right" mt="md">
				<Button variant="default" onClick={onCancel}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleConfirm}>
					Confirm
				</Button>
			</Group>
		</Stack>
	);
}
