import {
	Box,
	Checkbox,
	Divider,
	FileInput,
	Group,
	Radio,
	Stack,
	useMantineTheme,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
	type IntegrationSchemaKeys,
	integrationSchemas,
} from '@repo/common/constants/integration/integrations.schemas';
import {
	Banner,
	Button,
	Text,
	TextArea,
	TextInput,
	Title,
} from '@repo/foundations';
import { useFormik } from 'formik';
import {
	capitalize,
	debounce,
	isNil,
	keys,
	omit,
	omitBy,
	reverse,
} from 'lodash-es';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import type { AnySchema, ObjectSchema } from 'yup';
import * as Yup from 'yup';
import { api } from '../../../../../../network';
import {
	IIntegration,
	integrationsQueryKeyFactory,
	useAuthUser,
	useCreateIntegration,
	useIntegrationList,
	useUpdateIntegration,
} from '../../../../../api';
import { apiClient } from '../../../../../api/common';
import { usePublicEnv } from '../../../../../api/hooks/utils/usePublicEnv';
import type {
	BuiltinIntegrationSpec,
	SlackIntegrationSpec,
} from '../../../../../interfaces/IntegrationSpec';
import { trackEvent } from '../../../../../utils/analytics';
import {
	gracefulBase64Decode,
	snakeCaseToTitleCase,
	titleFromIdentifier,
} from '../../../../../utils/shared.utils';
import { uploadFileToS3 } from '../../../../../utils/upload.utils';
import { SmallLoadingSpinner } from '../../../../LoadingSpinner';
import { MultiTeamsSelector } from '../../../../MultiTeamsSelector/MultiTeamsSelector';
import { SlackOAuthButton } from '../../../IntegrationForm';
import { IntegrationTunnelSelect } from '../../../IntegrationTunnelSelect';
import ConnectionTypeRadio from './ConnectionTypeRadio';

interface BuiltInConnectionStepProps {
	spec: BuiltinIntegrationSpec;
	integration?: IIntegration;
	setIntegration?: (integration: IIntegration) => void;
	nextStep?: () => void;
	hideTitle?: boolean;
}

export function BuiltInConnectionStep({
	spec,
	integration,
	setIntegration,
	nextStep,
	hideTitle = false,
}: BuiltInConnectionStepProps) {
	const theme = useMantineTheme();

	const [error, setError] = useState<string | undefined>();

	const { name, type, groupSettings, oauth, is_demo: isDemo, testable } = spec;

	const { user, workspace } = useAuthUser();
	const { data: integrations } = useIntegrationList({});
	const { data: publicEnv } = usePublicEnv();

	const { mutateAsync: createIntegration } = useCreateIntegration({
		invalidationKeys: [integrationsQueryKeyFactory.allLists()],
	});
	const { mutateAsync: updateIntegration } = useUpdateIntegration({});

	const scrollToBottom = useCallback(() => {
		const scrollContainer = document.getElementById('integration-page-scroll');
		if (scrollContainer) {
			scrollContainer.scrollTo({
				top: scrollContainer.scrollHeight,
				behavior: 'smooth',
			});
		}
	}, []);

	const form = useMemo<ObjectSchema<any>>(
		() =>
			integrationSchemas[type as IntegrationSchemaKeys] ||
			Yup.object().shape({}),
		[type]
	);

	const groups = useMemo(() => {
		if (!form) return {};

		const { fields } = form;
		const schemas: Record<string, Record<string, AnySchema>> = {};

		keys(fields).forEach((key) => {
			if (key === '_group') return;

			const field = fields[key];
			const meta = field.spec?.meta;

			if (meta?.group) {
				if (!schemas[meta.group]) schemas[meta.group] = {};
				schemas[meta.group][key] = field;
			} else if (meta?.groups) {
				meta.groups.forEach((group: string) => {
					if (!schemas[group]) schemas[group] = {};
					schemas[group][key] = field;
				});
			} else {
				if (!schemas.default) schemas.default = {};
				schemas.default[key] = field;
			}
		});

		// Include all groups from groupSettings
		keys(groupSettings).forEach((groupName) => {
			if (!schemas[groupName]) {
				schemas[groupName] = {};
			}
		});

		// Add OAuth to the groups if it is supported (i.e. Slack)
		if (oauth) {
			if (!schemas.OAuth) schemas.OAuth = {};
		}

		return schemas;
	}, [form, groupSettings, oauth]);

	const disableDBT = useMemo(
		() =>
			['dbt', 'dbt_cloud'].includes(type) &&
			integrations?.results?.filter(
				(i) =>
					[
						'bigquery',
						'redshift',
						'snowflake',
						'mssql',
						'mysql',
						'oracle',
						'postgres',
						'databricks',
					].includes(i.type) && !i.name.toLowerCase().includes('demo')
			).length === 0,
		[integrations?.results, type]
	);

	const disableGithub = useMemo(
		() =>
			type === 'github' &&
			integrations?.results?.filter(
				(i) =>
					['dbt', 'dbt_core'].includes(i.type) &&
					!i.name.toLowerCase().includes('demo')
			).length === 0,
		[integrations?.results, type]
	);

	const hasMultipleGroups = keys(groups).length > 1;

	const initialValues = {
		name: integration?.name || name,
		teams: integration?.teams || [],
		ssh_tunnel: integration?.ssh_tunnel || 'no_tunnel',
		...(integration?.credentials || {}),
	};
	const defaultValues = form?.cast({});
	const defaultNonEmptyValues = omitBy(defaultValues, isNil);

	const [selectedGroup, setSelectedGroup] = useState<string>(
		integration?.credentials?._group ||
			defaultNonEmptyValues._group ||
			keys(groups)[0]
	);
	const selectedSchema = groups[selectedGroup];

	const [toggleState, setToggleState] = useState<Record<string, boolean>>({
		ssl: false,
		set_google_authorization_header: false,
		legacy_authentication: false,
	});

	const fieldsToOmit = useMemo(() => {
		const omittedFields: string[] = [];
		const selectedSchemaFields = keys(selectedSchema);
		keys(form.fields).forEach((field) => {
			if (field !== '_group' && !selectedSchemaFields.includes(field)) {
				omittedFields.push(field);
			}
		});
		return omittedFields;
	}, [form.fields, selectedSchema]);

	const formik = useFormik({
		initialValues: {
			...initialValues,
			...defaultNonEmptyValues,
		} as any,
		validationSchema: form.omit(fieldsToOmit).concat(
			Yup.object().shape({
				teams: Yup.array(Yup.string()),
			})
		),
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: async (values) => {
			setError(undefined);
			try {
				const result = await updateOrCreateIntegration(values);
				let isTestable = testable;

				if (groupSettings && selectedGroup in groupSettings) {
					// Check if the integration is OAuth and handle it
					if (groupSettings[selectedGroup]?.isOAuth) {
						handleOAuth(result);
					}

					// Check if the integration is testable
					if (groupSettings[selectedGroup]?.testable) {
						isTestable = true;
					}
				}

				const isIntegrationConnected = async (id: string) => {
					const res = await apiClient.post(
						`/integration/integrations/${id}/test_connection/`,
						{}
					);

					if (res.data.connected) {
						return true;
					}

					throw new Error(`${res.data.formattedError} ${res.data.error}`);
				};

				if (isTestable) {
					const isSuccess = await isIntegrationConnected(result.id);

					showNotification({
						title: isSuccess ? 'Connection successful' : 'Connection failed',
						message: isSuccess
							? 'The connection to the integration was successful. You can now proceed to the next step.'
							: 'If the issues persists, please contact support',
						color: isSuccess ? 'green' : 'red',
					});

					if (!isSuccess) {
						setError('Connection error');
						scrollToBottom();
					}
				}

				setIntegration?.(result);
				nextStep?.();
			} catch (error: any) {
				setError(
					`Encountered the following error: \n${JSON.stringify(error?.message ?? error, null, 2)}`
				);
				scrollToBottom();
			}
		},
	});

	const updateOrCreateIntegration = async (values: Record<string, any>) => {
		const { teams } = values;

		// Clear the tunnel values if "No tunnel" is selected
		if (values.ssh_tunnel === 'no_tunnel') {
			delete values.ssh_tunnel;
			if (integration) {
				delete integration.ssh_tunnel;
			}
		}

		// If the integration has not been created yet, create it
		if (!integration) {
			const params = {
				name: values.name || name,
				type,
			};

			const integration = await createIntegration({
				data: {
					...params,
					teams,
					credentials: omit(values, ['teams']),
				},
			});

			// Keep this event for historic activation tracking
			trackEvent(
				'integration/connection/update',
				{
					label: values.name || name,
					type: params.type,
				},
				user,
				workspace!
			);

			setIntegration?.(integration);
			return integration;
		}

		const updatedIntegration = await updateIntegration({
			data: {
				id: integration.id,
				name: values.name || name,
				credentials: {
					...omit(values, ['teams']),
					// TODO [@LikeCarter]: This component needs to be refactored and we
					//  need to decide on the consistent way to handle tunnels.
					ssh_tunnel: values?.ssh_tunnel,
					tunnel: values?.ssh_tunnel,
				},
				teams: values.teams,
				ssh_tunnel: values?.ssh_tunnel,
			},
		});

		setIntegration?.(updatedIntegration);
		return updatedIntegration;
	};

	const handleOAuth = (result: IIntegration) => {
		const baseUrl = api();
		// Use local server tunnel URL for debugging
		// baseUrl =
		// 	'https://e7ad-2607-fea8-fca0-8146-2464-b746-8c6d-7239.ngrok.io/';

		const authUrl = `${baseUrl}oauth/to_oauth/${result.id}/`;
		window.open(authUrl, '_self');
	};

	const getSubmitLabel = () => {
		if (groupSettings?.[selectedGroup]?.isOAuth) {
			return 'Connect with OAuth';
		}

		if (groupSettings?.[selectedGroup]?.testable || testable) {
			return 'Test connection';
		}

		return 'Submit';
	};

	const handleSelectGroup = (group: string) => {
		if (formik.values._group) {
			formik.setFieldValue('_group', group);
		}
		setSelectedGroup(group);
	};

	const debounceIntegrationNameChange = useCallback(
		debounce(async (value: string) => {
			if (integration) {
				await updateIntegration({
					data: {
						id: integration.id,
						name: value,
					},
				});

				showNotification({
					message: 'The integration name has been updated successfully',
					color: 'green',
				});
			}
		}, 1000),
		[integration]
	);

	const handleNameChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			formik.handleChange(event);
			if (integration) {
				debounceIntegrationNameChange(event.target.value);
			}
		},
		[debounceIntegrationNameChange, formik, integration]
	);

	const handleTeamsChange = useCallback(
		async (ids: string[]) => {
			formik.setFieldValue('teams', ids);
			if (integration) {
				await updateIntegration({
					data: {
						id: integration.id,
						teams: ids,
					},
				});

				showNotification({
					message: 'The integration teams has been updated successfully',
					color: 'green',
				});
			}
		},
		[formik, integration, updateIntegration]
	);

	return (
		<>
			{!hideTitle && <Title size="xl">Sync</Title>}
			{disableDBT && !isDemo && (
				<Banner
					tone="warning"
					message="You can proceed to connect dbt, but lineage and metadata will
				not be visible until the associated database or warehouse is integrated."
					title="The integration requires resources from a database or warehouse"
					header
				/>
			)}
			{disableGithub && !isDemo && (
				<Banner
					tone="warning"
					message="If dbt is not integrated, you will not be able to connect to
				Github."
					title="The integration requires the ID of your dbt integration"
					header
				/>
			)}
			{isDemo && (
				<Banner
					tone="warning"
					message="You can proceed to change the credentials to connect it to
				your existing data source."
					title="This integration is for demo purposes only"
					header
				/>
			)}
			<form onSubmit={formik.handleSubmit}>
				<Stack spacing="2xl">
					<Stack>
						<Title size="md">Name and workspaces</Title>
						<TextInput
							key="name"
							name="name"
							label="Integration name"
							placeholder={name}
							value={formik.values.name}
							onChange={handleNameChange}
							onBlur={formik.handleBlur}
						/>
						<Stack spacing="3xs">
							<MultiTeamsSelector
								label="Teams"
								value={formik.values.teams}
								setValue={handleTeamsChange}
								required
							/>
							<Text size="sm" color="text/secondary/default">
								The selected teams will have access to the integration
								resources.
							</Text>
						</Stack>
					</Stack>
					<Divider />
					{hasMultipleGroups && (
						<>
							<Stack spacing="4xs">
								<Title size="md">Connection type</Title>
								<Text size="sm">{`Choose how you want to connect to ${name}.`}</Text>
							</Stack>
							<Radio.Group value={selectedGroup} onChange={handleSelectGroup}>
								<Stack spacing="xs">
									{keys(groups).map((group) => (
										<ConnectionTypeRadio
											key={group}
											group={group}
											active={group === selectedGroup}
											selectGroup={handleSelectGroup}
										/>
									))}
								</Stack>
							</Radio.Group>
							<Divider />
						</>
					)}
					<Stack>
						{hasMultipleGroups && <Title size="md">{selectedGroup}</Title>}
						{/* When connecting to an AWS Bucket through AWS IAM Role */}
						{keys(selectedSchema).includes('external_id') && (
							<Box>
								<TextInput
									label="AWS Account ID"
									value={publicEnv?.AWS_ACCOUNT_ID}
									disabled
								/>
								<Text size="sm" color="text/secondary/default">
									On the AWS cross account role copy this value to the Account
									ID field
								</Text>
							</Box>
						)}
						{/* 	Filter out SSH Tunnel and OAuth Fields, which will be handled at the end */}
						{keys(selectedSchema)
							.filter((field) => !['ssh_tunnel', 'oauth'].includes(field))
							.map((field) => {
								const spec = selectedSchema[field]?.spec;

								if (spec.meta?.toggleController) {
									const toggle = spec.meta?.toggleController;

									return (
										<Checkbox
											key={toggle}
											name={field}
											label={spec.meta?.label}
											checked={toggleState[toggle]}
											onChange={(event) => {
												setToggleState((prevState) => ({
													...prevState,
													[toggle]: event.target.checked,
												}));
												formik.setFieldValue(
													spec.meta?.toggleController,
													event.target.checked
												);
											}}
										/>
									);
								}

								if (
									spec.meta?.toggleGroup &&
									!toggleState?.[spec.meta?.toggleGroup]
								) {
									return null;
								}

								if (spec.meta?.htmlType === 'file') {
									return (
										<Box key={field}>
											<FileInput
												key={field}
												name={field}
												w={theme.other.space[32]}
												multiple
												label={
													field.includes('_')
														? capitalize(field?.replaceAll('_', ' '))
														: capitalize(field)
												}
												// eslint-disable-next-line @typescript-eslint/ban-ts-comment
												// @ts-ignore poorly typed component
												placeholder={formik.values[field] ?? 'Select the file'}
												defaultValue={formik.values[field] ?? spec.default}
												onChange={async (files) => {
													if (files.length > 0) {
														if (type === 'great_expectations') {
															const reader = new FileReader();
															reader.onload = async (e) => {
																formik.setFieldValue(field, e.target?.result);
															};
															reader.readAsText(files[0]);
														} else {
															const fileType = spec.meta?.secodaFileType;

															const fileKey = await uploadFileToS3(
																files[0],
																fileType
															);

															if (fileKey !== '') {
																formik.setFieldValue(field, fileKey);
															}
														}
													}
												}}
											/>
											<Text size="sm" color="text/secondary/default">
												{spec.meta?.description}
											</Text>
										</Box>
									);
								}

								// Right now, only certificates are used in the textarea.
								// They need to be Base64 encoded, so do not add other fields
								// with textarea type, unless they are expected to be Base64 encoded.
								if (spec.meta?.htmlType === 'textarea') {
									return (
										<TextArea
											key={field}
											name={field}
											minRows={7}
											label={spec.meta?.label ?? titleFromIdentifier(field)}
											help={spec.meta?.description}
											placeholder={spec.meta?.placeholder}
											value={gracefulBase64Decode(formik.values[field] ?? '')}
											onChange={(event) =>
												formik.setFieldValue(field, btoa(event.target.value))
											}
											onBlur={formik.handleBlur}
											onDrop={(event) => {
												event.preventDefault();
												const file = event.dataTransfer.files[0];
												const reader = new FileReader();
												reader.onload = async (e) => {
													formik.setFieldValue(
														field,
														btoa(e.target?.result as string)
													);
												};
												reader.readAsText(file);
											}}
											defaultValue={gracefulBase64Decode(
												formik.values[field] ?? spec.default
											)}
										/>
									);
								}

								return (
									<TextInput
										key={field}
										name={field}
										type={spec.meta?.htmlType}
										label={spec.meta?.label ?? snakeCaseToTitleCase(field)}
										help={spec.meta?.description}
										value={formik.values[field]}
										error={formik.errors[field] as string}
										defaultValue={
											field?.endsWith('external_id')
												? // Obfuscate the workspace_id to use as the `external_id`.
													reverse(workspace.id.split('-')).join('')
												: spec.default
										}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										optional={spec.presence !== 'required'}
										disabled={spec.meta?.disabled}
										autoComplete="off"
									/>
								);
							})}
						{selectedSchema?.ssh_tunnel && (
							<Suspense fallback={<SmallLoadingSpinner />}>
								<IntegrationTunnelSelect
									formik={formik}
									onChange={(value) =>
										formik.handleChange('ssh_tunnel')(value ?? 'no_tunnel')
									}
								/>
							</Suspense>
						)}
						{/* Only applicable to Slack integration */}
						{oauth && (
							<Group spacing="3xs">
								<Text size="sm">OAuth Authorization</Text>
								<Text size="sm">{oauth.description}</Text>
								<SlackOAuthButton spec={spec as SlackIntegrationSpec} />
							</Group>
						)}
					</Stack>
					{error && (
						<Banner
							tone="critical"
							message={error}
							title="Connection failed"
							header
						/>
					)}
					<Group position="right">
						{!oauth && (
							<Button
								variant="primary"
								size="md"
								loading={formik.isSubmitting}
								type="submit"
								disabled={disableDBT || disableGithub || isDemo}
							>
								{getSubmitLabel()}
							</Button>
						)}
					</Group>
				</Stack>
			</form>
		</>
	);
}
