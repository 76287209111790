import {
	ActionIcon,
	Divider,
	Group,
	Modal,
	Stack,
	Textarea,
	TextInput,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Icon, Text } from '@repo/foundations';
import { includes } from 'lodash-es';
import { useMemo, useState } from 'react';
import {
	invalidateTagList,
	useCreateTag,
	useUpdateTag,
	type ITag,
} from '../../../../api';
import { ColorSelector, getRandomTagColor } from '../../../ColorSelector';
import { useModalStyles } from './styles';

interface TagModalProps {
	opened: boolean;
	onClose: VoidFunction;
	initialTitle?: string;
	tag?: ITag;
	allTags: Array<string>;
}

export function TagModal({
	opened,
	onClose,
	initialTitle,
	tag,
	allTags,
}: TagModalProps) {
	const { classes, theme } = useModalStyles();

	const isCreateNew = tag === undefined || tag === null || !tag.id;

	const [tagColor, setTagColor] = useState<string>(
		tag?.color ?? getRandomTagColor()
	);
	const [tagName, setTagName] = useState<string>(
		tag?.name ?? initialTitle ?? ''
	);
	const [tagDescription, setTagDescription] = useState<string>(
		tag?.description ?? ''
	);

	const tagIsUnique = useMemo(
		() => !includes(allTags, tagName),
		[tagName, allTags]
	);
	const canSave = tagName !== '' && tagIsUnique;
	const canUpdate =
		tagName !== '' &&
		(tagName !== tag?.name ||
			tagColor !== tag?.color ||
			tagDescription !== tag?.description) &&
		tagIsUnique;

	const { mutateAsync: createTag } = useCreateTag({
		options: {
			onSuccess: () => {
				showNotification({
					title: 'Tag created',
					message: `Tag "${tagName}" has been created successfully`,
					color: 'green',
				});
				invalidateTagList();
			},
			onError: () => {
				showNotification({
					title: 'An error occurred',
					message:
						'An error occurred that caused a failure while creating the tag',
					color: 'red',
				});
			},
		},
	});

	const { mutateAsync: updateTag } = useUpdateTag({
		options: {
			onSuccess: () => {
				showNotification({
					title: 'Tag updated',
					message: `Tag "${tagName}" has been updated successfully`,
					color: 'green',
				});
				invalidateTagList();
			},
			onError: () => {
				showNotification({
					title: 'An error occurred',
					message:
						'An error occurred that caused a failure while updating the tag',
					color: 'red',
				});
			},
		},
		disableOptimisticUpdate: true,
		disableInvalidation: true,
	});

	const handleAction = async () => {
		if (isCreateNew) {
			await createTag({
				data: {
					name: tagName,
					description: tagDescription,
					color: tagColor,
				},
			});
			onClose();
		} else {
			await updateTag({
				data: {
					id: tag.id,
					name: tagName,
					description: tagDescription,
					color: tagColor,
				},
			});
			onClose();
		}
	};

	return (
		<Modal.Root
			opened={opened}
			onClose={onClose}
			centered
			radius="lg"
			size={theme.other.width.md}
		>
			<Modal.Overlay />
			<Modal.Content>
				<Modal.Header className={classes.modalHeader} w="100%">
					<Group w="100%" position="apart">
						<Text weight="bold" size="md">
							{isCreateNew ? 'Create tag' : 'Edit tag'}
						</Text>
						<ActionIcon onClick={onClose}>
							<Icon name="x" color="icon/secondary/default" />
						</ActionIcon>
					</Group>
				</Modal.Header>
				<Modal.Body p={0}>
					<Stack spacing="sm" p="md">
						<Text weight="semibold" size="sm">
							Color and name
						</Text>
						<Group noWrap spacing="sm">
							<ColorSelector initialColor={tagColor} onChange={setTagColor} />
							<TextInput
								value={tagName}
								onChange={(e) => setTagName(e.target.value)}
								className={classes.textInput}
								error={
									tagIsUnique ? null : 'A tag with this name already exists.'
								}
							/>
						</Group>
						<Text weight="semibold" size="sm">
							Description&nbsp;
							<Text size="sm" color="text/secondary/default" span>
								(Optional)
							</Text>
						</Text>
						<Textarea
							value={tagDescription}
							onChange={(e) => setTagDescription(e.target.value)}
							minRows={3}
							maxRows={3}
						/>
					</Stack>
					<Divider />
					<Group position="right" p="md" spacing="sm">
						<Button onClick={onClose}>Cancel</Button>
						<Button
							disabled={isCreateNew ? !canSave : !canUpdate}
							variant="primary"
							onClick={handleAction}
						>
							{isCreateNew ? 'Save' : 'Update'}
						</Button>
					</Group>
				</Modal.Body>
			</Modal.Content>
		</Modal.Root>
	);
}
