import { Avatar, Menu, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { Button, Icon } from '@repo/foundations';
import { useNavigate } from 'react-router';
import {
	IReport,
	REPORT_TEMPLATES,
	ReportTemplateConfig,
	useAuthUser,
	useCreateReport,
	useWorkspace,
} from '../../../../api';
import { CATALOG_COLUMN_MAPPING } from '../../../../components/CatalogView/constants';
import IntegrationLogo from '../../../../components/IntegrationLogo';
import { EntityType } from '../../../../lib/types';
import { trackEvent } from '../../../../utils/analytics';

export interface ISelectReportTemplateMenuProps {
	reportId?: string;
	populateReportWithTemplate?: (template: string) => Promise<void>;
}

function SelectReportTemplateMenu({
	reportId,
	populateReportWithTemplate,
}: ISelectReportTemplateMenuProps) {
	/* Create a new report when reportId is undefined or populate the reportId with the template */
	const navigate = useNavigate();
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();

	const theme = useMantineTheme();

	const templates = [
		...REPORT_TEMPLATES,
		...(workspace.quality_enabled
			? [
					{
						value: 'data_quality',
						label: 'Quality score',
						icon: '📊',
						reportType: 'data_quality',
					} as ReportTemplateConfig,
				]
			: []),
	];

	const { mutateAsync: addReport } = useCreateReport({
		options: {
			onSuccess: () => {
				CATALOG_COLUMN_MAPPING.reports?.server?.invalidateCache?.();
				trackEvent('analytics/report/create', {}, user, workspace);
				showNotification({
					title: 'Report created',
					message: 'Report was successfully created',
					color: 'green',
				});
			},
		},
	});

	const handleAddReport = async (
		name: string,
		type: IReport['type'],
		template?: string
	) => {
		/* Create a new report when reportId is undefined or populate the reportId with the template */
		if (reportId && populateReportWithTemplate && template) {
			await populateReportWithTemplate(template);
		} else {
			const report = await addReport({
				data: {
					name,
					type,
					template,
				},
			});
			navigate(`/analytics/${report.id}`);
		}
	};

	return (
		<Menu>
			<Menu.Target>
				{reportId ? (
					<Button variant="default">Choose template</Button>
				) : (
					<Button variant="primary">Add report</Button>
				)}
			</Menu.Target>
			<Menu.Dropdown>
				{templates.map(
					({ label, value, reportType, integrationTypes, icon }) => (
						<Menu.Item
							key={value}
							onClick={() => handleAddReport(label, reportType, value)}
							icon={
								integrationTypes ? (
									<Avatar.Group pr="sm">
										{integrationTypes.map((integrationType) => (
											<IntegrationLogo
												key={integrationType}
												size={theme.other.iconSize.sm}
												integrationType={integrationType}
												entityType={EntityType.integration}
											/>
										))}
									</Avatar.Group>
								) : (
									icon && <IconWrapper>{icon}</IconWrapper>
								)
							}
						>
							{label}
						</Menu.Item>
					)
				)}
				{!reportId && (
					<>
						<Menu.Divider />
						<Menu.Item
							key="new_report"
							onClick={() => handleAddReport('New report', 'analytics')}
							icon={<Icon name="plus" />}
						>
							New blank report
						</Menu.Item>
					</>
				)}
			</Menu.Dropdown>
		</Menu>
	);
}

export default SelectReportTemplateMenu;
