import { Checkbox, createStyles, Group } from '@mantine/core';
import { Text } from '@repo/foundations';
import { NodeRendererProps } from 'react-arborist';
import type { IGroupItem } from '../../../../../api';
import { EntityType } from '../../../../../lib/types';
import { getEntityTypeDisplayInfo } from '../../../../../utils/entityDisplayUtils';

export enum VisibilityState {
	VISIBLE = 'visible',
	INDETERMINATE = 'indeterminate',
	HIDDEN = 'hidden',
}

interface ImportStepTreeNodeProps extends NodeRendererProps<IGroupItem> {
	isVisible: (node: IGroupItem) => VisibilityState;
	handleVisibilityChange: (item: IGroupItem, visible: boolean) => void;
}

const useStyles = createStyles(() => ({
	treeNodeCheckbox: {
		display: 'flex',
		alignItems: 'center',
	},
	treeNodeLabel: {
		whiteSpace: 'nowrap',
	},
}));

export function ImportStepTreeNode({
	node,
	style,
	isVisible,
	handleVisibilityChange,
}: ImportStepTreeNodeProps) {
	const { classes } = useStyles();

	const EntityIcon = getEntityTypeDisplayInfo(
		node.data.entity_type as EntityType
	).icon;

	return (
		<div style={style}>
			<Checkbox
				classNames={{
					body: classes.treeNodeCheckbox,
				}}
				checked={[
					VisibilityState.VISIBLE,
					VisibilityState.INDETERMINATE,
				].includes(isVisible(node.data))}
				indeterminate={isVisible(node.data) === VisibilityState.INDETERMINATE}
				onChange={(event) =>
					handleVisibilityChange(node.data, event.target.checked)
				}
				label={
					<Group spacing="xs" noWrap>
						<EntityIcon size={16} />
						<Text
							size="xs"
							classNames={{
								root: classes.treeNodeLabel,
							}}
						>
							{node.data.title}
						</Text>
					</Group>
				}
			/>
		</div>
	);
}
