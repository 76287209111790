import { createStyles } from '@mantine/core';
import { truncateWithEllipsis } from '@repo/common/utils';
import { Icon } from '@repo/foundations';
import { type IMetric } from '../../../api';
import SelectMenu from '../../../components/SelectMenu';
import type { Metric } from '../../../lib/models';
import { useQueryableIntegrationOptions } from './IntegrationSelectAction.hooks';

const useStyles = createStyles((theme, { compact }: { compact: boolean }) => ({
	input: {
		padding: 0,
	},
	select: {
		width: compact ? theme.other.space[12] * 1.25 : undefined,
	},
	dropdown: {
		minWidth: theme.other.space[60],
	},
	outline: {
		input: {
			backgroundColor: 'transparent',
			color: theme.other.getColor('text/secondary/default'),
			fontWeight: theme.other.typography.weight.semibold,
			'&:hover': {
				backgroundColor: theme.other.getColor('surface/primary/hover'),
			},
		},
	},
}));

export function IntegrationSelectAction({
	onChange,
	metric,
	withCompact = false,
	disabled = false,
}: {
	onChange: (value: string) => void;
	metric: IMetric | Metric;
	withCompact?: boolean;
	disabled?: boolean;
}) {
	// NOTE: While checking the pathname is not the most elegant way to determine
	// whether we are on the questions page, it's much less resource intensive
	// than checking with a "resize" event listener.
	const compact =
		withCompact || window.location.pathname.includes('/questions');

	const { classes } = useStyles({ compact });
	const data = useQueryableIntegrationOptions();

	const selected = data.find((item) => item.value === metric.integration);
	const placeholder = truncateWithEllipsis(
		selected ? selected.label : 'Select integration',
		19
	);

	return (
		<SelectMenu
			disabled={disabled}
			placeholder={compact ? '' : placeholder}
			searchValue={compact ? '' : placeholder}
			icon={selected?.icon ?? <Icon name="database" />}
			classNames={{
				dropdown: classes.dropdown,
				input: classes.input,
			}}
			variant="default"
			defaultValue={metric.integration}
			onChange={onChange}
			data={data}
		/>
	);
}
