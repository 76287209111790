import { Box, CloseButton, createStyles, Group } from '@mantine/core';
import { Badge } from '@repo/foundations';
import { getColor } from '@repo/theme/utils';
import { map } from 'lodash-es';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

interface IResourceFieldItemProps {
	label: string;
	subLabel?: string;
	value: string;
	icon: React.ReactNode;
	onAdd?: () => void;
	onRemove?: () => void;
}

interface IFormResourceFieldTargetProps {
	selected: IResourceFieldItemProps[];
}

const useStyles = createStyles((theme) => ({
	wrapper: {
		minHeight: 36,
		gap: theme.spacing['2xs'],
		padding: `${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing.sm}`,
		border: `0.5px solid ${getColor('border/secondary/default')}`,
		borderRadius: theme.radius.sm,
		backgroundColor: getColor('surface/input/default'),
		'&:focus': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
		'&:active': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
	},
}));

// eslint-disable-next-line react/display-name
const FormResourceFieldTarget = forwardRef(
	(
		{ selected, ...others }: IFormResourceFieldTargetProps,
		ref: ForwardedRef<HTMLDivElement>
	) => {
		const { classes, theme } = useStyles();

		const handleRemove = (item: IResourceFieldItemProps) => {
			if (item.onRemove) {
				item.onRemove();
			}
		};

		return (
			<Box ref={ref} {...others}>
				<Group className={classes.wrapper}>
					{map(selected, (item, index) => (
						<Badge key={index} variant="default">
							<Group spacing="xs">
								<Group spacing={theme.spacing['3xs']}>
									{item.icon}
									{item.label}
								</Group>
								<CloseButton iconSize={12} onClick={() => handleRemove(item)} />
							</Group>
						</Badge>
					))}
				</Group>
			</Box>
		);
	}
);

export default FormResourceFieldTarget;
