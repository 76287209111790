/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from 'react';
import { useParams } from 'react-router';

import { useAuthUser, useSchema, useUpdateSchema } from '../../api';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';

import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import SchemaEntityTabs from './SchemaEntityTabs';
import { SchemaEntityTabsStore } from './SchemaEntityTabs/SchemaEntityTabsStore';

export interface ISchemaPageProps {
	id?: string;
}

function SchemaPage({ id: propsId }: ISchemaPageProps) {
	const { tab } = useParams();
	const [schemaEntityTabsStore] = useState(new SchemaEntityTabsStore());
	const { user, workspace } = useAuthUser();
	const paramsId = useParamsIdSuffixUuid();

	const id = propsId || paramsId;

	const { data: schema } = useSchema({
		id,
		options: {
			onSettled: () => {
				trackEvent('schema/open', {}, user, workspace);
			},
		},
	});

	const { mutateAsync } = useUpdateSchema({});

	// Debouncing this is not a good idea, because it overwrites changes, does not
	// queue them.
	const updateSchema = async (
		key: string,
		value: DjangoValueType,
		saveRemotely = true
	) => {
		if (saveRemotely) {
			mutateAsync({
				data: {
					id: schema!.id,
					[key]: value,
				},
			});
			trackEvent('schema/properties/update', {}, user, workspace!);
		}
	};

	if (!schema) {
		return null;
	}

	return (
		<EntityPageLayout
			key={schema.id}
			name="Schema"
			isReadOnlyTitle
			entity={schema}
			icon={<EntityLogo size={32} entity={schema} />}
			updateEntity={updateSchema}
			withDescription
			withFrequentUsers
			withOwnerSelector
			withCollectionSelector
			withCustomPropertyEditors
			withTagSelector
			withEntityPopularity
			withRelatedResourceSelector
		>
			<SchemaEntityTabs
				key={schema.id}
				schemaEntityTabsStore={schemaEntityTabsStore}
				schema={schema}
				tab={tab}
			/>
		</EntityPageLayout>
	);
}

export default SchemaPage;
