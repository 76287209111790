import { Divider, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import { map } from 'lodash-es';
import { useMemo } from 'react';
import type { ISecodaEntity } from '../../../api';
import { useAuthUser, useIncident } from '../../../api';
import { useExtendedUserList } from '../../../api/hooks/user/useExtendedUserList';
import {
	SelectableProperty,
	StaticProperty,
} from '../../../components/EntityPageLayout/EntityPropertySidebar';
import EntityPageSidebarWrapper from '../../../components/EntityPageLayout/EntitySidebarWrapper';
import { SecodaEntityIcon } from '../../../components/SecodaEntity';

export interface IIncidentPageSidebarProps {
	incidentId?: string;
	targetEntity?: ISecodaEntity;
}

function IncidentPageSidebar({
	incidentId,
	targetEntity,
}: IIncidentPageSidebarProps) {
	const { data: incident } = useIncident({
		id: incidentId as string, // 'id' cannot be undefined here as enabled blocks it
		options: {
			enabled: Boolean(incidentId),
		},
	});

	const { isViewerOrGuestUser } = useAuthUser();

	const { activeUsers: users } = useExtendedUserList({});

	const owners = useMemo(
		() =>
			map(users, (u) => ({
				label: u.display_name,
				value: u.id,
				icon: u.profile_picture,
				navigateTo: `/user/${u.id}`,
			})),
		[users]
	);

	const lastRunAt = dayjs(incident?.last_seen_at).format('MMM D, YYYY h:mm A');

	const firstSeenAt = dayjs(incident?.first_seen_at).format(
		'MMM D, YYYY h:mm A'
	);

	const acknowledgedAt = incident?.acknowledged_at
		? dayjs(incident?.acknowledged_at).format('MMM D, YYYY h:mm A')
		: null;

	const resolvedAt = incident?.resolved_at
		? dayjs(incident?.resolved_at).format('MMM D, YYYY h:mm A')
		: null;

	const targetEntityOptions = [
		{
			label: targetEntity?.title ?? '',
			value: targetEntity?.id ?? '',
			icon: <SecodaEntityIcon entity={targetEntity} size={14} />,
			navigateTo: `/table/${targetEntity?.id}`,
		},
	];

	return (
		<EntityPageSidebarWrapper mode="info">
			<Stack>
				<Stack spacing="xs" px="sm" pt="md">
					<SelectableProperty
						selected={incident?.owner as string}
						type="single"
						label="Owner"
						value="owner"
						iconType="avatar"
						isViewerUser={isViewerOrGuestUser}
						searchable
						options={owners}
						readOnly
					/>
					{targetEntity && (
						<SelectableProperty
							selected={incident?.target as string}
							type="single"
							label="Resource"
							value="resource"
							iconType="react-node"
							isViewerUser={isViewerOrGuestUser}
							searchable
							readOnly
							options={targetEntityOptions}
						/>
					)}
				</Stack>
				<Divider />
				<Stack spacing="xs" px="sm">
					<StaticProperty type="text" label="First seen" value={firstSeenAt} />
					<StaticProperty type="text" label="Last seen" value={lastRunAt} />
					{acknowledgedAt && (
						<StaticProperty
							type="text"
							label="Acknowledged"
							value={acknowledgedAt}
						/>
					)}
					{resolvedAt && (
						<StaticProperty type="text" label="Resolved" value={resolvedAt} />
					)}
				</Stack>
			</Stack>
		</EntityPageSidebarWrapper>
	);
}

export default IncidentPageSidebar;
