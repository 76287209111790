import { map } from 'lodash-es';
import { CATALOG_COLUMN_MAPPING } from '../../components/CatalogView/constants';

export const ARCHIVED_TABLE_DATA = {
	restoreMessage: {
		title: 'Resources restored',
		message:
			'The resources have been unarchived, and are available in your workspace',
	},
	restoreData: { archived: false, archived_at: null },
	queryFilters: { archived: true },
};

export const STALE_TABLE_DATA = {
	restoreMessage: {
		title: 'Resources restored',
		message:
			'The resources have been unstaled, and are available in your workspace',
	},
	restoreData: {
		stale: false,
		forced_unstale_by_user: true,
		stale_at: null,
	},
	queryFilters: { stale: true },
};

export const TRASH_COLUMN_DEFS = map(
	[
		{
			field: 'title',
			headerName: 'Title',
			editable: false,
			sortable: true,

			checkboxSelection: true,
			pinned: 'left',
			lockPosition: 'left',
			suppressMovable: true,
			minWidth: 250,
			disableLink: true,
		} as any,
		{ field: 'entity_type', headerName: 'Resource Type' },
		...Object.values(CATALOG_COLUMN_MAPPING.entities.columns),
	],
	(column: any) => ({ ...column, editable: false })
);
