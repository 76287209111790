import { Group, Menu } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import type { FilterMenuItem } from '../FilterCarousel.constants';
import { SearchFilterStoreContext } from '../store';

interface IFilterDropdownHeaderProps {
	filterOption: FilterMenuItem;
	state: boolean;
	onIsIncludeChange: (state: boolean) => void;
	onDeleteFilter?: () => void;
	withIsNotMenu?: boolean;
}

function FilterDropdownHeader({
	filterOption,
	state,
	onIsIncludeChange,
	onDeleteFilter,
	withIsNotMenu = true,
}: IFilterDropdownHeaderProps) {
	const searchFilterStore = useContext(SearchFilterStoreContext);
	const handleMenuOnChange = (opened: boolean) => {
		searchFilterStore.setFilterDropdownHeaderUI('isMenuOpen', opened);
	};

	const handleDeleteFilter = () => {
		searchFilterStore.deleteFilter(filterOption.value);
		if (onDeleteFilter) {
			onDeleteFilter();
		}
	};

	const handleIsIncludeChange = () => {
		onIsIncludeChange(true);
	};

	const handleIsNotIncludeChange = () => {
		onIsIncludeChange(false);
	};

	return (
		<Group position="apart" mb={6}>
			<Group spacing={0}>
				<Text size="sm" color="text/secondary/default" mr={2}>
					{filterOption.label}{' '}
				</Text>
				{withIsNotMenu && (
					<Menu width={180} onChange={handleMenuOnChange} withinPortal>
						<Menu.Target>
							<Button variant="tertiary" rightIconName="chevronDown" p={2}>
								{state ? 'is' : 'is not'}
							</Button>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item onClick={handleIsIncludeChange}>is</Menu.Item>
							<Menu.Item onClick={handleIsNotIncludeChange}>is not</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				)}
			</Group>
			<Button variant="tertiary" onClick={handleDeleteFilter}>
				Delete Filter
			</Button>
		</Group>
	);
}

export default observer(FilterDropdownHeader);
