import { createStyles, Stack } from '@mantine/core';
import { Button } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { isNil, map } from 'lodash-es';
import type { IForm } from '../../../../api';
import {
	useCreateFormSubmission,
	useDraftFormSubmission,
	useSubmitFormSubmission,
} from '../../../../api/hooks/forms';
import { FullWidthLoadingSpinner } from '../../../LoadingSpinner';
import { FormField } from './FormField';

interface IPreviewProps {
	form: IForm;
}

const useStyles = createStyles((theme) => ({
	container: {
		width: '100%',
		maxWidth: space[160],
		paddingTop: theme.spacing.md,
		paddingBottom: theme.spacing.md,
		gap: theme.spacing.xl,
	},
}));

export function PreviewTab({ form }: IPreviewProps) {
	const { classes } = useStyles();

	const { mutate: createFormSubmission } = useCreateFormSubmission();

	const { data: draftSubmission, isLoading: isDraftSubmissionLoading } =
		useDraftFormSubmission(form.id as string, {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			retry: false,
			onError: () => {
				createFormSubmission({
					form: form.id as string,
				});
			},
		});

	const { mutate: submitFormSubmission } = useSubmitFormSubmission();

	if (isNil(draftSubmission) || isDraftSubmissionLoading) {
		return <FullWidthLoadingSpinner />;
	}

	const handleFormSubmission = () => {
		if (isNil(draftSubmission)) {
			return;
		}

		submitFormSubmission({
			submissionId: draftSubmission.id as string,
		});
	};

	return (
		<Stack className={classes.container}>
			<Stack>
				{map(form.fields, (field) => (
					<FormField
						key={field.id}
						field={field}
						submission={draftSubmission}
					/>
				))}
			</Stack>
			<Button variant="primary" w="fit-content" onClick={handleFormSubmission}>
				Submit for review
			</Button>
		</Stack>
	);
}
