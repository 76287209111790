import { useDebouncedValue, useInputState } from '@mantine/hooks';
import { Text } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useEffect, useState } from 'react';
import type { IFormField } from '../../../../../api';
import FormInputWrapper from '../../../FormInputWrapper';
import { AnchorButton } from './AnchorButton';
import { TextInputWithActions } from './TextInputWithActions';

interface IFormFieldHelperTextProps {
	field: IFormField;
	updateField: (field: IFormField) => void;
}

export default function FormFieldHelperText({
	field,
	updateField,
}: IFormFieldHelperTextProps) {
	const [helperText, setHelperText] = useInputState(field.helper_text);
	const [debouncedHelperText] = useDebouncedValue(helperText, 500);

	const [showHelperText, setShowHelperText] = useState(
		!isEmpty(field.helper_text)
	);

	useEffect(() => {
		if (helperText !== field.helper_text) {
			setHelperText(field.helper_text);
		}
	}, [field.helper_text]);

	useEffect(() => {
		if (debouncedHelperText !== field.helper_text) {
			updateField({ ...field, helper_text: helperText });
		}
	}, [debouncedHelperText]);

	const handleAddHelperText = () => {
		setShowHelperText(true);
	};

	const handleDeleteHelperText = () => {
		setShowHelperText(false);
		updateField({
			...field,
			helper_text: '',
		});
	};

	return (
		<>
			{showHelperText && (
				<FormInputWrapper label="Helper text" isOptional>
					<TextInputWithActions
						value={helperText}
						onChange={setHelperText}
						actions={[
							{
								icon: 'trash',
								onClick: handleDeleteHelperText,
							},
						]}
					/>
					<Text size="sm" color="text/secondary/default">
						Helper text will appear below the question, just like this text
					</Text>
				</FormInputWrapper>
			)}
			{!showHelperText && (
				<AnchorButton
					label="Add helper text"
					icon="plus"
					onClick={handleAddHelperText}
				/>
			)}
		</>
	);
}
