import { KeyboardShortcut } from '@repo/common/components/KeyboardShortcut/KeyboardShortcut';
import { Breadcrumbs, Button, WithConditionalTooltip } from '@repo/foundations';
import { find, isNil, lowerCase } from 'lodash-es';
import type { MouseEvent } from 'react';
import { useNavigate } from 'react-router';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import { useAuthUser } from '../../../api';
import { EntityType } from '../../../lib/types';
import { useHomepageTeam } from '../../../pages/HomePage/hooks/useHomepageTeam';
import { getModKey, isModKey } from '../../../utils/keyboard';
import { entityModalStore } from '../../EntityModal/store';
import { TemplatesButtonGroup } from '../../Templates/TemplatesButtonGroup';
import { TEAM_CATALOG_PAGE_PATHS } from './constants';

const CREATE_AND_OPEN_ENABLED_ENTITIES = [
	EntityType.document,
	EntityType.dictionary_term,
	EntityType.glossary_term,
	EntityType.collection,
	EntityType.metric,
];

function TeamCatalogPageNavBar() {
	const { user, isAdminUser } = useAuthUser();
	const { team, isTeamEditorUser } = useHomepageTeam();
	const page = find(TEAM_CATALOG_PAGE_PATHS, ({ pattern }) =>
		pattern.test(window.location.pathname)
	);

	const navigate = useNavigate();

	if (isNil(team) || isNil(page)) {
		return null;
	}

	const isUserCreatable =
		isTeamEditorUser || isAdminUser || page.entityType === EntityType.question;
	const hasEntityType = !isNil(page.entityType);
	const withTemplate = page.entityType !== EntityType.metric;
	const canQuickCreate = CREATE_AND_OPEN_ENABLED_ENTITIES.includes(
		page.entityType!
	);

	const handleNewEntity = (ev: MouseEvent<HTMLButtonElement>) => {
		if (hasEntityType) {
			if (canQuickCreate && isModKey(ev.nativeEvent)) {
				entityModalStore.setTitle('Untitled');
				entityModalStore.setTeamIds([team.id]);
				entityModalStore.quickCreateEntity(
					page.entityType!,
					navigate,
					user,
					true
				);
				return;
			}

			entityModalStore.setEntity('create', page.entityType!, team.id);
			entityModalStore.setMetadata('owners', [user.id]);
		}
	};

	const tooltip = canQuickCreate ? (
		<KeyboardShortcut keys={[getModKey(), 'Click']}>
			Create and open {lowerCase(page.entityType)}
		</KeyboardShortcut>
	) : undefined;

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Breadcrumbs
					crumbs={[
						{
							title: team.name,
							icon: team.icon || undefined,
							href: `/teams/${team.id}`,
						},
						{ title: page.name, href: `/teams/${team.id}/${page.path}` },
					]}
				/>
			</NavBarBreadcrumbWrapper>

			<NavBarRightSideWrapper>
				{hasEntityType && withTemplate && isUserCreatable && (
					<TemplatesButtonGroup
						handleNew={handleNewEntity}
						type={page.entityType!}
						withSetDefault
						leftButtonTooltip={tooltip}
					/>
				)}
				{hasEntityType && !withTemplate && isUserCreatable && (
					<WithConditionalTooltip tooltipLabel={tooltip}>
						<Button onClick={handleNewEntity} variant="primary">
							Create {lowerCase(page.entityType)}
						</Button>
					</WithConditionalTooltip>
				)}
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default TeamCatalogPageNavBar;
