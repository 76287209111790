import { ActionIcon, createStyles, getStylesRef, Group } from '@mantine/core';
import { Icon, type IconNames, Title } from '@repo/foundations';
import { getColor } from '@repo/theme/utils';
import { find, includes } from 'lodash-es';
import { useMemo } from 'react';
import type { IFormField } from '../../../../../api';
import { INPUT_TYPES } from './constants';

interface IFormQuestionHeaderProps {
	field: IFormField;
	numFields: number;
	moveField: (direction: 'UP' | 'DOWN') => void;
	deleteField: () => void;
}

const useStyles = createStyles((theme) => ({
	content: {
		padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.md}`,
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottom: `1px solid ${getColor('border/secondary/default')}`,
		background: getColor('surface/secondary/default'),
	},
	actionIcons: {
		ref: getStylesRef('formQuestionHeaderActionIcons'),
		visibility: 'hidden',
		gap: theme.spacing['3xs'],
	},
}));

export default function FormQuestionHeader({
	field,
	numFields,
	moveField,
	deleteField,
}: IFormQuestionHeaderProps) {
	const { classes, theme } = useStyles();

	const label = useMemo(() => {
		if (includes(['select', 'multi-select'], field.input_type)) {
			return 'Dropdown';
		}
		return find(INPUT_TYPES, { value: field.input_type })?.label;
	}, [field.input_type]);

	const iconName = useMemo(() => {
		if (includes(['select', 'multi-select'], field.input_type)) {
			return 'selector';
		}

		return find(INPUT_TYPES, { value: field.input_type })?.icon;
	}, [field.input_type]);

	return (
		<Group className={classes.content}>
			<Group spacing="xs">
				<Icon name={iconName as IconNames} />
				<Title size="sm">{field.label}</Title>
			</Group>
			<Group className={classes.actionIcons}>
				<ActionIcon
					size="sm"
					onClick={() => moveField('UP')}
					disabled={field.order === 0}
				>
					<Icon name="arrowUp" />
				</ActionIcon>
				<ActionIcon
					size="sm"
					onClick={() => moveField('DOWN')}
					disabled={field.order === numFields - 1}
				>
					<Icon name="arrowDown" />
				</ActionIcon>
				<ActionIcon size="sm" onClick={deleteField}>
					<Icon name="trash" />
				</ActionIcon>
			</Group>
		</Group>
	);
}
