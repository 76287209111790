import { apiQueryKey } from '@repo/api-codegen';
import { useMutation } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import type { Incident, IncidentStatus } from '../../types';
import {
	incidentsQueryKeyFactory,
	measurementsQueryKeyFactory,
	monitorsQueryKeyFactory,
} from './constants';

interface RequestData {
	data: {
		id: string;
		monitorId: string;
		status: Exclude<IncidentStatus, 'active' | 'expired'>;
	};
	signal?: AbortSignal;
}

export function useIncidentStatusUpdate() {
	return useMutation({
		mutationFn: async ({ data, signal }: RequestData) => {
			let path = 'acknowledge';

			if (data.status === 'acknowledged') {
				path = 'acknowledge';
			}

			if (data.status === 'resolved') {
				path = 'resolve';
			}

			const url = getEndpoints(incidentsQueryKeyFactory.namespace).byPath([
				data.id,
				path,
			]);

			const { data: responseData } = await apiClient.post<Incident>(url, data, {
				signal,
			});
			return responseData;
		},
		onSettled: async (data, error, variables) => {
			const { id, monitorId } = variables.data;
			await queryClient.invalidateQueries(
				monitorsQueryKeyFactory.byId(monitorId)
			);
			await queryClient.invalidateQueries(monitorsQueryKeyFactory.allLists());
			await queryClient.invalidateQueries(incidentsQueryKeyFactory.byId(id));
			await queryClient.invalidateQueries(incidentsQueryKeyFactory.allLists());
			await queryClient.invalidateQueries(measurementsQueryKeyFactory.all());
			await queryClient.invalidateQueries(
				apiQueryKey('audit/{resource_type}/{resource_id}', {
					resource_type: 'incident',
					resource_id: id,
				})
			);
		},
	});
}
