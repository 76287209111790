import {
	Box,
	Center,
	createStyles,
	Group,
	LoadingOverlay,
	Stack,
} from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { Button, Icon, Text, TextInput, Title } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Tree } from 'react-arborist';
import {
	type IGroupItem,
	IIntegration,
	useIntegrationGroupItems,
	useIntegrationGroupItemsRefreshSupport,
	useIntegrationGroupSettings,
	useRefreshIntegrationGroupItems,
	useUpdateIntegrationGroupSettings,
} from '../../../../../api';
import { IntegrationSpec } from '../../../../../interfaces/IntegrationSpec.ts';
import useIntegrationExtraction from '../../useIntegrationExtraction';
import { ImportStepTreeNode, VisibilityState } from './ImportStepTreeNode';
import { filterGroupItems, getAllDescendants } from './utils';

const useStyles = createStyles((theme) => ({
	treeWrapper: {
		position: 'relative',
		backgroundColor: theme.other.getColor('fill/primary/hover'),
		borderRadius: theme.radius.sm,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.other.getColor('border/secondary/default'),
		padding: `${theme.spacing.xs} ${theme.spacing.md}`,
	},
}));

interface SelectStepProps {
	integration: IIntegration;
	integrationSpec: IntegrationSpec;
	nextStep?: () => void;
}

export function SelectStep({
	integration,
	integrationSpec,
	nextStep,
}: SelectStepProps) {
	const { classes, theme } = useStyles();

	const { extractionJobs, polling, handleRunExtraction } =
		useIntegrationExtraction({ id: integration.id });

	const { data: groupItems, isLoading: isLoadingGroupItems } =
		useIntegrationGroupItems(integration.id);
	const { data: supportRefresh, isLoading: isLoadingRefreshSupport } =
		useIntegrationGroupItemsRefreshSupport(integration.type);
	const { data: groupSettings, isLoading: isLoadingGroupSettings } =
		useIntegrationGroupSettings(integration.id);

	const [searchTerm, setSearchTerm] = useInputState('');
	const [groupSettingsState, setGroupSettingsState] = useState(
		groupSettings || {}
	);
	const [filteredGroupItems, setFilteredGroupItems] = useState<IGroupItem[]>(
		groupItems || []
	);

	const {
		mutateAsync: apiRefreshIntegrationGroupItems,
		isLoading: isRefreshing,
	} = useRefreshIntegrationGroupItems(integration.id);
	const { mutateAsync: apiUpdateIntegrationGroupSettings } =
		useUpdateIntegrationGroupSettings(integration.id);

	useEffect(() => {
		setGroupSettingsState(groupSettings || {});
	}, [groupSettings]);

	useEffect(() => {
		if (groupItems) {
			setFilteredGroupItems(filterGroupItems(groupItems, searchTerm));
		}
	}, [groupItems, searchTerm]);

	useEffect(() => {
		apiRefreshIntegrationGroupItems();
	}, [apiRefreshIntegrationGroupItems]);

	const updateGroupSettings = useCallback(async () => {
		try {
			await apiUpdateIntegrationGroupSettings({
				group_settings: groupSettingsState,
			});

			if (extractionJobs?.length === 0) {
				handleRunExtraction();
			}

			nextStep?.();
		} catch {
			showNotification({
				message: 'Unable to update group settings',
				color: 'red',
				icon: <Icon name="x" />,
			});
		}
	}, [apiUpdateIntegrationGroupSettings, groupSettingsState, nextStep]);

	const refreshIntegrationGroupItems = useCallback(async () => {
		try {
			await apiRefreshIntegrationGroupItems();
		} catch {
			showNotification({
				title: 'Error refreshing groups',
				message: 'Please contact support if the issue persists.',
				color: 'red',
				icon: <Icon name="x" />,
			});
		}
	}, [apiRefreshIntegrationGroupItems]);

	const handleVisibilityChange = useCallback(
		(node: IGroupItem, visible: boolean) => {
			const descendants = getAllDescendants(node);
			const newState = descendants.reduce(
				(acc, descendant) => ({
					...acc,
					[descendant.databuilder_id]: {
						visible,
					},
				}),
				groupSettingsState
			);

			setGroupSettingsState(newState);
		},
		[groupSettingsState]
	);

	const isVisible = useCallback(
		(node: IGroupItem) => {
			// If the integration is a builtin integration and supports schema, we need to handle the visibility of the nodes differently
			if (
				integrationSpec.type === 'builtin' &&
				integrationSpec.value.schemaSupport
			) {
				const descendants = getAllDescendants(node).filter(
					(d) => d.databuilder_id !== node.databuilder_id
				);

				// If there are no descendants, check the visibility of the current node
				if (descendants.length === 0) {
					return (groupSettingsState[node.databuilder_id]?.visible ?? true)
						? VisibilityState.VISIBLE
						: VisibilityState.HIDDEN;
				}

				const allVisible = descendants.every(
					(descendant) =>
						groupSettingsState[descendant.databuilder_id]?.visible ?? true
				);
				const someVisible = descendants.some(
					(descendant) =>
						groupSettingsState[descendant.databuilder_id]?.visible ?? true
				);

				if (allVisible) {
					return VisibilityState.VISIBLE;
				}
				if (someVisible) {
					return VisibilityState.INDETERMINATE;
				}
				return VisibilityState.HIDDEN;
			}

			if (
				node.databuilder_id in groupSettingsState &&
				groupSettingsState[node.databuilder_id]
			) {
				return groupSettingsState[node.databuilder_id].visible
					? VisibilityState.VISIBLE
					: VisibilityState.HIDDEN;
			}

			return VisibilityState.VISIBLE;
		},
		[groupSettingsState, groupItems]
	);

	const isLoading = useMemo(
		() =>
			isLoadingGroupItems ||
			isLoadingRefreshSupport ||
			isLoadingGroupSettings ||
			isRefreshing,
		[
			isLoadingGroupItems,
			isLoadingRefreshSupport,
			isLoadingGroupSettings,
			isRefreshing,
		]
	);

	const subtitle = useMemo(() => {
		if (integrationSpec.type === 'builtin') {
			return `Confirm the ${integrationSpec.value.schemaSupport ? 'schemas' : 'folders'} you'd like to include in the sync`;
		}
		return '';
	}, []);

	return (
		<>
			<Stack spacing="xs">
				<Title size="xl">Import</Title>
				<Text size="sm">{subtitle}</Text>
			</Stack>
			<Stack spacing="sm">
				<Group spacing="sm" noWrap>
					<TextInput
						size="sm"
						iconName="search"
						placeholder="Search"
						value={searchTerm}
						onChange={setSearchTerm}
					/>
					{supportRefresh && (
						<Button
							variant="default"
							size="md"
							leftIconName="refresh"
							onClick={refreshIntegrationGroupItems}
						>
							Refresh
						</Button>
					)}
				</Group>
				<Box className={classes.treeWrapper}>
					<LoadingOverlay visible={isLoading} />
					<Tree
						height={space[90]}
						width="100%"
						data={filteredGroupItems}
						idAccessor="databuilder_id"
						childrenAccessor="children"
					>
						{({ node, style, ...others }) => (
							<ImportStepTreeNode
								node={node}
								style={style}
								isVisible={isVisible}
								handleVisibilityChange={handleVisibilityChange}
								{...others}
							/>
						)}
					</Tree>
				</Box>
			</Stack>
			<Center>
				<Button variant="primary" size="md" onClick={updateGroupSettings}>
					{nextStep ? 'Continue to sync' : 'Save'}
				</Button>
			</Center>
		</>
	);
}
