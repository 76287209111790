import { IconButton } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { entitySideBarStore } from '../EntityPageLayout/store';

function CommentCollapse() {
	const { collapsed, mode, setCollapsed, setMode } = entitySideBarStore;

	const toggleEntitySideBar = () => {
		if (collapsed) {
			setCollapsed(false);
			setMode('comment');
		} else if (mode === 'comment') {
			setCollapsed(!entitySideBarStore.collapsed);
		} else {
			setMode('comment');
		}
	};

	const isOpen = !collapsed && mode === 'comment';

	return (
		<IconButton
			tooltip={`${isOpen ? 'Hide' : 'Show'} comments`}
			iconName={isOpen ? 'messageFilled' : 'message'}
			variant="tertiary"
			onClick={toggleEntitySideBar}
			highlight={isOpen}
		/>
	);
}

export default observer(CommentCollapse);
