import type { MantineTheme } from '@mantine/core';
import { Box, createStyles, Group, Stack, Tooltip } from '@mantine/core';
import { KeyboardShortcut } from '@repo/common/components/KeyboardShortcut/KeyboardShortcut';
import { IconButton, Text } from '@repo/foundations';
import { type KeyboardEvent } from 'react';
import { trimMarkdown } from '../../pages/QuestionPage/components/helpers';
import { RichEditor } from '../RichEditor';
import { useAIAssistantContext } from './context';
import { AIAssistantMode } from './types';

const useStyles = createStyles(
	(theme: MantineTheme, { isLoading }: { isLoading: boolean }) => ({
		inputWrapper: {
			boxShadow: `0px 4px 12px 0px ${theme.colors['black-alpha'][2]}`,
			backgroundColor: theme.other.getColor('surface/input/default'),
			border: `1px solid ${theme.other.getColor('border/input/default')}`,
			borderRadius: theme.radius.md,
			padding: `${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.md}`,
			gap: theme.spacing.xs,
			'&:focus-within': isLoading
				? {}
				: {
						outline: `2px solid ${theme.colors.blue[5]}`,
						outlineOffset: '1px',
					},
		},
		input: {
			// CSS for RichEditor
			flex: '1 !important', // The editor forces `flex-grow: 0` when in readOnly mode
			'&:focus': {
				borderColor: theme.colors.blue[5],
			},
			'&:hover,&:focus': {
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: theme.colors.gray[5],
				},
			},
			// Scrollbar styling
			'&::-webkit-scrollbar': {
				backgroundColor: 'transparent',
				width: theme.spacing.xs,
			},
			'&::-webkit-scrollbar-track': {
				backgroundColor: 'transparent',
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: 'transparent',

				borderRadius: theme.radius.sm,
			},
			'&::-webkit-scrollbar-thumb:hover': {
				backgroundColor: theme.colors.gray[6],
			},
			// Outline editor
			'& .ProseMirror > *:last-child': {
				marginBottom: 0,
			},
		},
		actionIcon: {
			backgroundColor: theme.other.getColor('fill/brand/default'),
			color: theme.other.getColor('text/brand-on-fill/default'),

			'&[data-disabled]': {
				backgroundColor: theme.other.getColor('fill/brand/disabled'),
				color: theme.other.getColor('text/brand-on-fill/disabled'),
			},

			'&:hover, &:focus': {
				backgroundColor: theme.other.getColor('fill/brand/hover'),
				color: theme.other.getColor('text/brand-on-fill/hover'),
			},

			'&:active': {
				backgroundColor: theme.other.getColor('fill/brand/active'),
				color: theme.other.getColor('text/brand-on-fill/active'),
			},
		},
		sendTooltip: {
			backgroundColor: theme.other.getColor('fill/primary/default'),
			color: theme.other.getColor('text/primary/default'),
			borderRadius: theme.radius.sm,
			border: `1px solid ${theme.other.getColor('border/primary/default')}`,
		},
	})
);

type AIPromptInputProps = {
	value: string;
	onSubmit: VoidFunction;
	onChange: (value: string) => void;
	isLoading: boolean;
	onStop?: VoidFunction;
};

export function AIPromptInput({
	value,
	onSubmit,
	onChange,
	isLoading,
	onStop,
}: AIPromptInputProps) {
	const isEmpty = !trimMarkdown(value);

	const { classes, cx } = useStyles({ isLoading });

	const { mode } = useAIAssistantContext();

	const handleChange = (input?: string) => {
		onChange(input || '');
	};

	const handleKeyDown = (event: KeyboardEvent) => {
		if (!isEmpty && event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();
			onSubmit();
		}
	};

	const isSidebar = mode === AIAssistantMode.SIDEBAR;
	const actionIconSize = isSidebar ? 'sm' : 'md';
	const showDisclaimer = !isSidebar;

	return (
		<Stack spacing="xs">
			<Group
				className={classes.inputWrapper}
				onKeyDown={handleKeyDown}
				position="apart"
			>
				<Box className={cx(classes.input, 'ai-prompt-input')}>
					<RichEditor
						dataTestId="ai-prompt-input"
						initialValue={value}
						placeholder={
							isSidebar
								? 'Ask a question...'
								: "Ask a question, press '@' to mention a resource..."
						}
						onChangeCallback={handleChange}
						readOnly={isLoading}
						disableTopGap
						showMentionMenuButton
						disableExtensions={[
							'emoji',
							'emojimenu',
							'query_block',
							'chart_block',
							'image',
							'embed',
							'holy',
							'page',
							'attachment',
							'comment',
							'blockmenu',
						]}
						singleLineEditor
						disableResourceLinking
						disableChangeCallbackDebounce
						autoFocus
					/>
				</Box>
				<Group spacing="3xs">
					{isLoading ? (
						<Tooltip withArrow arrowSize={8} label="Stop" position="top">
							<IconButton
								iconName="playerStop"
								data-testid="stop-button"
								size={actionIconSize}
								onClick={onStop}
								variant="default"
							/>
						</Tooltip>
					) : (
						<Tooltip
							withArrow
							arrowSize={8}
							disabled={isEmpty}
							label={
								<KeyboardShortcut keys={['Enter']} variant="dark">
									Send message
								</KeyboardShortcut>
							}
							position="top"
						>
							<IconButton
								iconName="arrowUp"
								data-testid="submit-button"
								disabled={isEmpty}
								size={actionIconSize}
								onClick={onSubmit}
								className={classes.actionIcon}
							/>
						</Tooltip>
					)}
				</Group>
			</Group>
			{showDisclaimer && (
				<Text size="xs" color="text/secondary/default" align="center">
					Secoda AI can make mistakes. Consider checking important information.
				</Text>
			)}
		</Stack>
	);
}
