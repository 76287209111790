import { IconNames } from '@repo/foundations';
import { useCallback, useMemo } from 'react';
import type { IQuestion, ISecodaEntity } from '../../api';
import {
	QUESTION_PRIORITY_MAP,
	QUESTION_STATUS_MAP,
	useUpdateQuestion,
} from '../../api';
import { BulkUpdateData } from '../../api/hooks/secodaEntity/useBulkUpdateSecodaEntities';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList';
import { FilterOptionType } from '../../components/Filter';
import { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import {
	CollectionRender,
	CreatedAtRender,
	ReadOnlyOwnersIdsRender,
	RelatedEntitiesRender,
	SelectorWithIconRender,
	TitleRender,
	UserRender,
	UsersAndGroupsRender,
} from '../../components/TableV2/render';
import {
	onClickGenericAction,
	useGenericActions,
} from '../../components/TableV2/SecodaEntity.hooks';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { EntityType } from '../../lib/types';

export const useColumns = (): ExtendedDataTableColumn<IQuestion>[] => {
	const { mutateAsync: updateQuestion } = useUpdateQuestion({});

	const handleChange = useCallback(
		(key: string) => (id: string) => (value: string[] | string | boolean) => {
			updateQuestion(
				{
					data: {
						id,
						[key]: value,
					},
				},
				{}
			);
		},
		[updateQuestion]
	);

	const columns: ExtendedDataTableColumn<IQuestion>[] = useMemo(
		() => [
			{
				accessor: 'title',
				title: 'Title',
				render: (record) => <TitleRender record={record} />,
				width: 200,
				filterOptionType: FilterOptionType.TITLE,
			},
			{
				accessor: 'status',
				title: 'Status',
				navigate: false,
				esAccessor: 'question_status',
				render: (record) => (
					<SelectorWithIconRender
						key="status"
						accessor="status"
						esAccessor="question_status"
						nilOption="UNANSWERED"
						onChange={handleChange('status')}
						record={record}
					/>
				),
				width: 200,
				filterOptionType: FilterOptionType.QUESTION_STATUS,
			},
			{
				accessor: 'priority',
				title: 'Priority',
				esAccessor: 'question_priority',
				navigate: false,
				render: (record) => (
					<SelectorWithIconRender
						key="priority"
						accessor="priority"
						esAccessor="question_priority"
						nilOption="NONE"
						onChange={handleChange('priority')}
						record={record}
					/>
				),
				width: 200,
				filterOptionType: FilterOptionType.QUESTION_PRIORITY,
			},
			{
				accessor: 'children_count',
				title: 'Replies',
				width: 200,
			},
			{
				navigate: false,
				accessor: 'collections',
				title: 'Collections',
				render: (record) => <CollectionRender record={record} />,
				width: 200,
				filterOptionType: FilterOptionType.COLLECTIONS,
			},
			{
				accessor: 'owners',
				title: 'Asked by',
				render: (record) => (
					<ReadOnlyOwnersIdsRender record={record} accessor={'owners'} />
				),
				width: 200,
				filterOptionType: FilterOptionType.OWNERS,
			},
			{
				accessor: 'answered_by',
				esAccessor: 'question_answered_by',
				title: 'Answered by',

				render: (record) => (
					<UserRender<IQuestion>
						accessor="answered_by"
						esAccessor="question_answered_by"
						record={record}
					/>
				),
				width: 200,
			},
			{
				accessor: 'assigned_to',
				esAccessor: 'question_assigned_to',
				title: 'Assigned to',

				render: (record) => (
					<UsersAndGroupsRender<IQuestion>
						userAccessor="assigned_to"
						userEsAccessor="question_assigned_to"
						groupAccessor="assigned_to_group"
						groupEsAccessor="question_assigned_to_group"
						record={record}
					/>
				),
				width: 200,
			},
			{
				accessor: 'created_at',
				title: 'Asked at',
				render: (record) => <CreatedAtRender record={record} />,
				width: 200,
				filterOptionType: FilterOptionType.CREATED_TIME,
			},
			{
				navigate: false,
				accessor: 'related_entities',
				title: 'Related',
				render: (record) => (
					<RelatedEntitiesRender
						entity={
							record as IQuestion & {
								related_entities: ISecodaEntity[];
							}
						}
					/>
				),
			},
		],
		[handleChange]
	);

	return columns;
};

export const useActions = () => {
	const { mutateAsync: updateEntity } = useUpdateQuestion({});

	const emulateBulkUpdate = useCallback(
		async (
			{ data }: { data: BulkUpdateData },
			{ onSuccess }: { onSuccess: () => void }
		) => {
			await Promise.all(
				data.map((item) =>
					updateEntity({ data: { id: item.id, ...item.data } }, {})
				)
			);
			await onSuccess();
		},
		[updateEntity]
	);

	const genericActions = useGenericActions() as ICommandListItem<IQuestion>[];

	const { activeUsers, userGroups } = useExtendedUserList({});

	const actions = useMemo(
		() => [
			{
				id: 'actions::assignee',
				title: 'Set user assignee',
				name: 'Set user assignee',
				iconName: 'user' as IconNames,
				hotkey: '/ow',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: onClickGenericAction(
					'assigned_to',
					true,
					emulateBulkUpdate as never,
					activeUsers?.map((user) => ({
						value: user.id,
						label: user.first_name
							? `${user.first_name} ${user.last_name}`
							: user.email,
					})) ?? []
				),
			},
			{
				id: 'actions::assignee_group',
				title: 'Set group assignee',
				name: 'Set group assignee',
				iconName: 'userCircle' as IconNames,
				hotkey: '/ow',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: onClickGenericAction(
					'assigned_to_group',
					true,
					emulateBulkUpdate as never,
					userGroups?.map((userGroup) => ({
						value: userGroup.id,
						label: userGroup.name,
					})) ?? []
				),
			},
			{
				id: 'actions::priority',
				title: 'Set priority',
				name: 'Set priority',
				show: true,
				iconName: 'activity' as const,
				hotkey: '/sp',
				type: EntityType.question,
				team: undefined,
				category: 'actions',
				onClick: onClickGenericAction(
					'priority',
					true,
					emulateBulkUpdate as never,
					Object.entries(QUESTION_PRIORITY_MAP).map(([key, value]) => ({
						value: key,
						label: value,
					}))
				),
			},
			{
				id: 'actions::status',
				title: 'Set status',
				name: 'Set status',
				show: true,
				iconName: 'hourglassEmpty' as const,
				hotkey: '/ss',
				type: EntityType.question,
				team: undefined,
				category: 'actions',
				onClick: onClickGenericAction(
					'status',
					true,
					emulateBulkUpdate as never,
					Object.entries(QUESTION_STATUS_MAP).map(([key, value]) => ({
						value: key,
						label: value,
					}))
				),
			},
			...genericActions.filter((action) => action.id !== 'actions::owners'),
		],
		[activeUsers, emulateBulkUpdate, genericActions]
	);

	return actions;
};
