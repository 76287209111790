import {
	ActionIcon,
	Checkbox,
	Group,
	Select,
	Stack,
	Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Prism } from '@mantine/prism';
import { Button, Icon, Text } from '@repo/foundations';
import { entries, mapValues, pickBy } from 'lodash-es';
import { Language } from 'prism-react-renderer';
import { useEffect, useState } from 'react';
import { useCallIntegrationApiMethod } from '../../../api/hooks/inspectApi';
import type { IIntegratinApiSpec } from '../../../api/types/models/integrationApiSpec';

export function InspectAPIBlock({
	spec,
	integrationId,
	onDelete,
}: {
	spec: IIntegratinApiSpec;
	integrationId: string;
	onDelete: () => void;
}) {
	const methodNameAndSpecs = entries(spec.api_spec);
	const [pickedMethodName, setPickedMethodName] = useState(
		methodNameAndSpecs[0][0]
	);

	const [result, setResult] = useState('');
	const [showOriginal, setShowOriginal] = useState(false);
	const [collapsed, setCollapsed] = useState(false);
	const [language, setLanguage] = useState<string | null>('json');

	const resultTooLong = result.split('\n').length > 6;

	const modifiedResult =
		collapsed && resultTooLong
			? `${result
					.split('\n')
					.slice(0, 6)
					.join('\n')}\n\n\n .....\n Expand to see more`
			: result;

	const methodSpec = spec.api_spec[pickedMethodName].argument_spec;

	const form = useForm({
		initialValues: mapValues(methodSpec, () => ''),
		validate: mapValues(methodSpec, () => (value: string) => {
			try {
				JSON.parse(value);
			} catch (e) {
				return 'Invalid JSON value, string must be quoted in "';
			}
			return null;
		}),
	});

	useEffect(() => {
		form.setValues({});
	}, [pickedMethodName]);

	const { mutateAsync: callMethod, isLoading: isCalling } =
		useCallIntegrationApiMethod();

	const run = async () => {
		if (form.validate().hasErrors) {
			return;
		}

		const r = await callMethod({
			integrationId,
			method: pickedMethodName,
			payload: mapValues(
				pickBy(form.values, (_, k) => k in methodSpec),
				(value) => JSON.parse(value)
			),
			showOriginal,
		});

		if (r.status === 'failed') {
			setResult(r.error);
		} else {
			if (typeof r.result === 'string') {
				setResult(r.result);
			} else {
				setResult(JSON.stringify(r.result, null, 2));
			}
		}
	};

	return (
		<Group align="start" noWrap maw="100%">
			<Stack
				sx={{
					width: '25vw',
				}}
			>
				<Text size="sm" weight="semibold">
					Pick a method and fill in arguments
				</Text>
				<Group w="100%" noWrap>
					<Select
						sx={{
							flex: 1,
						}}
						placeholder="Pick one"
						value={pickedMethodName}
						onChange={(value) => setPickedMethodName(value as string)}
						dropdownPosition="bottom"
						data={entries(spec.api_spec).map(([key, _]) => ({
							value: key,
							label: key,
						}))}
					/>
					<ActionIcon
						variant="filled"
						color="green"
						loading={isCalling}
						onClick={run}
					>
						<Icon name="chevronRight" />
					</ActionIcon>
				</Group>
				<Checkbox
					label="Show original response"
					checked={showOriginal}
					onChange={(e) => setShowOriginal(e.currentTarget.checked)}
				/>
				{entries(methodSpec).map(([argumentName, argumentType]) => (
					<Textarea
						key={argumentName}
						required
						label={`${argumentName} (${argumentType})`}
						{...form.getInputProps(argumentName)}
					/>
				))}
				<Group>
					<Button onClick={onDelete} leftIconName="trash" size="md">
						Delete block
					</Button>
				</Group>
			</Stack>
			<Stack
				sx={{
					flex: 1,
				}}
			>
				<Group position="right">
					{resultTooLong && (
						<Button variant="tertiary" onClick={() => setCollapsed(!collapsed)}>
							{collapsed ? (
								<>
									Expand result <Icon name="chevronDown" />
								</>
							) : (
								<>
									Collapse result <Icon name="chevronUp" />
								</>
							)}
						</Button>
					)}
					<Select
						value={language}
						onChange={setLanguage}
						searchable
						data={[
							'markup',
							'bash',
							'clike',
							'c',
							'cpp',
							'css',
							'javascript',
							'jsx',
							'coffeescript',
							'actionscript',
							'css-extr',
							'diff',
							'git',
							'go',
							'graphql',
							'handlebars',
							'json',
							'less',
							'makefile',
							'markdown',
							'objectivec',
							'ocaml',
							'python',
							'reason',
							'sass',
							'scss',
							'sql',
							'stylus',
							'tsx',
							'typescript',
							'wasm',
							'yaml',
						]}
					/>
				</Group>

				<Prism
					language={(language as Language) ?? 'json'}
					sx={{
						overflowY: 'scroll',
						width: '68vw',
						minHeight: 200,
					}}
				>
					{modifiedResult}
				</Prism>
			</Stack>
		</Group>
	);
}
