import React, { createContext, ReactNode, useContext } from "react";
import { apiWithoutTrailSlash } from "../utils";

export interface ApiConfig {
	overriddenBaseURL?: string;
	overriddenHeaders?: Record<string, string>;
}

const ApiConfigContext = createContext<ApiConfig | undefined>(undefined);

export const useApiConfig = (): ApiConfig => {
	const context = useContext(ApiConfigContext);

	if (!context) {
		return {
			overriddenBaseURL: apiWithoutTrailSlash(),
			overriddenHeaders: {},
		};
	}

	return context;
};

interface ApiConfigProviderProps {
	children: ReactNode;
	config?: ApiConfig;
}

export const ApiConfigProvider = ({
	children,
	config = {},
}: ApiConfigProviderProps) => {
	const defaultBaseURL = apiWithoutTrailSlash();

	const mergedConfig: ApiConfig = {
		overriddenBaseURL: config.overriddenBaseURL || defaultBaseURL,
		overriddenHeaders: config.overriddenHeaders || {},
	};

	return (
		<ApiConfigContext.Provider value={mergedConfig}>
			{children}
		</ApiConfigContext.Provider>
	);
};

export default ApiConfigContext;
