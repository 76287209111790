import { Stack } from '@mantine/core';
import { isEmpty } from 'lib0/object';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAuthUser, useIntegrationList } from '../../api';
import { useTeamEmptyState } from '../../api/hooks/team/emptyStates';
import { type ButtonDetails, EmptyState } from '../../components/EmptyState';
import { useEntityBreadcrumbTeam } from '../../hooks/useEntityBreadcrumbs';
import { EntityType } from '../../lib/types';
import { CatalogTable } from './CatalogTable';
import { useStyles } from './TeamCatalogPage.styles';

function TeamCatalogPage() {
	const params = useParams();
	const teamId = params.teamId as string;

	const { classes } = useStyles();

	const navigate = useNavigate();

	const { isAdminUser } = useAuthUser();

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	const { data: integrations } = useIntegrationList({});

	const defaultFilters: Record<string, unknown> = {};

	if (teamId) {
		defaultFilters.teams = teamId;
	}

	useEffect(() => {
		// If the user opens a entity page from this context we want the team
		// breadcrumb to match the team of this page.
		if (teamId) {
			setTeamBreadcrumb(teamId);
		}
	}, [setTeamBreadcrumb, teamId]);

	const navigateToIntegrationsBrowse = useCallback(() => {
		navigate(`/integrations/browse?teamId=${teamId}`);
	}, [navigate, teamId]);

	const emptyState = useTeamEmptyState(teamId, 'catalog', {
		enabled: isAdminUser,
	});

	// If the empty state is loaded and indeed empty, then show the empty state.
	if (emptyState?.is_empty || isEmpty(integrations?.results)) {
		const buttons: ButtonDetails[] = isAdminUser
			? [
					{
						name: 'Learn more',
						action: () => {
							window.open('https://docs.secoda.co/features/catalog', '_blank');
						},
						isPrimary: false,
						size: 'md',
						leftIconName: 'externalLink',
					},
					{
						name: 'Add an integration',
						action: navigateToIntegrationsBrowse,
						isPrimary: true,
						size: 'md',
					},
				]
			: [];

		return (
			<EmptyState
				illustrationName="catalog"
				title="Welcome to Catalog"
				description="Organize all your data assets in one central hub. Connect your integrations to start documenting and organizing your data."
				includeGoBack={false}
				buttons={buttons}
				stateHeight="80vh"
				size="lg"
			/>
		);
	}

	return (
		<Stack className={classes.wrapper} spacing={0}>
			<CatalogTable
				withCustomProperties={EntityType.all}
				columnVisibility={{
					catalogType: EntityType.all,
					catalogServerType: 'catalog',
				}}
				defaultRequiredSearchParams={{
					team_id: teamId,
				}}
				additionalFilters={[
					'integration_id',
					'schema',
					'database',
					'sources',
					'schema',
					'related',
					'slack_channels__contains',
				]}
			/>
		</Stack>
	);
}

export default TeamCatalogPage;
