import { Stack } from '@mantine/core';

import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { StaticProperty } from '../../EntityPropertySidebar';

export interface IntegrationMetadataStackProps {
	updatedAtLabelSource: string | null;
	resourceTypeSource: string;
	withFrequentUsers: boolean;
	frequentUsersSource: SelectablePropertyItem[];
	withEntityRowCount: boolean;
	rowCountValue: string;
	hasRowCount: boolean;
	withEntityByteSize: boolean;
	byteSizeValue: string;
	hasByteSize: boolean;
	withEntityPopularity: boolean;
	popularityValue: string;
	hasPopularity: boolean;
}

function IntegrationMetadataStack({
	updatedAtLabelSource,
	resourceTypeSource,
	withFrequentUsers,
	frequentUsersSource,
	withEntityRowCount,
	rowCountValue,
	hasRowCount,
	withEntityByteSize,
	byteSizeValue,
	hasByteSize,
	withEntityPopularity,
	popularityValue,
	hasPopularity,
}: IntegrationMetadataStackProps) {
	return (
		<Stack spacing="xs">
			{updatedAtLabelSource && (
				<StaticProperty
					type="text"
					label="Updated"
					value={updatedAtLabelSource}
				/>
			)}
			{resourceTypeSource && (
				<StaticProperty type="text" label="Type" value={resourceTypeSource} />
			)}
			{withFrequentUsers && frequentUsersSource.length > 0 && (
				<StaticProperty
					type="users"
					label="Frequent Users"
					value={frequentUsersSource as SelectablePropertyItem[]}
				/>
			)}
			{withEntityRowCount && hasRowCount && (
				<StaticProperty type="text" label="Row count" value={rowCountValue} />
			)}
			{withEntityByteSize && hasByteSize && (
				<StaticProperty type="text" label="Table size" value={byteSizeValue} />
			)}
			{withEntityPopularity && hasPopularity && (
				<StaticProperty
					type="text"
					label="Popularity"
					value={popularityValue}
				/>
			)}
		</Stack>
	);
}

export default IntegrationMetadataStack;
