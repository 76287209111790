import {
	Box,
	Group,
	Modal,
	Stack,
	Tooltip,
	useMantineTheme,
} from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { UserRole } from '@repo/common/enums/UserRole';
import { Button, Text } from '@repo/foundations';
import { useFormik } from 'formik';
import { isNil, size } from 'lodash-es';
import * as Yup from 'yup';
import { useWorkspace } from '../../api';
import { useDefaultTeam } from '../../api/hooks/team/myMemberships';
import { useLimits } from '../../api/hooks/workspace/useLimits';
import { MultiTeamsSelector } from '../MultiTeamsSelector/MultiTeamsSelector';
import { MultiGroupSelector } from '../Settings/Selectors/MultiGroupSelector';
import RoleSelect from '../Settings/Selectors/RoleSelector';
import { MultiTextInput } from './MultiTextInput';
import { PricingDetails } from './PricingDetails';

interface IInviteMembersModalProps {
	title?: string;
	opened: boolean;
	onClose: () => void;
	onConfirm: (
		emails: string[],
		role: UserRole,
		groups: string[],
		teams: string[]
	) => void;
	role?: UserRole;
	hideGroupSelector?: boolean;
	hideTeamSelector?: boolean;
}

export function InviteMembersModal({
	title = 'Invite members',
	opened,
	onClose,
	onConfirm,
	role,
	hideGroupSelector = false,
	hideTeamSelector = false,
}: IInviteMembersModalProps) {
	const theme = useMantineTheme();
	const clipboard = useClipboard();

	const { usedEditorOrAdminSeats } = useLimits();
	const { workspace } = useWorkspace();
	const { defaultTeam } = useDefaultTeam();
	const teams = defaultTeam ? [defaultTeam.id] : [];

	const formik = useFormik({
		initialValues: {
			emails: [],
			role: role ?? UserRole.VIEWER,
			groups: [],
			teams,
		},
		validationSchema: Yup.object({
			emails: Yup.array()
				.of(
					Yup.string().email((email) => {
						if (!email.regex.test(email.value)) {
							return email.value;
						}
						return '';
					})
				)
				.required('Required')
				.min(1, 'No emails provided'),
			role: Yup.string().required('Role is required'),
			groups: Yup.array().of(Yup.string()),
			teams: Yup.array()
				.of(Yup.string())
				.required('Required')
				.min(1, 'At least one team is required'),
		}),
		enableReinitialize: true,
		onSubmit: async (values, formikHelpers) => {
			onConfirm(values.emails, values.role, values.groups, values.teams);
			formikHelpers.resetForm();
			onClose();
		},
	});

	const isPricingDetailsHidden =
		size(formik.values.emails) === 0 || !isNil(formik.errors.emails);

	let pricingDetailsType: 'viewers' | 'editors' | 'integrations' | undefined;

	switch (formik.values.role) {
		case UserRole.VIEWER:
			pricingDetailsType = 'viewers';
			break;
		case UserRole.EDITOR:
			pricingDetailsType = 'editors';
			break;
		case UserRole.ADMIN:
			pricingDetailsType = 'editors';
			break;
		default:
			break;
	}

	const handleSetEmails = (emails: string[]) => {
		formik.setFieldValue('emails', emails);
	};

	const handleOnClose = () => {
		formik.resetForm();
		onClose();
	};

	return (
		<Modal opened={opened} onClose={handleOnClose} title={title}>
			<Stack spacing="xl">
				<MultiTextInput
					type="email"
					values={formik.values.emails}
					setValues={handleSetEmails}
					label="Emails"
					placeholder="Enter teammates email addresses..."
					error={formik.errors.emails}
				/>
				<Box>
					<Text size="sm" weight="semibold" mb={theme.spacing['3xs']}>
						Role
					</Text>
					<RoleSelect
						disabled={!!role}
						role={formik.values.role}
						selectRole={(role) => {
							formik.setFieldValue('role', role);
						}}
						withDescriptions
					/>
				</Box>
				{!hideGroupSelector && (
					<MultiGroupSelector
						label="Groups"
						value={formik.values.groups}
						setValue={(value) => formik.setFieldValue('groups', value)}
					/>
				)}
				{!hideTeamSelector && (
					<MultiTeamsSelector
						label="Teams"
						error={formik.errors.teams as string}
						value={formik.values.teams}
						setValue={(value) => formik.setFieldValue('teams', value)}
					/>
				)}
				{!isNil(pricingDetailsType) && (
					<PricingDetails
						type={pricingDetailsType}
						nominalCurrentCount={usedEditorOrAdminSeats}
						nominalChange={size(formik.values.emails)}
						hidden={isPricingDetailsHidden}
					/>
				)}
				<Group position="apart">
					<Tooltip label="Members will join as a Viewer">
						<Button
							size="md"
							variant="tertiary"
							leftIconName="link"
							onClick={() => {
								clipboard.copy(
									`${window.location.origin}?workspace_invite=${
										workspace?.id ?? ''
									}`
								);
								showNotification({
									message: 'Invitation link copied to clipboard.',
								});
							}}
						>
							Copy invite link
						</Button>
					</Tooltip>
					<Group position="right" spacing="xs">
						<Button onClick={handleOnClose}>Cancel</Button>
						<Button variant="primary" onClick={() => formik.handleSubmit()}>
							Send invites
						</Button>
					</Group>
				</Group>
			</Stack>
		</Modal>
	);
}
