import type { Filter } from '@repo/api-codegen';
import {
	DEFAULT_FILTER_OPTIONS,
	FILTER_OPTIONS_DIVIDER,
} from '@repo/common/components/Filter/constants';
import type {
	FilterOption,
	FilterOptionType,
	FilterValue,
	FilterView,
	TopLevelOperatorType,
} from '@repo/common/components/Filter/types';
import {
	FilterDropdownType,
	SortValue,
} from '@repo/common/components/Filter/types';
import { isEmpty } from 'lodash-es';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { createContext } from 'react';
import type { ApiCatalogSort } from '../../api';
import { deleteParamsFromUrl, setParamsInUrl } from '../../utils/url';
import { FILTER_OPTIONS_CONFIG } from './constants';
import {
	getApiCatalogFilterFromFilterValues,
	legacyFilterToFilterValue,
	parseFilterValuesFromLocalStorage,
} from './utils';

export interface AddedFilterResult {
	value: FilterValue;
	changeFilter: (value: Partial<FilterValue>) => void;
	clearFilter: () => void;
}

export class SearchFilterV2Store {
	sort: SortValue;

	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];

	values: FilterValue[];

	preferencesLocalStorageKey: string | null;

	view: FilterView | null = null;

	topLevelOperator: TopLevelOperatorType = 'and';

	urlParamToSyncFilters: string | null = null;

	constructor(
		defaultFilterOptions: (
			| FilterOptionType
			| typeof FILTER_OPTIONS_DIVIDER
		)[] = DEFAULT_FILTER_OPTIONS,
		initialValues: FilterValue[] = [],
		preferencesLocalStorageKey: string | null = null,
		urlParamToSyncFilters: string | null = null
	) {
		makeAutoObservable(this);

		this.preferencesLocalStorageKey = preferencesLocalStorageKey;
		this.urlParamToSyncFilters = urlParamToSyncFilters;

		this.filterOptions = defaultFilterOptions.map((option) => {
			if (option === FILTER_OPTIONS_DIVIDER) {
				return option;
			}
			return FILTER_OPTIONS_CONFIG[option];
		});

		this.sort = SortValue.RELEVANCE;

		if (isEmpty(initialValues) && preferencesLocalStorageKey) {
			// Try to use the saved filter preferences from the local storage.
			this.values = parseFilterValuesFromLocalStorage(
				preferencesLocalStorageKey
			);
		} else {
			this.values = initialValues;
		}
	}

	reset() {
		this.sort = SortValue.RELEVANCE;
		this.values = [];

		// Always, save the preferences after resettting.
		this.savePreferences();
		this.syncFiltersToUrl();
	}

	prefetchPromises = () => {
		this.filterOptions.forEach((option) => {
			if (
				option === FILTER_OPTIONS_DIVIDER ||
				option.filterDropdownConfig.dropdownType !== FilterDropdownType.List
			) {
				return;
			}

			const { getItems } = option.filterDropdownConfig;

			if (typeof getItems !== 'function') {
				return;
			}

			Promise.resolve(getItems());
		});
	};

	setValues = (values: FilterValue[]) => {
		this.values = values;
	};

	setSort = (sort: SortValue) => {
		this.sort = sort;
	};

	setFilterView = (view: FilterView | null) => {
		this.view = view;

		if (view) {
			runInAction(async () => {
				this.values = await legacyFilterToFilterValue(view.filters);
			});
		} else {
			runInAction(() => {
				this.values = [];
			});
		}
	};

	setTopLevelOperator = (operator: TopLevelOperatorType) => {
		runInAction(() => {
			this.topLevelOperator = operator;
		});
	};

	savePreferences = () => {
		if (!this.preferencesLocalStorageKey) {
			return;
		}

		localStorage.setItem(
			this.preferencesLocalStorageKey,
			JSON.stringify(this.values)
		);
	};

	onAddValue = (value: FilterValue): AddedFilterResult => {
		const filterIdx = runInAction(() => {
			this.values = [...this.values, value];
			return this.values.length - 1;
		});

		// Always, save the preferences after adding a new filter.
		this.savePreferences();
		this.syncFiltersToUrl();

		return {
			value: this.values[filterIdx],
			changeFilter: this.onChangeValue(filterIdx),
			clearFilter: this.onClearValue(filterIdx),
		};
	};

	onChangeValue = (idx: number) => (value: Partial<FilterValue>) => {
		if (
			Array.isArray(value.value) &&
			value.value.length === 0 &&
			!value.isNotSetApplied &&
			!value.isSetApplied
		) {
			this.onClearValue(idx)();
			return;
		}

		runInAction(() => {
			const tempArr = [...this.values];
			tempArr[idx] = {
				...this.values[idx],
				...value,
			};
			this.values = [...tempArr];
		});

		// Always, save the preferences after changing a filter.
		this.savePreferences();
		this.syncFiltersToUrl();
	};

	onClearValue = (idx: number) => () => {
		runInAction(() => {
			const tempArr = [...this.values];
			tempArr.splice(idx, 1);
			this.values = [...tempArr];
		});

		// Always, save the preferences after clearing a filter.
		this.savePreferences();
		this.syncFiltersToUrl();
	};

	syncFiltersToUrl = () => {
		if (!this.urlParamToSyncFilters) {
			return;
		}

		if (this.values?.length > 0) {
			setParamsInUrl(this.urlParamToSyncFilters, JSON.stringify(this.values));
		} else {
			deleteParamsFromUrl(this.urlParamToSyncFilters);
		}
	};

	get catalogFilter(): Filter | undefined {
		const allValues = toJS(this.values);

		return getApiCatalogFilterFromFilterValues(
			allValues,
			this.topLevelOperator
		);
	}

	get catalogSort(): ApiCatalogSort | undefined {
		return {
			field: this.sort.toString(),
			order: 'desc',
		};
	}
}

export const SearchFilterV2StoreContext = createContext<SearchFilterV2Store>(
	new SearchFilterV2Store()
);
