/* eslint-disable class-methods-use-this */
import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { ParseSpec } from 'prosemirror-markdown';
import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';
import { NodeSelection, type EditorState } from 'prosemirror-state';
import { v4 } from '../../../../../utils/uuid/v4';

import { BlockSkeleton } from '../components/BlockSkeleton';
import { QueryBlockContainer } from '../components/MetricContainer/QueryBlockContainer';
import queryBlocksRule from '../rules/queryblocks';
import { ComponentProps, Dispatch } from '../types';
import { NodeOptions } from './Node';
import ReactNode from './ReactNode';

export default class QueryBlock extends ReactNode {
	get name() {
		return 'query_block';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				id: {
					default: '', // This value is shared across all new nodes, so the `uuid()` call cannot be here.
				},
			},
			group: 'block',
			defining: true,
			atom: true,
			parseDOM: [
				{
					preserveWhitespace: 'full',
					tag: 'div.queryblock',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									id: dom.getAttribute('id'),
								},
				},
			],
			toDOM: (node) => [
				'div',
				{
					...node.attrs,
					class: 'queryblock',
					id: node.attrs.id,
				},
				node.attrs.id,
			],
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		const { id } = node.attrs;
		if (id) {
			state.write(`\n\n;;;${id};;;\n\n`);
			state.closeBlock(node);
		}
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'query_block',
			getAttrs: (tok) => ({ id: tok.attrs?.[0][1].split(';;;')[1] }),
		};
	}

	get rulePlugins() {
		return [queryBlocksRule];
	}

	commands({ type }: NodeOptions) {
		return (attrs?: Record<string, unknown>) =>
			(state: EditorState, dispatch: Dispatch) => {
				let id = attrs?.id;
				if (!id) {
					id = v4();
				}

				dispatch(
					state.tr.insert(
						state.selection.from,
						type.create({ ...(attrs ?? {}), id })
					)
				);

				return true;
			};
	}

	component = ({ node, getPos, isSelected }: ComponentProps) => {
		if (!node) {
			return <BlockSkeleton isSelected={isSelected} height={100} />;
		}

		const deleteNode = () => {
			const { view } = this.editorState;
			const $pos = view.state.doc.resolve(getPos());
			const tr = view.state.tr.setSelection(new NodeSelection($pos));
			view.dispatch(tr.deleteSelection());
			view.focus();
		};

		return (
			<QueryBlockContainer
				id={node.attrs.id}
				readOnly={this.editorState.readOnly}
				onDelete={() => deleteNode()}
				isSelected={isSelected}
			/>
		);
	};
}
