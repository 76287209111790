import { Flex } from '@mantine/core';
import { UserRole } from '@repo/common/enums/UserRole';
import { lowerCase, map } from 'lodash-es';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useThisUser } from '../../api/hooks';
import { useUserOnboarding } from '../../hooks/useUserOnboarding';
import { LoadingSpinner } from '../LoadingSpinner';

const VIEWER_ROLES = map([UserRole.VIEWER, UserRole.GUEST], lowerCase);

// This component's role is to redirect to either the home screen or the
// onboarding screen after a user finished either logging in, creating a
// profile, or creating a workspace.
export function AuthStepConfirm() {
	const navigate = useNavigate();
	const { isLoading, data } = useThisUser();
	const { viewerOnboardingStepCompletion } = useUserOnboarding();

	useEffect(() => {
		if (!isLoading) {
			const role: string | undefined = data?.role?.toLowerCase();
			const isViewerUser: boolean = !!(role && VIEWER_ROLES.includes(role));
			if (isViewerUser && !viewerOnboardingStepCompletion.welcomePageSeen) {
				navigate('/onboarding/welcome');
			} else {
				navigate('/');
			}
		}
	});

	return (
		<Flex h="100vh">
			<LoadingSpinner />
		</Flex>
	);
}
