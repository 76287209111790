import { CopyButton, createStyles, Group, Menu } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Icon, IconButton, Text } from '@repo/foundations';
import { getColor } from '@repo/theme/utils';
import { useLocation, useNavigate, useParams } from 'react-router';
import type { IForm } from '../../../api';
import { queryClient, useAuthUser } from '../../../api';
import {
	formQueryKeys,
	useDeleteForm,
	useUpdateForm,
} from '../../../api/hooks/forms';
import { isTrue } from '../../../utils/utils';
import { RIGHT_SIDEBAR_WIDTH } from '../../SecodaAppShell/constants';

type IFormPageNavBarProps = {
	form?: IForm;
};

type IFormPageNavBarStyleProps = {
	isAdminUser: boolean;
};

const useStyles = createStyles(
	(theme, { isAdminUser }: IFormPageNavBarStyleProps) => {
		let rightSection = {};

		if (isAdminUser) {
			rightSection = {
				borderLeft: `1px solid ${getColor('border/secondary/default')}`,
				borderBottom: `1px solid ${getColor('border/secondary/default')}`,
			};
		}

		if (!isAdminUser) {
			rightSection = {
				borderLeft: `1px solid ${getColor('border/secondary/default')}`,
			};
		}

		return {
			wrapper: {
				justifyContent: 'space-between',
				width: '100%',
				gap: theme.spacing.md,
			},
			leftSection: {
				gap: theme.spacing['5xs'],
				padding: `${theme.spacing.md} 0 ${theme.spacing.md} ${theme.spacing.md}`,
			},
			rightSection: {
				...rightSection,
				flexDirection: 'row-reverse',
				width: RIGHT_SIDEBAR_WIDTH,
				height: '100%',
				paddingRight: theme.spacing.xl,
			},
			content: {
				padding: 0,
			},
		};
	}
);

export function FormPageNavBar({ form }: IFormPageNavBarProps) {
	const navigate = useNavigate();

	const { isAdminUser } = useAuthUser();

	const location = useLocation();
	const params = useParams();

	const { classes } = useStyles({
		isAdminUser,
	});

	const onBackButtonClick = () => {
		navigate('/forms');
	};

	const { mutateAsync: updateForm } = useUpdateForm();
	const { mutateAsync: deleteForm } = useDeleteForm();

	const handleFormUpdate = async (data: Partial<IForm>) => {
		if (form?.id) {
			await updateForm({
				form: {
					id: form.id,
					...data,
				},
			});
		}

		queryClient.invalidateQueries(formQueryKeys.all());
	};

	const handlePublishForm = () => {
		handleFormUpdate({ published: true });
	};

	const handleUnPublishForm = () => {
		handleFormUpdate({ published: false });
	};

	const handleDeleteForm = () => {
		deleteForm({ formId: form?.id as string });
		onBackButtonClick();
	};

	const isFormPublished = isTrue(form?.published);

	return (
		<Group className={classes.wrapper}>
			<Group className={classes.leftSection}>
				<IconButton
					size="sm"
					onClick={onBackButtonClick}
					iconName="chevronLeft"
				/>
				<Button onClick={onBackButtonClick} variant="tertiary">
					Forms
				</Button>
			</Group>
			<Group className={classes.rightSection}>
				{isAdminUser && isFormPublished && (
					<CopyButton
						value={`${window.location.origin}${location.pathname.replace(
							`/forms/${form?.id}/${params.tab}`,
							`/forms/${form?.id}/form`
						)}${location.search}`}
					>
						{({ copy }) => (
							<Button
								variant="primary"
								size="md"
								onClick={() => {
									copy();
									showNotification({
										title: 'Link copied',
										message: 'Link copied to clipboard',
										color: 'green',
									});
								}}
							>
								Share form
							</Button>
						)}
					</CopyButton>
				)}
				{isAdminUser && !isFormPublished && (
					<Button variant="primary" size="md" onClick={handlePublishForm}>
						Publish form
					</Button>
				)}
				{isAdminUser && (
					<Menu>
						<Menu.Target>
							<IconButton size="sm" iconName="dots" />
						</Menu.Target>
						<Menu.Dropdown>
							{isFormPublished && (
								<>
									<Menu.Item
										icon={<Icon name="eyeOff" />}
										onClick={handleUnPublishForm}
									>
										Unpublish
									</Menu.Item>
									<Menu.Divider />
								</>
							)}
							<Menu.Item
								icon={<Icon name="trash" color="icon/critical/default" />}
								onClick={handleDeleteForm}
							>
								<Text size="sm" color="text/critical/default">
									Delete
								</Text>
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				)}
			</Group>
		</Group>
	);
}
