import { Box, Group, Stack } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { pluralize } from '@repo/common/utils';
import { Icon, Text } from '@repo/foundations';
import { map, size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import useFuseSearch from '../../../../hooks/useFuseSearch';
import { EmptyState } from '../../../EmptyState';
import { NODE_CHILDREN_MAX_HEIGHT } from '../../constants';
import { lineageStore } from '../../store';
import type { EntityNodeChildren } from '../../types';
import { EdgeHandle } from '../../types';
import { ChildrenSearch } from './ChildrenSearch.tsx';
import { EntityChild } from './EntityChild.tsx';
import { useStyles } from './EntityChildren.styles.ts';

interface IEntityChildrenProps {
	id: string;
	numOfChildren: number;
	isOpen: boolean;
}

function EntityChildren({ id, numOfChildren, isOpen }: IEntityChildrenProps) {
	const [children, setChildren] = useState<EntityNodeChildren[]>([]);
	const [searchTerm, setSearchTerm] = useInputState('');

	useEffect(() => {
		if (numOfChildren > 0) {
			setChildren(lineageStore.getNodeChildren(id));
		}
	}, [
		id,
		numOfChildren,
		lineageStore.selectedChild,
		lineageStore.selectedNode,
		lineageStore.highlightedChildrenIds,
	]);

	const filteredChildren = useFuseSearch({
		term: searchTerm,
		items: children,
		keys: ['title'],
	});

	const { classes, cx } = useStyles();

	if (numOfChildren === 0 || children.length === 0) {
		return null;
	}

	const label = children
		? pluralize(children[0].types.native ?? '', numOfChildren)
		: children;

	const showChildren = isOpen && size(filteredChildren) > 0;
	const showEmptyState = isOpen && size(filteredChildren) === 0;

	const isHighlighted = children.length !== numOfChildren;

	const handleToggleEntityChildren = () => {
		lineageStore.toggleChildren(id, !isOpen);
	};

	return (
		<>
			<Stack className={classes.wrapper}>
				{children.length > 0 && (
					<Group
						className={classes.accordion}
						onClick={handleToggleEntityChildren}
					>
						<Text size="xs" color="text/secondary/default">{`${size(
							filteredChildren
						)} ${label}`}</Text>
						<Icon
							name={isOpen ? 'chevronUp' : 'chevronDown'}
							color="icon/primary/default"
						/>
					</Group>
				)}

				{isOpen && (
					<ChildrenSearch
						term={searchTerm}
						setTerm={setSearchTerm}
						label={label}
					/>
				)}
				{showChildren && (
					<Stack
						className={cx(classes.scrollableChildren, 'nowheel')} // Disables the default canvas pan behavior while scrolling
						mah={isHighlighted ? '100%' : NODE_CHILDREN_MAX_HEIGHT}
					>
						{map(filteredChildren, (child, index) => (
							<EntityChild key={child.id} child={child} order={index + 1} />
						))}
					</Stack>
				)}
				{showEmptyState && (
					<Box p="xl">
						<EmptyState
							title={`No ${label} found`}
							description="Try searching for something else"
							iconName="columns"
							buttons={[
								{
									isPrimary: false,
									name: 'Clear filters',
									action: () => setSearchTerm(''),
									size: 'md',
								},
							]}
							includeGoBack={false}
							size="sm"
						/>
					</Box>
				)}
			</Stack>
			{!isOpen &&
				map(filteredChildren, (child) => (
					<Box key={`handle-wrapper--${child.id}`}>
						<Handle
							className={classes.handle}
							id={`${EdgeHandle.CHILD_TARGET}--${child.id}`}
							type="target"
							position={Position.Left}
							isConnectable={false}
						/>
						<Handle
							className={classes.handle}
							id={`${EdgeHandle.CHILD_SOURCE}--${child.id}`}
							type="source"
							position={Position.Right}
							isConnectable={false}
						/>
					</Box>
				))}
		</>
	);
}

export default observer(EntityChildren);
