import { getColor } from '@repo/theme/utils';
import type { EdgeProps } from 'reactflow';
import { BaseEdge, getBezierPath } from 'reactflow';
import { EdgeData } from '../types.ts';
import { EntityEdgeWithJob } from './EntityEdgeWithJob';

export function EntityEdge({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	markerEnd,
	data,
}: EdgeProps<EdgeData>) {
	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});

	const style = {
		stroke: getColor('border/tertiary/default'),
		strokeWidth: 1,
	};

	return data?.metadata ? (
		<EntityEdgeWithJob
			id={id}
			path={edgePath}
			labelX={labelX}
			labelY={labelY}
			markerEnd={markerEnd}
			style={style}
			isHovered={data.isHovered}
			metadata={data.metadata}
		/>
	) : (
		<BaseEdge id={id} path={edgePath} markerEnd={markerEnd} style={style} />
	);
}
