import { Group, Menu, createStyles } from '@mantine/core';
import {
	Breadcrumbs,
	BreadcrumbsSkeleton,
	Button,
	Icon,
	IconButton,
} from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { queryClient, useAuthUser, type Automation } from '../../../api';
import {
	useDeleteAutomation,
	useUpdateAutomation,
} from '../../../api/hooks/automation';

import { automationQueryKeyFactory } from '../../../api/hooks/automation/constants';
import RunAutomationButton from '../../../components/Automation/RunAutomationButton';
import EntityPageAsideCollapse from '../../../components/EntityPageAsideCollapse/EntityPageAsideCollapse';
import DeleteModal from '../../../components/ModalDelete/DeleteModal';
import { closeAllModals, openModal } from '../../../components/ModalManager';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarSidesheetControlsWrapper,
	NavBarWrapper,
} from '../../../components/SecodaAppShell/SecodaNavBar';
import { trackEvent } from '../../../utils/analytics';

type IAutomationPageNavBarProps = {
	automation?: Automation;
};

const useStyles = createStyles(() => ({
	content: {
		padding: 0,
	},
}));

function AutomationPageNavBar({ automation }: IAutomationPageNavBarProps) {
	const navigate = useNavigate();
	const { user, workspace } = useAuthUser();
	const { classes } = useStyles();

	const { mutateAsync: updateAutomation } = useUpdateAutomation({});

	const handleAutomationUpdate = useCallback(
		async (data: Partial<Automation>) => {
			if (automation?.id) {
				await updateAutomation({
					data: {
						id: automation.id,
						...data,
					},
				});
			}
		},
		[automation?.id, updateAutomation]
	);

	const { mutateAsync: deleteAutomation } = useDeleteAutomation({
		options: {
			onSuccess: async () => {
				await queryClient.invalidateQueries(
					automationQueryKeyFactory.allLists()
				);
				navigate('/automations');
			},
		},
	});

	const handleAutomationStatusToggle = async () => {
		await handleAutomationUpdate({ is_enabled: !automation?.is_enabled });
	};

	const deleteAutomationFn = async (automationId: string): Promise<void> => {
		await deleteAutomation({
			id: automationId,
		});
		trackEvent('automation/delete', {}, user, workspace);

		closeAllModals();
	};

	const handleDeleteAutomation = async () => {
		if (automation?.id) {
			openModal({
				variant: 'default',
				title: `Delete "${automation.title}" automation`,
				children: (
					<DeleteModal
						text="This can't be undone."
						onClose={closeAllModals}
						onConfirm={async () => await deleteAutomationFn(automation.id)}
					/>
				),
				classNames: {
					body: classes.content,
				},
			});
		}
	};

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				{automation === undefined ? (
					<BreadcrumbsSkeleton />
				) : (
					<Breadcrumbs
						crumbs={[
							{ title: 'Automations', href: '/automations' },
							{ title: automation.title, href: `/automation/${automation.id}` },
						]}
					/>
				)}
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<Group spacing="xs">
					{automation?.is_enabled ? (
						<RunAutomationButton automationId={automation?.id} />
					) : (
						<Button variant="primary" onClick={handleAutomationStatusToggle}>
							Turn on automation
						</Button>
					)}
					<Menu position="bottom-end">
						<Menu.Target>
							<IconButton
								tooltip="More controls"
								iconName="dots"
								variant="tertiary"
							/>
						</Menu.Target>
						<Menu.Dropdown>
							{automation?.is_enabled && (
								<Menu.Item
									icon={
										<Icon
											name={
												automation.is_enabled ? 'playerPause' : 'playerPlay'
											}
										/>
									}
									onClick={handleAutomationStatusToggle}
								>
									Turn off
								</Menu.Item>
							)}
							<Menu.Item
								icon={<Icon name="trash" />}
								onClick={handleDeleteAutomation}
							>
								Delete
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Group>
			</NavBarRightSideWrapper>
			<NavBarSidesheetControlsWrapper>
				<EntityPageAsideCollapse />
			</NavBarSidesheetControlsWrapper>
		</NavBarWrapper>
	);
}

export default observer(AutomationPageNavBar);
