import { Box, UnstyledButton } from '@mantine/core';
import { useMantineTheme } from '@mantine/styles';
import { Text } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { IUser, IUserGroup, useUpdateUserGroup } from '../../../api';
import { OwnerSelector } from '../../EntityModal/Metadata/OwnerSelector';
import { openModal } from '../../ModalManager';
import { ExtendedDataTableColumn } from '../../TableV2/types';
import { EmojiIcon, GroupsTitle } from './GroupsTitle';

export function MemberRender<T extends IUserGroup>({
	record,
	onChangeUserOwners,
}: {
	record: T;
	onChangeUserOwners?: (id: string) => (value: string[]) => void;
}) {
	const theme = useMantineTheme();

	const ownerIds = useMemo(() => {
		const userIds =
			record.users
				?.map((user) => (typeof user === 'object' ? (user as IUser).id : user))
				.filter((owner) => !isNil(owner)) ?? [];

		return [...userIds];
	}, [record.users]);

	return (
		<Box ml={`-${theme.spacing['2xs']}`}>
			<OwnerSelector
				property="member"
				propertyLabel="Members"
				placeholder={'Add members'}
				forceVariant="tertiary"
				hideOnEmpty
				initialValue={ownerIds}
				key={ownerIds?.join('-')}
				onChangeUserOwners={onChangeUserOwners?.(record.id)}
			/>
		</Box>
	);
}

export const useAvatarColumn = (): ExtendedDataTableColumn<IUserGroup> => {
	const { mutateAsync } = useUpdateUserGroup({});

	const column: ExtendedDataTableColumn<IUserGroup> = useMemo(
		() => ({
			accessor: 'members',
			title: 'Members',
			render: (record: IUserGroup) => (
				<MemberRender
					record={record}
					onChangeUserOwners={(id) => (value) =>
						mutateAsync({
							data: {
								id,
								users: value,
							},
						})
					}
				/>
			),
		}),
		[mutateAsync]
	);

	return column;
};

export const useUserGroupTitle = (): ExtendedDataTableColumn<IUserGroup> => {
	const column: ExtendedDataTableColumn<IUserGroup> = useMemo(
		() => ({
			accessor: 'name',
			title: 'Name',
			render: (record: IUserGroup) => (
				<UnstyledButton
					sx={{
						display: 'flex',
						flexWrap: 'nowrap',
						alignItems: 'center',
						gap: '8px',
					}}
					onClick={() =>
						openModal({
							title: 'Edit group',
							children: <GroupsTitle group={record} />,
						})
					}
				>
					<EmojiIcon size="md" icon={record.icon} />
					<Text size="sm" weight="semibold">
						{record.name}
					</Text>
				</UnstyledButton>
			),
		}),
		[]
	);

	return column;
};
