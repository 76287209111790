import { createStyles, Loader, Tabs } from '@mantine/core';

import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

import { IEvent } from '../../../api';
import Documentation from '../../../components/Documentation';
import EntityDiscussions from '../../../components/EntityDiscussions';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import type { TabItem } from '../../../components/TabsList';
import TabsList from '../../../components/TabsList';
import { useTabNavigate } from '../../../utils/hook/useTabNavigate';
import { withParanthesis } from '../../../utils/shared.utils';
import EventPropertiesTab from '../components/EventPropertiesTab';
import type { EventEntityTabsStore } from './EventEntityTabsStore';

const useStyles = createStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginTop: theme.spacing.md,
	},
	tab: {
		paddingLeft: theme.spacing.sm,
		paddingRight: theme.spacing.sm,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

export interface IEventEntityTabsProps {
	event: IEvent;
	eventEntityTabsStore: EventEntityTabsStore;
	tab: string | undefined;
}

function TabLoader() {
	return <Loader size={12} />;
}

function EventEntityTabs({
	event,
	eventEntityTabsStore: store,
	tab,
}: IEventEntityTabsProps) {
	const { classes } = useStyles();

	const propertiesActive = (store.propertiesCount ?? 0) > 0;

	const isPropertiesDisabled = store.isPropertiesLoading;
	const propertiesCount = withParanthesis(store.propertiesCount);
	const showPropertiesTab = store.isPropertiesLoading || propertiesActive;

	const discussionsCount = withParanthesis(store.discussionCount);

	const documentationFirst = (event.definition?.length || 0) > 2;
	const defaultTab = documentationFirst ? 'documentation' : 'properties';

	const currentTab = tab ?? defaultTab;

	const navigateToTab = useTabNavigate();

	const handleTabChange = (updatedTab: string) => {
		navigateToTab(updatedTab);
	};

	const tabsList: TabItem[] = [
		{
			value: 'properties',
			label: `Properties ${propertiesCount}`,
			icon: store.isPropertiesLoading && <TabLoader />,
			disabled: isPropertiesDisabled,
			hidden: !showPropertiesTab,
		},
		{
			value: 'discussions',
			label: `Questions ${discussionsCount}`,
			icon: store.isDiscussionLoading && <TabLoader />,
		},
	];

	const documentationTab = {
		value: 'documentation',
		label: 'Documentation',
	};

	if (documentationFirst) {
		tabsList.unshift(documentationTab);
	} else {
		tabsList.push(documentationTab);
	}

	return (
		<Tabs
			color="dark"
			classNames={{
				root: classes.root,
				tab: classes.tab,
				panel: classes.panel,
			}}
			value={currentTab}
			onTabChange={handleTabChange}
		>
			<TabsList tabs={tabsList} />
			<Tabs.Panel value="documentation" pt="sm">
				<Documentation entity={event} />
			</Tabs.Panel>

			<Tabs.Panel value="properties" pt="sm">
				<ErrorBoundary onError={() => store.setPropertiesCount(0)}>
					<Suspense fallback={<Loader />}>
						<EventPropertiesTab event={event} store={store} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="discussions" pt="sm">
				<ErrorBoundary onError={() => store.setDiscussionCount(0)}>
					<Suspense fallback={<Loader />}>
						<EntityDiscussions entity={event} store={store} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>
		</Tabs>
	);
}

export default observer(EventEntityTabs);
