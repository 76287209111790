import { showNotification } from '@mantine/notifications';
import { useCallback } from 'react';
import slugify from 'slugify';
import { api } from '../../../../network';
import { IMetric } from '../../types';

export function useMetricDownload(metric: IMetric) {
	const download = useCallback(async () => {
		const title = `${slugify(metric.title ?? 'Export')}.csv`;

		try {
			// Use fetch to get the presigned url, instead of axios, because
			// axios has middleware that appends slashes.
			const response = await fetch(
				`${api()}file/presign_download/?path=metrics&unique_file_key=${
					metric.id
				}&original_name=${encodeURIComponent(title)}`
			);
			window.open((await response.text()).replaceAll('"', ''), '_blank');
		} catch (error) {
			showNotification({
				title: 'Error',
				message: 'Failed to download file',
				color: 'red',
			});
		}
	}, [metric]);

	return {
		download,
	};
}
