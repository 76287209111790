import { Group } from '@mantine/core';
import { forwardRef } from 'react';
import type { SecodaEntity } from '../../../lib/models';
import { SecodaEntityIcon } from '../../SecodaEntity';
import NodeMenuItem from './NodeMenuItem';

interface ISelectItemComponentProps {
	label: string;
	value: string;
	entity: SecodaEntity;
}

const SelectItemComponent = forwardRef<
	HTMLDivElement,
	ISelectItemComponentProps
>(({ entity, ...others }: ISelectItemComponentProps, ref) => (
	<div ref={ref} {...others}>
		<Group noWrap>
			<SecodaEntityIcon entity={entity} />
			<NodeMenuItem entity={entity} />
		</Group>
	</div>
));

export default SelectItemComponent;
