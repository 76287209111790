import { Tabs } from '@mantine/core';
import { useMemo } from 'react';
import type { IForm } from '../../../api';
import { useAuthUser } from '../../../api';
import { useTabNavigate } from '../../../utils/hook/useTabNavigate';
import type { TabItem } from '../../TabsList';
import TabsList from '../../TabsList';
import { useStyles } from './FormTabs.styles';
import { PreviewTab } from './PreviewTab';
import QuestionsTab from './QuestionsTab';
import SubmissionsTab from './SubmissionsTab';

interface IFormPageTabsProps {
	form: IForm;
	tab?: string;
}

export function FormPageTabs({ form, tab = 'fields' }: IFormPageTabsProps) {
	const { classes } = useStyles();

	const { isAdminUser } = useAuthUser();

	const navigateToTab = useTabNavigate();

	const handleTableChange = (updatedTab: string) => {
		navigateToTab(updatedTab);
	};

	const tabsList: TabItem[] = useMemo(() => {
		let list: { value: string; label: string }[] = [];

		if (isAdminUser) {
			list = [
				...list,
				{ value: 'fields', label: 'Fields' },
				{ value: 'preview', label: 'Preview' },
			];
		} else {
			list.push({ value: 'form', label: 'Form' });
		}
		return [...list, { value: 'submissions', label: 'Submissions' }];
	}, [isAdminUser]);

	return (
		<Tabs
			color="dark"
			classNames={{
				root: classes.root,
				panel: classes.panel,
			}}
			defaultValue="automation"
			value={tab}
			onTabChange={handleTableChange}
		>
			<TabsList tabs={tabsList} />
			{isAdminUser && (
				<>
					<Tabs.Panel value="fields" pt="sm">
						<QuestionsTab form={form} />
					</Tabs.Panel>
					<Tabs.Panel value="preview" pt="sm">
						<PreviewTab form={form} />
					</Tabs.Panel>
				</>
			)}
			{!isAdminUser && (
				<Tabs.Panel value="form" pt="sm">
					<PreviewTab form={form} />
				</Tabs.Panel>
			)}
			<Tabs.Panel value="submissions" pt="sm">
				<SubmissionsTab formId={form.id as string} />
			</Tabs.Panel>
		</Tabs>
	);
}
