import type { ModalProps } from '@mantine/core';
import { createContext, type ReactNode } from 'react';
import type { ConfirmationModalProps } from './ConfirmationModal';

export type ModalSettings = Partial<Omit<ModalProps, 'opened'>> & {
	modalId?: string;
};

export type ConfirmLabels = Record<'confirm' | 'cancel', ReactNode>;

export interface OpenConfirmModal
	extends ModalSettings,
		ConfirmationModalProps {}

export type ModalState =
	| {
			id: string;
			props: OpenConfirmModal;
			type: 'confirm';
	  }
	| { id: string; props: ModalSettings; type: 'content' };

export interface ModalsContextProps {
	modals: ModalState[];
	openConfirmModal: (props: OpenConfirmModal) => string;
	closeModal: (id: string, canceled?: boolean) => void;
	closeAll: () => void;
}

export const ModalsContext = createContext<ModalsContextProps | null>(null);
ModalsContext.displayName = 'ConfirmationModal/context';
