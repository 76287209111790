import { Box, Stack } from '@mantine/core';
import { AuditItemOut, useListAuditItems } from '@repo/api-codegen';
import { Title } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { filter, orderBy, sortBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import {
	commentsQueryKeyFactory,
	IComment,
	Incident,
	queryClient,
	useCommentList,
	useCreateComment,
	useDeleteComment,
	useUpdateComment,
} from '../../../../api';
import { removeTrailingWhitespaces } from '../../../../components/Editor/outline/src/utils/removeTrailingWhitespaces';
import { WithOnlyIdRequired } from '../../../../lib/typescript';
import Comment from '../../../DocumentPage/components/Comment';
import CommentForm from '../../../DocumentPage/components/CommentForm';
import { useCommentStoreContext } from '../../../DocumentPage/context';
import IncidentAuditItem from '../../components/IncidentAuditItem';
import { VISIBLE_AUDIT_ITEM_EVENT_TYPE } from '../../components/IncidentAuditItem.constant';

interface IncidentActivityProps {
	incident: Incident;
}

const isComment = (activity: IComment | AuditItemOut): activity is IComment =>
	'edited_at' in activity;

function IncidentActivity({ incident }: IncidentActivityProps) {
	const { setUploadingEditorID } = useCommentStoreContext();

	// === COMMENTS
	const { data: comments } = useCommentList({
		filters: { incident_id: incident.id },
		options: {
			select: (response) => orderBy(response.results, 'created_at'),
			onSuccess: () => {
				setUploadingEditorID('');
			},
		},
	});

	const invalidateCommentQuery = () => {
		queryClient.invalidateQueries(
			commentsQueryKeyFactory.list(1, {
				incident_id: incident.id,
			})
		);
	};

	const { mutateAsync: createComment } = useCreateComment({
		options: {
			onSuccess: invalidateCommentQuery,
		},
	});

	const handleSubmitComment = useCallback(
		async (definition: string) =>
			createComment({
				data: {
					incident: incident.id,
					root: comments && comments?.length > 0 ? comments[0].root : undefined,
					definition: removeTrailingWhitespaces(definition),
				},
			}),
		[comments, createComment, incident.id]
	);

	const { mutateAsync: updateComment } = useUpdateComment({
		options: {
			onSuccess: invalidateCommentQuery,
		},
	});
	const handleUpdateComment = useCallback(
		async (data: WithOnlyIdRequired<IComment>) => {
			await updateComment({
				data,
			});
		},
		[updateComment]
	);

	const { mutateAsync: deleteComment } = useDeleteComment({
		options: {
			onSuccess: () => {
				invalidateCommentQuery();
			},
		},
	});
	const handleDeleteComment = async (id: string) => {
		deleteComment({ id: id });
	};

	// === AUDIT LOGS
	const { data: auditLogs } = useListAuditItems(
		{
			pathParams: {
				resourceId: incident.id,
				resourceType: 'incident',
			},
		},
		{
			select: (data) =>
				filter(data, (item) =>
					VISIBLE_AUDIT_ITEM_EVENT_TYPE.includes(item.event_type)
				),
		}
	);

	// === JOINING COMMENTS AND LOGS
	const activities = useMemo(
		() => sortBy([...(comments ?? []), ...(auditLogs ?? [])], 'created_at'),
		[comments, auditLogs]
	);

	return (
		<Stack spacing={0}>
			<Title size="md" pb="md">
				Activity
			</Title>
			{activities.map((activity, idx) =>
				isComment(activity) ? (
					<Comment
						key={activity.id}
						comment={activity}
						onUpdateComment={handleUpdateComment}
						onDeleteComment={handleDeleteComment}
						showLine={idx !== activities.length - 1}
						titleMaxWidth={space[120]}
					/>
				) : (
					<IncidentAuditItem
						key={activity.id}
						auditItem={activity}
						showLine={idx !== activities.length - 1}
					/>
				)
			)}
			<Box pt="lg">
				<CommentForm
					key="incident-comment-box"
					definition={''}
					onSubmit={handleSubmitComment}
					onCancel={() => {}}
				/>
			</Box>
		</Stack>
	);
}

export default IncidentActivity;
