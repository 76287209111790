import type {
	SelectItemProps as MantineSelectItemProps,
	SelectProps,
} from '@mantine/core';
import { createStyles, Group, Select } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { forwardRef } from 'react';

const useStyles = createStyles((theme) => ({
	input: {
		borderRadius: theme.radius.sm,
		height: '2rem',
	},
	itemRoot: {
		width: 'auto',
		// Applies styles to selected item
		'&[data-selected]': {
			'&, &:hover': {
				backgroundColor: theme.other.getColor('surface/primary/selected'),
				color: theme.other.getColor('text/primary/default'),
			},
		},
		'&[data-hovered]': {},
		'&:not([data-selected])': {
			'> svg': {
				display: 'none',
			},
		},
	},
	text: {
		flexGrow: 1,
	},
	icon: {
		flexShrink: 0,
	},
	dropdown: {
		'.mantine-ScrollArea-viewport > div': {
			display: 'flex !important',
		},
	},
}));

interface SelectItemProps extends MantineSelectItemProps {
	label: string;
	selected: boolean;
	icon?: React.ReactElement;
	className?: string;
}

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
	({ label, icon, selected, className, ...others }: SelectItemProps, ref) => {
		const { cx, classes } = useStyles();

		return (
			<Group
				{...others}
				position="apart"
				noWrap
				ref={ref}
				className={cx(className, classes.itemRoot)}
				spacing="3xs"
			>
				{icon ? icon : null}
				<Text className={classes.text} size="sm" truncate>
					{label}
				</Text>
				{selected && <Icon name="check" className={classes.icon} />}
			</Group>
		);
	}
);

SelectItem.displayName = 'SelectItem';

function SelectMenu(props: SelectProps) {
	const { classes, cx } = useStyles();

	return (
		<Select
			{...props}
			classNames={{
				...props?.classNames,
				input: cx(classes.input, props?.classNames?.input),
				dropdown: cx(classes.dropdown, props?.classNames?.dropdown),
			}}
			withinPortal
			rightSection={<Icon name="chevronDown" color="icon/secondary/default" />}
			itemComponent={SelectItem}
		/>
	);
}

export default SelectMenu;
