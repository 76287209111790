import { createStyles, Select } from '@mantine/core';
import { MultiSelect } from '@repo/foundations';
import { getColor } from '@repo/theme/utils';
import { map } from 'lodash-es';
import { useMemo } from 'react';
import type { FormFieldValue, FormFieldValues } from '../../../../../api';
interface IFormSelectField {
	type: 'select' | 'multi-select';
	initialValue?: string | string[];
	updateValue: (values: FormFieldValues) => void;
	options?: FormFieldValue[];
	isReadOnly: boolean;
}

const useStyles = createStyles((theme) => ({
	input: {
		border: `0.5px solid ${getColor('border/secondary/default')}`,
		borderRadius: theme.radius.sm,
		backgroundColor: getColor('surface/input/default'),
		'&:hover': {
			backgroundColor: getColor('surface/input/hover'),
		},
		'&:focus': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
		'&:active': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
	},
	item: {
		'&[data-selected="true"]': {
			backgroundColor: getColor('fill/brand/default'),
			'&:hover': {
				backgroundColor: getColor('fill/brand/hover'),
			},
		},
	},
}));

export default function FormSelectField({
	type,
	initialValue,
	updateValue,
	options,
	isReadOnly,
}: IFormSelectField) {
	const { classes } = useStyles();

	const handleSelectChange = (value: string) => {
		updateValue({ values: [{ value }] });
	};

	const handleMultiSelectChange = (values: string[]) => {
		updateValue({
			values: map(values, (v) => ({
				value: v,
			})),
		});
	};

	const data = useMemo(
		() =>
			map(options, (option: FormFieldValue) => ({
				label: option.value,
				value: option.value,
			})),
		[options]
	);

	if (type === 'multi-select') {
		return (
			<MultiSelect
				classNames={{ input: classes.input }}
				value={initialValue as string[]}
				data={data}
				setValue={handleMultiSelectChange}
				multiple
				radius="sm"
				maxDropdownHeight={420}
				disabled={isReadOnly}
			/>
		);
	}

	return (
		<Select
			classNames={{ input: classes.input, item: classes.item }}
			value={initialValue as string}
			data={data}
			onChange={handleSelectChange}
			radius="sm"
			maxDropdownHeight={420}
			disabled={isReadOnly}
		/>
	);
}
