import { Box, Skeleton, Stack } from '@mantine/core';
import { useIsJiraIntegrated } from '@repo/api-codegen';
import { space } from '@repo/theme/primitives';
import { ISecodaEntity, useIncident } from '../../../api';

import CollapsableStack from '../../../components/CollapsableStack';
import EntityPageSidebarWrapper from '../../../components/EntityPageLayout/EntitySidebarWrapper';

import { StaticProperty } from '../../../components/EntityPageLayout/EntityPropertySidebar';
import { EntityDisplay } from '../../../components/EntityPageLayout/SidesheetStacks/EntityDisplay/EntityDisplay';
import IncidentSummary from '../../MonitorPage/v2/IncidentDrawer/IncidentSummary';
import { IncidentJiraIssue } from './JiraIssueWorkflow/IncidentJiraIssue';

export interface IIncidentPageSidebarProps {
	incidentId?: string;
	targetEntity?: ISecodaEntity;
}

function V2IncidentPageSidebar({
	incidentId,
	targetEntity,
}: IIncidentPageSidebarProps) {
	const { data: isJiraIntegrated } = useIsJiraIntegrated({});

	const { data: incident } = useIncident({
		id: incidentId as string, // 'id' cannot be undefined here as enabled blocks it
		options: {
			enabled: Boolean(incidentId),
		},
	});

	return (
		<EntityPageSidebarWrapper>
			<Stack>
				<CollapsableStack groupName="Overview">
					{!incident && <Skeleton width="100%" height={400} />}
					{incident && <IncidentSummary incident={incident} />}
				</CollapsableStack>
				{targetEntity && (
					<CollapsableStack groupName="Resource">
						<Stack spacing="xs">
							<EntityDisplay entity={targetEntity} />
						</Stack>
					</CollapsableStack>
				)}
				{isJiraIntegrated && incident && (
					<CollapsableStack groupName="Jira">
						<StaticProperty
							wideLabel
							label="Jira issue"
							custom={
								<Box ml={-space[2]}>
									<IncidentJiraIssue incident={incident} />
								</Box>
							}
						/>
					</CollapsableStack>
				)}
			</Stack>
		</EntityPageSidebarWrapper>
	);
}

export default V2IncidentPageSidebar;
