import { useSortable } from '@dnd-kit/sortable';
import type { MantineTheme } from '@mantine/core';
import {
	ActionIcon,
	Box,
	Group,
	Stack,
	Tooltip,
	createStyles,
} from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { motion } from 'framer-motion';
import { useState } from 'react';
import type { IMetricWidget } from '../../../api';
import { type WidgetSize } from '../../../interfaces';
import type { WidgetUpdateFn } from '../../HomePage/types';
import {
	LOOKBACK_KEY,
	formatMetricWidgetTitle,
	getAnimation,
	getMetricNameTooltip,
	getTransition,
	getWidgetSpan,
	hasLookback,
	joinFilters,
	lookbackLabel,
} from '../utils/utils';
import EditableTitle from './EditableTitle';
import { EditMenu } from './EditMenu';
import { FilterMenu } from './FilterMenu/FilterMenu';
import { MetricWidgetContent } from './MetricWidgetContent';

const useStyles = createStyles(
	(
		theme: MantineTheme,
		params: {
			size: WidgetSize;
			isDragging: boolean;
		}
	) => ({
		container: {
			minHeight: 364,
			minWidth: 364,
			borderRadius: theme.radius.md,
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: theme.colors.gray[2],
			gridColumn: getWidgetSpan(params.size),
			backgroundColor: theme.other.getColor('fill/primary/default'),
			boxShadow: params.isDragging ? theme.shadows.lg : undefined,
			cursor: 'default!important',
		},
		header: {
			cursor: params.isDragging ? 'grabbing' : 'grab',
		},
	})
);

interface IMetricWidgetProps {
	metricWidget: IMetricWidget;
	updateWidget: WidgetUpdateFn;
	deleteWidget: VoidFunction;
}

export function MetricWidget({
	metricWidget,
	updateWidget,
	deleteWidget,
}: IMetricWidgetProps) {
	const {
		attributes,
		setNodeRef,
		setActivatorNodeRef,
		listeners,
		transform,
		isDragging,
	} = useSortable({
		id: metricWidget.id,
		transition: null,
	});

	const { classes, theme } = useStyles({
		size: metricWidget.size,
		isDragging,
	});

	const [editingTitle, setEditingTitle] = useState<boolean>(false);
	const [title, setTitle] = useState<string>(
		formatMetricWidgetTitle(metricWidget)
	);

	const handleSaveTitle = (value: string) => {
		updateWidget(metricWidget.id, 'title')(value);
		setTitle(value);
	};
	const handleExitEditing = () => setEditingTitle(false);

	let lookbackBadge = null;
	if (hasLookback(metricWidget)) {
		const label = lookbackLabel(joinFilters(metricWidget)[LOOKBACK_KEY]);
		lookbackBadge = (
			<Text size="xs" weight="semibold" color="text/secondary/default">
				{label}
			</Text>
		);
	}

	const titleElement = editingTitle ? (
		<EditableTitle
			initialValue={formatMetricWidgetTitle(metricWidget)}
			onSave={handleSaveTitle}
			onExitEditing={handleExitEditing}
		/>
	) : (
		<Text size="md" weight="bold" lineClamp={1}>
			{title}
		</Text>
	);

	const tooltipLabel = getMetricNameTooltip(
		metricWidget.metric_metadata.metric_name
	);
	const tooltip = tooltipLabel && !editingTitle && (
		<Tooltip label={tooltipLabel}>
			<ActionIcon variant="transparent">
				<Icon name="infoCircle" />
			</ActionIcon>
		</Tooltip>
	);

	return (
		<motion.div
			className={classes.container}
			ref={setNodeRef}
			layoutId={String(metricWidget.id)}
			{...{
				...attributes,
				animate: getAnimation(transform, isDragging),
				transition: getTransition(isDragging),
			}}
		>
			<Group
				noWrap
				position="apart"
				align="center"
				pt={8}
				className={classes.header}
			>
				<Stack
					pl={20}
					w="100%"
					spacing={4}
					ref={setActivatorNodeRef}
					{...listeners}
				>
					<Group spacing="xs" p={0} w="100%">
						{titleElement}
						{tooltip}
					</Group>
					{lookbackBadge}
				</Stack>
				<Group h={36} pr={14} align="center" noWrap spacing={0}>
					<FilterMenu
						metricWidget={metricWidget}
						updateWidgetMetricMetadata={updateWidget(
							metricWidget.id,
							'metric_metadata'
						)}
					/>
					<EditMenu
						metricWidget={metricWidget}
						deleteWidget={deleteWidget}
						updateWidgetSize={updateWidget(metricWidget.id, 'size')}
						onEditingTitle={() => setEditingTitle(true)}
					/>
				</Group>
			</Group>
			<Box px={20} py={16}>
				<MetricWidgetContent source="widget" metricWidget={metricWidget} />
			</Box>
		</motion.div>
	);
}
