import {
	Box,
	Center,
	createStyles,
	Group,
	HoverCard,
	Image,
	Stack,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { Badge, IconButton, Text, Title } from '@repo/foundations';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { BaseEdge, EdgeLabelRenderer } from 'reactflow';
import { IIntegration, LineageMetadata } from '../../../api';
import { iconSrcFromIntegration } from '../../../utils/integrationLogo';
import { isTestRunSuccessful } from '../EntityNode/EntityNodeHeader/utils.ts';

dayjs.extend(advancedFormat);

interface EntityEdgeWithJobProps {
	id: string;
	path: string;
	labelX: number;
	labelY: number;
	markerEnd?: string;
	style: {
		stroke: string;
		strokeWidth: number;
	};
	isHovered: boolean;
	metadata: LineageMetadata;
}

type EntityEdgeWithJobStylesParams = Pick<
	EntityEdgeWithJobProps,
	'labelX' | 'labelY'
> & {
	isHovered: boolean;
};

const useStyles = createStyles(
	(theme, { labelX, labelY, isHovered }: EntityEdgeWithJobStylesParams) => ({
		container: {
			position: 'absolute',
			transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
			pointerEvents: 'all',
			zIndex: isHovered ? 1 : 0,
		},
		jobIndicator: {
			width: theme.other.space[1.5],
			height: theme.other.space[1.5],
			borderRadius: theme.other.space[0.5],
			backgroundColor: theme.other.getColor('border/tertiary/default'),
			position: 'relative',
		},
		icon: {
			backgroundColor: theme.other.getColor('fill/primary/default'),
			width: theme.other.space[7],
			height: theme.other.space[7],
			borderWidth: theme.other.space[0.125],
			borderStyle: 'solid',
			borderColor: theme.other.getColor('border/primary/default'),
			borderRadius: theme.radius.sm,
			padding: `${theme.other.space[1.5]}px 0`,
			position: 'relative',
		},
		dropdown: {
			padding: 0,
			borderWidth: theme.other.space[0.125],
			borderStyle: 'solid',
			borderColor: theme.other.getColor('border/primary/default'),
			borderRadius: theme.radius.md,
			boxShadow:
				'0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04)',
			overflow: 'clip',
		},
		dropdownWrapper: {
			position: 'relative',
		},
		dropdownHeader: {
			padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.md}`,
			justifyContent: 'space-between',
			flexWrap: 'nowrap',
		},
		dropdownContent: {
			backgroundColor: theme.other.getColor('surface/secondary/default'),
			padding: `${theme.spacing.sm} ${theme.spacing.md}`,
		},
	})
);

export function EntityEdgeWithJob({
	id,
	path,
	labelX,
	labelY,
	markerEnd,
	style,
	isHovered,
	metadata,
}: EntityEdgeWithJobProps) {
	const [debouncedIsHovered] = useDebouncedValue(isHovered, 100);
	const { classes, cx } = useStyles({
		labelX,
		labelY,
		isHovered: debouncedIsHovered,
	});

	const isSuccess =
		metadata.last_status && isTestRunSuccessful(metadata.last_status);

	const handleExternalLinkClick = () => {
		window.open(metadata.url, '_blank');
	};

	return (
		<>
			<BaseEdge id={id} path={path} markerEnd={markerEnd} style={style} />
			<EdgeLabelRenderer>
				<Box className={cx(classes.container, 'nodrag', 'nopan')}>
					{debouncedIsHovered ? (
						<HoverCard
							classNames={{
								dropdown: classes.dropdown,
							}}
							width={280}
						>
							<HoverCard.Target>
								<Center className={classes.icon}>
									<Image
										width={16}
										height={16}
										src={iconSrcFromIntegration({
											id: metadata.integration_id,
											type: metadata.integration_type,
										} as IIntegration)}
									/>
								</Center>
							</HoverCard.Target>
							<HoverCard.Dropdown>
								<Stack className={classes.dropdownWrapper} spacing={0}>
									<Group className={classes.dropdownHeader}>
										<Group spacing="sm" noWrap>
											<Image
												width={16}
												height={16}
												src={iconSrcFromIntegration({
													id: metadata.integration_id,
													type: metadata.integration_type,
												} as IIntegration)}
											/>
											<Stack spacing={0}>
												<Title size="sm">{metadata.name}</Title>
												<Text size="xs" color="text/secondary/default">
													Job
												</Text>
											</Stack>
										</Group>
										<IconButton
											variant="tertiary"
											iconName={'externalLink'}
											onClick={handleExternalLinkClick}
										/>
									</Group>
									<Stack className={classes.dropdownContent} spacing="sm">
										{metadata.last_status && (
											<Group position="apart" noWrap>
												<Text size="sm" color="text/secondary/default">
													Last run status
												</Text>
												<Badge variant={isSuccess ? 'success' : 'critical'}>
													{metadata.last_status}
												</Badge>
											</Group>
										)}
										{metadata.last_run_date && (
											<Group position="apart" noWrap>
												<Text size="sm" color="text/secondary/default">
													Last run
												</Text>
												<Text size="sm" color="text/secondary/default">
													{dayjs(metadata.last_run_date).format(
														'MMM D, YYYY [at] h:mmA'
													)}
												</Text>
											</Group>
										)}
										{metadata.next_run_date && (
											<Group position="apart" noWrap>
												<Text size="sm" color="text/secondary/default">
													Next run
												</Text>
												<Text size="sm" color="text/secondary/default">
													{dayjs(metadata.next_run_date).format(
														'MMM D, YYYY [at] h:mmA'
													)}
												</Text>
											</Group>
										)}
									</Stack>
								</Stack>
							</HoverCard.Dropdown>
						</HoverCard>
					) : (
						<Box className={classes.jobIndicator} />
					)}
				</Box>
			</EdgeLabelRenderer>
		</>
	);
}
