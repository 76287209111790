import type { IconNames } from '@repo/foundations';

export const INPUT_TYPES: {
	label: string;
	value: string;
	icon: IconNames;
}[] = [
	{
		label: 'Short answer',
		value: 'text',
		icon: 'alignJustified',
	},
	{
		label: 'Paragraph',
		value: 'multiline-text',
		icon: 'alignJustified',
	},
	{
		label: 'Number',
		value: 'number',
		icon: 'hash',
	},
	{
		label: 'Date',
		value: 'date',
		icon: 'calendar',
	},
	{
		label: 'Option List',
		value: 'checkbox',
		icon: 'checkbox',
	},
	{
		label: 'Dropdown',
		value: 'select',
		icon: 'selector',
	},
	{
		label: 'User',
		value: 'user',
		icon: 'user',
	},
	{
		label: 'Resource',
		value: 'resource',
		icon: 'file',
	},
];
