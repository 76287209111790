import { Center, createStyles, Flex, getStylesRef } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import type { TeamOut } from '@repo/api-codegen';
import { useApiGetTeam } from '@repo/api-codegen';
import { Icon, IconButton, Text } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthUser } from '../../../api';
import { useTeamEmptyStates } from '../../../api/hooks/team/emptyStates';
import { getSideBarSettingValue } from '../../../api/types/models/team';
import NewCatalogTree from '../CatalogTree/NewCatalogTree';
import CollectionsTree from '../CollectionsTree/CollectionsTree';
import { DictionaryTermTree } from '../DictionaryTermTree/DictionaryTermTree';
import { DocumentTree } from '../DocumentTree/DocumentTree';
import { MetricTree } from '../MetricTree';
import { isActive } from '../utils';
import type { Link } from './SideBarLink';
import SideBarLink from './SideBarLink';
import SidebarTooltipWrapper from './SidebarTooltipWrapper';
import { sideBarStore } from './store';
import { TeamSettingMenu } from './TeamSettingMenu';

export const useTeamSideBarItemStyle = createStyles(
	(
		theme,
		{
			open,
			sideBarCollapsed,
		}: {
			open: boolean;
			sideBarCollapsed: boolean;
		}
	) => ({
		displayOnHover: {
			ref: getStylesRef('displayOnHover'),
			opacity: 0,
		},
		chevron: {
			transform: open ? 'rotate(90deg)' : 'none',
			transition: 'transform 100ms ease',
			color: theme.other.getColor('icon/secondary/default'),
		},
		wrapper: {
			width: '100%',
			height: theme.other.space[7],
			paddingRight: 0,
		},
		innerWrapper: {
			width: '100%',
			justifyContent: sideBarCollapsed ? 'center' : 'space-between',
			alignItems: 'center',
			borderRadius: theme.other.borderRadius.sm,
			':hover': {
				backgroundColor: theme.other.getColor('fill/transparent/hover'),
			},
			':active': {
				backgroundColor: theme.other.getColor('fill/transparent/active'),
			},
			[`&:hover .${getStylesRef('displayOnHover')}`]: {
				opacity: 1,
			},
		},
		clickArea: {
			display: 'flex',
			justifyContent: sideBarCollapsed ? 'center' : 'start',
			flex: '1 0 auto',
			gap: theme.other.space[0.5],
		},
		hiddenButton: {
			':focus': {
				opacity: 1,
			},
		},
		nonButtonParent: {
			[`&:hover.${getStylesRef('displayOnHover')}`]: {
				opacity: 1,
			},
		},
	})
);

export interface TeamSideBarItemHandle {
	open: () => void;
}

interface TeamSideBarItemProps {
	team: TeamOut;
	sideBarCollapsed: boolean;
}

export const TeamSideBarItem = forwardRef<
	TeamSideBarItemHandle,
	TeamSideBarItemProps
>(({ team: initialTeamFromList, sideBarCollapsed }, ref) => {
	const location = useLocation();

	const [collapsableOpened, setCollapsableOpened] = useLocalStorage({
		key: `team-${initialTeamFromList.id}-sidebar-collapsable-opened`,
		defaultValue: initialTeamFromList.is_default_team,
	});

	const { data: teamDetail } = useApiGetTeam(
		{
			pathParams: {
				teamId: initialTeamFromList.id,
			},
		},
		{
			suspense: false,
			enabled: !isNil(initialTeamFromList.id),
		}
	);

	const { isAdminUser } = useAuthUser();

	useImperativeHandle(ref, () => ({
		open() {
			setCollapsableOpened(true);
		},
	}));

	const { cx, classes, theme } = useTeamSideBarItemStyle({
		open: collapsableOpened,
		sideBarCollapsed,
	});

	const emptyStates = useTeamEmptyStates(initialTeamFromList.id);

	const team = teamDetail || initialTeamFromList;

	const toggleCollapsable = useCallback(() => {
		setCollapsableOpened(!collapsableOpened);
	}, [collapsableOpened, setCollapsableOpened]);

	const contentLinks: Link[] = useMemo(
		() => [
			{
				id: 'nav::home',
				label: 'Home',
				icon: <Icon name="home" color="icon/primary/default" />,
				href: [`/teams/${team.id}/`],
				show: getSideBarSettingValue(team.sidebar_settings, 'home'),
				underTeam: true,
			},
			{
				id: 'nav::catalog',
				label: 'Catalog',
				icon: <Icon name="listDetails" color="icon/primary/default" />,
				href: [`/teams/${team.id}/catalog`],
				show: getSideBarSettingValue(team.sidebar_settings, 'catalog'),
				collapsable: true,
				collapsedComponent: <NewCatalogTree teamId={team.id} />,
				count: emptyStates?.data?.exist_dict?.catalog?.is_empty ? 0 : 1,
				underTeam: true,
			},
			{
				id: 'nav::collections',
				label: 'Collections',
				icon: <Icon name="folders" color="icon/primary/default" />,
				href: [`/teams/${team.id}/collections`],
				show: getSideBarSettingValue(team.sidebar_settings, 'collections'),
				collapsable: true,
				collapsedComponent: <CollectionsTree teamId={team.id} />,
				count: emptyStates?.data?.exist_dict?.collection?.is_empty ? 0 : 1,
				underTeam: true,
			},
			{
				id: 'nav::metrics',
				label: 'Metrics',
				icon: <Icon name="chartHistogram" color="icon/primary/default" />,
				href: [`/teams/${team.id}/metrics`],
				show: getSideBarSettingValue(team.sidebar_settings, 'metrics'),
				collapsable: false,
				collapsedComponent: <MetricTree teamId={team.id} />,
				count: emptyStates?.data?.exist_dict?.metric?.is_empty ? 0 : 1,
				underTeam: true,
			},
			{
				id: 'nav::dictionary',
				label: 'Dictionary',
				icon: <Icon name="book" color="icon/primary/default" />,
				href: [`/teams/${team.id}/dictionary`],
				show: getSideBarSettingValue(team.sidebar_settings, 'dictionary'),
				collapsable: true,
				collapsedComponent: <DictionaryTermTree teamId={team.id} />,
				count: emptyStates?.data?.exist_dict?.dictionary_term?.is_empty ? 0 : 1,
				underTeam: true,
			},

			{
				id: 'nav::docs',
				label: 'Documents',
				icon: <Icon name="fileAnalytics" color="icon/primary/default" />,
				href: [`/teams/${team.id}/docs`],
				show: getSideBarSettingValue(team.sidebar_settings, 'documents'),
				collapsable: true,
				collapsedComponent: <DocumentTree teamId={team.id} />,
				count: emptyStates?.data?.exist_dict?.document?.is_empty ? 0 : 1,
				underTeam: true,
			},
			{
				id: 'nav::questions',
				label: 'Questions',
				icon: (
					<Icon name="messageCircleQuestion" color="icon/primary/default" />
				),
				href: [`/teams/${team.id}/questions`],
				show: getSideBarSettingValue(team.sidebar_settings, 'questions'),
				underTeam: true,
			},
		],
		[
			emptyStates?.data?.exist_dict?.catalog?.is_empty,
			emptyStates?.data?.exist_dict?.collection?.is_empty,
			emptyStates?.data?.exist_dict?.dictionary_term?.is_empty,
			emptyStates?.data?.exist_dict?.document?.is_empty,
			emptyStates?.data?.exist_dict?.metric?.is_empty,
			team.id,
			team.sidebar_settings,
		]
	);

	return (
		<>
			<Flex className={classes.wrapper}>
				<Flex className={classes.innerWrapper}>
					<SidebarTooltipWrapper
						label={team.name}
						showTooltip={sideBarCollapsed}
					>
						<Flex
							role="button"
							onClick={toggleCollapsable}
							className={classes.clickArea}
						>
							<Center w={theme.other.space[7]} h={theme.other.space[7]}>
								<Center
									w={theme.other.space[5]}
									h={theme.other.space[5]}
									p={theme.other.space[0.5]}
								>
									{team.icon}
								</Center>
							</Center>
							{!sideBarStore.collapsed && (
								<Flex align="center">
									<Text
										size="sm"
										color="text/primary/default"
										weight="semibold"
										lineClamp={1}
									>
										{team.name}
									</Text>
									<Icon
										name="chevronRight"
										className={cx(classes.displayOnHover, classes.chevron)}
									/>
								</Flex>
							)}
						</Flex>
					</SidebarTooltipWrapper>
					{isAdminUser && !sideBarStore.collapsed && (
						<TeamSettingMenu team={team} position="right-start" withinPortal>
							<IconButton
								iconName="dots"
								variant="tertiary"
								size="sm"
								className={cx(classes.hiddenButton, classes.displayOnHover)}
								tooltip="Show actions"
								tooltipProps={{ position: 'right', withArrow: true }}
								mr="xs"
							/>
						</TeamSettingMenu>
					)}
				</Flex>
			</Flex>

			<Flex
				w="100%"
				direction="column"
				pb="xs"
				sx={{
					display: collapsableOpened ? 'flex' : 'none',
				}}
			>
				{contentLinks
					.filter((link) => link.show)
					.map((link) => (
						<SideBarLink
							key={link.id}
							link={link}
							selected={isActive(link.href, location, true)}
						/>
					))}
			</Flex>
		</>
	);
});

TeamSideBarItem.displayName = 'TeamSideBarItem';
