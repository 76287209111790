import { Box, createStyles, Input, Slider, Stack } from '@mantine/core';
import { isNumber } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import type { Monitor } from '../../../api';
import {
	ISelectablePropertyProps,
	SelectableProperty,
	StaticProperty,
} from '../../../components/EntityPageLayout/EntityPropertySidebar';
import { NumericProperty } from '../../../components/EntityPageLayout/EntityPropertySidebar/NumericProperty';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { THRESHOLD_OPTIONS } from '../constants';
import { formatValue } from '../utils';

interface ThresholdSelectorProps
	extends Pick<ISelectablePropertyProps, 'label' | 'variant'> {
	value: string;
	onChange: (value: string | number | boolean | string[]) => void;
	threshold: 'automatic' | 'manual';
	data?: Pick<
		Monitor,
		| 'condition_manual_max'
		| 'condition_manual_min'
		| 'condition_auto_sensitivity'
		| 'metric_type'
	>;
	onMinimumValueChange: (value: number | null | undefined) => Promise<void>;
	onMaximumValueChange: (value: number | null | undefined) => Promise<void>;
	onSensitivityChange: (value: number | string) => Promise<void>;
	readOnly?: boolean;
	showSensitivityControl?: boolean;
}

const useStyles = createStyles((theme) => ({
	slider: {
		width: '100%',
	},
}));

function ThresholdSelector({
	data,
	onChange,
	onMinimumValueChange,
	onMaximumValueChange,
	threshold,
	value,
	label,
	onSensitivityChange,
	variant,
	showSensitivityControl = false,
	readOnly = false,
}: ThresholdSelectorProps) {
	const { classes } = useStyles();
	const { monitoringV2 } = useFeatureFlags();
	const autoThresholdsMessage =
		"Automatic thresholds use your monitor's historical data to adjust limits, improving accuracy progressively over time.";

	const [sliderValue, setSliderValue] = useState(5);

	const metricType = data?.metric_type ?? 'custom_sql';

	useEffect(() => {
		if (data?.condition_auto_sensitivity) {
			setSliderValue(data?.condition_auto_sensitivity);
		}
	}, [data]);

	// The unit/suffix text on the right of the text input
	const unit = useMemo(() => {
		const units = {
			freshness: 'days',
			row_count: 'rows',
		} as const;

		return units[metricType as keyof typeof units] ?? undefined;
	}, [metricType]);

	return (
		<Stack spacing="3xs">
			<SelectableProperty
				selected={value}
				type="single"
				label={label}
				variant={variant}
				value="threshold"
				iconType="tabler"
				readOnly={readOnly}
				isViewerUser={false}
				options={THRESHOLD_OPTIONS}
				labelTooltip={monitoringV2 ? autoThresholdsMessage : undefined}
				onChange={onChange}
			/>
			{threshold === 'automatic' &&
				data?.condition_auto_sensitivity &&
				showSensitivityControl && (
					<Box mb="md">
						<StaticProperty
							infoTooltip="If adjusted, higher sensitivity catches smaller details, while lower sensitivity focuses on bigger changes."
							label="Sensitivity"
							custom={
								<Slider
									classNames={{
										root: classes.slider,
									}}
									color="dark"
									label={null}
									showLabelOnHover={false}
									step={1}
									min={1}
									max={10}
									value={sliderValue}
									onChange={setSliderValue}
									onChangeEnd={(val) => onSensitivityChange(val)}
									marks={[
										{ value: 1, label: 'Low' },
										{ value: 5, label: 'Default' },
										{ value: 9, label: 'High' },
									]}
								/>
							}
						/>
					</Box>
				)}
			{threshold === 'manual' && (
				<>
					<NumericProperty
						readOnly={readOnly}
						label="Minimum"
						value={data?.condition_manual_min}
						onValueChange={onMinimumValueChange}
						displayValue={
							isNumber(data?.condition_manual_min)
								? formatValue(metricType, data?.condition_manual_min)
								: undefined
						}
						unit={unit}
					/>
					<NumericProperty
						readOnly={readOnly}
						label="Maximum"
						value={data?.condition_manual_max}
						displayValue={
							isNumber(data?.condition_manual_max)
								? formatValue(metricType, data?.condition_manual_max)
								: undefined
						}
						onValueChange={onMaximumValueChange}
						unit={unit}
					/>
					{data?.condition_manual_max === null &&
						data?.condition_manual_min === null && (
							<Input.Error size="sm" color="critical">
								Please specify either a minimum or maximum threshold. If both
								are left unset, automatic thresholds will be applied.
							</Input.Error>
						)}
					{data?.condition_manual_max !== null &&
						data?.condition_manual_max !== undefined &&
						data?.condition_manual_min !== null &&
						data?.condition_manual_min !== undefined &&
						data?.condition_manual_max < data?.condition_manual_min && (
							<Input.Error size="xs" color="critical" pl="xs" pt="xs">
								The maximum value should be greater than the minimum value.
							</Input.Error>
						)}
				</>
			)}
		</Stack>
	);
}

export default ThresholdSelector;
