// NOTE: Generating breadcrumbs from search metadata is an approximation.

import { capitalize, compact, lowerCase } from "lodash-es";
import { ISearchMetadata } from "../models/secodaEntity";

// This is used where it's expensive to do full recursive CTE queries in the backend to get the full path (Catalog page, selectors, etc..)
export function getBreadCrumbsFromSearchMetadata(
	metadata?: ISearchMetadata
): Array<string> {
	if (!metadata) {
		return [];
	}

	const { group, database, schema, table } = metadata;
	let breadcrumbs: Array<string | undefined> = [];

	if (database) {
		breadcrumbs = [database, schema];
		if (table) {
			breadcrumbs.push(table);
		}
	}

	if (group) {
		breadcrumbs = [group];
	}

	return compact(breadcrumbs);
}

export function getSummaryAndBreadCrumbs(
	entityType: string | null,
	metadata?: ISearchMetadata
) {
	const type = entityType ? capitalize(lowerCase(entityType)) : '';
	const breadCrumbs = getBreadCrumbsFromSearchMetadata(metadata);
	if (breadCrumbs.length === 0) {
		return type;
	}
	return `${type}${type ? ' · ' : ''}${breadCrumbs.join(' / ')}`;
}
