import { makeAutoObservable } from 'mobx';
import type { SecodaEntity } from '../../lib/models';

export enum EmbeddedQuestionsViewEnum {
	LIST = 'list',
	QUESTION = 'question',
}

class EmbeddedQuestionsStore {
	loading = false;

	open = false;

	entity?: SecodaEntity;

	view: EmbeddedQuestionsViewEnum = EmbeddedQuestionsViewEnum.LIST;

	setOpen(open: boolean) {
		this.open = open;
	}

	constructor() {
		makeAutoObservable(this);
	}
}

export const embeddedQuestionsStore = new EmbeddedQuestionsStore();
