import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IMetric } from '../../types';

export const METRIC_NAMESPACE = ['metric', 'metrics'];

export const metricsQueryKeyFactory = createQueryKeys(METRIC_NAMESPACE);

const { prefetchMetric, prefetchMetricList } = prefetchFunctionsFactory(
	'metric',
	metricsQueryKeyFactory
);

const {
	useMetric,
	useMetricInfiniteList,
	useMetricList,
	useCreateMetric,
	useDeleteMetric,
	useUpdateMetric,
	fetchMetricList,
	updateMetric,
} = baseQueryHooksFactory<IMetric, 'metric'>('metric', metricsQueryKeyFactory);

export {
	fetchMetricList,
	prefetchMetric,
	prefetchMetricList,
	updateMetric,
	useCreateMetric,
	useDeleteMetric,
	useMetric,
	useMetricInfiniteList,
	useMetricList,
	useUpdateMetric,
};
