import type {
	QueryFunctionContext,
	QueryKey,
	UseQueryOptions,
} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import { createQueryKeys } from '../../factories';
import type { Namespace } from '../../types';
import { useWorkspace } from './useWorkspace';

export interface IFeatureAccess {
	monitoring_access: boolean;
	automations_access: boolean;
	ai_access: boolean;
	api_access: boolean;
	analytics_access: boolean;
	saml_access: boolean;
	tunnel_access: boolean;
	group_access: boolean;
	dqs_access: boolean;
}

interface IUseFeatureAccessArgs {
	options?: UseQueryOptions<IFeatureAccess>;
}

export function getCloudflareFeatureAccessQueryFn(namespace: Namespace) {
	const defaultQueryFn = async ({ signal }: QueryFunctionContext<QueryKey>) => {
		const url = getEndpoints(namespace).root();

		const { data } = await apiClient.get<IFeatureAccess>(url, { signal });
		return data;
	};

	return defaultQueryFn;
}

function useCloudflareFeatureAccess({ options }: IUseFeatureAccessArgs = {}) {
	const { workspace } = useWorkspace();
	const queryKeyFactory = createQueryKeys([
		'workspace',
		workspace.id,
		'feature_access',
	]);
	const queryFn = getCloudflareFeatureAccessQueryFn(queryKeyFactory.namespace);
	return useQuery<IFeatureAccess>(queryKeyFactory.namespace, queryFn, {
		suspense: false,
		enabled: !!workspace.id,
		...options,
	});
}

export function useFeatureAccess() {
	const { data, isLoading } = useCloudflareFeatureAccess({
		options: {
			suspense: false,
		},
	});
	return {
		monitoringAccess: data?.monitoring_access,
		automationsAccess: data?.automations_access,
		aiAccess: data?.ai_access,
		apiAccess: data?.api_access,
		analyticsAccess: data?.analytics_access,
		samlAccess: data?.saml_access,
		tunnelAccess: data?.tunnel_access,
		groupAccess: data?.group_access,
		dqsAccess: data?.dqs_access,
		isLoading,
	};
}
