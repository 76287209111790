import { Checkbox, Divider, ScrollArea, Stack } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useInputState } from '@mantine/hooks';
import { sortBy } from 'lodash-es';
import { useCallback } from 'react';
import { IIntegration, IUser } from '../../../../../api';
import useFuseSearch from '../../../../../hooks/useFuseSearch.tsx';
import { getHash } from '../../../../../utils/shared.utils.ts';
import SearchBox from '../../../../SearchBox/SearchBox.tsx';
import { PreferenceSection } from './PreferenceSection';
import { useStyles } from './PreferencesPanel.styles';

interface PopularityProps {
	integration: IIntegration;
	serviceAccounts: IUser[];
	form: UseFormReturnType<
		Record<string, boolean | undefined>,
		(
			values: Record<string, boolean | undefined>
		) => Record<string, boolean | undefined>
	>;
	handlePopularityChange: (emailHash: string, checked: boolean) => void;
}

export function Popularity({
	serviceAccounts,
	form,
	handlePopularityChange,
}: PopularityProps) {
	const { classes, theme } = useStyles();

	const [searchTerm, setSearchTerm] = useInputState('');

	const filteredServiceAccounts = useFuseSearch({
		term: searchTerm,
		items: serviceAccounts,
		keys: ['email'],
		options: { threshold: 0.3 },
	});

	const handleSearch = useCallback(
		(newSearchTerm: string) => {
			setSearchTerm(newSearchTerm);
		},
		[setSearchTerm]
	);

	return (
		<>
			<Divider />
			<PreferenceSection
				header="Popularity"
				subheader="Deselect service accounts to exclude their queries from resource popularity counts."
			>
				<Stack>
					<SearchBox
						variant="tertiary"
						placeholder="Search"
						onSearch={handleSearch}
					/>
					{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
					{/* @ts-ignore poorly typed component by Mantine */}
					<ScrollArea.Autosize mah={theme.other.space[100]} type="auto">
						<Stack className={classes.formWrapper}>
							{sortBy(filteredServiceAccounts, 'email').map((user) => {
								const emailHash = getHash(user.email).toString();
								return (
									<Checkbox
										key={emailHash}
										label={user.email}
										checked={
											form.values[emailHash]
												? form.values[emailHash]
												: user.service_account_count_popularity
										}
										onChange={(event) => {
											handlePopularityChange(
												emailHash,
												event.currentTarget.checked
											);
										}}
									/>
								);
							})}
						</Stack>
					</ScrollArea.Autosize>
				</Stack>
			</PreferenceSection>
		</>
	);
}
