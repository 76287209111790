import { Box, createStyles, Group, Skeleton } from '@mantine/core';
import { Text } from '@repo/foundations';
import { useState } from 'react';
import { Icon } from '../../../../../../packages/foundations';
import { Monitor, TimeRange, useMeasurementsChartData } from '../../../api';
import { ButtonDetails, EmptyState } from '../../../components/EmptyState';
import SelectMenu from '../../../components/SelectMenu';
import { MEASUREMENT_TIME_RANGE_OPTIONS } from '../constants.ts';
import { metricTypeLabel } from '../utils.tsx';
import MonitorTimeseriesChart from './MonitorTimeseriesChart.tsx';

interface V2ChartWrapperProps {
	monitor: Monitor;
}

const useStyles = createStyles((theme) => ({
	root: {
		flexShrink: 0,
	},
	title: {
		color: theme.other.getColor('text/primary/default'),
		fontWeight: theme.other.typography.weight.bold,
		flexGrow: 1,
	},
	content: {
		fontSize: theme.fontSizes.xl,
		fontWeight: theme.other.typography.weight.bold,
	},
	emptyState: {
		padding: theme.spacing.xl,
		paddingBottom: theme.other.space[16],
	},
}));

function V2ChartWrapper({ monitor }: V2ChartWrapperProps) {
	const { classes } = useStyles();

	const [timeRange, setTimeRange] = useState<TimeRange>('7');

	const chartTitle = metricTypeLabel(monitor.metric_type);

	const { data: measurements, isLoading } = useMeasurementsChartData({
		monitorId: monitor.id,
		timeRange,
	});

	const learningMode = monitor?.status === 'learning';

	const emptyStateButton: ButtonDetails[] = [
		{
			name: 'Learn more',
			isPrimary: false,
			size: 'sm',
			action: () => {
				window.open('https://docs.secoda.co/features/monitoring', '_blank');
			},
			leftIconName: 'externalLink',
		},
	];

	const renderContent = () => {
		if (learningMode) {
			return (
				<EmptyState
					className={classes.emptyState}
					illustrationName="ai"
					title="Automatic threshold is learning"
					description="Check back later for a detailed view of the status and history of this monitor."
					includeGoBack={false}
					buttons={emptyStateButton}
					size="sm"
				/>
			);
		}

		if (isLoading) {
			return <Skeleton height={400} />;
		}

		if (!measurements || measurements.length === 0) {
			return (
				<EmptyState
					className={classes.emptyState}
					illustrationName="monitors"
					title="No History to Display"
					description="There is no activity for the given time period. Please check back later."
					includeGoBack={false}
					buttons={emptyStateButton}
					size="sm"
				/>
			);
		}

		return (
			<MonitorTimeseriesChart
				data={measurements}
				width="100%"
				configuredLowerThreshold={monitor.condition_manual_min ?? undefined}
				configuredUpperThreshold={monitor.condition_manual_max ?? undefined}
				metricType={monitor.metric_type}
			/>
		);
	};

	return (
		<Box className={classes.root}>
			<Group mb={'sm'}>
				<Text className={classes.title}>{chartTitle}</Text>
				{!learningMode && timeRange && (
					<SelectMenu
						w="14ch"
						icon={<Icon name="calendar" color="icon/primary/default" />}
						value={timeRange.toString()}
						data={MEASUREMENT_TIME_RANGE_OPTIONS}
						onChange={(val) => setTimeRange(val as TimeRange)}
					/>
				)}
			</Group>
			{renderContent()}
		</Box>
	);
}

export default V2ChartWrapper;
