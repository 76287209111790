import { Group, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import {
	useIncident,
	useIncidentMeasurement,
	useMeasurementsChartData,
	useMonitor,
	useSecodaEntity,
} from '../../api';
import { AIAssistantSidebar } from '../../components/AIAssistant/AIAssistantSidebar/AIAssistantSidebar';
import EntityPageTitle from '../../components/EntityPageLayout/EntityPageTitle';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import { useFeatureFlags } from '../../utils/featureFlags';
import { getMonitorMetricTypeInformation } from '../MonitorPage/utils';
import IncidentPageNavbar from './containers/IncidentPageNavbar';
import IncidentPageSidebar from './containers/IncidentPageSidebar';
import IncidentContent from './IncidentContent';
import { getIncidentStatus, getIncidentStatusIcon } from './utils';
import V2IncidentContent from './v2/V2IncidentContent';
import V2IncidentPageNavbar from './v2/V2IncidentPageNavbar';
import V2IncidentPageSidebar from './v2/V2IncidentPageSidebar';

export interface IIncidentPageProps {
	id?: string;
}

function IncidentPage({ id: propsId }: IIncidentPageProps) {
	const { id: paramsId } = useParams();

	const id = propsId || paramsId;

	const { monitoringV2 } = useFeatureFlags();

	const { data: incident } = useIncident({
		id: id as string, // 'id' cannot be undefined here as enabled blocks it
		options: {
			enabled: Boolean(id),
		},
	});

	const { data: monitor } = useMonitor({
		id: incident?.monitor as string, // 'monitor' cannot be undefined here as enabled blocks it
		options: {
			enabled: Boolean(incident?.monitor),
		},
	});

	const { data: lastMeasurement } = useIncidentMeasurement(incident?.id);

	const { data: targetEntity } = useSecodaEntity({
		id: incident?.target as string, // 'target' cannot be undefined here as enabled blocks it
		options: {
			enabled: Boolean(incident?.target),
		},
	});

	const { data: measurements } = useMeasurementsChartData({
		monitorId: incident?.monitor,
		incidentDate: incident?.created_at,
	});

	const icon = getIncidentStatusIcon(incident?.status, { size: 32 });
	const { label: metricType } = getMonitorMetricTypeInformation(
		monitor?.metric_type
	);
	return (
		<PageLayoutOuterWrapper>
			<Helmet>
				<title>{incident?.name || 'Incident'}</title>
			</Helmet>
			<PageLayoutWrapper name="incident">
				<PageLayoutContentWrapper name="incident">
					{monitoringV2 ? (
						<V2IncidentPageNavbar incident={incident} monitor={monitor} />
					) : (
						<IncidentPageNavbar
							targetEntity={targetEntity}
							incident={incident}
						/>
					)}
					<PageLayoutContent>
						<Stack spacing="xs" py="md">
							<Group spacing="xs" noWrap>
								{!monitoringV2 && icon}
								<EntityPageTitle
									entity={{
										title: incident?.name || '',
										title_cased: incident?.name,
									}}
									isReadOnly
									onChange={() => {}}
									placeholder="Enter Incident Name"
									icon={undefined}
								/>
							</Group>
							{!monitoringV2 && (
								<Text size="sm">
									{getIncidentStatus(metricType, lastMeasurement)}
								</Text>
							)}
						</Stack>
						{monitoringV2 ? (
							<V2IncidentContent incident={incident} />
						) : (
							<IncidentContent
								incident={incident}
								measurements={measurements}
								title={metricType}
							/>
						)}
					</PageLayoutContent>
				</PageLayoutContentWrapper>
				{monitoringV2 ? (
					<V2IncidentPageSidebar
						incidentId={incident?.id}
						targetEntity={targetEntity}
					/>
				) : (
					<IncidentPageSidebar
						incidentId={incident?.id}
						targetEntity={targetEntity}
					/>
				)}
				<AIAssistantSidebar />
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}

export default observer(IncidentPage);
