import {
	Box,
	createStyles,
	Divider,
	Flex,
	Input,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { rem } from '@mantine/styles';
import { Button, IconButton, Text, TextInput } from '@repo/foundations';
import axios from 'axios';
import { map, startCase } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { Ref, useCallback, useRef, useState } from 'react';
import { api } from '../../../../../network';
import type { User, Workspace } from '../../../../lib/models';
import { openModal } from '../../../ModalManager';
import { UserAvatar } from '../../../UserAvatar';
import { WorkspaceItem } from './WorkspaceItem';
import { workspaceSelectorStore } from './store';

function TOTPModalContent({ user }: { user: User }) {
	const input = useRef<HTMLInputElement>();
	const [error, setError] = useState<string | null>(null);

	const handleSubmit = useCallback(async () => {
		if (input?.current?.value) {
			const errorResponse = await workspaceSelectorStore.impersonate(
				user.email,
				input?.current?.value
			);
			if (errorResponse) {
				setError(errorResponse);
			}
		}
	}, [user.email]);

	return (
		<>
			<TextInput
				ref={input as Ref<HTMLInputElement>}
				label="Confirmation code"
				placeholder="Enter confirmation code"
				data-autofocus
			/>
			{error && <Text>{error}</Text>}
			<Button w="100%" onClick={handleSubmit} mt="md" variant="primary">
				Submit
			</Button>
		</>
	);
}

export const useStyles = createStyles((theme) => ({
	workspacesListSectionWrapper: {
		maxHeight: '70vh',
		gap: 0,
		padding: theme.other.space[1],
	},
	sectionWrapper: {
		gap: 0,
		padding: theme.other.space[1],
	},
	userList: {
		maxHeight: '30vh',
		overflowY: 'scroll',
	},
	itemWrapper: {
		padding: `${theme.spacing['3xs']} ${theme.spacing.xs}}`,
	},
	menuRowButton: {
		padding: `0 ${rem(theme.other.space[2])}`,
		height: theme.other.height.sm,
		borderRadius: theme.radius.sm,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/primary/selected'),
		},
	},
	workspace: {
		padding: '8px',
		position: 'relative',
		borderRadius: theme.radius.sm,
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: theme.colors.gray[0],
		},
	},
}));

interface WorkspaceSelectorSubMenuProps {
	workspaces: Workspace[];
	user?: User;
}

export const WorkspaceSelectorSubMenu = observer(
	({ workspaces, user }: WorkspaceSelectorSubMenuProps) => {
		const { classes } = useStyles();
		const [opened, handlers] = useDisclosure();

		const openModalManager = useCallback((impersonatedUser: User) => {
			openModal({
				title: `Impersonating ${impersonatedUser.email}`,
				children: <TOTPModalContent user={impersonatedUser} />,
			});
		}, []);

		const handleCreateWorkspace = useCallback(() => {
			handlers.open();
			workspaceSelectorStore.createWorkspace();
		}, []);

		const handleAddAuthorizedDomain = useCallback(async () => {
			const domain = workspaceSelectorStore.authorizedDomainSearchTerm;
			try {
				if (
					domain.match(
						'^(?!-)[A-Za-z0-9-*]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$'
					)
				) {
					await axios.post(`${api()}auth/utilities/authorized_domain`, {
						domain,
					});
					showNotification({
						title: 'Success',
						message: `Added ${domain} to authorized domains`,
						color: 'green',
					});
				} else {
					showNotification({
						title: 'Error',
						message: `Invalid format for domain: ${domain}`,
						color: 'red',
					});
				}
			} catch (error) {
				showNotification({
					title: 'Error',
					message: `Error adding domain: ${domain} ${error}`,
					color: 'red',
				});
			}
		}, []);

		return (
			<>
				<Stack className={classes.workspacesListSectionWrapper}>
					<Box className={classes.itemWrapper}>
						<Text size="xs" weight="semibold" color="text/secondary/default">
							{user?.email}
						</Text>
					</Box>
					{map(workspaces, (workspace) => (
						<WorkspaceItem key={workspace.id} workspace={workspace} />
					))}
				</Stack>
				{(user?.is_admin || workspaceSelectorStore.account?.is_superuser) && (
					<>
						<Divider />
						<Stack className={classes.sectionWrapper}>
							<UnstyledButton
								disabled={opened}
								className={classes.menuRowButton}
								onClick={handleCreateWorkspace}
							>
								<Text size="sm">Create a new workspace</Text>
							</UnstyledButton>
						</Stack>
					</>
				)}
				{user?.email.endsWith('@secoda.co') && (
					<>
						<Divider />
						<Stack className={classes.sectionWrapper}>
							<Stack spacing="xs" py="xs">
								<Box className={classes.itemWrapper}>
									<Text
										size="xs"
										weight="semibold"
										color="text/secondary/default"
									>
										Authorized instance domains
									</Text>
								</Box>
								<Flex>
									<Input
										size="xs"
										px="xs"
										placeholder="Add a domain"
										value={workspaceSelectorStore.authorizedDomainSearchTerm}
										onChange={(e: any) => {
											workspaceSelectorStore.authorizedDomainSearchTerm =
												e.target.value;
										}}
									/>
									<IconButton
										iconName="plus"
										onClick={handleAddAuthorizedDomain}
									/>
								</Flex>
							</Stack>
						</Stack>
					</>
				)}
				{workspaceSelectorStore.account?.is_superuser && (
					<Stack className={classes.sectionWrapper}>
						<Divider />
						<Stack spacing="xs" py="xs">
							<Box className={classes.itemWrapper}>
								<Text
									size="xs"
									weight="semibold"
									color="text/secondary/default"
								>
									Impersonate
								</Text>
							</Box>
							<Input
								size="xs"
								px="xs"
								placeholder="Search for a user"
								value={workspaceSelectorStore.usersSearchTerm}
								onChange={(e: any) => {
									workspaceSelectorStore.usersSearchTerm = e.target.value;
									workspaceSelectorStore.searchUsers();
								}}
							/>
						</Stack>
						<Box className={classes.userList}>
							{workspaceSelectorStore.filteredUsers.map((filteredUser) => (
								<Flex
									key={filteredUser.id}
									className={classes.workspace}
									onClick={() => openModalManager(filteredUser)}
									gap={8}
									align="center"
								>
									<UserAvatar user={filteredUser} size="xxs" />
									<Stack spacing={0} p={0}>
										<Text size="sm" weight="semibold">
											{filteredUser.email}
										</Text>
										<Text size="xs">{startCase(filteredUser.role)}</Text>
									</Stack>
								</Flex>
							))}
						</Box>
					</Stack>
				)}
			</>
		);
	}
);
