import { Group } from '@mantine/core';
import { Button } from '@repo/foundations';
import { lowerCase } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useAuthUser } from '../../../../api';
import { EntityType } from '../../../../lib/types';
import { trackEvent } from '../../../../utils/analytics';

import { entityModalStore } from '../../store';

function DictionaryTermFooter() {
	const { user, workspace } = useAuthUser();
	const navigate = useNavigate();

	if (entityModalStore.type !== EntityType.dictionary_term) {
		return null;
	}

	const handleOnClick = () => {
		trackEvent('dictionary_term/create', {}, user, workspace!);
		entityModalStore.createDictionaryTerm(navigate);
	};

	return (
		<Group position="right">
			<Button
				onClick={handleOnClick}
				loading={entityModalStore.loading}
				variant="primary"
			>
				{entityModalStore.action === 'create'
					? `Create ${lowerCase(entityModalStore.type)}`
					: `Save ${lowerCase(entityModalStore.type)}`}
			</Button>
		</Group>
	);
}

export default observer(DictionaryTermFooter);
