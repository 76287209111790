import { Box, Stack, createStyles } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
	fetchApiTablePropertiesAddCustomProperty,
	fetchApiTablePropertiesGenerateProfile,
} from '@repo/api-codegen';
import { find, get, set } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import {
	IColumn,
	ISecodaEntity,
	queryClient,
	useAuthUser,
	useUpdateSecodaEntity,
	useWorkspace,
} from '../../../../api';
import {
	fetchCatalogList,
	useCatalogInfiniteList,
} from '../../../../api/hooks/resourceCatalog';
import { ColumnName } from '../../../../components/CatalogView/helpers';
import { useColumnDefs } from '../../../../components/CatalogView/hooks/useColumnDefs';
import { TableV2 } from '../../../../components/TableV2';

import {
	FetchModelInfiniteListHook,
	FetchModelList,
} from '../../../../api/factories/types';
import { resourceCatalogQueryKeyFactory } from '../../../../api/hooks/resourceCatalog/constants';
import { DescriptionRender } from '../../../../components/TableV2/render';
import {
	CUSTOM_PROPERTY_COLUMN_PREFIX,
	PROFILER_COLUMN_NAME,
} from '../../../../constants';
import { BackgroundJob } from '../../../../lib/models';
import { EntityType } from '../../../../lib/types';
import { trackEvent } from '../../../../utils/analytics';
import { TableEntityTabsStore } from '../TableEntityTabsStore';
import ColumnProfiler from './ColumnProfiler';
import { useActions, useColumns } from './hooks';

export const useStyles = createStyles(() => ({
	gridWrapper: {
		width: '100%',
		minHeight: 350,
		flexGrow: 1,
	},
	optionsWrapper: {
		visibility: 'visible',
		marginBottom: 12,
	},
	tableWrapper: {
		height: '100%',
	},
}));

interface IColumnsTabProps {
	table: ISecodaEntity;
	store: TableEntityTabsStore;
}

function ColumnsTab({ table, store }: IColumnsTabProps) {
	const columns = useColumns();
	const actions = useActions();

	const { mutateAsync: updateColumn } = useUpdateSecodaEntity({});

	const handleChange = useCallback(
		(field: string) => (id: string) => (value: string | string[] | boolean) => {
			const nested = {};
			// Support field being a `.properties` path and turn into a nested object.
			field.split('.').reduce((acc, key, index, arr) => {
				if (index === arr.length - 1) {
					set(acc, key, value);
				} else {
					set(acc, key, {});
				}
				return get(acc, key);
			}, nested);
			return (
				updateColumn(
					{
						data: {
							id,
							...nested,
						},
					},
					{
						onSuccess: () => {},
					}
				),
				[updateColumn]
			);
		},
		[updateColumn]
	);

	const { classes } = useStyles();
	const { isEditorOrAdminUser, user } = useAuthUser();
	const { workspace } = useWorkspace();

	const [backgroundJob, setBackgroundJob] = useState<BackgroundJob | null>(
		null
	);

	const { columnDefs, onAddColumn } = useColumnDefs({
		defaultColumns: columns,
		catalogType: EntityType.column,
		catalogServerType: EntityType.column,
		isEditorOrAdminUser,
	});

	const handleAddPropertyName = useCallback(
		async (columnName: ColumnName) => {
			await onAddColumn(`${CUSTOM_PROPERTY_COLUMN_PREFIX}${columnName}`);
			await fetchApiTablePropertiesAddCustomProperty({
				pathParams: {
					entityId: table.id,
				},
				body: {
					property: columnName,
				},
			});
		},
		[onAddColumn, table.id]
	);

	const handleRunProfilerClick = useCallback(async () => {
		const job = await fetchApiTablePropertiesGenerateProfile({
			pathParams: {
				entityId: table.id,
			},
		});
		setBackgroundJob(new BackgroundJob(job));
		trackEvent('table/profiler/click', {}, user, workspace);
	}, [table.id, user, workspace]);

	const handleBackgroundJobCompleted = useCallback(async () => {
		setBackgroundJob(null);

		const hasProfilerColumn = find(columnDefs, { field: PROFILER_COLUMN_NAME });
		if (hasProfilerColumn === undefined) {
			await onAddColumn(PROFILER_COLUMN_NAME);
		}

		trackEvent('table/profiler/completed', {}, user, workspace);
		showNotification({
			title: 'Profiling completed',
			message: 'Table profiling completed successfully',
		});

		queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.allLists());
	}, [columnDefs, onAddColumn, user, workspace]);

	// Modify TableV2 columns with the custom properties.
	const customColumns = useMemo(() => {
		const cols = [...columns];
		columnDefs.forEach((column) => {
			if (column.field?.startsWith(CUSTOM_PROPERTY_COLUMN_PREFIX)) {
				cols.push({
					navigate: false,
					accessor: column.field,
					title: column.headerName,
					render: (record) => (
						<DescriptionRender
							record={record}
							field={column.field}
							onChange={handleChange(column.field)}
						/>
					),
					width: 350,
				});
			}
		});
		return cols;
	}, [columnDefs, columns, handleChange]);

	return (
		<Stack className={classes.gridWrapper}>
			<Box className={classes.tableWrapper}>
				<TableV2<IColumn>
					withCustomProperties={EntityType.column}
					key={columnDefs.length}
					withSearch
					withInteractiveHeader
					withCsvExport
					withAddProperty={table.id}
					withCheckbox
					withActions={actions}
					withAdditionalButtons={
						<ColumnProfiler
							backgroundJob={backgroundJob}
							onRunProfilerClick={handleRunProfilerClick}
							onBackgroundJobCompleted={handleBackgroundJobCompleted}
						/>
					}
					columns={customColumns}
					withInfiniteScroll
					usePaginationList={
						useCatalogInfiniteList as FetchModelInfiniteListHook<IColumn>
					}
					columnVisibility={{
						catalogType: EntityType.column,
						catalogServerType: EntityType.column,
					}}
					defaultRequiredCatalogFilters={{
						operands: [
							{
								operands: [],
								field: 'parent_id',
								operator: 'exact',
								value: table.id,
							},
						],
					}}
					defaultRequiredSearchParams={{
						entity_type: EntityType.column,
						calculate_children_count: true,
					}}
					defaultRequiredSearchParamsNesting={{
						entity_type: EntityType.column,
						calculate_children_count: true,
					}}
					nestingFilter="parent_id"
					fetchPaginationList={
						fetchCatalogList as unknown as FetchModelList<IColumn>
					}
					onTotalRowCountChange={(totalCount) => {
						store.setColumnCount(totalCount);
					}}
					onAddProperty={handleAddPropertyName}
				/>
			</Box>
		</Stack>
	);
}

export default observer(ColumnsTab);
