import { Group } from '@mantine/core';
import { Icon, type IconNames, Text } from '@repo/foundations';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

interface IInputTypeItemComponentProps {
	label: string;
	value: string;
	icon: IconNames;
	group: string;
}

const InputTypeItemComponent = forwardRef(
	(
		{ label, value, icon, group, ...others }: IInputTypeItemComponentProps,
		ref: ForwardedRef<HTMLDivElement>
	) => (
		<Group ref={ref} {...others} noWrap>
			<Icon name={icon} color="icon/primary/default" />
			<Text size="sm">{label}</Text>
		</Group>
	)
);

InputTypeItemComponent.displayName = 'InputTypeItemComponent';

export default InputTypeItemComponent;
