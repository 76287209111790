import {
	MetricExecutionOut,
	MetricOut,
	useApiSuperuserExecuteMetric,
	useApiSuperuserGetMetricExecution,
} from '@repo/api-codegen';
import { useCallback, useState } from 'react';
import { api } from '../../../../network';
import { ExecutionStatus } from '../../../components/Sql/types';
import { fetchExecutionStatusWithRetry } from '../../../components/Sql/utils';
import queryClient from '../../queryClient';
import { IMetric } from '../../types';
import { metricsQueryKeyFactory } from '../metricTerm';
import { useCreateMetricExecution } from '../metricTerm/useCreateMetricExecution';

export function useMetricExecution(metric: IMetric) {
	const [isExecuting, setIsExecuting] = useState(false);
	const [executionStatus, setExecutionStatus] =
		useState<ExecutionStatus | null>(null);
	const { mutateAsync: createExecution } = useCreateMetricExecution();

	const execute = useCallback(async () => {
		if (isExecuting) {
			return;
		}

		setIsExecuting(true);
		setExecutionStatus(null);

		const {
			data: { id: newExecutionId },
		} = await createExecution({ metricId: metric.id });

		const url = `${api()}metric/executions/${newExecutionId}/`;
		const newExecutionStatus = await fetchExecutionStatusWithRetry(url, 1);

		setExecutionStatus(newExecutionStatus);
		setIsExecuting(false);

		// refetch metric data
		queryClient.refetchQueries({
			queryKey: metricsQueryKeyFactory.byId(metric.id),
		});
		queryClient.refetchQueries({
			queryKey: [...metricsQueryKeyFactory.all(), 'get_or_create', metric.id],
		});
	}, [createExecution, isExecuting, metric]);

	return {
		isExecuting,
		executionStatus,
		execute,
	};
}

export function useSuperuserMetricExecution(metric?: MetricOut) {
	const [isExecuting, setIsExecuting] = useState(false);
	const [executionStatus, setExecutionStatus] =
		useState<MetricExecutionOut | null>(null);
	const [executionId, setExecutionId] = useState<string | null>(null);

	const { mutateAsync: createExecution } = useApiSuperuserExecuteMetric();

	// Refetch execution status loop
	const { data: metricExecution } = useApiSuperuserGetMetricExecution(
		{
			pathParams: { executionId: executionId || '' },
		},
		{
			enabled: !!executionId,
			refetchInterval(data, query) {
				if (data) {
					setExecutionStatus(data);
				}
				if (data?.status === 'completed' || data?.status === 'failed') {
					setIsExecuting(false);
					return false;
				}

				return 1000;
			},
		}
	);

	const execute = useCallback(async () => {
		if (isExecuting) {
			return;
		}

		setIsExecuting(true);
		setExecutionStatus(null);
		setExecutionId(null);

		const { id } = await createExecution({
			pathParams: { id: metric?.id || '' },
		});
		setExecutionId(id);
	}, [createExecution, isExecuting, metric?.id]);

	if (!metric) {
		return {
			isExecuting,
			executionStatus,
			execute: () => {},
		};
	}

	return {
		isExecuting,
		executionStatus,
		execute,
	};
}
