import { createStyles, ScrollArea, Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useAIConversation, useAuthUser } from '../../api';
import { AIChatConversation } from './AIChatConversation';
import AIChatEmpty from './AIChatEmpty';
import { AIPromptInput } from './AIPromptInput';
import { useAIAssistantContext } from './context';
import { AIAssistantMode } from './types';

const useStyles = createStyles(
	(theme, { isSidebar }: { isSidebar: boolean }) => ({
		wrapper: {
			width: '100%',
			height: '100%',
			paddingBottom: !isSidebar ? theme.spacing.md : undefined,
			gap: 0,
		},
		scrollAreaRoot: {
			width: '100%',
			height: '100%',
		},
		scrollAreaViewport: {
			width: '100%',
			height: '100%',
			'>div': {
				width: '100%',
				height: '100%',
			},
		},
		scrollInnerContent: {
			height: '100%',
		},
		scrollInnerContainer: {
			width: isSidebar ? '100%' : 'calc(640px + (2 * 1.5rem))',
			margin: '0 auto',
			paddingLeft: isSidebar ? 0 : theme.spacing.xl,
			paddingRight: isSidebar ? 0 : theme.spacing.xl,
		},
	})
);

export interface AIChatProps {
	// We don't want users to be able to focus on the `AIPromptInput` input in
	// cases where we're hiding the `AIChat`.
	hideInput?: boolean;
}

export const AIChat = observer(({ hideInput }: AIChatProps) => {
	const store = useAIAssistantContext();

	const { classes, cx } = useStyles({
		isSidebar: store.mode === AIAssistantMode.SIDEBAR,
	});

	const { user } = useAuthUser();

	const {
		isLoading,
		submitPrompt,
		submit,
		stop,
		inputPrompt,
		setInputPrompt,
		isWaitingAI,
		messages,
		viewportRef,
		rerenderKey,
		retryPrompt,
	} = useAIConversation({ id: store.id });

	const isEmpty = store.id === 'new';
	const showInput =
		hideInput !== true &&
		(isEmpty ||
			(messages && messages.length >= 1 && user?.id === messages[0]?.user_id));

	return (
		<Stack className={classes.wrapper}>
			<ScrollArea
				viewportRef={viewportRef}
				classNames={{
					root: classes.scrollAreaRoot,
					viewport: classes.scrollAreaViewport,
				}}
				type="scroll"
			>
				<div
					className={cx(
						classes.scrollInnerContainer,
						classes.scrollInnerContent
					)}
				>
					{isEmpty ? (
						<AIChatEmpty onPromptChange={submitPrompt} />
					) : (
						<AIChatConversation
							isLoading={isLoading}
							messages={messages}
							scrollAreaRef={viewportRef}
							onRetry={retryPrompt}
						/>
					)}
				</div>
			</ScrollArea>
			<div className={classes.scrollInnerContainer}>
				{showInput && (
					<AIPromptInput
						key={rerenderKey}
						value={inputPrompt}
						onChange={setInputPrompt}
						onSubmit={submit}
						isLoading={isWaitingAI}
						onStop={stop}
					/>
				)}
			</div>
		</Stack>
	);
});
