import { useQuery } from '@tanstack/react-query';
import { get, intersection } from 'lodash-es';
import { apiClient, getEndpoints } from '../../common';
import { monitorsQueryKeyFactory } from './constants';

export type MetricType =
	| 'row_count'
	| 'cardinality'
	| 'freshness'
	| 'unique_percentage'
	| 'null_percentage'
	| 'min'
	| 'max'
	| 'mean'
	| 'custom_sql'
	| 'analytics';

export const supportsCustomQuery = (type: MetricType) => type === 'custom_sql';

export const supportsWhereClause = (type: MetricType): boolean =>
	[
		'row_count',
		'cardinality',
		'unique_percentage',
		'null_percentage',
		'min',
		'max',
		'mean',
	].includes(type);

interface MonitorRecommendation {
	type: MetricType;
	score: number;
	target: string;
}

export function useMonitorRecommendations(
	tableId: string,
	filterIds: string[] = []
) {
	return useQuery(
		[...monitorsQueryKeyFactory.byArgs('recommend', tableId), filterIds],
		async ({ signal }) => {
			const url = getEndpoints([
				...monitorsQueryKeyFactory.namespace,
				'recommend',
			]).root();

			const params = { table: tableId };

			const { data } = await apiClient.get<MonitorRecommendation[]>(url, {
				params,
				signal,
			});

			const targetToTypes = data
				.filter((item) => filterIds.includes(item.target))
				.reduce(
					(acc, item) => {
						const value = get(acc, item.target, []);

						return {
							...acc,
							[item.target]: [...value, item.type],
						};
					},
					{} as Record<string, MetricType[]>
				);

			return intersection(...Object.values(targetToTypes));
		}
	);
}
