import type {
	ILineage,
	ILineageEntityChildren,
	ILineageTableQuery,
	ILineageTableTest,
	ISecodaEntity,
	LineageEntityMetadata,
	LineageMetadata,
} from '../../api';
import type { EntityType } from '../../lib/types';

export enum LineageDirectionEnum {
	UPSTREAM = 'UPSTREAM',
	DOWNSTREAM = 'DOWNSTREAM',
}

export enum NodeType {
	ENTITY = 'ENTITY',
	TEMPORARY = 'TEMPORARY',
}

export enum EdgeType {
	ENTITY = 'ENTITY',
	CHILD = 'CHILD',
	MANUAL = 'MANUAL',
}

export enum EdgeHandle {
	MAIN_SOURCE = 'MAIN_SOURCE',
	MAIN_TARGET = 'MAIN_TARGET',
	CHILD_SOURCE = 'CHILD_SOURCE',
	CHILD_TARGET = 'CHILD_TARGET',
	TEMPORARY_SOURCE = 'TEMPORARY_SOURCE',
	TEMPORARY_TARGET = 'TEMPORARY_TARGET',
}

export type LineageTypes = {
	entity: EntityType;
	native?: string;
};

export type EdgeData = {
	isHovered: boolean;
	metadata?: LineageMetadata;
};

export type EntityNodeChildren = {
	id: string;
	node: string;
	title: string;
	types: LineageTypes;
	metadata: LineageEntityMetadata;
	isHighlighted: boolean;
	isFocused: boolean;
};

export type LineageIntegration = {
	id?: string;
	type?: string;
};

export type LineageCount = {
	upstream: number;
	downstream: number;
};

export type LineageBoolean = {
	upstream: boolean;
	downstream: boolean;
};

export type EntityNodeData = {
	id: string;
	title: string;
	ids: {
		databuilder?: string;
		lineage?: string;
	};
	types: LineageTypes;
	published: boolean;
	integration: LineageIntegration;
	metadata: LineageEntityMetadata;
	numOfChildren: number;
	creationQuery?: ILineageTableQuery;
	tests?: ILineageTableTest[];
	direction?: LineageDirectionEnum;
	count: LineageCount;
	fetched: LineageBoolean;
	connectable: {
		source: boolean;
		target: boolean;
	};
	collapsed: LineageBoolean;
	height: number;
	isChildrenOpen: boolean;
	isFetching: boolean;
	isHighlighted: boolean;
	isManual?: boolean;
	isRoot?: boolean;
};

export type TemporaryNodeData = {
	entity?: ISecodaEntity;
	hasEdges: boolean;
};

export type LineageGraphData = {
	entity: ISecodaEntity;
	upstream: ILineage[];
	downstream: ILineage[];
	creationQuery?: ILineageTableQuery;
	children?: ILineageEntityChildren[];
	tests?: ILineageTableTest[];
};

// Lineage Graph Modals
export enum LineageGraphModalType {
	IMPACT_ANALYSIS = 'IMPACT_ANALYSIS',
	TESTS = 'TESTS',
	CREATION_QUERY = 'CREATION_QUERY',
}

interface ModalStateBase {
	opened: boolean;
}

interface ImpactAnalysisModalState extends ModalStateBase {}

interface TestsModalState extends ModalStateBase {
	tests?: ILineageTableTest[];
}

interface CreationQueryModalState extends ModalStateBase {
	query?: ILineageTableQuery;
}

export type LineageGraphModals = {
	[LineageGraphModalType.IMPACT_ANALYSIS]: ImpactAnalysisModalState;
	[LineageGraphModalType.TESTS]: TestsModalState;
	[LineageGraphModalType.CREATION_QUERY]: CreationQueryModalState;
};

// Lineage Graph Search
export type LineageSearchResultItem = {
	id: string;
	nodeId: string;
	title: string;
	types: {
		entity: EntityType;
		native?: string;
	};
	integration: {
		id?: string;
		type?: string;
	};
	metadata: LineageEntityMetadata;
	isSelectable: boolean;
};
