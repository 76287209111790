import { Stack } from '@mantine/core';
import type { IForm } from '../../../api';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutWrapper,
} from '../../PageLayout';
import { FormPageHeader } from '../FormPageHeader';
import { FormPageSideBar } from '../FormPageSideBar';
import { FormPageTabs } from '../FormPageTabs';
import { useStyles } from './FormPageBody.styles';

interface IFormPageBodyProps {
	form: IForm;
	tab?: string;
}

export function FormPageBody({ form, tab }: IFormPageBodyProps) {
	const { classes } = useStyles();

	return (
		<PageLayoutWrapper name="form">
			<PageLayoutContentWrapper name="form">
				<PageLayoutContent className={classes.stackContainer}>
					<Stack className={classes.stack}>
						<FormPageHeader form={form} />
						<FormPageTabs form={form} tab={tab} />
					</Stack>
				</PageLayoutContent>
			</PageLayoutContentWrapper>
			<FormPageSideBar form={form} />
		</PageLayoutWrapper>
	);
}
