import {
	Checkbox,
	Container,
	createStyles,
	Select,
	Stack,
} from '@mantine/core';
import { includes, size } from 'lodash-es';
import { type ChangeEvent } from 'react';
import type {
	FormFieldInputType,
	FormFieldValue,
	IFormField,
} from '../../../../../api';
import FormInputWrapper from '../../../FormInputWrapper';
import { AnchorButton } from './AnchorButton';
import { INPUT_TYPES } from './constants';
import FormFieldHelperText from './FormFieldHelperText';
import FormFieldLabel from './FormFieldLabel';
import FormFieldSelectOptions from './FormFieldSelectOptions';
import InputTypeItemComponent from './InputTypeItemComponent';

interface IFormQuestionContentProps {
	field: IFormField;
	updateField: (field: IFormField) => void;
}

const useStyles = createStyles((theme) => ({
	container: {
		display: 'flex',
		padding: theme.spacing.md,
		flexDirection: 'column',
		alignItems: 'flex-start',
		gap: theme.spacing.md,
		alignSelf: 'stretch',
		margin: '0',
		maxWidth: 'none',
	},
}));

export default function FormQuestionContent({
	field,
	updateField,
}: IFormQuestionContentProps) {
	const { classes } = useStyles();

	const handleUpdateRequired = (event: ChangeEvent<HTMLInputElement>) => {
		updateField({ ...field, required: event.currentTarget.checked });
	};

	const handleUpdateInputType = (inputType: FormFieldInputType) => {
		updateField({ ...field, input_type: inputType });
	};

	const handleMultipleChoiceChange = (event: ChangeEvent<HTMLInputElement>) => {
		updateField({
			...field,
			input_type: event.currentTarget.checked ? 'multi-select' : 'select',
		});
	};

	const handleCreateOption = () => {
		const newOption = {
			value: '',
		};

		updateField({
			...field,
			options: { values: [...(field.options?.values ?? []), newOption] },
		});
	};

	const handleUpdateOptions = (options: FormFieldValue[]) => {
		updateField({
			...field,
			options: { values: options },
		});
	};

	const isSelectInputType = includes(
		['select', 'multi-select'],
		field.input_type
	);

	const isCheckboxInputType = field.input_type === 'checkbox';

	return (
		<Container className={classes.container}>
			<Stack w="100%">
				<FormFieldLabel field={field} updateField={updateField} />
				<FormFieldHelperText field={field} updateField={updateField} />
				<FormInputWrapper label="Input type">
					<Select
						variant="default"
						value={field.input_type}
						itemComponent={InputTypeItemComponent}
						data={INPUT_TYPES}
						onChange={handleUpdateInputType}
					/>
				</FormInputWrapper>
				{(isSelectInputType || isCheckboxInputType) && (
					<>
						<FormInputWrapper
							label="Options"
							isLabelHidden={size(field.options?.values) === 0}
							isChildrenHidden={size(field.options?.values) === 0}
						>
							<FormFieldSelectOptions
								options={field.options?.values ?? []}
								createOption={handleCreateOption}
								updateOptions={handleUpdateOptions}
							/>
						</FormInputWrapper>
						<AnchorButton
							label="Add option"
							icon="plus"
							onClick={handleCreateOption}
						/>
					</>
				)}
				{isSelectInputType && (
					<Checkbox
						label="Allow multiple choices"
						size="sm"
						checked={field.input_type === 'multi-select'}
						onChange={handleMultipleChoiceChange}
					/>
				)}
				<Checkbox
					label="Make required"
					size="sm"
					checked={field.required}
					onChange={handleUpdateRequired}
				/>
			</Stack>
		</Container>
	);
}
