import { Radio, Stack, Textarea } from '@mantine/core';
import type { ChangeEvent } from 'react';
import FormInputWrapper from '../../../FormInputWrapper';
import { useStyles } from './ReviewSubmissionModal.styles';

interface IAdminRightSectionProps {
	review: string;
	setReview: (value: string) => void;
	comment: string;
	setComment: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function AdminRightSection({
	review,
	setReview,
	comment,
	setComment,
}: IAdminRightSectionProps) {
	const { classes } = useStyles();

	return (
		<Stack p="md">
			<FormInputWrapper label="Provide your feedback">
				<Radio.Group value={review} onChange={setReview}>
					<Radio value="approve" label="Approve" />
					<Radio value="reject" label="Reject" />
				</Radio.Group>
			</FormInputWrapper>
			{review === 'reject' && (
				<FormInputWrapper label="Comments" isOptional>
					<Textarea
						classNames={{ input: classes.input }}
						minRows={5}
						value={comment}
						onChange={setComment}
					/>
				</FormInputWrapper>
			)}
		</Stack>
	);
}
