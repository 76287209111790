import { showNotification } from '@mantine/notifications';
import { Button, Icon } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import type { Monitor } from '../../../api';
import { useRunMonitors } from '../../../api';
import BackgroundJobProgress from '../../BackgroundJobProgress/BackgroundJobProgress';
import { ITableViewStore } from '../../CatalogView/TableView/types';

export interface IRunMonitorsProps {
	size?: 'sm' | 'md' | 'lg';
	monitor?: Monitor;
	onSuccess?: VoidFunction;
	store?: ITableViewStore<Monitor>;
	disabled?: boolean;
	variant?: 'default' | 'primary';
}

function RunMonitors({
	size = 'md',
	monitor,
	store,
	onSuccess,
	disabled = false,
	variant = 'default',
}: IRunMonitorsProps) {
	const { backgroundJob, resetBackgroundJob, runMonitors } = useRunMonitors();

	const isSingular = Boolean(monitor) || store?.selectedEntitiesCount === 1;
	const buttonLabel = `Run monitor${isSingular ? '' : 's'}`;

	const handleRunMonitors = async () => {
		let monitorIds =
			store?.selectedEntities
				?.filter(({ is_enabled: isEnabled }) => isEnabled)
				.map(({ id }) => id) ?? [];

		if (monitor) {
			monitorIds = [monitor.id];
		}

		if (monitorIds.length === 0) {
			return;
		}

		await runMonitors(monitorIds);
	};

	const handleBackgroundJobComplete = () => {
		resetBackgroundJob();
		if (onSuccess) {
			onSuccess();
		}

		showNotification({
			title: 'Monitors ran successfully',
			message: 'All the selected monitors ran successfully',
			color: 'green',
			icon: <Icon name="check" />,
		});
	};

	return (
		<>
			{backgroundJob && (
				<BackgroundJobProgress
					key={backgroundJob.id}
					job={backgroundJob}
					onCompleted={handleBackgroundJobComplete}
				/>
			)}
			<Button
				disabled={disabled}
				size={size}
				variant={variant}
				onClick={handleRunMonitors}
			>
				{buttonLabel}
			</Button>
		</>
	);
}

export default observer(RunMonitors);
