import { useCallback } from 'react';
import { queryClient } from '../../api';
import { useAuthUser, useUpdateUser } from '../../api/hooks';
import { thisUserQueryKey } from '../../api/hooks/user/useThisUser';
import { trackEvent } from '../../utils/analytics';
import { createMockableHook } from '../../utils/createMockableHook';

interface ViewerOnboardingStepCompletion {
	dismissAll: boolean;
	search: boolean;
	question: boolean;
	homescreen: boolean;
	welcomePageSeen: boolean;
}

function useUserOnboardingInternal() {
	const { user, isViewerUser, workspace } = useAuthUser();
	const { mutateAsync: updateUser } = useUpdateUser({
		options: {
			onSuccess: () => {
				queryClient.refetchQueries({ queryKey: thisUserQueryKey });
			},
		},
	});

	const dismissViewerStep = useCallback(
		async (step: string) => {
			if (!user || !workspace) {
				return;
			}

			if (!isViewerUser) {
				// If the user isn't a viewer don't dismiss things.
				return;
			}

			if (user.onboarding_steps && user.onboarding_steps[step] === true) {
				// If the value is already set don't set it again.
				return;
			}

			await updateUser({
				data: {
					id: user.id,
					onboarding_steps: {
						...user.onboarding_steps,
						[step]: true,
					},
				},
			});
		},
		[user, workspace, isViewerUser, updateUser]
	);

	const viewerOnboardingStepCompletion: ViewerOnboardingStepCompletion = {
		dismissAll: user?.onboarding_steps?.viewer_onboarding_dismiss_all || false,
		search: user?.onboarding_steps?.viewer_onboarding_completed_search || false,
		question:
			user?.onboarding_steps?.viewer_onboarding_completed_question || false,
		homescreen:
			user?.onboarding_steps?.viewer_onboarding_completed_homescreen || false,
		welcomePageSeen:
			user?.onboarding_steps?.viewer_welcome_page_dismiss || false,
	};

	return {
		dismissViewerOnboardingAll: async () => {
			await dismissViewerStep('viewer_onboarding_dismiss_all');
			trackEvent('viewer_onboarding/dismiss_all/click', {}, user, workspace);
		},
		dismissViewerOnboardingStepSearch: async () => {
			await dismissViewerStep('viewer_onboarding_completed_search');
			trackEvent(
				'viewer_onboarding/search_complete/click',
				{},
				user,
				workspace
			);
		},
		dismissViewerOnboardingStepQuestion: async () => {
			await dismissViewerStep('viewer_onboarding_completed_question');
			trackEvent(
				'viewer_onboarding/search_complete/click',
				{},
				user,
				workspace
			);
		},
		dismissViewerOnboardingStepHomescreen: async () => {
			await dismissViewerStep('viewer_onboarding_completed_homescreen');
			trackEvent(
				'viewer_onboarding/customize_homescreen_complete/click',
				{},
				user,
				workspace
			);
		},
		dismissViewerWelcomePage: async () => {
			await dismissViewerStep('viewer_welcome_page_dismiss');
		},
		viewerOnboardingStepCompletion,
	};
}

export const [useUserOnboarding, MockUseUserOnboarding] = createMockableHook(
	useUserOnboardingInternal
);
