import { Group, useMantineTheme } from '@mantine/core';
import { IconTags } from '@tabler/icons-react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../lib/types';
import { entityModalStore } from '../../store';
import { TagSelector } from '../TagSelector';
import { AssigneeSelector } from './AssigneeSelector';
import { PrioritySelector } from './PrioritySelector';

function QuestionMetadata() {
	const theme = useMantineTheme();
	if (entityModalStore.type !== EntityType.question) {
		return null;
	}

	const handleChange = (key: string) => (value: string | string[]) => {
		entityModalStore.setMetadata(key, value);
	};

	return (
		// We use the key here to force a re-render when the template changes.
		<Group key={entityModalStore?.template?.id}>
			<AssigneeSelector
				initialValue={entityModalStore?.template?.assignee}
				onChange={handleChange}
			/>
			<PrioritySelector
				initialValue={entityModalStore?.template?.priority}
				onChange={handleChange('priority')}
			/>
			<TagSelector
				initialValue={entityModalStore?.template?.tags}
				placeholderIcon={IconTags}
				onChange={handleChange('tags')}
			/>
		</Group>
	);
}

export default observer(QuestionMetadata);
