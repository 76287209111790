import { createStyles, Skeleton, Tabs } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTabNavigate } from '../../utils/hook/useTabNavigate';
import TabsList from '../TabsList';
import type { CollectionsEntityTabsStore } from './store';
import TabsPanel from './TabsPanel';

const useStyles = createStyles(() => ({
	tabs: {
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		marginTop: '16px',
	},
}));

interface CollectionsEntityTabsProps {
	store: CollectionsEntityTabsStore;
}

function CollectionsEntityTabs({ store }: CollectionsEntityTabsProps) {
	const { classes } = useStyles();
	const navigateToTab = useTabNavigate();

	const handleTabChange = useCallback(
		(tab: string) => {
			store.setActiveTab(tab);
			navigateToTab(tab);
		},
		[navigateToTab, store]
	);

	if (store.isLoading) {
		return <Skeleton mt={12} mb={12} height={72} radius="sm" />;
	}

	return (
		<Tabs
			value={store.activeTab}
			onTabChange={handleTabChange}
			color="dark"
			className={classes.tabs}
		>
			<TabsList tabs={store.tabsList} />
			<TabsPanel store={store} />
		</Tabs>
	);
}

CollectionsEntityTabs.displayName = 'CollectionsEntityTabs';

export default observer(CollectionsEntityTabs);
