import type { DefaultMantineColor, Tuple } from '@mantine/styles';

export const neutralColors = {
	white: '#FFFFFF',
	black: '#000000',
};

export type ExtendedCustomColors =
	| 'primary'
	| 'brand'
	| 'black-alpha'
	| 'white-alpha'
	| 'sunset'
	| DefaultMantineColor;

export const colors: Record<ExtendedCustomColors, Tuple<string, 10>> = {
	brand: [
		'#F56551',
		'#FBEEE6',
		'#FFBEB5',
		'#F99385',
		'#F56551',
		'#4A0901',
		'#4A0901',
		'#4A0901',
		'#4A0901',
		'#4A0901',
	],
	primary: [
		'#DCF6FF',
		'#AEE0FF',
		'#7ECAFF',
		'#4DB5FF',
		'#229FFE',
		'#0D86E5',
		'#0074C7',
		'#004A81',
		'#002D50',
		'#001020',
	],
	gray: [
		'rgba(252,252,252,1.00)',
		'rgba(247,247,247,1.00)',
		'rgba(241,241,241,1.00)',
		'rgba(235,235,235,1.00)',
		'rgba(221,221,221,1.00)',
		'rgba(204,204,204,1.00)',
		'rgba(138,138,138,1.00)',
		'rgba(110,110,110,1.00)',
		'rgba(74,74,74,1.00)',
		'rgba(34,34,34,1.00)',
	],
	yellow: [
		'rgba(255,250,218,1.00)',
		'rgba(255,240,173,1.00)',
		'rgba(255,230,125,1.00)',
		'rgba(255,219,75,1.00)',
		'rgba(255,209,26,1.00)',
		'rgba(230,184,0,1.00)',
		'rgba(179,143,0,1.00)',
		'rgba(128,102,0,1.00)',
		'rgba(78,61,0,1.00)',
		'rgba(29,20,0,1.00)',
	],
	dark: [
		'rgba(221,221,221,1.00)',
		'rgba(176,176,176,1.00)',
		'rgba(158,158,158,1.00)',
		'rgba(102,102,102,1.00)',
		'rgba(64,64,64,1.00)',
		'rgba(43,43,43,1.00)',
		'rgba(32,32,32,1.00)',
		'rgba(25,25,25,1.00)',
		'rgba(16,16,16,1.00)',
		'rgba(0,0,0,1.00)',
	],
	blue: [
		'rgba(220,243,255,1.00)',
		'rgba(174,215,255,1.00)',
		'rgba(125,189,255,1.00)',
		'rgba(74,162,255,1.00)',
		'rgba(26,135,255,1.00)',
		'rgba(0,102,204,1.00)',
		'rgba(0,85,180,1.00)',
		'rgba(0,61,130,1.00)',
		'rgba(0,37,81,1.00)',
		'rgba(0,13,33,1.00)',
	],
	'black-alpha': [
		'rgba(0,0,0,0.00)',
		'rgba(0,0,0,0.03)',
		'rgba(0,0,0,0.05)',
		'rgba(0,0,0,0.08)',
		'rgba(0,0,0,0.12)',
		'rgba(0,0,0,0.16)',
		'rgba(0,0,0,0.20)',
		'rgba(0,0,0,0.24)',
		'rgba(0,0,0,0.48)',
		'rgba(0,0,0,1.00)',
	],
	violet: [
		'rgba(249,232,255,1.00)',
		'rgba(227,190,247,1.00)',
		'rgba(206,147,237,1.00)',
		'rgba(186,105,227,1.00)',
		'rgba(166,62,218,1.00)',
		'rgba(140,37,193,1.00)',
		'rgba(109,28,151,1.00)',
		'rgba(79,18,109,1.00)',
		'rgba(48,9,67,1.00)',
		'rgba(20,1,27,1.00)',
	],
	lime: [
		'rgba(243,253,224,1.00)',
		'rgba(225,245,184,1.00)',
		'rgba(206,237,142,1.00)',
		'rgba(187,230,100,1.00)',
		'rgba(168,223,57,1.00)',
		'rgba(143,198,32,1.00)',
		'rgba(110,154,23,1.00)',
		'rgba(78,110,14,1.00)',
		'rgba(46,66,5,1.00)',
		'rgba(13,23,0,1.00)',
	],
	green: [
		'rgba(226,253,234,1.00)',
		'rgba(189,240,203,1.00)',
		'rgba(152,230,172,1.00)',
		'rgba(112,218,139,1.00)',
		'rgba(73,207,107,1.00)',
		'rgba(48,182,81,1.00)',
		'rgba(35,142,62,1.00)',
		'rgba(22,101,43,1.00)',
		'rgba(8,62,24,1.00)',
		'rgba(0,22,2,1.00)',
	],
	orange: [
		'rgba(255,243,218,1.00)',
		'rgba(255,223,174,1.00)',
		'rgba(255,202,125,1.00)',
		'rgba(255,181,75,1.00)',
		'rgba(255,160,26,1.00)',
		'rgba(230,134,0,1.00)',
		'rgba(173,101,0,1.00)',
		'rgba(129,75,0,1.00)',
		'rgba(79,44,0,1.00)',
		'rgba(31,13,0,1.00)',
	],
	grape: [
		'rgba(255,231,248,1.00)',
		'rgba(246,190,224,1.00)',
		'rgba(235,149,200,1.00)',
		'rgba(225,106,177,1.00)',
		'rgba(215,65,155,1.00)',
		'rgba(190,40,129,1.00)',
		'rgba(149,29,100,1.00)',
		'rgba(106,19,73,1.00)',
		'rgba(66,9,44,1.00)',
		'rgba(28,0,17,1.00)',
	],
	sunset: [
		'rgba(255,231,226,1.00)',
		'rgba(255,190,181,1.00)',
		'rgba(249,147,133,1.00)',
		'rgba(245,101,81,1.00)',
		'rgba(0, 0, 0, 1.0)',
		'rgba(0, 0, 0, 1.0)',
		'rgba(169,29,10,1.00)',
		'rgba(122,20,6,1.00)',
		'rgba(74,9,1,1.00)',
		'rgba(31,0,0,1.00)',
	],
	indigo: [
		'rgba(234,233,255,1.00)',
		'rgba(193,193,245,1.00)',
		'rgba(153,152,232,1.00)',
		'rgba(113,111,221,1.00)',
		'rgba(72,70,210,1.00)',
		'rgba(47,45,185,1.00)',
		'rgba(36,35,145,1.00)',
		'rgba(24,25,105,1.00)',
		'rgba(13,14,65,1.00)',
		'rgba(4,4,28,1.00)',
	],
	pink: [
		'rgba(255,225,233,1.00)',
		'rgba(255,177,192,1.00)',
		'rgba(255,126,152,1.00)',
		'rgba(255,76,111,1.00)',
		'rgba(255,26,69,1.00)',
		'rgba(230,0,44,1.00)',
		'rgba(180,0,33,1.00)',
		'rgba(129,0,23,1.00)',
		'rgba(80,0,13,1.00)',
		'rgba(33,0,3,1.00)',
	],
	'white-alpha': [
		'rgba(255,255,255,0.00)',
		'rgba(255,255,255,0.03)',
		'rgba(255,255,255,0.05)',
		'rgba(255,255,255,0.08)',
		'rgba(255,255,255,0.12)',
		'rgba(255,255,255,0.16)',
		'rgba(255,255,255,0.20)',
		'rgba(255,255,255,0.24)',
		'rgba(255,255,255,0.48)',
		'rgba(255,255,255,1.00)',
	],
	cyan: [
		'rgba(220,251,255,1.00)',
		'rgba(185,234,243,1.00)',
		'rgba(147,219,232,1.00)',
		'rgba(108,204,221,1.00)',
		'rgba(70,189,210,1.00)',
		'rgba(45,164,185,1.00)',
		'rgba(29,127,145,1.00)',
		'rgba(14,91,104,1.00)',
		'rgba(0,56,65,1.00)',
		'rgba(0,20,26,1.00)',
	],
	red: [
		'rgba(255,227,224,1.00)',
		'rgba(255,181,177,1.00)',
		'rgba(255,133,127,1.00)',
		'rgba(255,85,76,1.00)',
		'rgba(255,38,26,1.00)',
		'rgba(230,12,0,1.00)',
		'rgba(180,6,0,1.00)',
		'rgba(129,3,0,1.00)',
		'rgba(80,0,0,1.00)',
		'rgba(33,0,0,1.00)',
	],
	teal: [
		'rgba(223,252,244,1.00)',
		'rgba(190,238,223,1.00)',
		'rgba(154,227,203,1.00)',
		'rgba(115,214,182,1.00)',
		'rgba(78,202,161,1.00)',
		'rgba(53,177,135,1.00)',
		'rgba(38,138,105,1.00)',
		'rgba(23,98,74,1.00)',
		'rgba(8,60,44,1.00)',
		'rgba(0,22,12,1.00)',
	],
};

export const chartColors = Object.keys(colors)
	.filter((c) =>
		[
			'blue',
			'cyan',
			'teal',
			'green',
			'lime',
			'primary',
			'yellow',
			'orange',
			'grape',
			'indigo',
		].includes(c)
	)
	.map((c) => colors[c][4]);

export const lightShades = {
	'border/ai-secondary/active': colors.violet[4],
	'border/ai-secondary/default': colors.violet[2],
	'border/ai-secondary/hover': colors.violet[3],
	'border/ai/default': colors.violet[1],
	'border/critical-secondary/default': colors.red[7],
	'border/critical/default': colors.red[1],
	'border/emphasis-secondary/active': colors.blue[4],
	'border/emphasis-secondary/default': colors.blue[3],
	'border/emphasis-secondary/hover': colors.blue[2],
	'border/emphasis/active': colors.blue[7],
	'border/emphasis/default': colors.blue[5],
	'border/emphasis/hover': colors.blue[6],
	'border/info/default': colors.cyan[2],
	'border/input/active': colors.gray[9],
	'border/input/default': colors.gray[6],
	'border/input/hover': colors.gray[7],
	'border/inverse/active': colors.gray[3],
	'border/inverse/default': colors.gray[7],
	'border/inverse/hover': colors.gray[5],
	'border/primary/default': colors.gray[4],
	'border/primary/disabled': colors.gray[3],
	'border/primary/hover': colors.gray[5],
	'border/secondary/default': colors.gray[3],
	'border/secondary/hover': colors.gray[5],
	'border/secondary/active': colors.gray[6],
	'border/tertiary/default': colors.gray[5],
	'border/transparent/default': colors['white-alpha'][9],
	'fill/ai-secondary/active': colors.violet[3],
	'fill/ai-secondary/default': colors.violet[0],
	'fill/ai-secondary/hover': colors.violet[2],
	'fill/ai/default': colors.violet[5],
	'fill/brand/active': colors.dark[8],
	'fill/brand/default': colors.gray[9],
	'fill/brand/disabled': colors['black-alpha'][5],
	'fill/brand/hover': colors.dark[7],
	'fill/brand/selected': colors.gray[9],
	'fill/caution-secondary/default': colors.yellow[1],
	'fill/caution/default': colors.yellow[4],
	'fill/critical-secondary/default': colors.red[1],
	'fill/critical/active': colors.red[7],
	'fill/critical/default': colors.red[5],
	'fill/critical/hover': colors.red[6],
	'fill/critical/selected': colors.red[7],
	'fill/decorative/cyan': colors.cyan[0],
	'fill/decorative/grape': colors.grape[0],
	'fill/decorative/lime': colors.lime[0],
	'fill/decorative/orange': colors.orange[0],
	'fill/decorative/sunset': colors.sunset[0],
	'fill/emphasis-secondary/active': colors.blue[2],
	'fill/emphasis-secondary/default': colors.blue[0],
	'fill/emphasis-secondary/hover': colors.blue[1],
	'fill/emphasis/default': colors.blue[5],
	'fill/info-secondary/default': colors.cyan[1],
	'fill/info/default': colors.cyan[4],
	'fill/inverse/active': colors.gray[7],
	'fill/inverse/default': colors.gray[9],
	'fill/inverse/hover': colors.gray[8],
	'fill/primary/active': colors.gray[2],
	'fill/primary/default': colors['white-alpha'][9],
	'fill/primary/disabled': colors['black-alpha'][2],
	'fill/primary/hover': colors.gray[1],
	'fill/primary/selected': colors.gray[2],
	'fill/secondary/active': colors.gray[4],
	'fill/secondary/default': colors.gray[2],
	'fill/secondary/hover': colors.gray[3],
	'fill/success-secondary/default': colors.green[1],
	'fill/success/default': colors.green[6],
	'fill/tertiary/active': colors.gray[5],
	'fill/tertiary/default': colors.gray[3],
	'fill/tertiary/hover': colors.gray[4],
	'fill/transparent/active': colors['black-alpha'][3],
	'fill/transparent/default': colors['black-alpha'][0],
	'fill/transparent/hover': colors['black-alpha'][1],
	'fill/transparent/selected': colors['black-alpha'][3],
	'fill/transparent-secondary/active': colors['black-alpha'][4],
	'fill/transparent-secondary/default': colors['black-alpha'][2],
	'fill/transparent-secondary/hover': colors['black-alpha'][3],
	'fill/warning-secondary/default': colors.orange[1],
	'fill/warning/default': colors.orange[4],
	'icon/ai/default': colors.violet[4],
	'icon/brand/default': colors.gray[9],
	'icon/caution/default': colors.yellow[5],
	'icon/critical/default': colors.red[6],
	'icon/decorative/cyan': colors.cyan[5],
	'icon/decorative/grape': colors.grape[5],
	'icon/decorative/lime': colors.lime[5],
	'icon/decorative/orange': colors.orange[5],
	'icon/decorative/sunset': colors.sunset[3],
	'icon/emphasis/active': colors.blue[7],
	'icon/emphasis/default': colors.blue[5],
	'icon/emphasis/hover': colors.blue[6],
	'icon/info/default': colors.cyan[6],
	'icon/inverse-ai/default': colors.violet[3],
	'icon/inverse-secondary/default': colors.gray[7],
	'icon/inverse/default': colors.gray[3],
	'icon/primary/active': colors.dark[8],
	'icon/primary/default': colors.gray[8],
	'icon/primary/disabled': colors.gray[5],
	'icon/primary/hover': colors.gray[9],
	'icon/secoda/default': colors.sunset[3],
	'icon/secondary/active': colors.gray[8],
	'icon/secondary/default': colors.gray[6],
	'icon/secondary/hover': colors.gray[7],
	'icon/success/default': colors.green[6],
	'icon/warning/default': colors.orange[6],
	'surface/ai/active': colors.violet[2],
	'surface/ai/default': colors.violet[0],
	'surface/ai/hover': colors.violet[1],
	'surface/app/default': colors['white-alpha'][9],
	'surface/caution/active': colors.yellow[2],
	'surface/caution/default': colors.yellow[0],
	'surface/caution/hover': colors.yellow[1],
	'surface/critical/active': colors.red[1],
	'surface/critical/default': colors.red[0],
	'surface/critical/hover': colors.red[0],
	'surface/emphasis/active': colors.blue[2],
	'surface/emphasis/default': colors.blue[0],
	'surface/emphasis/hover': colors.blue[1],
	'surface/info/active': colors.cyan[2],
	'surface/info/default': colors.cyan[0],
	'surface/info/hover': colors.cyan[1],
	'surface/input/active': colors.gray[3],
	'surface/input/default': colors.gray[0],
	'surface/input/hover': colors.gray[1],
	'surface/inverse/default': colors.dark[8],
	'surface/overlay/default': colors['black-alpha'][7],
	'surface/primary/active': colors.gray[2],
	'surface/primary/default': colors['white-alpha'][9],
	'surface/primary/disabled': colors['black-alpha'][2],
	'surface/primary/hover': colors.gray[1],
	'surface/primary/selected': colors.gray[2],
	'surface/secondary/active': colors.gray[3],
	'surface/secondary/default': colors.gray[1],
	'surface/secondary/hover': colors.gray[2],
	'surface/secondary/selected': colors.gray[3],
	'surface/success/active': colors.green[2],
	'surface/success/default': colors.green[0],
	'surface/success/hover': colors.green[1],
	'surface/tertiary/active': colors.gray[4],
	'surface/tertiary/default': colors.gray[2],
	'surface/tertiary/hover': colors.gray[3],
	'surface/tertiary/selected': colors.gray[4],
	'surface/transparent/default': colors['black-alpha'][0],
	'surface/warning/active': colors.orange[2],
	'surface/warning/default': colors.orange[0],
	'surface/warning/hover': colors.orange[1],
	'text/ai-on-fill/default': colors['white-alpha'][9],
	'text/ai/default': colors.violet[6],
	'text/brand-on-fill/active': colors.gray[5],
	'text/brand-on-fill/default': colors['white-alpha'][9],
	'text/brand-on-fill/disabled': colors['white-alpha'][9],
	'text/brand-on-fill/hover': colors.gray[3],
	'text/caution-on-fill/default': colors.yellow[8],
	'text/caution/default': colors.yellow[8],
	'text/critical-on-fill/active': colors.red[1],
	'text/critical-on-fill/default': colors['white-alpha'][9],
	'text/critical-on-fill/hover': colors.red[0],
	'text/critical/active': colors.red[9],
	'text/critical/default': colors.red[7],
	'text/critical/hover': colors.red[8],
	'text/emphasis-on-fill/default': colors['white-alpha'][9],
	'text/emphasis/active': colors.blue[7],
	'text/emphasis/default': colors.blue[5],
	'text/emphasis/hover': colors.blue[6],
	'text/info-on-fill/default': colors.cyan[8],
	'text/info/default': colors.cyan[7],
	'text/inverse-emphasis/active': colors.blue[0],
	'text/inverse-emphasis/default': colors.blue[2],
	'text/inverse-emphasis/hover': colors.blue[1],
	'text/inverse-secondary/default': colors.gray[5],
	'text/inverse/default': colors.gray[3],
	'text/inverse': colors.gray[1],
	'text/primary/default': colors.gray[9],
	'text/primary/disabled': colors.gray[5],
	'text/secondary/default': colors.gray[7],
	'text/success-on-fill/default': colors['white-alpha'][9],
	'text/success/default': colors.green[8],
	'text/warning-on-fill/default': colors.orange[8],
	'text/warning/default': colors.orange[7],
} as const;

export const darkShades: typeof lightShades = {
	'border/ai-secondary/active': colors.violet[3],
	'border/ai-secondary/default': colors.violet[2],
	'border/ai-secondary/hover': colors.violet[3],
	'border/ai/default': colors.violet[1],
	'border/critical-secondary/default': colors.red[5],
	'border/critical/default': colors.red[5],
	'border/emphasis-secondary/active': colors.blue[4],
	'border/emphasis-secondary/default': colors.blue[5],
	'border/emphasis-secondary/hover': colors.blue[6],
	'border/emphasis/active': colors.blue[5],
	'border/emphasis/default': colors.blue[5],
	'border/emphasis/hover': colors.blue[5],
	'border/info/default': colors.cyan[2],
	'border/input/active': colors.gray[5],
	'border/input/default': colors.gray[7],
	'border/input/hover': colors.gray[6],
	'border/inverse/active': colors.gray[8],
	'border/inverse/default': colors.gray[8],
	'border/inverse/hover': colors.gray[8],
	'border/primary/default': colors.dark[4],
	'border/primary/disabled': colors.dark[4],
	'border/primary/hover': colors.dark[4],
	'border/secondary/default': colors.dark[5],
	'border/secondary/hover': colors.gray[4],
	'border/secondary/active': colors.gray[3],
	'border/tertiary/default': colors.dark[5],
	'border/transparent/default': colors.dark[6],
	'fill/ai-secondary/active': colors.violet[1],
	'fill/ai-secondary/default': colors.violet[1],
	'fill/ai-secondary/hover': colors.violet[1],
	'fill/ai/default': colors.violet[5],
	'fill/brand/active': colors.dark[1],
	'fill/brand/default': colors.dark[0],
	'fill/brand/disabled': colors['white-alpha'][5],
	'fill/brand/hover': colors.dark[1],
	'fill/brand/selected': colors.dark[1],
	'fill/caution-secondary/default': colors.yellow[7],
	'fill/caution/default': colors.yellow[7],
	'fill/critical-secondary/default': colors.red[6],
	'fill/critical/active': colors.red[7],
	'fill/critical/default': colors.red[7],
	'fill/critical/hover': colors.red[6],
	'fill/critical/selected': colors.red[7],
	'fill/decorative/cyan': colors.cyan[0],
	'fill/decorative/grape': colors.grape[0],
	'fill/decorative/lime': colors.lime[0],
	'fill/decorative/orange': colors.orange[0],
	'fill/decorative/sunset': colors.sunset[0],
	'fill/emphasis-secondary/active': colors.blue[6],
	'fill/emphasis-secondary/default': colors.blue[8],
	'fill/emphasis-secondary/hover': colors.blue[7],
	'fill/emphasis/default': colors.blue[6],
	'fill/info-secondary/default': colors.cyan[7],
	'fill/info/default': colors.cyan[7],
	'fill/inverse/active': colors['white-alpha'][1],
	'fill/inverse/default': colors['white-alpha'][1],
	'fill/inverse/hover': colors['white-alpha'][1],
	'fill/primary/active': colors.dark[4],
	'fill/primary/default': colors.dark[6],
	'fill/primary/disabled': colors['white-alpha'][3],
	'fill/primary/hover': colors.dark[5],
	'fill/primary/selected': colors.dark[4],
	'fill/secondary/active': colors.dark[3],
	'fill/secondary/default': colors.dark[5],
	'fill/secondary/hover': colors.dark[4],
	'fill/success-secondary/default': colors.green[7],
	'fill/success/default': colors.green[7],
	'fill/tertiary/active': colors.dark[2],
	'fill/tertiary/default': colors.dark[3],
	'fill/tertiary/hover': colors.dark[4],
	'fill/transparent/active': colors['white-alpha'][3],
	'fill/transparent/default': colors['white-alpha'][0],
	'fill/transparent/hover': colors['white-alpha'][1],
	'fill/transparent/selected': colors['white-alpha'][3],
	'fill/transparent-secondary/active': colors['white-alpha'][4],
	'fill/transparent-secondary/default': colors['white-alpha'][2],
	'fill/transparent-secondary/hover': colors['white-alpha'][3],
	'fill/warning-secondary/default': colors.orange[7],
	'fill/warning/default': colors.orange[7],
	'icon/ai/default': colors.violet[3],
	'icon/brand/default': colors.gray[4],
	'icon/caution/default': colors.yellow[5],
	'icon/critical/default': colors.red[6],
	'icon/decorative/cyan': colors.cyan[5],
	'icon/decorative/grape': colors.grape[5],
	'icon/decorative/lime': colors.lime[5],
	'icon/decorative/orange': colors.orange[5],
	'icon/decorative/sunset': colors.sunset[3],
	'icon/emphasis/active': colors.blue[5],
	'icon/emphasis/default': colors.blue[5],
	'icon/emphasis/hover': colors.blue[5],
	'icon/info/default': colors.cyan[6],
	'icon/inverse-ai/default': colors.violet[5],
	'icon/inverse-secondary/default': colors.gray[3],
	'icon/inverse/default': colors.gray[3],
	'icon/primary/active': colors.dark[2],
	'icon/primary/default': colors.dark[2],
	'icon/primary/disabled': colors.gray[7],
	'icon/primary/hover': colors.dark[2],
	'icon/secoda/default': colors.sunset[3],
	'icon/secondary/active': colors.dark[2],
	'icon/secondary/default': colors.dark[2],
	'icon/secondary/hover': colors.dark[2],
	'icon/success/default': colors.green[6],
	'icon/warning/default': colors.orange[6],
	'surface/ai/active': colors.violet[6],
	'surface/ai/default': colors.violet[8],
	'surface/ai/hover': colors.violet[7],
	'surface/app/default': colors.dark[7],
	'surface/caution/active': colors.yellow[6],
	'surface/caution/default': colors.yellow[8],
	'surface/caution/hover': colors.yellow[7],
	'surface/critical/active': colors.red[6],
	'surface/critical/default': colors.red[8],
	'surface/critical/hover': colors.red[7],
	'surface/emphasis/active': colors.blue[6],
	'surface/emphasis/default': colors.blue[8],
	'surface/emphasis/hover': colors.blue[7],
	'surface/info/active': colors.cyan[6],
	'surface/info/default': colors.cyan[8],
	'surface/info/hover': colors.cyan[7],
	'surface/input/active': colors.dark[5],
	'surface/input/default': colors.dark[5],
	'surface/input/hover': colors.dark[5],
	'surface/inverse/default': colors.gray[6],
	'surface/overlay/default': colors['white-alpha'][7],
	'surface/primary/active': colors.dark[5],
	'surface/primary/default': colors.dark[6],
	'surface/primary/disabled': colors['white-alpha'][3],
	'surface/primary/hover': colors.dark[6],
	'surface/primary/selected': colors.dark[5],
	'surface/secondary/active': colors.dark[3],
	'surface/secondary/default': colors.dark[5],
	'surface/secondary/hover': colors.dark[4],
	'surface/secondary/selected': colors.dark[3],
	'surface/success/active': colors.green[7],
	'surface/success/default': colors.green[8],
	'surface/success/hover': colors.green[7],
	'surface/tertiary/active': colors.dark[2],
	'surface/tertiary/default': colors.dark[4],
	'surface/tertiary/hover': colors.dark[3],
	'surface/tertiary/selected': colors.dark[2],
	'surface/transparent/default': colors['white-alpha'][0],
	'surface/warning/active': colors.orange[6],
	'surface/warning/default': colors.orange[8],
	'surface/warning/hover': colors.orange[7],
	'text/ai-on-fill/default': colors.gray[9],
	'text/ai/default': colors.violet[1],
	'text/brand-on-fill/active': colors.dark[4],
	'text/brand-on-fill/default': colors.dark[6],
	'text/brand-on-fill/disabled': colors.dark[6],
	'text/brand-on-fill/hover': colors.dark[5],
	'text/caution-on-fill/default': colors.gray[9],
	'text/caution/default': colors.yellow[1],
	'text/critical-on-fill/active': colors.gray[9],
	'text/critical-on-fill/default': colors.gray[9],
	'text/critical-on-fill/hover': colors.gray[9],
	'text/critical/active': colors.red[1],
	'text/critical/default': colors.red[1],
	'text/critical/hover': colors.red[1],
	'text/emphasis-on-fill/default': colors.gray[9],
	'text/emphasis/active': colors.blue[0],
	'text/emphasis/default': colors.blue[2],
	'text/emphasis/hover': colors.blue[1],
	'text/info-on-fill/default': colors.gray[9],
	'text/info/default': colors.cyan[1],
	'text/inverse-emphasis/active': neutralColors.white,
	'text/inverse-emphasis/default': neutralColors.white,
	'text/inverse-emphasis/hover': neutralColors.white,
	'text/inverse-secondary/default': neutralColors.white,
	'text/inverse/default': colors.dark[7],
	'text/inverse': colors.dark[8],
	'text/primary/default': colors.dark[0],
	'text/primary/disabled': colors.dark[3],
	'text/secondary/default': colors.dark[1],
	'text/success-on-fill/default': colors.gray[9],
	'text/success/default': colors.green[1],
	'text/warning-on-fill/default': colors.gray[9],
	'text/warning/default': colors.orange[1],
} as const;
