import {
	Box,
	Skeleton,
	UnstyledButton,
	createStyles,
	getStylesRef,
	keyframes,
} from '@mantine/core';
import { Text } from '@repo/foundations';
import type { ISecodaEntity, IUser } from '../../api';
import { ResourcePreview } from '../ResourcePreview';
import { isUserPreview } from '../ResourcePreview/ResourcePreview';
import { SecodaEntityIcon } from '../SecodaEntity/SecodaEntityIcon';
import { UserAvatar } from '../UserAvatar';
import { useResourceLink } from './useResourceLink';

const fade = keyframes({
	'from, to': { opacity: 0.4 },
	'50%': { opacity: 1 },
});

const useStyles = createStyles(
	(theme, { isSelected }: { isSelected: boolean }) => ({
		wrapper: {
			display: 'inline-block',
			verticalAlign: 'baseline',
			borderRadius: theme.radius.sm,
			outline: `2px solid ${isSelected ? theme.colors.blue[5] : 'transparent'}`,
			lineHeight: '22px', // this is fixed because our line height is fixed to 24px

			'&:focus-within': {
				outlineColor: theme.colors.blue[5],
			},
		},
		anchor: {
			ref: getStylesRef('anchor'),

			// NOTE: Do not set a `height` property here. We want the element to take up the height of its content. Fix the content height instead.
			display: 'inline-flex',
			gap: theme.spacing['4xs'],
			alignItems: 'baseline',
			cursor: 'pointer !important',
			textDecoration: 'none !important',
			fontSize: 'inherit', // we want the text size to match the paragraph around it
			lineHeight: 'inherit',
			color: `${theme.other.getColor('text/primary/default')} !important`,
			backgroundColor: theme.other.getColor(
				'fill/transparent-secondary/default'
			),
			outline: 'none !important',
			padding: `0 ${theme.spacing['2xs']} 0 ${theme.spacing['4xs']}`,
			borderRadius: theme.radius.sm,

			'&:hover': {
				backgroundColor: theme.other.getColor(
					'fill/transparent-secondary/hover'
				),
			},
			'&:active': {
				backgroundColor: theme.other.getColor(
					'fill/transparent-secondary/active'
				),
			},
		},
		icon: {
			alignSelf: 'center',
			width: 20,
			height: 20,
		},
		avatar: {
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		text: {
			fontSize: 'inherit',
			lineHeight: 'inherit',
		},
		skeleton: {
			display: 'inline-block',
			verticalAlign: 'bottom',
		},
	})
);

interface ResourceLinkProps {
	href: string | undefined | null;
	isSelected?: boolean;
}

export function ResourceLink({ href, isSelected = false }: ResourceLinkProps) {
	const { id, data, resourceType, isLoading, hasError } = useResourceLink({
		href,
	});
	const { classes, cx } = useStyles({ isSelected });

	if (!href) {
		return null;
	}

	if (isLoading) {
		return (
			<div className={classes.wrapper}>
				<Skeleton className={classes.skeleton} width={200} height={22} />
			</div>
		);
	}

	if (hasError || !data || !id) {
		return <a href={href}>{href}</a>;
	}

	return (
		<div className={classes.wrapper}>
			<ResourcePreview
				id={id}
				data={data}
				resourceType={resourceType}
				isLoading={isLoading}
				hasError={hasError}
			>
				<UnstyledButton
					component="a"
					href={href}
					className={classes.anchor}
					data-testid="resource-link"
				>
					{/* <Group spacing="4xs" align="center" noWrap display="inline-flex"> */}
					{isUserPreview(data) ? (
						<>
							<Box className={cx(classes.icon, classes.avatar)}>
								<UserAvatar user={data as IUser} size="xxs" />
							</Box>
							<Text className={classes.text}>
								{(data as IUser).display_name}
							</Text>
						</>
					) : (
						<>
							<Box className={classes.icon}>
								<SecodaEntityIcon
									entity={
										data.entity_type === 'monitor' ? data.targetEntity : data
									}
									inline
									size={20}
								/>
							</Box>
							<Text className={classes.text}>
								{(data as ISecodaEntity).title}
							</Text>
						</>
					)}
					{/* </Group> */}
				</UnstyledButton>
			</ResourcePreview>
		</div>
	);
}
