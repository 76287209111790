import { Dialog, ScrollArea, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import { useCallback, useEffect, useState } from 'react';
import { useStyles } from './BackgroundJobProgress';

interface MetricJobProgressProps {
	isExecuting: boolean;
	logs?: string;
}

export function MetricJobProgress({
	isExecuting,
	logs,
}: MetricJobProgressProps) {
	const { classes, theme } = useStyles();

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (isExecuting) {
			setIsOpen(true);
		}
	}, [isExecuting]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsOpen(false);
		}, 50_000);
		return () => clearTimeout(timer);
	}, [isExecuting]);

	const handleClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	return (
		<Dialog
			className={classes.dialog}
			opened={isOpen}
			withCloseButton
			onClose={handleClose}
			size="xl"
		>
			<Stack p={0} mt={'xs'}>
				<ScrollArea
					className={`${classes.logs} horizontal-scroll`}
					offsetScrollbars
					type="auto"
					w={theme.other.space[120]}
					h={theme.other.space[100]}
				>
					<Stack>
						<Text size="xs" ff={'monospace'}>
							Submitting job
						</Text>
						{logs?.split('\n').map((line, idx) => (
							// eslint-disable-next-line react/no-array-index-key
							<Text size="xs" ff={'monospace'} key={idx}>
								{line}
							</Text>
						))}
					</Stack>
				</ScrollArea>
			</Stack>
		</Dialog>
	);
}
