import { Carousel } from '@mantine/carousel';
import { filter, isEmpty, map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';
import { useContext, useLayoutEffect } from 'react';
import { FilterValue } from '../FilterCarousel.constants';
import { SearchFilterStoreContext } from '../store';
import SelectedFilterMenu from './SelectedFilterMenu';

// eslint-disable-next-line react/no-unstable-nested-components, react/function-component-definition, arrow-body-style
const SlideWrapper = ({ children }: { children: ReactNode }) => {
	return (
		<Carousel.Slide sx={{ flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}>
			{children}
		</Carousel.Slide>
	);
};

function NilWrapper({ children }: { children: ReactNode }) {
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}

interface SelectedFiltersProps {
	standalone?: boolean;
}

function SelectedFilters({ standalone = false }: SelectedFiltersProps) {
	const searchFilterStore = useContext(SearchFilterStoreContext);

	const selectedFilterValues = filter(
		FilterValue,
		(key) => !isEmpty(searchFilterStore.searchFilters[key].selectedOptions)
	);

	useLayoutEffect(() => {
		searchFilterStore.determineControlVisibility();
	}, [searchFilterStore, selectedFilterValues]);

	if (isEmpty(selectedFilterValues)) {
		return null;
	}

	let Wrapper = SlideWrapper;

	if (standalone) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		Wrapper = NilWrapper;
	}

	return (
		<>
			{map(selectedFilterValues, (filterValue) => (
				<Wrapper key={filterValue}>
					<SelectedFilterMenu filterValue={filterValue} />
				</Wrapper>
			))}
		</>
	);
}

export default observer(SelectedFilters);
