/* eslint-disable no-use-before-define */
import { Box, Menu } from '@mantine/core';
import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { Icon, Text } from '@repo/foundations';
import type {
	DataTableColumn,
	DataTableSortStatus,
} from '@repo/mantine-datatable';
import { capitalize, isNil } from 'lodash-es';
import { toJS } from 'mobx';
import { useCallback, useContext, useRef, useState } from 'react';
import { FilterOptionType, SearchFilterV2StoreContext } from '../Filter';
import { POCFilter } from './Filter';
import TableV2HeaderResizeHandle from './TableV2HeaderResizeHandle/TableV2HeaderResizeHandle';
import { SortStatusV2StoreContext } from './context';

export function TableV2Header<T>({
	column,
	onSort,
	withFilters,
	onColumnVisibilityChange,
	withColumnVisibility = false,
	onResizeColumn,
}: {
	column: DataTableColumn<T> & {
		esAccessor?: string;
		filterOptionType?: FilterOptionType;
	};
	onSort?: (args: DataTableSortStatus) => void;
	withFilters?: boolean;
	withColumnVisibility?: boolean;
	onColumnVisibilityChange: (columnName: string, visible: boolean) => void;
	onResizeColumn?: (columnName: string, newWidth: number) => void;
}) {
	const searchFilterStoreV2 = useContext(SearchFilterV2StoreContext);
	const sortStatus = useContext(SortStatusV2StoreContext);

	const handleSortAsc = useCallback(() => {
		onSort?.({
			columnAccessor: column.esAccessor || column.accessor,
			direction: 'asc',
		});
	}, [column.accessor, onSort]);

	const handleSortDesc = useCallback(() => {
		onSort?.({
			columnAccessor: column.esAccessor || column.accessor,
			direction: 'desc',
		});
	}, [column.accessor, onSort]);

	const [
		filterDropdown,
		{ open: filterDropdownOpen, close: filterDropdownClose },
	] = useDisclosure(false);

	const [dropdown, { close: dropdownClose, toggle: dropdownToggle }] =
		useDisclosure(false);

	const handleDropdownToggle = useCallback(() => {
		if (!dropdown) {
			filterDropdownClose();
			dropdownToggle();
		}
	}, [dropdown, dropdownToggle, filterDropdownClose]);

	const handleFilterDropdownClose = useCallback(() => {
		filterDropdownClose();
		dropdownClose();
	}, [dropdownClose, filterDropdownClose]);

	const filterOption = toJS(searchFilterStoreV2.filterOptions).find(
		(option) => option !== 'divider' && option.type === column.filterOptionType
	);

	const filterable = !isNil(filterOption) && filterOption !== 'divider';

	// Refs for click outside.
	const [dropdownRef, setDropdownRef] = useState<HTMLDivElement | null>(null);
	const [controlRef, setControlRef] = useState<HTMLDivElement | null>(null);
	useClickOutside(() => dropdownClose(), null, [controlRef, dropdownRef]);

	const ref = useRef<HTMLDivElement>(null);

	const activeSortAsc =
		sortStatus?.columnAccessor === column.accessor &&
		sortStatus.direction === 'asc';

	const activeSortDesc =
		sortStatus?.columnAccessor === column.accessor &&
		sortStatus.direction === 'desc';

	return (
		<Box ref={ref}>
			<Menu
				disabled={!onSort && !withFilters}
				withinPortal
				opened={dropdown && !filterDropdown}
				position="bottom-start"
			>
				<Menu.Target>
					<Box
						onClick={handleDropdownToggle}
						ref={setControlRef}
						w="100%"
						sx={{
							cursor: 'pointer',
						}}
					>
						<Text size="sm" fw={500} color="text/secondary/default">
							{column.title ?? capitalize(column.accessor.replace(/_/g, ' '))}
						</Text>
					</Box>
				</Menu.Target>

				<Menu.Dropdown>
					<Box ref={setDropdownRef}>
						<Menu.Item
							onClick={handleSortAsc}
							icon={<Icon name={activeSortAsc ? 'x' : 'arrowUp'} />}
						>
							{activeSortAsc ? 'Remove sort' : 'Sort ascending'}
						</Menu.Item>

						<Menu.Item
							onClick={handleSortDesc}
							icon={<Icon name={activeSortDesc ? 'x' : 'arrowDown'} />}
						>
							{activeSortDesc ? 'Remove sort' : 'Sort descending'}
						</Menu.Item>
						{withFilters && filterable && (
							<Menu.Item
								onClick={filterDropdownOpen}
								icon={<Icon name="filter" />}
							>
								Add filter
							</Menu.Item>
						)}
					</Box>
				</Menu.Dropdown>
			</Menu>
			{withFilters && filterDropdown && filterable && (
				<POCFilter
					controlRef={controlRef}
					dropdownStandaloneOption={filterOption}
					onClose={handleFilterDropdownClose}
				/>
			)}
			<TableV2HeaderResizeHandle
				headerRef={ref.current}
				minWidth={35}
				maxWidth={1200}
				onResize={(updatedWidth: number) => {
					onResizeColumn?.(column.accessor, updatedWidth);
				}}
			/>
		</Box>
	);
}
