import { getDefaultZIndex, Modal } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { useCallback, useReducer, useRef } from 'react';
import { ConfirmationModal } from './ConfirmationModal';
import type {
	ConfirmLabels,
	ModalsContextProps,
	ModalSettings,
	OpenConfirmModal,
} from './context';
import { ModalsContext } from './context';
import { useModalsEvents } from './events';
import { modalsReducer } from './reducer';

export interface ModalsProviderProps {
	/** Your app */
	children: React.ReactNode;

	/** Shared Modal component props, applied for every modal */
	modalProps?: ModalSettings;

	/** Confirm modal labels */
	labels?: ConfirmLabels;
}

function separateConfirmModalProps(props: OpenConfirmModal) {
	if (!props) {
		return { confirmProps: {}, modalProps: {} };
	}

	const {
		id,
		children,
		onCancel,
		onConfirm,
		closeOnConfirm,
		closeOnCancel,
		cancelProps,
		confirmProps,
		groupProps,
		labels,
		...others
	} = props;

	return {
		confirmProps: {
			id,
			children,
			onCancel,
			onConfirm,
			closeOnConfirm,
			closeOnCancel,
			cancelProps,
			confirmProps,
			groupProps,
			labels,
		},
		modalProps: {
			id,
			...others,
		},
	};
}

export function ModalManagerProvider({
	children,
	modalProps,
	labels,
}: ModalsProviderProps) {
	const [state, dispatch] = useReducer(modalsReducer, {
		modals: [],
		current: null,
	});
	const stateRef = useRef(state);
	stateRef.current = state;

	const closeAll = useCallback(
		(canceled?: boolean) => {
			dispatch({ type: 'CLOSE_ALL', canceled });
		},
		[dispatch]
	);

	const openModal = useCallback(
		({ modalId, ...props }: ModalSettings) => {
			const id = modalId || randomId();

			dispatch({
				type: 'OPEN',
				modal: {
					id,
					type: 'content',
					props,
				},
			});
			return id;
		},
		[dispatch]
	);

	const openConfirmModal = useCallback(
		({ modalId, ...props }: OpenConfirmModal) => {
			const id = modalId || randomId();
			dispatch({
				type: 'OPEN',
				modal: {
					id,
					type: 'confirm',
					props,
				},
			});
			return id;
		},
		[dispatch]
	);

	const closeModal = useCallback(
		(id: string, canceled?: boolean) => {
			dispatch({ type: 'CLOSE', modalId: id, canceled });
		},
		[dispatch]
	);

	useModalsEvents({
		openModal,
		openConfirmModal,
		closeModal,
		closeAllModals: closeAll,
	});

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const ctx: ModalsContextProps = {
		modals: state.modals,
		openConfirmModal,
		closeModal,
		closeAll,
	};

	const getCurrentModal = () => {
		const currentModal = stateRef.current.current;
		switch (currentModal?.type) {
			case 'confirm': {
				const {
					modalProps: separatedModalProps,
					confirmProps: separatedConfirmProps,
				} = separateConfirmModalProps(currentModal.props);

				return {
					modalProps: separatedModalProps,
					content: (
						<ConfirmationModal
							{...separatedConfirmProps}
							id={currentModal.id}
							labels={currentModal.props.labels || labels}
						/>
					),
				};
			}
			case 'content': {
				const { children: currentModalChildren, ...rest } = currentModal.props;

				return {
					modalProps: rest,
					// eslint-disable-next-line react/jsx-no-useless-fragment
					content: <>{currentModalChildren}</>,
				};
			}
			default: {
				return {
					modalProps: {},
					content: null,
				};
			}
		}
	};

	const { modalProps: currentModalProps, content } = getCurrentModal();

	return (
		<ModalsContext.Provider value={ctx}>
			<Modal
				zIndex={getDefaultZIndex('modal') + 1}
				{...modalProps}
				{...currentModalProps}
				opened={state.modals.length > 0}
				onClose={() => closeModal(state.current!.id)}
			>
				{content}
			</Modal>

			{children}
		</ModalsContext.Provider>
	);
}
