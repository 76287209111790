import {
	createStyles,
	DefaultProps,
	Select as MantineSelect,
	SelectProps as MantineSelectProps,
	SelectItem,
	Selectors,
	Stack,
} from '@mantine/core';
import { useId } from '@mantine/hooks';
import { iconSize } from '@repo/theme/primitives';
import type { ColorNames } from '@repo/theme/utils';
import { forwardRef, ReactNode, useCallback, useState } from 'react';
import ItemComponent from '../MultiSelect/ItemComponent';
import { TextInputHelp, TextInputLabel } from '../TextInput';
import { TextInputError } from '../TextInput/TextInputError';

type SelectSizes = 'sm' | 'md';

export interface SelectStylesParams {
	size: SelectSizes;
	error?: string;
}

const useStyles = createStyles((theme, { size }: SelectStylesParams) => {
	let height: number = theme.other.space[8];

	let backgroundColor: ColorNames = 'surface/input/default';
	let hoverBackgroundColor: ColorNames = 'surface/input/default';
	let focusBackgroundColor: ColorNames = 'surface/input/default';
	const disabledBackgroundColor: ColorNames = 'surface/primary/disabled';

	let borderWidth: number = 0.5;
	let hoverBorderWidth: number = 0.5;
	let focusBorderWidth: number = 0.5;
	const disabledBorderWidth: number = 0;

	let borderColor: ColorNames = 'border/input/default';
	let hoverBorderColor: ColorNames = 'border/input/hover';
	let focusBorderColor: ColorNames = 'border/input/active';

	const boxShadow = `0px 0px 0px 1px white, 0px 0px 0px 3px ${theme.other.getColor('border/emphasis/default')}`;

	if (size === 'sm') {
		height = theme.other.space[7];
	}

	return {
		input: {
			height,
			minHeight: height,
			backgroundColor: theme.other.getColor(backgroundColor),
			borderRadius: theme.radius.sm,
			borderWidth,
			borderStyle: 'solid',
			borderColor: theme.other.getColor(borderColor),
			'&:hover': {
				backgroundColor: theme.other.getColor(hoverBackgroundColor),
				borderWidth: hoverBorderWidth,
				borderColor: theme.other.getColor(hoverBorderColor),
			},
			'&:focus, &:active': {
				backgroundColor: theme.other.getColor(focusBackgroundColor),
				borderWidth: focusBorderWidth,
				borderColor: theme.other.getColor(focusBorderColor),
				boxShadow,
			},
			'&:disabled': {
				backgroundColor: theme.other.getColor(disabledBackgroundColor),
				borderWidth: disabledBorderWidth,
			},
			'&[data-with-icon]': {
				// have to calculate the padding of the label manually to be consistent with item icon spacing
				// 8px left pad + 20px icon size + 4px right pad
				paddingLeft:
					theme.other.space[1] + iconSize['md'] + theme.other.space[2],
			},
		},
		icon: {
			color: 'inherit',
		},
	};
});

type SelectStylesNames = Selectors<typeof useStyles>;

type SelectProps<T extends SelectItem> = {
	id?: string;
	size?: SelectSizes;
	label?: string;
	help?: string | React.ReactNode;
	error?: string;
	optional?: boolean;

	// Data
	data: T[];

	// Rendering
	renderIcon?: (item: T) => ReactNode;
	renderLabel?: (item: T) => ReactNode;
} & Omit<MantineSelectProps, 'size' | 'required'> &
	DefaultProps<SelectStylesNames, SelectStylesParams>;

const Select = <T extends SelectItem = SelectItem>(
	{
		id,
		size = 'md',
		label,
		name,
		help,
		error,
		optional = false,
		disabled = false,

		// Data
		data,
		value,
		defaultValue,
		onChange,

		// Rendering
		renderIcon,
		renderLabel = (item: T) => item.label,
		...others
	}: SelectProps<T>,
	ref: React.ForwardedRef<HTMLInputElement>
) => {
	const uuid = useId(id);
	const { classes, theme } = useStyles({ size });
	const [selectedItem, setSelectedItem] = useState<T | undefined>(
		data.find((item) => item.value === defaultValue || item.value === value)
	);

	const handleOnChange = useCallback(
		(value: string) => {
			if (disabled) {
				return;
			}

			onChange?.(value);
			setSelectedItem(data.find((item) => item.value === value));
		},
		[disabled, onChange]
	);

	return (
		<Stack w="100%" spacing={theme.spacing['3xs']}>
			<TextInputLabel label={label} optional={optional} inputId={uuid} />
			<MantineSelect
				id={uuid}
				ref={ref}
				classNames={classes}
				name={name}
				data={data}
				value={value}
				onChange={handleOnChange}
				itemComponent={forwardRef<HTMLDivElement, T>((props, ref) => (
					<ItemComponent
						ref={ref}
						label={renderLabel(props as T)}
						icon={renderIcon?.(props as T)}
						{...props}
					/>
				))}
				icon={renderIcon && selectedItem ? renderIcon(selectedItem) : undefined}
				{...others}
			/>
			<TextInputHelp help={help} error={error} />
			<TextInputError error={error} />
		</Stack>
	);
};

Select.displayName = 'Select';

export { Select };
