import { Text } from '@repo/foundations';
import dayjs from 'dayjs';

interface IDateRendererProps {
	value?: string;
	format?: string;
	relative?: boolean;
}

export function DateRenderer({
	value,
	format = 'MMM DD, YYYY HH:mm',
	relative = false,
}: IDateRendererProps) {
	if (!value) {
		return null;
	}

	const date = dayjs(value);

	let dateText = date.format(format);

	if (relative) {
		dateText = date.fromNow();
	}

	return (
		<Text size="sm" truncate>
			{dateText}
		</Text>
	);
}
