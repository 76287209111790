
import { Flex, Group, Stack, createStyles } from '@mantine/core';
import { IconButton, Title, Text } from '@repo/foundations';
import { iconSize } from '@repo/theme/primitives';
import { lowerCase } from 'lodash-es';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { openConfirmModal } from '../../ModalManager';
import { MonitorIcon } from '../MonitorIcon/MonitorIcon';
import { getMonitorSpec } from '../monitors';
import { MonitorType } from '../types';
import ConfigureStack from './components/ConfigureStack';
import ManageStack from './components/ManageStack';

const useStyles = createStyles((theme) => ({
	wrapper: {
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		gap: 0,
	},
	header: {
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		padding: `${theme.spacing.sm} ${theme.spacing['3xl']}`,
	},
	title: {},
	closeIcon: {},
	content: {
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		height: 'calc(100vh - 52px)',
	},
	monitorWrapper: {
		alignItems: 'flex-start',
		justifyContent: 'center',
		flexGrow: 1,
		height: '100%',
		overflowY: 'auto',
		padding: `0 ${theme.spacing.xl} ${theme.spacing.xl} ${theme.spacing.xl}`,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.other.getColor('border/secondary/default'),
	},
	monitorContent: {
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		height: '100%',
	},
	separator: {
		backgroundColor: theme.other.getColor('border/secondary/default'),
		height: 1,
	},
	separatorActive: {
		backgroundColor: theme.other.getColor('border/secondary/default'),
		height: 1,
	},
	exitModalContent: {
		width: 100,
		borderRadius: theme.radius.lg,
	},
	exitModalHeader: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.lg}`,
	},
	exitModalTitle: {
		fontWeight: theme.other.typography.weight.bold,
		fontSize: theme.other.typography.text.md,
	},
	exitModalBody: {
		padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.lg}`,
		marginTop: `${theme.spacing.md}`
	}
}));

function AddMonitorPage() {
	const [searchParams] = useSearchParams();

	const type = {
		metricType: searchParams.get('metricType'),
		integrationMetric: searchParams.get('integrationMetric'),
	} as MonitorType;

	const navigate = useNavigate();

	const { classes, theme } = useStyles();
	const { title, description } = getMonitorSpec(type);

	const handleClose = useCallback(() => {
		openConfirmModal({
			title: 'Are you sure you want to exit?',
			children: 'All progress will be lost.',
			labels: { confirm: 'Exit', cancel: 'Cancel' },
			cancelProps: { size: 'md' },
			confirmProps: { variant: 'primary', tone: 'default', size: 'md' },
			groupProps: {
				spacing: 'xs',
			},
			onConfirm: () => navigate(-1),
			classNames: {
				content: classes.exitModalContent,
				header: classes.exitModalHeader,
				title: classes.exitModalTitle,
				body: classes.exitModalBody,
			},
		});
	}, [
		classes.exitModalBody,
		classes.exitModalHeader,
		classes.exitModalContent,
		classes.exitModalTitle,
		navigate,
	]);

	return (
		<Flex className={classes.wrapper}>
			<Flex className={classes.header}>
				<div
					style={{
						width: iconSize.md,
						height: iconSize.md,
						visibility: 'hidden',
					}}
				/>
				<Group className={classes.title}>
					<MonitorIcon type={type} />
					<Text size="sm" weight="bold">
						{title}
					</Text>
				</Group>
				<IconButton variant="tertiary" iconName="x" onClick={handleClose} />
			</Flex>
			<Flex className={classes.content}>
				<Flex className={classes.monitorWrapper}>
					<Stack
						w={theme.other.width.lg}
						pt={theme.spacing['3xl']}
						spacing="3xl"
					>
						<Stack p={0} spacing="sm">
							<Title size="xl">
								Create{' '}
								{title.startsWith('Snowflake') ? title : lowerCase(title)}{' '}
								monitor
							</Title>
							<Text size="md" color="text/secondary/default">
								{description}
							</Text>
						</Stack>
						<ConfigureStack />
						<ManageStack />
					</Stack>
				</Flex>
			</Flex>
		</Flex>
	);
}

export default AddMonitorPage;
