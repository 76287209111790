import type { MantineTheme, SelectItemProps } from '@mantine/core';
import {
	Divider,
	Group,
	Modal,
	Stack,
	TextInput,
	createStyles,
} from '@mantine/core';
import type {
	FilterValue,
	FilterView,
} from '@repo/common/components/Filter/types';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { Button, MultiSelect, Text } from '@repo/foundations';
import { forwardRef } from 'react';
import { useFilterViewModal } from './useFilterViewModal';

const useStyles = createStyles((theme: MantineTheme) => ({
	modalHeader: {
		background: theme.other.getColor('surface/secondary/default'),
		padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.md}`,
	},
	modalTitle: {
		fontWeight: 600,
		fontSize: theme.fontSizes.md,
		lineHeight: theme.spacing.xl,
	},
	modalBody: {
		padding: 0,
	},
	valueWrapper: {
		background: theme.other.getColor('surface/secondary/default'),
		borderRadius: theme.radius.sm,
		padding: `0 ${theme.spacing['2xs']}`,
	},
}));

const Item = forwardRef<HTMLDivElement, SelectItemProps & { icon: string }>(
	({ label, icon, ...others }, ref) => (
		<div {...others} ref={ref}>
			<Group spacing="xs">
				<Text size="sm">{icon}</Text>
				{label}
			</Group>
		</div>
	)
);
Item.displayName = 'Item';

export interface FilterViewModalProps {
	onClose: (view?: FilterView | null) => void;
	view?: FilterView | null;
	selectedFilters: FilterValue[];
}

export function FilterViewModal({
	onClose,
	view,
	selectedFilters,
}: FilterViewModalProps) {
	const { classes } = useStyles();

	const {
		handleNameChange,
		handleTeamChange,
		teams,
		selectedTeamIds,
		name,
		handleOnSave,
		handleOnUpdate,
	} = useFilterViewModal({ onClose, view, selectedFilters });

	return (
		<Modal
			opened
			onClose={() => onClose()}
			title={view ? 'Update or save as a new view' : 'Save View'}
			classNames={{
				header: classes.modalHeader,
				title: classes.modalTitle,
				body: classes.modalBody,
			}}
		>
			<Stack py="md" spacing="md">
				<Stack spacing="3xs" px="md">
					<Text size="sm" fw="500">
						Name
					</Text>
					<TextInput
						placeholder="Enter view name"
						value={name}
						onChange={(e) => handleNameChange(e.currentTarget.value)}
					/>
				</Stack>
				<Stack spacing="3xs" px="md">
					<Text size="sm" fw="500">
						Team visibility
					</Text>
					<MultiSelect
						data={teams ?? []}
						placeholder="No team selected"
						renderIcon={(item) => <IconWrapper>{item.icon}</IconWrapper>}
						setValue={handleTeamChange}
						value={selectedTeamIds}
					/>
				</Stack>
				<Divider />
				<Group spacing="xs" position="apart" px="md">
					<Button onClick={() => onClose()} variant="default">
						Cancel
					</Button>
					{view ? (
						<Group spacing="xs" position="right">
							<Button onClick={handleOnSave} variant="default">
								Save as new view
							</Button>
							<Button onClick={handleOnUpdate} variant="primary">
								Update this view
							</Button>
						</Group>
					) : (
						<Button onClick={handleOnSave} variant="primary">
							Save
						</Button>
					)}
				</Group>
			</Stack>
		</Modal>
	);
}
