import { Skeleton } from '@mantine/core';
import { Suspense } from 'react';
import { MetricContainer } from './MetricContainer';

export interface QueryBlockContainerProps {
	id: string;
	readOnly: boolean;
	onDelete: VoidFunction;
	isSelected?: boolean;
}

export function QueryBlockContainer({
	id,
	readOnly,
	onDelete,
	isSelected,
}: QueryBlockContainerProps) {
	return (
		<Suspense fallback={<Skeleton height={300} />}>
			<MetricContainer
				withQuery
				withChart={false}
				id={id}
				readOnly={readOnly}
				isSelected={isSelected}
				onDelete={onDelete}
			/>
		</Suspense>
	);
}
