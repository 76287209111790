import { createStyles, Group, Stack } from '@mantine/core';
import { Button } from '@repo/foundations';
import { isNil } from 'lodash-es';
import {
	ISecodaEntity,
	queryClient,
	questionsQueryKeyFactory,
	useQuestionList,
} from '../../api';
import type { Question, SecodaEntity } from '../../lib/models';
import { EntityType } from '../../lib/types';
import type { ButtonDetails } from '../EmptyState';

import { EmptyState } from '../EmptyState';
import { entityModalStore } from '../EntityModal/store';
import EmbeddedQuestionsList from './EmbeddedQuestionsList';
import { embeddedQuestionsStore } from './store';

const useStyles = createStyles((_, { empty = false }: { empty?: boolean }) => ({
	centerStack: {
		height: empty ? 'calc( 100% - 90px );' : 'unset',
	},
}));

interface IEmbeddedQuestionsDrawerProps {
	entity: SecodaEntity | ISecodaEntity;
}

export function EmbeddedQuestions({ entity }: IEmbeddedQuestionsDrawerProps) {
	const { data } = useQuestionList({
		filters: {
			entity_id: entity.id,
		},
		options: {
			suspense: false,
			enabled: !isNil(entity.id),
		},
	});

	const questions = data?.results || [];

	const { classes } = useStyles({
		empty: questions ? questions.length === 0 : false,
	});

	const handleButtonClick = () => {
		// Close the drawer so the modal can open.
		embeddedQuestionsStore.setOpen(false);

		queryClient.invalidateQueries(
			questionsQueryKeyFactory.list(1, { entity_id: entity.id })
		);

		entityModalStore.setEntity('create', EntityType.question);
		entityModalStore.setRelatedResource(entity);
	};

	const buttons: ButtonDetails[] = [
		{
			name: 'Ask a question',
			action: handleButtonClick,
			isPrimary: true,
			size: 'md',
		},
	];

	return (
		<Stack className={classes.centerStack}>
			{questions?.length === 0 && (
				<EmptyState
					iconName="messages"
					title="No questions found"
					stateHeight="80vh"
					description="Start a discussion by creating a question for this resource"
					buttons={buttons}
					includeGoBack={false}
					size="sm"
				/>
			)}
			{questions?.length > 0 && (
				<Group position="right">
					<Button size="sm" onClick={handleButtonClick}>
						Ask a question
					</Button>
				</Group>
			)}
			<EmbeddedQuestionsList questions={questions as unknown as Question[]} />
		</Stack>
	);
}
