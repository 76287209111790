import { Plugin, TextSelection } from 'prosemirror-state';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { captureError } from '../../../../../web-tracing';
import Extension from '../lib/Extension';

export default class ScrollToHeading extends Extension {
	get name() {
		return 'scroll-to-heading';
	}

	get plugins() {
		return [
			new Plugin({
				props: {
					handleScrollToSelection(view) {
						const { state } = view;
						const { selection } = state;

						if (
							selection instanceof TextSelection &&
							selection.$anchor.parent?.type?.name === 'heading'
						) {
							const dom = view.domAtPos(selection.$anchor.pos);

							try {
								if (!dom.node) {
									return false;
								}

								// give it a bit of time for the browser to stabilize the layout
								requestAnimationFrame(() => {
									scrollIntoView(dom.node as HTMLElement, {
										scrollMode: 'if-needed',
										block: 'start',
									});
								});
							} catch (e) {
								// scrollIntoView might throw an error if the node is not in the DOM
								// we shouldn't be scrolling to a node that's not in the DOM
								// the only situation this has happened so far is when vite is hot reloading
								captureError(e);
							}
							return true;
						}

						return false;
					},
				},
			}),
		];
	}
}
