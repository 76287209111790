import { createContext, useContext, useMemo } from 'react';
import { FormStore } from './store';

const FormStoreContext = createContext<FormStore | null>(null);

export interface FormStoreProviderProps {
	children: React.ReactNode;
}

export function FormStoreProvider({ children }: FormStoreProviderProps) {
	const store = useMemo(() => new FormStore(), []);

	return (
		<FormStoreContext.Provider value={store}>
			{children}
		</FormStoreContext.Provider>
	);
}

export function useFormStore() {
	const ctx = useContext(FormStoreContext);
	if (ctx === null) {
		throw new Error(
			'useFormContext was called outside of FormProvider context'
		);
	}

	return ctx;
}
