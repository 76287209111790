import { Avatar, createStyles, Group } from '@mantine/core';
import { pickColorType } from '@repo/common/utils';
import { MultiSelect, Text } from '@repo/foundations';
import { getColor } from '@repo/theme/utils';
import { map } from 'lodash-es';
import type { ForwardedRef } from 'react';
import { forwardRef, useMemo } from 'react';
import type { FormFieldValues } from '../../../../../api';
import { useExtendedUserList } from '../../../../../api/hooks/user/useExtendedUserList';

interface IFormUserFieldProps {
	initialValue?: string[];
	updateValue: (values: FormFieldValues) => void;
	isReadOnly: boolean;
}

interface IUserFieldItemProps {
	label: string;
	value: string;
	src: string;
}

const useStyles = createStyles((theme) => ({
	input: {
		border: `0.5px solid ${getColor('border/secondary/default')}`,
		borderRadius: theme.radius.sm,
		backgroundColor: getColor('surface/input/default'),
		'&:hover': {
			backgroundColor: getColor('surface/input/hover'),
		},
		'&:focus': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
		'&:active': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
	},
}));

// eslint-disable-next-line react/display-name
const UserFieldItem = forwardRef(
	(
		{ label, value, src, ...others }: IUserFieldItemProps,
		ref: ForwardedRef<HTMLDivElement>
	) => (
		<Group ref={ref} {...others} noWrap>
			<Avatar src={src} alt={label} color={pickColorType(label)} />
			<Text size="sm">{label}</Text>
		</Group>
	)
);

export default function FormUserField({
	initialValue,
	updateValue,
	isReadOnly,
}: IFormUserFieldProps) {
	const { classes } = useStyles();

	const { activeUsers } = useExtendedUserList({ suspense: false });

	const data = useMemo(
		() =>
			map(activeUsers, (user) => ({
				label: `${user.display_name}`,
				value: user.id,
				src: user.profile_picture,
			})),
		[activeUsers]
	);

	const handleUserChange = (values: string[]) => {
		updateValue({
			values: map(values, (v) => ({
				value: v,
			})),
		});
	};

	return (
		<MultiSelect
			classNames={{ input: classes.input }}
			value={initialValue || []}
			data={data}
			setValue={handleUserChange}
			itemComponent={UserFieldItem}
			multiple
			disabled={isReadOnly}
		/>
	);
}
