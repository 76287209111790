import { Box } from '@mantine/core';
import { useDisclosure, useId } from '@mantine/hooks';
import MultiSelector from '@repo/common/components/MultiSelector/MultiSelector';
import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import type { ButtonVariants } from '@repo/foundations';
import type { SecodaThemeShades } from '@repo/theme/types';
import React, { useCallback, useMemo } from 'react';
import { useAuthUser, useTagList } from '../../../api';
import { extractInheritedTags } from '../../../utils/extractInheritedTags';
import IntegrationTooltipSelectorItem from '../../RichTooltip/IntegrationTooltipSelectorItem';
import { TagModal } from '../../Settings/TagSettings/Modal/TagModal';

interface TagSelectorProps {
	onChange?: (value: string[]) => void;
	initialValue?: string[];
	placeholder?: string;
	placeholderIcon?: React.FC;
	forceVariant?: ButtonVariants;
	readOnly?: boolean;
	hideOnEmpty?: boolean;
	maintainTags?: boolean;
	entityIntegrationId?: string;
	entityIntegrationName?: string;
}

export function TagSelector({
	onChange,
	initialValue,
	placeholder = 'Tags',
	hideOnEmpty = false,
	placeholderIcon,
	forceVariant,
	readOnly = false,
	maintainTags,
	entityIntegrationId,
	entityIntegrationName,
}: TagSelectorProps) {
	const uuid = useId();
	const { user, isAdminUser } = useAuthUser();

	const [selected, setSelected] = React.useState<string[]>(initialValue ?? []);
	const [initTitle, setInitTitle] = React.useState<string>('');
	const [tagModalOpened, { close: closeTagModal, open: openTagModal }] =
		useDisclosure(false);

	const { data } = useTagList({});

	const tags = useMemo(
		() =>
			data?.results
				.filter((tag) => (maintainTags ? initialValue?.includes(tag.id) : true))
				.map((tag) => ({
					label: tag.name,
					value: tag.id,
					color: tag.color as SecodaThemeShades,
					tagIntegration: tag.integration,
					maintainProp: maintainTags,
					entityIntegrationId: entityIntegrationId,
					itemTypeName: 'tag',
					description: tag.description,
				})),
		[data?.results, entityIntegrationId, initialValue, maintainTags]
	);

	const allTags = useMemo(() => (tags || []).map((t) => t.label), [tags]);

	const inheritedTags = extractInheritedTags(tags, initialValue);

	const handleChange = useCallback(
		(value: (string | boolean)[]) => {
			setSelected(value as string[]);
			onChange?.(value as string[]);
		},
		[onChange]
	);

	const handleCreate = useCallback(
		(value: string) => {
			openTagModal();
			setInitTitle(value);
		},
		[openTagModal]
	);

	let variant: ButtonVariants =
		selected && selected.length > 0 ? 'default' : 'tertiary';

	if (forceVariant) {
		variant = forceVariant;
	}

	return (
		<Box maw={256}>
			{maintainTags && selected.length === 0 ? (
				<RichTooltip
					title={`No tags were set in ${entityIntegrationName}`}
					body={'To maintain in Secoda, go to integration settings.'}
					linkLabel={
						isAdminUser ? `${entityIntegrationName} settings` : undefined
					}
					linkUrl={
						isAdminUser
							? `/integrations/${entityIntegrationId}/preferences`
							: undefined
					}
				>
					<MultiSelector
						hideOnEmpty={hideOnEmpty}
						placeholder={placeholder}
						placeholderIcon={placeholderIcon}
						variant={variant}
						iconType="tag"
						isViewerUser={false}
						options={tags ?? []}
						onChange={handleChange}
						initialSelected={selected}
						inheritedValues={maintainTags ? inheritedTags : []}
						property="tags"
						permittedId={user.id}
						isMenuItemBadge
						readOnly={readOnly}
						selectorItem={IntegrationTooltipSelectorItem}
						onCreate={handleCreate}
						withDescriptionTooltip
					/>
				</RichTooltip>
			) : (
				<MultiSelector
					hideOnEmpty={hideOnEmpty}
					placeholder={placeholder}
					placeholderIcon={placeholderIcon}
					variant={variant}
					iconType="tag"
					isViewerUser={false}
					options={tags ?? []}
					onChange={handleChange}
					initialSelected={selected}
					inheritedValues={maintainTags ? inheritedTags : []}
					property="tags"
					permittedId={user.id}
					isMenuItemBadge
					readOnly={readOnly}
					selectorItem={IntegrationTooltipSelectorItem}
					onCreate={handleCreate}
					withDescriptionTooltip
				/>
			)}
			<TagModal
				key={`tag-modal-${uuid}`}
				opened={tagModalOpened}
				onClose={closeTagModal}
				initialTitle={initTitle}
				allTags={allTags}
			/>
		</Box>
	);
}

export default TagSelector;
