import { Box } from '@mantine/core';
import { useApiListTeams } from '@repo/api-codegen';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import type { ButtonVariants } from '@repo/foundations';
import { IconUsers } from '@tabler/icons-react';
import React from 'react';
import { useCurrentTeam } from '../../../api/hooks/team/myMemberships';

interface TeamSelectorProps {
	onChange?: (value: string) => void;
	initialValue?: string;
	forceVariant?: ButtonVariants;
	allowEmptyOption?: boolean;
	editorOnly?: boolean;
}

export function TeamSelector({
	onChange,
	initialValue,
	forceVariant,
	allowEmptyOption = false,
	editorOnly = false,
}: TeamSelectorProps) {
	const { data: teams } = useApiListTeams(
		{
			queryParams: {
				only_write: editorOnly,
			},
		},
		{
			suspense: false,
			select: (res) => res.results,
		}
	);

	const { currentTeamId } = useCurrentTeam();

	const [selected, setSelected] = React.useState<string>(
		initialValue ?? currentTeamId ?? ''
	);

	const options: SelectablePropertyItem[] = [
		...(allowEmptyOption
			? [
					{
						label: 'All teams',
						value: '',
						iconName: 'users',
					} as SelectablePropertyItem,
				]
			: []),
		...(teams?.map((t) => ({
			label: t.name,
			value: t.id,
			icon: t.icon,
		})) ?? []),
	];

	const handleChange = (value: string) => {
		setSelected(value);
		onChange?.(value);
	};

	let variant: ButtonVariants = 'default';

	if (forceVariant) {
		variant = forceVariant;
	}

	return (
		<Box maw={512}>
			<SingleSelector
				variant={variant}
				iconType="emoji"
				isViewerUser={false}
				searchable
				options={options}
				placeholder="Team"
				placeholderIcon={IconUsers}
				onChange={handleChange}
				initialSelected={selected ?? currentTeamId ?? ''}
				property="team"
			/>
		</Box>
	);
}
