import { Box, Checkbox, Group, Table, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useApiColumnUpdatePii, useApiGetPiiColumns } from '@repo/api-codegen';
import { Pagination } from '@repo/common/components/Pagination';
import { Button, Text } from '@repo/foundations';
import { cloneDeep } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IColumn, useAuthUser, useWorkspace } from '../../api';
import { usePlan } from '../../hooks/usePlans';
import { trackEvent } from '../../utils/analytics';
import { LoadingSpinner } from '../LoadingSpinner';
import { SettingsTableEmpty } from './Empty';
import { PIIWordsList } from './PIIWordsList';
import { Setting } from './Setting';
import { UpgradeButton } from './UpgradeButton';

const PAGE_SIZE = 20;

type SelectableColumn = Partial<IColumn> & {
	selected: boolean;
	parent_id: string;
};

export function PIITable() {
	const { user, isAdminUser } = useAuthUser();
	const { plan } = usePlan();
	const { workspace } = useWorkspace();
	const theme = useMantineTheme();

	const [paginationIndex, setPaginationIndex] = useState(0);
	const [rows, setRows] = useState<SelectableColumn[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const { mutateAsync: update_pii } = useApiColumnUpdatePii();
	const { data } = useApiGetPiiColumns({});

	useEffect(() => {
		if (data) {
			setRows(
				data.map((column) => ({
					...column,
					selected: true,
				}))
			);
			setLoading(false);
		}
	}, [data]);

	const disabled = !isAdminUser || !plan?.pii_support;

	const allChecked = rows.every((r) => r.selected);
	const anyChecked = rows.some((r) => r.selected) && !allChecked;

	const toggleAll = () => {
		setRows(rows.map((r) => ({ ...r, selected: !(allChecked || anyChecked) })));
	};

	return (
		<Box>
			<Setting
				title="PII columns"
				description="Data marked as PII can only be viewed by admins and editors."
			>
				{plan?.pii_support ? (
					<Group>
						<PIIWordsList />
						<Button
							disabled={disabled}
							onClick={async () => {
								trackEvent('table/pii/update', {}, user, workspace);
								showNotification({
									title: 'Adding PII badges',
									message: 'This may take a few seconds',
									color: 'blue',
								});
								// eslint-disable-next-line no-underscore-dangle
								const _rows = cloneDeep(rows);
								setRows((prev) => prev.filter((r) => !r.selected));
								await Promise.all(
									_rows
										.filter((r) => r.selected)
										.map((col: any) => {
											update_pii({
												queryParams: {
													column_id: col.id,
												},
											});
										})
								);
								showNotification({
									title: 'Success',
									message: 'PII badges added to tables',
									color: 'green',
								});
							}}
							my="10px"
						>
							Apply PII tag to{' '}
							{rows.filter((r: SelectableColumn) => !!r.selected).length} tables
						</Button>
					</Group>
				) : (
					<UpgradeButton feature="PII Table" />
				)}
			</Setting>

			{loading && <LoadingSpinner margin="25px" />}
			{!loading && rows.length === 0 && (
				<SettingsTableEmpty
					title="No tables with PII data found"
					description="Secoda will analyze table and column names to identify potential PII data"
				/>
			)}
			{!loading && rows.length > 0 && (
				<Table mb="24px">
					<thead
						style={{
							backgroundColor: theme.other.getColor('fill/secondary/default'),
						}}
					>
						<tr>
							<th style={{ borderTopLeftRadius: '4px', width: '45%' }}>
								<Text size="sm">Table</Text>
							</th>
							<th style={{ width: '40%' }}>
								<Text size="sm">Column</Text>
							</th>
							<th style={{ borderTopRightRadius: '4px', width: '15%' }}>
								<Checkbox
									checked={allChecked || anyChecked}
									indeterminate={anyChecked}
									onChange={toggleAll}
									label="Mark PII"
								/>
							</th>
						</tr>
					</thead>
					<tbody>
						{rows
							.slice(
								paginationIndex * PAGE_SIZE,
								paginationIndex * PAGE_SIZE + PAGE_SIZE
							)
							.map((column: SelectableColumn, index: number) => (
								<tr key={column.id}>
									<td>
										<Link to={`/table/${column.parent_id}`}>
											<Text size="sm" truncate>
												{column.table_title ?? ''}
											</Text>
										</Link>
									</td>
									{column.title && (
										<td>
											<Link to={`/column/${column.id}`}>
												<Text size="sm" truncate style={{ maxWidth: '200px' }}>
													{column.title}
												</Text>
											</Link>
										</td>
									)}
									<td aria-label="Is PII?">
										<Checkbox
											checked={
												!!rows[paginationIndex * PAGE_SIZE + index].selected
											}
											onChange={() =>
												setRows(
													rows.map((row, i) =>
														i === paginationIndex * PAGE_SIZE + index
															? {
																	...row,
																	selected: !row.selected,
																}
															: row
													)
												)
											}
										/>
									</td>
								</tr>
							))}
					</tbody>
				</Table>
			)}
			{rows.length > PAGE_SIZE && (
				<Pagination
					onChange={(page: number) => {
						setPaginationIndex(page - 1);
					}}
					page={paginationIndex + 1}
					total={Math.ceil(rows.length / PAGE_SIZE)}
				/>
			)}
		</Box>
	);
}
