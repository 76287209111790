import { Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { capitalize } from 'lodash-es';
import { useFormsList } from '../../api/hooks/forms';
import { TableV2 } from '../../components/TableV2';

import type { IForm } from '../../api';
import {
	CreatedAtRender,
	ReadOnlyOwnersRender,
	ReadOnlyStatusRender,
} from '../../components/TableV2/render';
import type { Render } from '../../components/TableV2/types';

function FormList() {
	const navigate = useNavigate();

	return (
		<Stack>
			<TableV2
				pluralTypeString="forms"
				usePaginationList={useFormsList}
				defaultSort={null}
				withCheckbox={false}
				withInteractiveHeader={false}
				withSearch={false}
				withFilters={false}
				withCsvExport
				onRowClick={(row) => {
					navigate(`/forms/${row.id}`);
				}}
				columns={[
					{
						accessor: 'title',
						title: 'Title',
						width: '20%',
					},
					{
						accessor: 'status',
						title: 'Status',
						render: (record: IForm) => <ReadOnlyStatusRender record={record} />,
						width: '20%',
					},
					{
						accessor: 'owners',
						title: 'Reviewers',
						render: (record: IForm) => (
							<ReadOnlyOwnersRender owners={record.owners} />
						),
						width: '20%',
					},
					{
						accessor: 'approval_condition',
						title: 'Approval by',
						width: '20%',
						render: ((record: { approval_condition: string }) =>
							capitalize(
								record.approval_condition
							)) as unknown as Render<IForm>,
					},
					{
						accessor: 'created_at',
						title: 'Created at',
						render: (record) => <CreatedAtRender record={record} />,
						width: '20%',
					},
				]}
			/>
		</Stack>
	);
}

export default FormList;
