import { Group, Menu } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import {
	invalidateSearchViews,
	useAuthUser,
	useDeleteSearchView,
	useWorkspace,
} from '../../../../api';
import { TemplateDotsButton } from '../../../../components/Templates/TemplateDotsButton';
import { trackEvent } from '../../../../utils/analytics';
import type { SearchView } from '../FilterCarousel.constants';
import { SearchFilterStoreContext } from '../store';

interface IViewItemProps {
	view: SearchView;
	onDuplicate: () => void;
	openEditDropdown: () => void;
	closeMenu: () => void;
}

function ViewItem({
	view,
	onDuplicate,
	openEditDropdown,
	closeMenu,
}: IViewItemProps) {
	const { label, value, isPersonal } = view;
	const searchFilterStore = useContext(SearchFilterStoreContext);

	// === ReactQuery hooks
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();
	const { mutateAsync: deleteView } = useDeleteSearchView({});
	const handleDeleteView = (v: SearchView) => {
		if (searchFilterStore.selectedView?.value === v.value) {
			searchFilterStore.selectedView = undefined;
		}
		deleteView(
			{ id: v.value },
			{
				onSettled: () => {
					invalidateSearchViews(1, {});
					trackEvent('search/views/delete', {}, user, workspace);
				},
			}
		);
		searchFilterStore.resetSelectedFilters();
	};

	// === Event handlers
	const handleDuplicateView = () => {
		onDuplicate();
	};

	const handleEditView = () => {
		searchFilterStore.setSelectedView(view);
		closeMenu();
		openEditDropdown();
	};

	const handleToggleView = () => {
		if (searchFilterStore.selectedView?.value === value) {
			searchFilterStore.setSelectedView(undefined);
			trackEvent('search/views/select', {}, user, workspace);
		} else {
			searchFilterStore.setSelectedView(view);
		}
	};

	return (
		<Menu.Item
			key={value}
			onClick={handleToggleView}
			icon={<Icon name="stack" />}
			rightSection={
				isPersonal && (
					<TemplateDotsButton
						data-testid="view-menu-item-dots-button"
						displayName="view"
						item={view}
						// TODO[tan]: (after navbar) OnSetAsDefault={handleSetDefaultView}
						onDuplicateItem={handleDuplicateView}
						onEditItem={handleEditView}
						onDeleteItem={handleDeleteView}
						visible
					/>
				)
			}
		>
			<Group position="apart" spacing="md">
				<Text>{label}</Text>
				{/* <Text size="xs">DEFAULT</Text> */}
			</Group>
		</Menu.Item>
	);
}

export default observer(ViewItem);
