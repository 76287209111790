import {
	Box,
	createStyles,
	getStylesRef,
	Group,
	Navbar,
	ScrollArea,
	Space,
	Stack,
} from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { IconButton } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useAuthUser } from '../../../api';
import { entityModalStore } from '../../EntityModal/store';
import {
	COLLAPSED_NAV_SIDEBAR_WIDTH,
	EXPANDED_NAV_MAX_SIDEBAR_WIDTH,
} from '../constants';
import ResizeSideBar from '../ResizeSideBar';
import { SearchButton } from './SearchButton';
import SideBarFooter from './SideBarFooter';
import SideBarHeader from './SideBarHeader';
import SideBarTeamsLinks from './SideBarTeamsLinks';
import { SideBarUserMenu } from './SideBarUserMenu';
import SideBarWorkspaceLinks from './SideBarWorkspaceLinks';
import { sideBarStore } from './store';
import { WorkspaceSelector } from './WorkspaceSelector';

interface SideBarStyleProps {
	collapsed: boolean;
	active: boolean;
	hover: boolean;
}

const useStyles = createStyles(
	(theme, { collapsed, active, hover }: SideBarStyleProps) => {
		const getBorderRightStyle = useCallback(
			(active: boolean, hover: boolean) => {
				if (active) {
					return 'active';
				}
				if (hover) {
					return 'hover';
				}
				return 'default';
			},
			[]
		);

		return {
			navbar: {
				ref: getStylesRef('appSideBar'),
				minWidth: COLLAPSED_NAV_SIDEBAR_WIDTH,
				maxWidth: EXPANDED_NAV_MAX_SIDEBAR_WIDTH,
				paddingTop: theme.other.space[3],
				paddingBottom: 0,
				paddingLeft: collapsed ? theme.other.space[1] : theme.other.space[3],
				paddingRight: theme.other.space[1],
				backgroundColor: theme.other.getColor('surface/tertiary/default'),
				borderRightWidth: theme.other.space[0.25],
				borderRightColor: theme.other.getColor(
					`border/secondary/${getBorderRightStyle(active, hover)}`
				),
				'@media print': {
					display: 'none',
				},
			},
			topRowWrapper: {
				display: 'flex',
				flex: '0 1 auto',
				flexDirection: collapsed ? 'column' : 'row',
				justifyContent: collapsed ? 'unset' : 'space-between',
				gap: collapsed ? theme.spacing.sm : '0',
				alignItems: 'center',
			},
			scrollbar: {
				display: collapsed ? 'flex' : 'block',
				justifyContent: collapsed ? 'center' : 'unset',
				'.mantine-ScrollArea-scrollbar': {
					backgroundColor: 'transparent',
				},
				'.mantine-ScrollArea-viewport': {
					padding: 0,
					paddingRight: collapsed ? 0 : theme.other.space[2],
					width: collapsed
						? theme.other.space[5] + 2 * theme.other.space[2]
						: 'unset',
				},
			},
			scrollAreaTransitionDown: {
				background: `linear-gradient(0deg, transparent, ${theme.other.getColor('surface/tertiary/default')})`,
				height: theme.spacing.sm,
				width: collapsed ? 'calc(100% - 14px)' : 'calc(100% - 6px)', // This allows the scrollbar to not be obscured
				position: 'absolute',
				top: 0,
				zIndex: 10,
			},
		};
	}
);

function SideBar() {
	const [isActive, setActive] = useState(false);
	const [isHover, setHover] = useState(false);

	const { classes, theme } = useStyles({
		collapsed: !!sideBarStore.collapsed,
		active: isActive,
		hover: isHover,
	});

	const { isViewerUser } = useAuthUser();

	const handleActiveChange = useCallback((active: boolean) => {
		setActive(active);
	}, []);

	const handleHoverChange = useCallback((hover: boolean) => {
		setHover(hover);
	}, []);

	const openQuestionModal = () => {
		entityModalStore.setEntity('create', EntityType.question, undefined, false);
		entityModalStore.setOpened(true);
	};

	if (!sideBarStore.showSideBar) {
		return null;
	}

	return (
		<Navbar
			className={classes.navbar}
			style={{
				width: sideBarStore.width,
			}}
			withBorder
			data-testid="sidebar"
		>
			<Navbar.Section>
				<Stack
					spacing="sm"
					pr={sideBarStore.collapsed ? 'none' : 'xs'}
					align={sideBarStore.collapsed ? 'center' : 'unset'}
				>
					<Box className={classes.topRowWrapper}>
						<WorkspaceSelector />
						<Group spacing={theme.spacing.xs} noWrap>
							<SideBarUserMenu />
							{isViewerUser && (
								<IconButton
									variant="tertiary"
									iconName="messageCircleQuestion"
									tooltip="Ask the data team a question"
									onClick={openQuestionModal}
								/>
							)}
						</Group>
					</Box>
					<SearchButton collapsed={!!sideBarStore.collapsed} />
				</Stack>
			</Navbar.Section>
			<Space h="sm" />
			<Navbar.Section>
				<SideBarHeader />
			</Navbar.Section>
			<Space h="sm" />
			<Navbar.Section
				className={classes.scrollbar}
				type="auto"
				component={ScrollArea}
				offsetScrollbars
				scrollbarSize={theme.other.space[2]}
				grow
			>
				<Box className={classes.scrollAreaTransitionDown} />
				{sideBarStore.collapsed && <Space h="2xs" />}
				<SideBarTeamsLinks />
				<Space h="md" />
				<SideBarWorkspaceLinks />
				{!sideBarStore.collapsed && (
					<Space h={theme.other.space[10] + theme.other.space[3]} />
				)}
			</Navbar.Section>
			<SideBarFooter />
			<ResizeSideBar
				onActiveChange={handleActiveChange}
				onHoverChange={handleHoverChange}
			/>
		</Navbar>
	);
}

export default observer(SideBar);
