/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { observer } from 'mobx-react-lite';

import EntitySidebarWrapper from './EntitySidebarWrapper';

import EntitySidebarContent, {
	IEntitySidebarContentProps,
} from './EntitySidebarContent';
import ResizeEntitySidebar from './ResizeEntitySidebar';

export interface IEntityPageSidebarProps extends IEntitySidebarContentProps {
	classNames?: Record<string, string>;
}

function EntityPageSidebar({ classNames, ...props }: IEntityPageSidebarProps) {
	return (
		<EntitySidebarWrapper classNames={classNames} mode="info">
			<EntitySidebarContent {...props} />
			<ResizeEntitySidebar />
		</EntitySidebarWrapper>
	);
}

export default observer(EntityPageSidebar);
