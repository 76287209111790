/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionIcon, Group, Stack } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { useApiListTeams } from '@repo/api-codegen';
import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { Icon } from '@repo/foundations';
import { useUpdateEffect } from 'ahooks';
import cuid from 'cuid';
import dayjs from 'dayjs';
import { capitalize, map, noop } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import {
	IQuestion,
	ISecodaEntity,
	ITag,
	useAuthUser,
	useCollectionListAll,
	useIntegrationPrefetched,
	useTagList,
} from '../../api';
import {
	useExtendedUserList,
	useGuestUserList,
} from '../../api/hooks/user/useExtendedUserList';
import type { UserGroup } from '../../lib/models';
import { isNonColumnProperties, User } from '../../lib/models';
import { EntityType } from '../../lib/types';
import type { DjangoValueType } from '../../pages/TemplatePage/types';
import { trackEvent } from '../../utils/analytics';
import { isViewerOfEntity } from '../../utils/authorization/roles';
import { extractInheritedTags } from '../../utils/extractInheritedTags';
import { useFeatureFlags } from '../../utils/featureFlags';
import CollapsableStack from '../CollapsableStack';
import type {
	CustomProperty,
	CustomPropertyType,
} from '../CustomPropertyEditor';
import CustomPropertyEditor, {
	ModifyCustomProperty,
} from '../CustomPropertyEditor';
import { ErrorBoundary } from '../ErrorBoundary';
import { externalUsers } from '../UserAvatar/helpers';
import { SelectableProperty, StaticProperty } from './EntityPropertySidebar';
import type { SidebarEntityKeys } from './types';
import {
	getAssigneeAndGroupSelectorOptions,
	getOwnerAndGroupSelectorOptions,
} from './utils';

import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants';
import { FreshnessStack } from './SidesheetStacks/Freshness/FreshnessStack';
import MetadataStack from './SidesheetStacks/Metadata/MetadataStack';
import { RelatedResourceStack } from './SidesheetStacks/RelatedResource/RelatedResourceStack';

export interface IEntitySidebarContentProps {
	entity: ISecodaEntity | IQuestion;
	updateEntity: (
		key: SidebarEntityKeys,
		value: DjangoValueType,
		saveRemotely?: boolean
	) => void;
	withFrequentUsers?: boolean;
	withVerifiedSelector?: boolean;
	withGovernanceSelector?: boolean;
	withCollectionSelector?: boolean;
	withTagSelector?: boolean;
	withOwnerSelector?: boolean;
	withRelatedResourceSelector?: boolean;
	withCustomPropertyEditors?: boolean;
	withEntityPopularity?: boolean;
	withEntityRowCount?: boolean;
	withEntityByteSize?: boolean;
	withTeamSelector?: boolean;
	// Question-specific properties:
	withAssignedToSelector?: boolean;
	withPrioritySelector?: boolean;
	withStatusSelector?: boolean;
	withSource?: string;
	withMetricSection?: JSX.Element;
	withCustomPublishElement?: JSX.Element;
	withAIInclusionStatus?: boolean;
	withCollectionParentSelector?: boolean;
}

function EntitySidebarContent({
	entity,
	updateEntity,
	withFrequentUsers = false,
	withVerifiedSelector = false,
	withGovernanceSelector = false,
	withCollectionSelector = false,
	withTagSelector = false,
	withOwnerSelector = false,
	withCustomPublishElement,
	withRelatedResourceSelector = false,
	withCustomPropertyEditors = false,
	withEntityPopularity = false,
	withEntityRowCount = false,
	withEntityByteSize = false,
	withTeamSelector = true,
	// Question-specific properties:
	withAssignedToSelector = false,
	withPrioritySelector = false,
	withStatusSelector = false,
	withSource = undefined,
	withMetricSection,
	withAIInclusionStatus = true,
	withCollectionParentSelector = false,
}: IEntitySidebarContentProps) {
	const { user, workspace, isGuestUser, isAdminUser } = useAuthUser();
	const viewerOfEntity = isViewerOfEntity(user, entity);
	const editorOfEntity = !viewerOfEntity;

	const { dataQualityScoreSla } = useFeatureFlags();

	const { data: integration } = useIntegrationPrefetched({
		id: entity.integration,
		options: {
			enabled:
				Boolean(entity.integration) && entity.entity_type !== EntityType.metric,
		},
	});

	const maintainTags = !!integration?.credentials?.import_tags_preference;
	const maintainOwners = !!integration?.credentials?.import_owners_preference;

	const canShowSLA =
		dataQualityScoreSla && entity?.entity_type?.toLowerCase() === 'table';

	const { usersVisibleToGuests } = useGuestUserList({ suspense: false });

	const { disabledUsers, activeUsers, serviceAccountsActive } =
		useExtendedUserList({ suspense: false });

	const { data: collections } = useCollectionListAll();

	const { data: teams } = useApiListTeams(
		{
			queryParams: {
				only_joined: isGuestUser,
			},
		},
		{
			select: (res) => res.results,
		}
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const visibleUsers = (isGuestUser ? usersVisibleToGuests : activeUsers) ?? [];

	const serviceAccountOptions = useMemo(
		() =>
			(serviceAccountsActive?.filter((serviceAccount) =>
				entity.owners.includes(serviceAccount.id)
			) as unknown as User[]) ?? [],
		[entity.owners, serviceAccountsActive]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const ownerOptions = useMemo(
		() =>
			isGuestUser
				? ((usersVisibleToGuests ?? []) as unknown as User[])
				: [
						...((disabledUsers ?? []) as unknown as User[]),
						...((activeUsers ?? []) as unknown as User[]),
						...serviceAccountOptions,
						...externalUsers(
							activeUsers ?? [],
							serviceAccountsActive ?? [],
							entity
						),
					].filter((owner) =>
						maintainOwners ? entity.owners.includes(owner.id) : true
					),
		[
			activeUsers,
			disabledUsers,
			entity,
			isGuestUser,
			maintainOwners,
			serviceAccountOptions,
			serviceAccountsActive,
			usersVisibleToGuests,
		]
	);

	const [properties, { append, setItem, remove }] = useListState(
		isNonColumnProperties(entity) ? entity.properties.custom : []
	);

	const handleEntityChange = useCallback(
		(key: SidebarEntityKeys) =>
			(value: DjangoValueType, saveRemotely = true) => {
				updateEntity(key, value, saveRemotely);
				trackEvent(
					`${entity.entity_type}/${key}/update`,
					{ id: entity.id },
					user,
					workspace
				);
			},
		[entity.entity_type, entity.id, updateEntity, user, workspace]
	);

	const handleCustomPropertiesChange = useCallback(
		(customProperties: CustomProperty[]) => {
			updateEntity(
				'properties',
				{
					...entity.properties,
					custom: customProperties,
				} as unknown as CustomProperty[],
				true
			);
		},
		[entity.properties, updateEntity]
	);

	// `useUpdateEffect` is important to prevent a callback
	// from being called on the first render.
	useUpdateEffect(() => {
		handleCustomPropertiesChange(properties);
	}, [properties]);

	const handleAddProperty = useCallback(
		(type: CustomPropertyType) => {
			const property = {
				id: cuid(),
				created_at: dayjs().toISOString(),
				updated_at: dayjs().toISOString(),
				name: capitalize(type),
				value: '',
				type,
				order: properties.length,
				options: [],
			};

			append(property);
		},
		[append, properties.length]
	);

	const { userGroups: groups } = useExtendedUserList({});

	const assignees = useMemo(
		() =>
			getAssigneeAndGroupSelectorOptions(
				visibleUsers,
				groups as unknown as UserGroup[],
				handleEntityChange
			),
		[visibleUsers, groups]
	);

	const owners = useMemo(
		() =>
			getOwnerAndGroupSelectorOptions(
				ownerOptions,
				(!maintainOwners && groups ? groups : []) as unknown as UserGroup[],
				handleEntityChange,
				handleEntityChange
			).map((owner) => ({
				...owner,
				itemTypeName: 'owners',
				entityIntegrationId: integration?.id,
				maintainProp: maintainOwners,
			})),
		[groups, handleEntityChange, integration?.id, maintainOwners, ownerOptions]
	);

	const subscriberOptions = map(visibleUsers, (u) => ({
		label: u.display_name,
		value: u.id,
		icon: u.profile_picture,
		navigateTo: `/user/${u.id}`,
		onClick: noop,
	}));

	const teamOptions = map(teams, (team) => ({
		label: team.name,
		value: team.id,
		icon: team.icon,
		navigateTo: '/teams',
		onClick: noop,
	}));

	const selectedOwners = [
		...(entity?.owners ?? []),
		...(entity?.owners_groups ?? []),
	];

	const serviceAccountOwners = entity.owners.filter((owner) =>
		serviceAccountsActive?.find(
			(serviceAccount: { id: string }) => serviceAccount.id === owner
		)
	);

	const selectedAssignee =
		(entity as IQuestion).assigned_to_group ||
		(entity as IQuestion).assigned_to;

	const collectionOptions = map(collections?.results, (collection) => ({
		label: collection.title || 'Untitled',
		value: collection.id,
		icon: collection.icon,
		navigateTo: `/collections/${collection.id}`,
		onClick: noop,
	}));

	const { data: tags } = useTagList({
		filters: {
			visible: true,
		},
	});

	const tagOptions = (tags?.results
		.filter((tag: ITag) => (maintainTags ? entity.tags.includes(tag.id) : true))
		.map((tag: ITag) => ({
			label: tag.name,
			value: tag.id,
			color: tag.color,
			fill: tag.color,
			itemTypeName: 'tag',
			onClick: noop,
			description: tag.description,
			showDescriptionInTooltip: true,
			navigateTo: '/settings/tags',
			tagIntegration: tag.integration ? tag.integration : '',
			maintainProp: maintainTags,
			entityIntegrationId: integration?.id,
		})) ?? []) as SelectablePropertyItem[];

	const inheritedTags = extractInheritedTags(tagOptions, entity?.tags);

	const noTags = maintainTags && entity.tags.length === 0;
	const noOwners = maintainOwners && entity.owners.length === 0;

	const withAddCustomProperty = withCustomPropertyEditors && editorOfEntity;

	return (
		<Stack>
			{withMetricSection && (
				<CollapsableStack groupName="Configuration">
					{withMetricSection}
				</CollapsableStack>
			)}
			<CollapsableStack
				groupName="Properties"
				actions={
					withAddCustomProperty && (
						<ModifyCustomProperty onAddProperty={handleAddProperty} />
					)
				}
			>
				<Stack spacing="xs">
					{withAssignedToSelector && (
						<SelectableProperty
							selected={selectedAssignee as string}
							type="single"
							label="Assigned to"
							value="assigned_to"
							iconType="avatar"
							isViewerUser={viewerOfEntity}
							searchable
							options={assignees}
							placeholder={viewerOfEntity ? 'Not assigned' : 'Assign user'}
						/>
					)}
					{withPrioritySelector && (
						<SelectableProperty
							selected={(entity as IQuestion).priority}
							type="single"
							label="Priority"
							value="priority"
							iconType="tabler"
							isViewerUser={viewerOfEntity}
							options={SELECTABLE_PROPERTY_OPTIONS.priority}
							onChange={handleEntityChange('priority')}
							placeholder={viewerOfEntity ? 'No priority' : 'Add priority'}
						/>
					)}
					{withStatusSelector && (
						<SelectableProperty
							selected={(entity as IQuestion).status}
							type="single"
							label="Status"
							value="status"
							iconType="badge"
							isViewerUser={viewerOfEntity}
							options={SELECTABLE_PROPERTY_OPTIONS.status}
							onChange={handleEntityChange('status')}
						/>
					)}
					{editorOfEntity &&
						!withCustomPublishElement &&
						!withStatusSelector && (
							<SelectableProperty
								selected={entity.published}
								type="single"
								label="Status"
								value="published"
								iconType="badge"
								isViewerUser={viewerOfEntity}
								onChange={handleEntityChange('published')}
							/>
						)}
					{!!withCustomPublishElement && withCustomPublishElement}
					{noTags ? (
						<RichTooltip
							title={`No tags were set in ${integration.name}`}
							body={'To maintain in Secoda, go to integration settings.'}
							linkLabel={
								isAdminUser ? `${integration?.name} settings` : undefined
							}
							linkUrl={
								isAdminUser
									? `/integrations/${integration?.id}/preferences`
									: undefined
							}
						>
							{withTagSelector && (
								<SelectableProperty
									readOnly
									selected={entity?.tags ?? []}
									type="tags"
									label="Tags"
									value="tags"
									iconType="tag"
									inheritedValues={maintainTags ? inheritedTags : []}
									options={tagOptions}
									isMenuItemBadge
									isViewerUser={viewerOfEntity}
									onChange={handleEntityChange('tags')}
									placeholder={viewerOfEntity || noTags ? 'No tags' : 'Add tag'}
								/>
							)}
						</RichTooltip>
					) : (
						withTagSelector && (
							<SelectableProperty
								selected={entity?.tags ?? []}
								type="tags"
								label="Tags"
								value="tags"
								iconType="tag"
								inheritedValues={maintainTags ? inheritedTags : []}
								options={tagOptions}
								isMenuItemBadge
								isViewerUser={viewerOfEntity}
								onChange={handleEntityChange('tags')}
								placeholder={viewerOfEntity ? 'No tags' : 'Add tag'}
							/>
						)
					)}

					{withCollectionSelector && (
						<SelectableProperty
							selected={entity?.collections ?? []}
							type="multi"
							label="Collections"
							value="collections"
							iconType="emoji"
							options={collectionOptions}
							isViewerUser={viewerOfEntity}
							onChange={handleEntityChange('collections')}
							placeholder={
								viewerOfEntity ? 'No collections' : 'Add to collection'
							}
						/>
					)}
					{noOwners ? (
						<RichTooltip
							title={`No owners were set in ${integration.name}`}
							body={'To maintain in Secoda, go to integration settings.'}
							linkLabel={
								isAdminUser ? `${integration?.name} settings` : undefined
							}
							linkUrl={
								isAdminUser
									? `/integrations/${integration?.id}/preferences`
									: undefined
							}
						>
							{withOwnerSelector && (
								<SelectableProperty
									readOnly
									selected={selectedOwners}
									inheritedValues={maintainOwners ? serviceAccountOwners : []}
									type="multi"
									label="Owners"
									value="owners"
									iconType="avatar"
									isViewerUser={viewerOfEntity}
									options={owners}
									placeholder={
										viewerOfEntity || noOwners ? 'No owners' : 'Add owner'
									}
								/>
							)}
						</RichTooltip>
					) : (
						withOwnerSelector && (
							<SelectableProperty
								selected={selectedOwners}
								inheritedValues={maintainOwners ? serviceAccountOwners : []}
								type="multi"
								label="Owners"
								value="owners"
								iconType="avatar"
								isViewerUser={viewerOfEntity}
								options={owners}
								placeholder={viewerOfEntity ? 'No owners' : 'Add owner'}
							/>
						)
					)}
					{withGovernanceSelector && (
						<SelectableProperty
							selected={!!entity.pii}
							type="single"
							label="Governance"
							value="pii"
							iconType="tabler"
							isViewerUser={viewerOfEntity}
							onChange={handleEntityChange('pii')}
						/>
					)}
					{withVerifiedSelector && (
						<SelectableProperty
							selected={!!entity.verified}
							type="single"
							label="Verification"
							value="verified"
							iconType="tabler"
							isViewerUser={viewerOfEntity}
							onChange={handleEntityChange('verified')}
						/>
					)}
					{withTeamSelector && !isGuestUser && (
						<SelectableProperty
							inheritedValues={integration?.teams}
							inheritedValuesTooltip={`From ${integration?.name}`}
							selected={entity?.teams ?? []}
							type="multi"
							label="Teams"
							value="teams"
							iconType="emoji"
							options={teamOptions}
							isViewerUser={viewerOfEntity}
							onChange={handleEntityChange('teams')}
						/>
					)}
					{withCollectionParentSelector && (
						<Group>
							<SelectableProperty
								selected={
									entity.collections?.length > 0 ? entity.collections[0] : []
								}
								type="single"
								label="Parent"
								value="collections"
								iconType="emoji"
								isViewerUser={viewerOfEntity}
								onChange={(value) => {
									const collectionId = value as string;
									handleEntityChange('collections')(
										collectionId ? [collectionId] : []
									);
								}}
								options={collectionOptions}
								searchable
							/>
							{entity.collections?.length > 0 && (
								<ActionIcon
									size="xs"
									onClick={() => handleEntityChange('collections')([])}
								>
									<Icon name="x" />
								</ActionIcon>
							)}
						</Group>
					)}
					{withCustomPropertyEditors && (
						<ErrorBoundary disableFallback>
							<CustomPropertyEditor
								properties={properties}
								readOnly={viewerOfEntity}
								onAddItem={setItem}
								onRemoveItem={remove}
							/>
						</ErrorBoundary>
					)}
				</Stack>
			</CollapsableStack>
			{canShowSLA && <FreshnessStack entity={entity} />}
			<RelatedResourceStack
				entity={entity}
				isVisible={withRelatedResourceSelector}
			/>
			<MetadataStack
				integration={integration}
				entity={entity}
				visibleUsers={visibleUsers}
				withFrequentUsers={withFrequentUsers}
				withEntityPopularity={withEntityPopularity}
				withEntityRowCount={withEntityRowCount}
				withEntityByteSize={withEntityByteSize}
				withAIInclusionStatus={withAIInclusionStatus}
			/>
			<CollapsableStack groupName="Subscribers">
				<Stack spacing="xs">
					<SelectableProperty
						selected={entity.subscribers ?? []}
						type="multi"
						label="Subscribers"
						value="subscribers"
						iconType="avatar"
						options={subscriberOptions}
						permittedId={user.id}
						isViewerUser={viewerOfEntity}
						onChange={handleEntityChange('subscribers')}
					/>
					{withSource && (
						<StaticProperty type="source" label="Source" value={withSource} />
					)}
				</Stack>
			</CollapsableStack>
		</Stack>
	);
}
export default observer(EntitySidebarContent);
