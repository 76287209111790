import { IconButton } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { entitySideBarStore } from '../EntityPageLayout/store';

function EntityPageAsideCollapse() {
	const { collapsed, mode, setCollapsed, setMode } = entitySideBarStore;

	const toggleEntitySideBar = () => {
		if (collapsed) {
			setCollapsed(false);
			setMode('info');
		} else if (mode === 'info') {
			setCollapsed(!entitySideBarStore.collapsed);
		} else {
			setMode('info');
		}
	};

	const isOpen = !collapsed && mode === 'info';

	return (
		<IconButton
			tooltip={`${isOpen ? 'Hide' : 'Show'} details`}
			onClick={toggleEntitySideBar}
			highlight={isOpen}
			iconName={isOpen ? 'infoCircleFilled' : 'infoCircle'}
			variant="tertiary"
		/>
	);
}

export default observer(EntityPageAsideCollapse);
