import { Box, Input, Skeleton, Stack } from '@mantine/core';
import { Button } from '@repo/foundations';
import dayjs from 'dayjs';
import { isNumber, map, noop } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { ISecodaEntity, Monitor } from '../../../api';
import { useAuthUser, useMonitor, useUpdateMonitor } from '../../../api';
import type { ScheduleConfig } from '../../../api/types/models/schedule';
import CollapsableStack from '../../../components/CollapsableStack';

import { useExtendedUserList } from '../../../api/hooks/user/useExtendedUserList';
import {
	SelectableProperty,
	StaticProperty,
} from '../../../components/EntityPageLayout/EntityPropertySidebar';
import EntityPageSidebarWrapper from '../../../components/EntityPageLayout/EntitySidebarWrapper';
import { EntityDisplay } from '../../../components/EntityPageLayout/SidesheetStacks/EntityDisplay/EntityDisplay';
import type { SidebarEntityKeys } from '../../../components/EntityPageLayout/types';
import { getOwnerOptions } from '../../../components/EntityPageLayout/utils';
import ScheduleSelector from '../../../components/ScheduleSelector/ScheduleSelector';
import { SecodaEntityIcon } from '../../../components/SecodaEntity';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { buildResourceUrl } from '../../../utils/navigationUtils';
import type { DjangoValueType } from '../../TemplatePage/types';
import CustomQueryEditor from '../components/CustomQueryEditor';
import MonitorStatusBadge from '../components/MonitorStatusBadge';
import ThresholdSelector from '../components/ThresholdSelector';

export interface IMonitorConfigurationSidebarProps {
	monitorId?: string;
	targetEntity?: ISecodaEntity;
}

function MonitorConfigurationSidebar({
	monitorId,
	targetEntity,
}: IMonitorConfigurationSidebarProps) {
	const { data: monitor } = useMonitor({
		id: monitorId as string, // 'id' cannot be undefined here as enabled blocks it
		options: {
			enabled: Boolean(monitorId),
		},
	});

	const { activeUsers } = useExtendedUserList({ suspense: false });
	const { monitoringV2 } = useFeatureFlags();

	const { mutateAsync: updateMonitor } = useUpdateMonitor({});

	const handleUpdateMonitor = useCallback(
		async (data: Partial<Monitor>) => {
			if (!monitor?.id) {
				return;
			}

			await updateMonitor({
				data: {
					id: monitor.id,
					metric_type: monitor.metric_type,
					...data,
				},
			});
		},
		[monitor, updateMonitor] // make sure to add necessary dependencies here
	);

	const canEdit = monitor?.permissions?.edit || false;

	const handleEntityChange = useCallback(
		(key: SidebarEntityKeys) => (value: DjangoValueType) => {
			if (key === 'owners') {
				handleUpdateMonitor({
					owners: value as string[],
				});
			} else if (key === 'subscribers') {
				// Subscribers could contain UUIDs or emails. We need to differentiate between them
				// and update the correct field in the monitor.
				const emailSubscribers = (value as string[]).filter((v) =>
					v.includes('@')
				) as string[];

				const subscribers = (value as string[]).filter(
					(v) => !v.includes('@')
				) as string[];

				handleUpdateMonitor({
					email_subscribers: emailSubscribers,
					subscribers,
				});
			}
		},
		[handleUpdateMonitor]
	);

	const owners = useMemo(
		() => getOwnerOptions(activeUsers ?? [], handleEntityChange, true),
		[activeUsers, handleEntityChange]
	);

	const [searchTerm, setSearchTerm] = useState('');

	const subscriberOptions = [
		...map(activeUsers, (u) => ({
			label: u.display_name,
			value: u.id,
			icon: u.profile_picture,
			navigateTo: `/user/${u.id}`,
			onClick: noop,
		})),
		...map(monitor?.email_subscribers ?? [], (email) => ({
			label: email,
			value: email,
			icon: email[0],
			onClick: noop,
		})),
	];

	const selectedOwners = [...(monitor?.owners ?? [])];

	const selectedSubscribers = [
		...(monitor?.subscribers ?? []),
		...(monitor?.email_subscribers ?? []),
	];

	// The value for the threshold dropdown
	const [threshold, setThreshold] = useState<'automatic' | 'manual'>(
		'automatic'
	);

	const prevMonitor = useRef<Monitor | undefined>();

	// We need to set the default value of the threshold dropdown when the monitor goes from undefined to a valid monitor
	// Wrapping this in a regular useEffect may overwrite the state of the threshold selector when the monitor is updated.
	// Using a "useRef" ensures that this effect only runs once. We can potentially use a state variable to store the flag
	// but a ref seems more appropriate.
	useEffect(() => {
		if (prevMonitor.current === undefined && monitor !== undefined) {
			setThreshold(
				isNumber(monitor?.condition_manual_min) ||
					isNumber(monitor?.condition_manual_max)
					? 'manual'
					: 'automatic'
			);
		}

		// Update prevMonitor for the next render
		prevMonitor.current = monitor;
	}, [monitor]);

	const { isViewerOrGuestUser } = useAuthUser();

	const handleScheduleChange = async (schedule: ScheduleConfig) => {
		await handleUpdateMonitor({ schedule });
	};

	const handleThresholdChange = async (newThreshold: string | null) => {
		if (newThreshold === 'automatic') {
			await handleUpdateMonitor({
				condition_auto_sensitivity: 5,
				condition_manual_min: null,
				condition_manual_max: null,
			});
		}
		// We do not need to do something specific for manual monitors.
		// This is updated with handleMinimumChange and handleMaximumChange
	};

	const handleSensitivityChange = async (newSensitivity: number | string) => {
		let sensitivity =
			typeof newSensitivity === 'string'
				? parseInt(newSensitivity)
				: newSensitivity;

		if (!isNaN(sensitivity)) {
			await handleUpdateMonitor({
				condition_auto_sensitivity: sensitivity,
				condition_manual_min: null,
				condition_manual_max: null,
			});
		}
	};

	const handleMinimumChange = async (value: number | null | undefined) => {
		await handleUpdateMonitor({
			condition_auto_sensitivity: null,
			condition_manual_min: value,
		});
	};

	const handleMaximumChange = async (value: number | null | undefined) => {
		await handleUpdateMonitor({
			condition_auto_sensitivity: null,
			condition_manual_max: value,
		});
	};

	const handleNewEmailSubscriber = async () => {
		await handleUpdateMonitor({
			email_subscribers: Array.from(
				new Set([...(monitor?.email_subscribers ?? []), searchTerm])
			),
		});
	};

	const lastRun = monitor?.last_attempted_at
		? dayjs(monitor?.last_attempted_at).fromNow()
		: '--';

	const nextRun =
		monitor?.next_run_at && dayjs(monitor?.next_run_at).isAfter(dayjs())
			? `${dayjs(monitor?.next_run_at).diff(dayjs(), 'hours')} hours`
			: '--';

	const createdAt = monitor?.created_at
		? dayjs(monitor?.created_at).fromNow()
		: '--';

	const initialThreshold =
		isNumber(monitor?.condition_manual_min) ||
		isNumber(monitor?.condition_manual_max)
			? 'manual'
			: 'automatic';

	const targetEntityOptions = [
		{
			label: targetEntity?.title ?? '',
			value: targetEntity?.id ?? '',
			icon: <SecodaEntityIcon entity={targetEntity} size={14} />,
			navigateTo: buildResourceUrl(targetEntity ?? ({} as ISecodaEntity)),
		},
	];

	const handleThresholdOptionChange = (
		value: string | number | boolean | string[]
	) => {
		if (typeof value !== 'string') {
			return;
		}
		setThreshold(value === 'manual' ? 'manual' : 'automatic');
		handleThresholdChange(value);
	};

	return (
		<EntityPageSidebarWrapper mode="info">
			<Stack>
				<CollapsableStack groupName="Overview">
					<Stack spacing="xs">
						<StaticProperty
							label="Status"
							custom={<MonitorStatusBadge monitor={monitor} />}
						/>
						<StaticProperty type="text" label="Last run" value={lastRun} />
						<StaticProperty type="text" label="Next run" value={nextRun} />
						<StaticProperty type="text" label="Created" value={createdAt} />
						{targetEntity && !monitoringV2 && (
							<SelectableProperty
								selected={monitor?.target as string}
								type="single"
								label="Resource"
								value="resource"
								iconType="react-node"
								isViewerUser={isViewerOrGuestUser}
								searchable
								readOnly
								options={targetEntityOptions}
							/>
						)}
						<SelectableProperty
							selected={selectedOwners}
							inheritedValues={[]}
							type="multi"
							label="Owners"
							value="owners"
							iconType="avatar"
							isViewerUser={false}
							options={owners}
							readOnly={!canEdit}
						/>
					</Stack>
				</CollapsableStack>
				{monitoringV2 && targetEntity && (
					<CollapsableStack groupName="Resource">
						<Box pl="sm">
							<EntityDisplay entity={targetEntity} />
						</Box>
					</CollapsableStack>
				)}
				<CollapsableStack groupName="Schedule">
					<Stack spacing="xs">
						{/* We disable weekly cadence for monitors, but just in case we have them, there's a warning */}
						{monitor?.schedule.cadence === 'weekly' &&
							threshold === 'automatic' && (
								<Input.Error size="xs" color="critical" pt="xs">
									Weekly repetitions may extend the time needed for automatic
									threshold determination. Consider manual thresholds or daily
									runs for quicker results.{' '}
								</Input.Error>
							)}
						{monitor?.schedule && (
							<ScheduleSelector
								readOnly={!canEdit}
								disableWeekly
								schedule={monitor.schedule}
								onChange={handleScheduleChange}
							/>
						)}
					</Stack>
				</CollapsableStack>
				<CollapsableStack groupName="Threshold">
					{monitor ? (
						<ThresholdSelector
							readOnly={!canEdit}
							label="Threshold"
							value={initialThreshold}
							threshold={threshold}
							data={monitor}
							onChange={handleThresholdOptionChange}
							onMaximumValueChange={handleMaximumChange}
							onMinimumValueChange={handleMinimumChange}
							onSensitivityChange={handleSensitivityChange}
							showSensitivityControl={true}
						/>
					) : (
						<Skeleton height={60} />
					)}
				</CollapsableStack>
				{monitor && <CustomQueryEditor monitor={monitor} />}
				<CollapsableStack groupName="Subscribers">
					<Stack>
						<SelectableProperty
							selected={selectedSubscribers}
							inheritedValues={[]}
							type="multi"
							label="Subscribers"
							value="subscribers"
							iconType="avatar"
							isViewerUser={false}
							options={subscriberOptions}
							onChange={handleEntityChange('subscribers')}
							onSearchChange={setSearchTerm}
							emptyState={
								<Button w="100%" size="sm" onClick={handleNewEmailSubscriber}>
									Add {searchTerm}
								</Button>
							}
						/>
					</Stack>
				</CollapsableStack>
			</Stack>
		</EntityPageSidebarWrapper>
	);
}

export default observer(MonitorConfigurationSidebar);
