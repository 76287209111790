import { Popover } from '@mantine/core';
import { useState } from 'react';

interface PopoverMenuItemProps {
	children: React.ReactNode;
	target?: React.ReactNode;
}

function PopoverMenuItem({ children, target }: PopoverMenuItemProps) {
	const [targetFocused, setTargetFocused] = useState(false);
	const [dropdownFocused, setDropdownFocused] = useState(false);
	const close = () => {
		setTargetFocused(false);
		setDropdownFocused(false);
	};

	return (
		<Popover
			opened={targetFocused || dropdownFocused}
			onClose={close}
			width={280}
			position="right-start"
			radius={'md'}
			shadow="xl"
		>
			<Popover.Target>
				<div
					onMouseEnter={() => setTargetFocused(true)}
					onMouseLeave={() => setTimeout(() => setTargetFocused(false), 200)}
				>
					{target}
				</div>
			</Popover.Target>

			<Popover.Dropdown>
				<div
					onMouseEnter={() => setDropdownFocused(true)}
					onBlurCapture={() => setDropdownFocused(false)}
				>
					{children}
				</div>
			</Popover.Dropdown>
		</Popover>
	);
}

export default PopoverMenuItem;
