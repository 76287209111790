import type { ColumnDefinition } from '../../types';
import { ColumnType } from '../../types';

export const DefaultColumns: ColumnDefinition = {
	tags: {
		field: 'tags',
		editable: true,
		sortable: false,
	},
	published: {
		field: 'published',
		editable: true,
		sortable: true,
	},
	pii: {
		field: 'pii',
		editable: true,
		sortable: false,
	},

	verified: {
		field: 'verified',
		editable: true,
		sortable: false,
	},

	owners: {
		field: 'owners',
		editable: true,
		sortable: true,
	},
	collections: {
		field: 'collections',
		editable: true,
		sortable: false,
	},
	related_entities: {
		field: 'related_entity_ids',
		editable: true,
		sortable: false,
	},
	updated_at: {
		field: 'updated_at',
		editable: false,
		sortable: true,

		filter: undefined,
	},
};

export const PopularityColumns: ColumnDefinition = {
	external_usage: {
		field: 'external_usage',
		editable: false,
		sortable: true,

		filter: undefined,
	},
};

export const DataEntityColumns: ColumnDefinition = {
	external_updated_at: {
		field: 'external_updated_at',
		editable: false,
		sortable: true,

		filter: undefined,
	},
};

export const DescriptionColumns: ColumnDefinition = {
	description: {
		field: 'description',
		sortable: false,
		editable: true,
		type: ColumnType.textFilterColumn,
		minWidth: 250,
	},
};

export const TableDerivativeColumns: ColumnDefinition = {
	cluster: {
		field: 'cluster',
		editable: false,
		sortable: true,
	},

	database: {
		field: 'database',
		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
	},

	schema: {
		field: 'schema',
		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
	},
};

export const DatabaseDerivativeColumns: ColumnDefinition = {
	cluster: {
		field: 'cluster',
		editable: false,
		sortable: true,
	},
};

export const SchemaDerivativeColumns: ColumnDefinition = {
	...DatabaseDerivativeColumns,
	database: {
		field: 'database',
		editable: false,
		sortable: true,
		type: ColumnType.textFilterColumn,
	},
};

export const DashboardCommonColumns: ColumnDefinition = {
	product: {
		...TableDerivativeColumns.cluster,
		field: 'product',
	},
};

export const DataQualityColumns: ColumnDefinition = {
	dqs: {
		field: 'dqs',
		headerName: 'Quality',
		editable: false,
		sortable: true,
	},
};
