import {
	Anchor,
	Box,
	Divider,
	FileButton,
	Menu,
	createStyles,
	useMantineTheme,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { Button, Icon } from '@repo/foundations';
import { documentImporter } from '@repo/secoda-editor/importer';
import axios from 'axios';
import { map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { api } from '../../../network';
import { useAuthUser } from '../../api';
import { usePlan } from '../../hooks/usePlans';
import {
	BackgroundJob,
	Document,
	downloadExportTemplate,
	downloadImportTemplate,
	exportData,
} from '../../lib/models';
import { snakeCaseToTitleCase } from '../../utils/shared.utils';
import BackgroundJobProgress from '../BackgroundJobProgress';
import ModalUpload from '../ModalUpload';
import { Setting } from './Setting';
import { UpgradeButton } from './UpgradeButton';

const useStyles = createStyles((theme) => ({
	callToAction: {
		color: theme.colors.primary[6],
	},
}));

// @ts-expect-error TS(7006): Parameter 'files' implicitly has an 'any' type.
const handleFilesSelected = async (files) => {
	if (files.length === 0) {
		return null;
	}
	const file = files[0];
	const form = new FormData();
	form.append('file', file);
	const response = await axios({
		method: 'post',
		url: `${api()}metadata/data/`,
		data: form,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
	return new BackgroundJob(response.data);
};

export const ImportSettings = observer(() => {
	const [opened, setOpened] = useState(false);
	const theme = useMantineTheme();
	const { classes } = useStyles();

	const { plan } = usePlan();
	const { isAdminUser } = useAuthUser();

	const disabled = !isAdminUser || !plan?.export_support;
	const [backgroundJob, setBackgroundJob] =
		React.useState<BackgroundJob | null>(null);
	const resetRef = useRef<() => void>(null);
	const [actionType, setActionType] = useState<'import' | 'export'>('import');

	const handleConfluence = async (files: File[]) => {
		const created = [];
		for (const file of files) {
			const map = {
				fileName: file.name,
				mimeType: file.type,
			};
			const { title, text } = await documentImporter(file, map);
			Document.create({ title, definition: text });
			created.push(title);
		}

		showNotification({
			title: 'Documents imported',
			message: `Imported ${created.length} documents: ${created.join(', ')}`,
			icon: <Icon name="check" />,
			color: theme.colors.green[theme.colorScheme === 'dark' ? 4 : 6],
		});
	};

	const typesToExport = [
		'table',
		'column',
		'dashboard',
		'chart',
		'job',
		'event',
		'dictionary_term',
		'metric',
		'document',
		'tag',
	];

	const handleBackgroundJobComplete = () => {
		resetRef.current?.();
	};

	return (
		<Box>
			{!!backgroundJob && (
				<BackgroundJobProgress
					key={backgroundJob.id}
					job={backgroundJob}
					withReloadButton={actionType === 'import'}
					onCompleted={handleBackgroundJobComplete}
				/>
			)}
			<Setting
				title="Import"
				description="Bulk import resource descriptions and custom properties."
				callToAction={
					<Anchor
						onClick={downloadImportTemplate}
						component="button"
						type="button"
						className={classes.callToAction}
					>
						Download template
					</Anchor>
				}
			>
				<Box style={{ position: 'relative' }}>
					{plan?.export_support ? (
						<FileButton
							resetRef={resetRef}
							disabled={disabled}
							multiple={false}
							onChange={(file) =>
								handleFilesSelected([file]).then((newBackgroundJob) => {
									setActionType('import');
									setBackgroundJob(newBackgroundJob);
								})
							}
							accept="text/csv"
						>
							{(props) => <Button {...props}>Select file</Button>}
						</FileButton>
					) : (
						<UpgradeButton feature="Import" />
					)}
				</Box>
			</Setting>
			<Divider />
			<Setting
				title="Export"
				description="Bulk export resource descriptions and custom properties."
				callToAction={
					<Anchor
						onClick={downloadExportTemplate}
						component="button"
						type="button"
						className={classes.callToAction}
					>
						Download sample
					</Anchor>
				}
			>
				<Box style={{ position: 'relative' }}>
					{plan?.export_support ? (
						<Menu
							opened={opened}
							onChange={setOpened}
							position="bottom-start"
							width={150}
						>
							<Menu.Target>
								<Button disabled={disabled}>Export data</Button>
							</Menu.Target>
							<Menu.Dropdown>
								{map(typesToExport, (type: string) => (
									<Menu.Item
										key={type}
										onClick={() => {
											exportData(type).then((newBackgroundJob) => {
												setActionType('export');
												setBackgroundJob(newBackgroundJob);
											});
										}}
									>
										{`${snakeCaseToTitleCase(type)}s`}
									</Menu.Item>
								))}
							</Menu.Dropdown>
						</Menu>
					) : (
						<UpgradeButton feature="Export" />
					)}
				</Box>
			</Setting>
			<Divider />

			<Setting
				title="Documents"
				description="Import documents from Confluence, Google Docs, and Microsoft Word."
			>
				<Box style={{ position: 'relative' }}>
					{plan?.export_support ? (
						<ModalUpload
							handleDrop={handleConfluence}
							instructions={
								'For html upload, navigate to Space Settings > Manage space > Export space and choose to export as HTML with the "Normal Export" option. You will need to unzip the file and upload each html file separately. Maximum file size is 16MB.'
							}
							title="Drag and drop .html or .doc files from Confluence's export options, or click to upload"
						/>
					) : (
						<UpgradeButton feature="Documents Import" />
					)}
				</Box>
			</Setting>
			<Divider />
		</Box>
	);
});
