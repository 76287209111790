import { Group, Stack } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import RelatedFilterDropdown from './RelatedFilterDropdown';

interface IRelatedFilterProps {
	onDeleteFilter?: () => void;
}

function RelatedFilter({ onDeleteFilter }: IRelatedFilterProps) {
	return (
		<>
			<Stack spacing={0}>
				<Group position="apart" mb={6}>
					<Text size="sm" color="text/secondary/default" mr={2}>
						Related To
					</Text>
					<Button variant="tertiary" onClick={onDeleteFilter}>
						Delete Filter
					</Button>
				</Group>
			</Stack>
			<RelatedFilterDropdown />
		</>
	);
}

export default observer(RelatedFilter);
