/* eslint-disable react/jsx-props-no-spreading */
import type { MantineTheme } from '@mantine/core';
import { Box, createStyles, Group, Stack } from '@mantine/core';
import { Icon, Switch, Text } from '@repo/foundations';
import type React from 'react';
import type { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';

import type { ColumnName } from '../helpers';

export type IMenuItemProps = {
	name: string;
};

export type IDraggableMenuItemProps = IMenuItemProps & {
	disableDragging?: boolean;
	columnName: ColumnName;
	height?: number;
	index: number;
	onVisibilityChange?: (isVisible: boolean) => void;
	isVisible?: boolean;
	suffix?: React.ReactNode;
	subtext?: React.ReactNode;
	rightContent?: React.ReactNode;
};

const getItemStyle = (
	theme: MantineTheme,
	isDragging: boolean,
	draggableStyle?: DraggingStyle | NotDraggingStyle
): React.CSSProperties => ({
	// Styles we need to apply on draggables
	...draggableStyle,

	// Some basic styles to make the items look a bit nicer
	userSelect: 'none',

	// Change background color if dragging
	backgroundColor: isDragging
		? theme.other.getColor('surface/primary/hover')
		: undefined,
});

const useStyles = createStyles(
	(theme, { height = 52 }: { height?: number }) => ({
		draggableWrapper: {
			cursor: 'move',
		},
		wrapper: {
			height,
			paddingTop: 0,
			paddingBottom: 0,
			paddingRight: theme.spacing.xs,
			paddingLeft: 2,
			borderRadius: theme.radius.sm,
			backgroundColor: theme.other.getColor('surface/primary/default'),
			'&:hover': {
				backgroundColor: theme.other.getColor('surface/primary/hover'),
			},
		},
		emptyMenuWrapper: {},
		label: {
			flex: '1 1 auto',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			maxWidth: '25ch',
		},
	})
);

export function DraggableMenuItem({
	name,
	height = 32,
	columnName,
	index,
	onVisibilityChange,
	subtext,
	isVisible,
	suffix,
	disableDragging = false,
	rightContent,
}: IDraggableMenuItemProps) {
	const { classes, cx, theme } = useStyles({ height });
	const handleVisibilityChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		onVisibilityChange?.(event.target.checked);
	};

	return (
		<Draggable
			isDragDisabled={disableDragging}
			key={columnName}
			draggableId={columnName}
			index={index}
		>
			{(provided, snapshot) => (
				<Group
					className={cx(classes.wrapper, {
						[classes.draggableWrapper]: !disableDragging,
					})}
					noWrap
					align="center"
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={getItemStyle(
						theme,
						snapshot.isDragging,
						provided.draggableProps.style
					)}
					position="apart"
				>
					<Group spacing="xs">
						{!disableDragging && <Icon p={0} name="gripVertical" />}
						<Stack spacing={0}>
							<Text className={classes.label} size="sm">
								{name}
							</Text>
							{subtext}
						</Stack>
					</Group>
					<Group spacing={0}>
						{suffix && <Box>{suffix}</Box>}
						{rightContent ? (
							rightContent
						) : (
							<Switch
								size="sm"
								onChange={handleVisibilityChange}
								checked={isVisible}
							/>
						)}
					</Group>
				</Group>
			)}
		</Draggable>
	);
}

export function MenuItem({ name }: IMenuItemProps) {
	const { classes } = useStyles({});
	return (
		<Stack className={classes.emptyMenuWrapper}>
			<Text size="sm">{name}</Text>
		</Stack>
	);
}
