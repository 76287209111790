import {
	Group,
	Tooltip,
	UnstyledButton,
	createStyles,
	getStylesRef,
} from '@mantine/core';
import { getHotkeyHandler, useDisclosure, useHotkeys } from '@mantine/hooks';
import type { FILTER_OPTIONS_DIVIDER } from '@repo/common/components/Filter/constants';
import type {
	FilterOption,
	FilterValue,
} from '@repo/common/components/Filter/types';
import { KeyboardShortcut } from '@repo/common/components/KeyboardShortcut/KeyboardShortcut';
import { Icon, IconButton, ListBox, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { useAuthUser } from '../../../api';
import { trackEvent } from '../../../utils/analytics';
import { FilterDropdown } from '../FilterDropdown/FilterDropdown';
import type { AddedFilterResult } from '../store';
import { AddFilterDropdown } from './AddFilterDropdown';
import { useAddFilter } from './useAddFilter';

const useStyles = createStyles((theme) => ({
	target: {
		ref: getStylesRef('target'),

		border: `1px dashed ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.sm,
		padding: `${theme.spacing['5xs']} ${theme.spacing['2xs']} ${theme.spacing['5xs']} ${theme.spacing.sm}`,

		backgroundColor: theme.other.getColor('fill/primary/default'),

		'&:hover, &:focus': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},

		'&:active': {
			backgroundColor: theme.other.getColor('fill/primary/active'),
		},
	},
	targetText: {
		fontSize: theme.fontSizes.xs,
		lineHeight: theme.spacing.md,
	},
	targetIcon: {
		color: theme.other.getColor('icon/primary/default'),

		[`.${getStylesRef('target')} &:hover, .${getStylesRef('target')} &:focus`]:
			{
				color: theme.other.getColor('icon/primary/hover'),
			},

		[`.${getStylesRef('target')} &:active`]: {
			color: theme.other.getColor('icon/primary/active'),
		},
	},
}));

export interface AddFilterProps {
	withIconTarget?: boolean;
	options: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];
	onAddFilter: (value: FilterValue) => AddedFilterResult;
}

export const AddFilter = observer(
	({ withIconTarget, options, onAddFilter }: AddFilterProps) => {
		const { classes } = useStyles();
		const { user, workspace } = useAuthUser();
		const location = useLocation();
		const [opened, { toggle, close, open }] = useDisclosure();

		const {
			selectedFilterOption,
			setSelectedFilterOption,
			filterValue,
			reset,
			handleAddFilter,
		} = useAddFilter({ onAddFilter, close });

		const onToggle = useCallback(() => {
			if (!opened)
				trackEvent(
					'filters/open',
					{
						url: location.pathname,
					},
					user,
					workspace
				);
			reset();
			toggle();
		}, [location.pathname, opened, reset, toggle, user, workspace]);

		useHotkeys([['F', onToggle]]);

		const handleOnOpenChange = useCallback(
			(newOpen: boolean) => {
				if (newOpen) {
					reset();
					open();
				} else {
					close();
				}
			},
			[close, open, reset]
		);

		const handleEscapeKey = useCallback(
			(e: React.KeyboardEvent<HTMLElement> | KeyboardEvent) => {
				e.preventDefault();
				e.stopPropagation();
				if (selectedFilterOption) {
					reset();
				} else {
					close();
				}
			},
			[close, reset, selectedFilterOption]
		);

		return (
			<ListBox
				opened={opened}
				onOpenChange={handleOnOpenChange}
				closeOnEscape={false}
				onKeyDown={getHotkeyHandler([['Escape', handleEscapeKey]])}
				autoAdjustPosition={false}
			>
				<ListBox.Target>
					<Tooltip
						label={<KeyboardShortcut keys={['F']}>Add filter</KeyboardShortcut>}
					>
						{withIconTarget ? (
							<IconButton
								data-testid="filter-button"
								onClick={onToggle}
								variant="tertiary"
								iconName="filter"
							/>
						) : (
							<UnstyledButton
								data-testid="filter-button"
								onClick={onToggle}
								className={classes.target}
							>
								<Group spacing={0}>
									<Text className={classes.targetText}>Add filter</Text>
									<Icon name="plus" className={classes.targetIcon} />
								</Group>
							</UnstyledButton>
						)}
					</Tooltip>
				</ListBox.Target>

				{opened && selectedFilterOption && (
					<FilterDropdown
						value={
							filterValue ?? {
								value: null,
								operator:
									selectedFilterOption.filterDropdownConfig.defaultOperator,
								filterType: selectedFilterOption.type,
							}
						}
						filterOption={selectedFilterOption}
						onChange={handleAddFilter}
					/>
				)}
				{opened && !selectedFilterOption && (
					<AddFilterDropdown
						options={options}
						onAddFilter={handleAddFilter}
						onItemClick={setSelectedFilterOption}
					/>
				)}
			</ListBox>
		);
	}
);
