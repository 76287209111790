import { Box, createStyles, Group, LoadingOverlay, Stack } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { Button, Icon } from '@repo/foundations';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import {
	type IGroupItem,
	IIntegration,
	useIntegrationGroupItems,
	useIntegrationGroupItemsRefreshSupport,
	useIntegrationGroupSettings,
	useRefreshIntegrationGroupItems,
	useUpdateIntegrationGroupSettings,
} from '../../../../../api';
import { IntegrationSpec } from '../../../../../interfaces/IntegrationSpec';
import SearchBox from '../../../../SearchBox/SearchBox.tsx';
import {
	filterGroupItems,
	getAllDescendants,
} from '../../../IntegrationSelectionPage/StepComponents/ImportStep/utils';
import {
	SelectPanelTableRow,
	VisibilityState,
} from './SelectPanelTableRow.tsx';

interface SelectPanelProps {
	integration: IIntegration;
	spec: IntegrationSpec;
}

const useStyles = createStyles((theme) => ({
	table: {
		borderCollapse: 'separate',
		border: `1px solid ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.md,
		overflow: 'hidden',
		'thead tr th': {
			padding: theme.spacing.xs,
			fontSize: theme.other.typography.text.sm,
			fontWeight: theme.other.typography.weight.semibold,
			color: theme.other.getColor('text/secondary/default'),
			backgroundColor: theme.other.getColor('surface/secondary/default'),
			borderBottomColor: theme.other.getColor('border/secondary/default'),
		},
		table: {
			width: '100%',
		},
		'thead tr th:first-of-type': {
			paddingLeft: theme.spacing.md,
		},
		'tbody tr td': {
			padding: theme.spacing.xs,
			fontSize: theme.other.typography.text.xs,
			borderTopColor: theme.other.getColor('border/secondary/default'),
		},
		'tbody tr td:first-of-type': {
			paddingLeft: theme.spacing.md,
			fontWeight: theme.other.typography.weight.bold,
		},
		'tbody tr': {
			cursor: 'pointer',
		},
	},
}));

export function SelectPanel({ integration, spec }: SelectPanelProps) {
	const { classes } = useStyles();

	const { data: groupItems, isLoading: isLoadingGroupItems } =
		useIntegrationGroupItems(integration.id);
	const { data: supportRefresh, isLoading: isLoadingRefreshSupport } =
		useIntegrationGroupItemsRefreshSupport(integration.type);
	const { data: groupSettings, isLoading: isLoadingGroupSettings } =
		useIntegrationGroupSettings(integration.id);

	const [searchTerm, setSearchTerm] = useInputState('');
	const [groupSettingsState, setGroupSettingsState] = useState(
		groupSettings || {}
	);
	const [filteredGroupItems, setFilteredGroupItems] = useState<IGroupItem[]>(
		groupItems || []
	);

	const {
		mutateAsync: apiRefreshIntegrationGroupItems,
		isLoading: isRefreshing,
	} = useRefreshIntegrationGroupItems(integration.id);
	const { mutateAsync: apiUpdateIntegrationGroupSettings } =
		useUpdateIntegrationGroupSettings(integration.id);

	useEffect(() => {
		setGroupSettingsState(groupSettings || {});
	}, [groupSettings]);

	useEffect(() => {
		if (groupItems) {
			setFilteredGroupItems(filterGroupItems(groupItems, searchTerm));
		}
	}, [groupItems, searchTerm]);

	useEffect(() => {
		apiRefreshIntegrationGroupItems();
	}, [apiRefreshIntegrationGroupItems]);

	const refreshIntegrationGroupItems = useCallback(async () => {
		try {
			await apiRefreshIntegrationGroupItems();
		} catch {
			showNotification({
				title: 'Error refreshing groups',
				message: 'Please contact support if the issue persists.',
				color: 'red',
				icon: <Icon name="x" />,
			});
		}
	}, [apiRefreshIntegrationGroupItems]);

	const handleVisibilityChange = useCallback(
		async (item: IGroupItem, visible: boolean) => {
			const descendants = getAllDescendants(item);
			const newState = descendants.reduce(
				(acc, descendant) => ({
					...acc,
					[descendant.databuilder_id]: {
						visible,
					},
				}),
				groupSettingsState
			);

			setGroupSettingsState(newState);

			try {
				await apiUpdateIntegrationGroupSettings({
					group_settings: newState,
				});
			} catch {
				showNotification({
					message: 'Unable to update group settings',
					color: 'red',
					icon: <Icon name="x" />,
				});
			}
		},
		[apiUpdateIntegrationGroupSettings, groupSettingsState]
	);

	const isVisible = useCallback(
		(node: IGroupItem) => {
			if (spec.type === 'builtin' && spec.value.schemaSupport) {
				const descendants = getAllDescendants(node).filter(
					(d) => d.databuilder_id !== node.databuilder_id
				);

				// If there are no descendants, check the visibility of the current node
				if (descendants.length === 0) {
					return (groupSettingsState[node.databuilder_id]?.visible ?? true)
						? VisibilityState.VISIBLE
						: VisibilityState.HIDDEN;
				}

				const allVisible = descendants.every(
					(descendant) =>
						groupSettingsState[descendant.databuilder_id]?.visible ?? true
				);
				const someVisible = descendants.some(
					(descendant) =>
						groupSettingsState[descendant.databuilder_id]?.visible ?? true
				);

				if (allVisible) {
					return VisibilityState.VISIBLE;
				}
				if (someVisible) {
					return VisibilityState.INDETERMINATE;
				}
				return VisibilityState.HIDDEN;
			}

			if (
				node.databuilder_id in groupSettingsState &&
				groupSettingsState[node.databuilder_id]
			) {
				return groupSettingsState[node.databuilder_id].visible
					? VisibilityState.VISIBLE
					: VisibilityState.HIDDEN;
			}

			return VisibilityState.VISIBLE;
		},
		[groupSettingsState, groupItems]
	);

	const handleSearch = useCallback(
		(newSearchTerm: string) => {
			setSearchTerm(newSearchTerm);
		},
		[setSearchTerm]
	);

	const isLoading = useMemo(
		() =>
			isLoadingGroupItems ||
			isLoadingRefreshSupport ||
			isLoadingGroupSettings ||
			isRefreshing,
		[
			isLoadingGroupItems,
			isLoadingRefreshSupport,
			isLoadingGroupSettings,
			isRefreshing,
		]
	);

	return (
		<Stack h="100%">
			<Group spacing="sm" noWrap>
				<SearchBox
					variant="tertiary"
					placeholder="Search"
					onSearch={handleSearch}
				/>
				{supportRefresh && (
					<Button
						variant="default"
						size="md"
						leftIconName="refresh"
						onClick={refreshIntegrationGroupItems}
					>
						Refresh
					</Button>
				)}
			</Group>
			<Box h="100%" pos="relative">
				<LoadingOverlay visible={isLoading} />
				<TableVirtuoso
					className={classes.table}
					style={{ height: '100%', width: '100%' }}
					data={filteredGroupItems}
					fixedHeaderContent={() => (
						<tr>
							<th>Name</th>
						</tr>
					)}
					itemContent={(_, item) => (
						<SelectPanelTableRow
							item={item}
							isVisible={isVisible}
							handleVisibilityChange={handleVisibilityChange}
						/>
					)}
				/>
			</Box>
		</Stack>
	);
}
