import { Drawer } from '@mantine/core';
import { Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';
import { ISecodaEntity } from '../../api';
import type { SecodaEntity } from '../../lib/models';
import { EmbeddedQuestions } from './EmbeddedQuestions';
import { embeddedQuestionsStore } from './store';

interface IEmbeddedQuestionsDrawerProps {
	entity: SecodaEntity | ISecodaEntity;
}

function EmbeddedQuestionsDrawer({ entity }: IEmbeddedQuestionsDrawerProps) {
	const onClose = () => {
		embeddedQuestionsStore.setOpen(false);
	};

	return (
		<Drawer
			title={
				<Text size="md" weight="bold">
					Questions
				</Text>
			}
			position="right"
			opened={embeddedQuestionsStore.open}
			onClose={onClose}
			padding="xl"
			size="xl"
		>
			{/* Only render on open to trigger the `usePromise` */}
			{embeddedQuestionsStore.open && (
				<Suspense fallback={null}>
					<EmbeddedQuestions entity={entity} />
				</Suspense>
			)}
		</Drawer>
	);
}

export default observer(EmbeddedQuestionsDrawer);
