import { createStyles } from '@mantine/core';
import { getColor } from '@repo/theme/utils';

export const useStyles = createStyles((theme) => ({
	header: {
		backgroundColor: getColor('surface/secondary/default'),
		padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.xl}`,
		borderBottom: `1px solid ${getColor('border/secondary/default')}`,
	},
	body: {
		padding: 0,
	},
	wrapper: {
		borderBottom: `1px solid ${getColor('border/secondary/default')}`,
		gap: 0,
	},
	leftSection: {
		width: theme.other.width.lg,
		height: '100%',
		padding: theme.spacing.md,
		gap: theme.spacing.md,
		borderRight: `1px solid ${getColor('border/secondary/default')}`,
	},
	rightSection: {
		alignSelf: 'flex-start',
		width: theme.other.width.sm,
	},
	input: {
		padding: `${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing.sm}`,
		border: `0.5px solid ${getColor('border/secondary/default')}`,
		borderRadius: theme.radius.sm,
		backgroundColor: getColor('surface/input/default'),
		'&:hover': {
			backgroundColor: getColor('surface/input/hover'),
		},
		'&:focus': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
		'&:active': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
	},
	footer: {
		padding: theme.spacing.md,
		flexDirection: 'row-reverse',
	},
	collapsableWrapper: {
		padding: 0,
	},
	commentsHeaderWrapper: {
		padding: `${theme.spacing['2xs']} ${theme.spacing.xs} ${theme.spacing['2xs']} 0`,
	},
	rejectedByWrapper: {
		flexWrap: 'nowrap',
		alignItems: 'flex-start',
		gap: theme.spacing.sm,
	},
}));
