import { Menu } from '@mantine/core';
import { Button, IconButton } from '@repo/foundations';
import {
	IUserGroup,
	queryClient,
	useDeleteUserGroup,
	userGroupsQueryKeyFactory,
	useUserGroupList,
} from '../../../api';
import { openConfirmModal, openModal } from '../../ModalManager';
import { TableV2 } from '../../TableV2';
import { useAvatarColumn, useUserGroupTitle } from './GroupsTable.hooks';
import { GroupsTitle } from './GroupsTitle';

export function GroupTable() {
	const userGroupTitle = useUserGroupTitle();
	const userAvatarColumn = useAvatarColumn();

	const { mutateAsync } = useDeleteUserGroup({});

	return (
		<TableV2<IUserGroup>
			withAdditionalButtons={
				<Button
					variant="primary"
					onClick={() => {
						openModal({
							title: 'Create group',
							children: <GroupsTitle />,
						});
					}}
				>
					Create a group
				</Button>
			}
			withStickyColumnBorder={false}
			withHeader={true}
			withSearch={true}
			withFilters={false}
			pluralTypeString="groups"
			withInteractiveHeader={false}
			usePaginationList={useUserGroupList}
			columns={[
				userGroupTitle,
				userAvatarColumn,
				{
					accessor: 'actions',
					title: 'Actions',
					textAlignment: 'right',
					render: (row) => (
						<Menu withinPortal position="bottom-end">
							<Menu.Target>
								<IconButton iconName="dots" variant="tertiary" />
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item
									onClick={async () => {
										openModal({
											title: 'Edit group',
											children: <GroupsTitle group={row} />,
										});
									}}
								>
									Edit
								</Menu.Item>
								<Menu.Item
									onClick={async () => {
										openConfirmModal({
											title: 'Remove group',
											children: `Are you sure you want to delete the group "${row.name}"?`,
											labels: {
												confirm: 'Delete',
												cancel: 'Cancel',
											},
											onConfirm: async () => {
												await mutateAsync({ id: row.id });
												queryClient.invalidateQueries(
													userGroupsQueryKeyFactory.all()
												);
											},
											onCancel: () => {},
										});
									}}
								>
									Remove
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					),
				},
			]}
		/>
	);
}
