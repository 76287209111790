import { Stack } from '@mantine/core';
import { spotlight } from '@mantine/spotlight';
import { useKeyPress } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import type { IQuestion } from '../../api';
import { useTeamEmptyState } from '../../api/hooks/team/emptyStates';
import { useTemplateList } from '../../api/hooks/templates';
import { type ButtonDetails, EmptyState } from '../../components/EmptyState';
import { entityModalStore } from '../../components/EntityModal/store';

import type { FetchModelInfiniteListHook } from '../../api/factories/types';
import { useCatalogInfiniteList } from '../../api/hooks/resourceCatalog';
import { TableV2 } from '../../components/TableV2';
import type { OnCellClickHandlerParams } from '../../components/TableV2/types';
import { useEntityBreadcrumbTeam } from '../../hooks/useEntityBreadcrumbs';
import { EntityType } from '../../lib/types';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './TeamQuestionsPage.hooks';

function TeamQuestionsPage() {
	const { classes } = useStyles();
	const { teamId } = useParams();
	const navigate = useNavigate();

	const emptyState = useTeamEmptyState(teamId as string, 'question');

	const columns = useColumns();
	const actions = useActions();

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	useEffect(() => {
		// If the user opens a entity page from this context we want the team
		// breadcrumb to match the team of this page.
		if (teamId) {
			setTeamBreadcrumb(teamId);
		}
	}, [teamId]);

	const { data: defaultTemplates } = useTemplateList({
		filters: {
			is_default: true,
		},
		options: {
			// Disable suspense, in case the default template is not found. We do not
			// want to trigger a suspense fallback error.
			suspense: false,
		},
	});

	useKeyPress(['shift.p'], () => {
		spotlight.open();
	});

	const handleCreateQuestionClick = useCallback(async () => {
		entityModalStore.setEntity('create', EntityType.question, teamId, false);
		const defaultTemplate = defaultTemplates?.results?.[0];
		if (defaultTemplate) {
			entityModalStore.setTemplate(defaultTemplate);
		}
		entityModalStore.setOpened(true);
	}, [defaultTemplates?.results, teamId]);

	const buttons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Learn more',
				action: () => {
					window.open(
						'https://docs.secoda.co/features/ask-questions-in-secoda',
						'_blank'
					);
				},
				isPrimary: false,
				size: 'md',
			},
			{
				name: 'Create a question',
				action: handleCreateQuestionClick,
				isPrimary: true,
				size: 'md',
			},
		],
		[handleCreateQuestionClick]
	);

	const handleCellClick = useCallback(
		({ column, record }: OnCellClickHandlerParams<IQuestion>) => {
			if (column.navigate !== false) {
				navigate(`/questions/${record.id}`);
			}
		},
		[navigate]
	);

	if (emptyState?.is_empty) {
		return (
			<EmptyState
				illustrationName="questions"
				title="Welcome to Questions"
				description="Foster collaboration and communication around data. Ask questions and share insights to make data-driven decisions"
				includeGoBack={false}
				buttons={buttons}
				stateHeight="80vh"
				size="lg"
			/>
		);
	}

	return (
		<Stack className={classes.wrapper}>
			<TableV2<IQuestion>
				withCustomProperties={EntityType.question}
				pluralTypeString="questions"
				withCsvExport
				withInfiniteScroll
				withCheckbox
				withInteractiveHeader
				onCellClick={handleCellClick}
				usePaginationList={
					useCatalogInfiniteList as FetchModelInfiniteListHook<IQuestion>
				}
				columns={columns}
				withActions={actions}
				withQuickActions={[
					'actions::assignee',
					'actions::assignee_group',
					'actions::priority',
					'actions::status',
					'actions::sidebar',
				]}
				columnVisibility={{
					catalogType: EntityType.question,
					catalogServerType: EntityType.question,
				}}
				withSearch
				defaultRequiredSearchParams={{
					team_id: teamId,
					entity_type: EntityType.question,
				}}
			/>
		</Stack>
	);
}

export default observer(TeamQuestionsPage);
