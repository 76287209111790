import { Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import type { ICollection } from '../../api';
import { useAuthUser } from '../../api';
import { useTeamEmptyState } from '../../api/hooks/team/emptyStates';
import type { ButtonDetails } from '../../components/EmptyState';
import { EmptyState } from '../../components/EmptyState';
import { entityModalStore } from '../../components/EntityModal/store';

import type {
	FetchModelInfiniteListHook,
	FetchModelList,
} from '../../api/factories/types';
import {
	fetchCatalogList,
	useCatalogInfiniteList,
} from '../../api/hooks/resourceCatalog';
import { TableV2 } from '../../components/TableV2';
import type { OnCellClickHandlerParams } from '../../components/TableV2/types';
import { useEntityBreadcrumbTeam } from '../../hooks/useEntityBreadcrumbs';
import { EntityType } from '../../lib/types';
import { useHomepageTeam } from '../HomePage/hooks/useHomepageTeam';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './TeamCollectionsPage.hooks';

function TeamCollectionsPage() {
	const { teamId } = useParams();
	const { classes } = useStyles();

	const { user, isAdminUser, isViewerOrGuestUser } = useAuthUser();
	const { isTeamEditorUser } = useHomepageTeam();

	const columns = useColumns();
	const actions = useActions();

	const navigate = useNavigate();

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	useEffect(() => {
		// If the user opens a entity page from this context we want the team
		// breadcrumb to match the team of this page.
		if (teamId) {
			setTeamBreadcrumb(teamId);
		}
	}, [setTeamBreadcrumb, teamId]);

	const handleNewCollection = useCallback(() => {
		entityModalStore.setEntity('create', EntityType.collection, teamId);
		entityModalStore.setMetadata('owners', [user.id]);
	}, [teamId, user.id]);

	const emptyState = useTeamEmptyState(teamId as string, 'collection', {
		enabled: !isViewerOrGuestUser,
	});

	const buttons: ButtonDetails[] = [
		{
			name: 'Learn more',
			action: () => {
				window.open('https://docs.secoda.co/features/collections-1', '_blank');
			},
			isPrimary: false,
			size: 'md',
			leftIconName: 'externalLink',
		},
		...(isTeamEditorUser || isAdminUser
			? [
					{
						name: 'Create a collection',
						action: handleNewCollection,
						isPrimary: true,
						size: 'md',
					} as ButtonDetails,
				]
			: []),
	];

	const handleCellClick = useCallback(
		({ column, record }: OnCellClickHandlerParams<ICollection>) => {
			if (column.navigate !== false) {
				navigate(`/collections/${record.id}`);
			}
		},
		[navigate]
	);

	if (!isViewerOrGuestUser) {
		if (emptyState?.is_empty) {
			return (
				<EmptyState
					illustrationName="collections"
					title="Welcome to Collections"
					description="Curate relevant data and resources in collections for easy sharing and discoverability across your team"
					includeGoBack={false}
					buttons={buttons}
					stateHeight="80vh"
					size="lg"
				/>
			);
		}
	}

	return (
		<Stack className={classes.wrapper}>
			<TableV2<ICollection>
				withCustomProperties={EntityType.collection}
				withQuickActions={[
					'actions::collections',
					'actions::pii',
					'actions::verified',
					'actions::sidebar',
					'actions::ai',
					'actions::delete',
				]}
				nestingFilter="collections"
				withCsvExport
				withCheckbox
				withInteractiveHeader
				withSearch
				columns={columns}
				withActions={actions}
				onCellClick={handleCellClick}
				withInfiniteScroll
				fetchPaginationList={
					fetchCatalogList as unknown as FetchModelList<ICollection>
				}
				usePaginationList={
					useCatalogInfiniteList as FetchModelInfiniteListHook<ICollection>
				}
				columnVisibility={{
					catalogType: EntityType.document,
					catalogServerType: EntityType.document,
				}}
				defaultRequiredSearchParamsNesting={{
					entity_type: EntityType.collection,
					calculate_children_count: true,
				}}
				defaultRequiredSearchParams={{
					team_id: teamId,
					entity_type: EntityType.collection,
					calculate_children_count: true,
				}}
				defaultRequiredCatalogFilters={{
					operands: [
						{
							operator: 'not',
							field: 'collections',
							operands: [],
						},
					],
				}}
			/>
		</Stack>
	);
}

export default observer(TeamCollectionsPage);
