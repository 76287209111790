import { includes, isNil } from 'lodash-es';
import type { LineageEntityMetadata } from '../../../../api';
import { EntityType } from '../../../../lib/types';
import type { LineageTypes } from '../../types';

export const getMetadataText = (
	types: LineageTypes,
	metadata: LineageEntityMetadata
) => {
	let metadataText = '';

	if (types.entity === EntityType.table) {
		const tableMetadata = [];
		if (metadata.database) {
			tableMetadata.push(metadata.database);
		}
		if (metadata.schema) {
			tableMetadata.push(metadata.schema);
		}
		metadataText = tableMetadata.join(' / ');
	}
	if (types.entity === EntityType.column) {
		const columnMetadata = [];
		if (metadata.database) {
			columnMetadata.push(metadata.database);
		}
		if (metadata.schema) {
			columnMetadata.push(metadata.schema);
		}
		if (metadata.table) {
			columnMetadata.push(metadata.table);
		}
		metadataText = columnMetadata.join(' / ');
	}
	if (types.entity === EntityType.dashboard) {
		const dashboardMetadata = [];
		if (metadata.product) {
			dashboardMetadata.push(metadata.product);
		}
		if (metadata.group) {
			dashboardMetadata.push(metadata.group);
		}
		metadataText = dashboardMetadata.join(' / ');
	}
	if (types.entity === EntityType.chart) {
		metadataText = metadata.group || '';
	}
	if (types.entity === EntityType.job) {
		metadataText = metadata.group || '';
	}

	return metadataText;
};

export const isTestRunSuccessful = (status?: string) => {
	if (isNil(status)) {
		return false;
	}
	return includes(
		['success', 'successful', 'pass', 'passed'],
		status.toLowerCase()
	);
};
