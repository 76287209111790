import { Box } from '@mantine/core';
import { IconActivity } from '@tabler/icons-react';
import React from 'react';
import {
	QUESTION_PRIORITY_ICON_NAMES,
	QUESTION_PRIORITY_MAP,
} from '../../../../api';

import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';

interface PrioritySelectorProps {
	onChange?: (value: string) => void;
	initialValue?: string;
}

export function PrioritySelector({
	onChange,
	initialValue,
}: PrioritySelectorProps) {
	const [selected, setSelected] = React.useState<string>(initialValue ?? '');

	const priorities = Object.entries(QUESTION_PRIORITY_MAP).map((priority) => ({
		label: priority[1] as string,
		value: priority[0].toUpperCase(),
		iconName: QUESTION_PRIORITY_ICON_NAMES[priority[1]],
	}));

	const handleChange = (value: string) => {
		setSelected(value);
		onChange?.(value);
	};

	return (
		<Box maw={256}>
			<SingleSelector
				placeholder="Priority"
				placeholderIcon={IconActivity}
				variant={selected ? 'default' : 'tertiary'}
				iconType="emoji"
				isViewerUser={false}
				options={priorities}
				onChange={handleChange}
				property="priority"
				searchable
				initialSelected={selected}
			/>
		</Box>
	);
}
