import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import type { Reaction } from '../../api';
import { authHeaders } from '../../api/common';
import { SecodaEntity } from './entity';
import type { User } from './user';
import type { UserGroup } from './userGroup';

export class Question extends SecodaEntity {
	static CLASS_NAME = 'Question';

	// @ts-expect-error TS(2564): Property 'id' has no initializer and is not defini... Remove this comment to see the full error message
	id: string;

	// @ts-expect-error TS(2564): Property 'assigned_to' has no initializer and is n... Remove this comment to see the full error message
	assigned_to: User | string;

	// @ts-expect-error TS(2564): Property 'assigned_to_group' has no initializer an... Remove this comment to see the full error message
	assigned_to_group: UserGroup | string;

	// @ts-expect-error TS(2564): Property 'answer' has no initializer and is not de... Remove this comment to see the full error message
	answer: string;

	// @ts-expect-error TS(2564): Property 'status' has no initializer and is not de... Remove this comment to see the full error message
	status: string;

	// @ts-expect-error TS(2564): Property 'priority' has no initializer and is not ... Remove this comment to see the full error message
	priority: string;

	// @ts-expect-error TS(2564): Property 'votes_sum' has no initializer and is not... Remove this comment to see the full error message
	votes_sum: number;

	// @ts-expect-error TS(2564): Property 'current_user_vote' has no initializer an... Remove this comment to see the full error message
	current_user_vote: number;

	reactions: Reaction[] | undefined;

	constructor(obj: Question) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get namespace() {
		return ['question', 'questions'];
	}

	static get fields() {
		return SecodaEntity.fields.concat([
			'priority',
			'status',
			'reactions',
			'answer',
			'assigned_to',
			'assigned_to_group',
			'votes_sum',
			'current_user_vote',
		]);
	}

	upvote() {
		return axios.post(`${this.apiPath}upvote/`, {}, authHeaders());
	}
}
