import { IconButton } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useAuthUser } from '../../../api';
import { useAiEnabled } from '../../../hooks/useAIEnabled';
import { trackEvent } from '../../../utils/analytics';
import { entitySideBarStore } from '../../EntityPageLayout/store';

export const AIAssistantSidebarToggle = observer(() => {
	const { user, workspace } = useAuthUser();
	const { enableAi } = useAiEnabled();

	const location = useLocation();
	const { collapsed, mode, setCollapsed, setMode } = entitySideBarStore;

	if (!enableAi) {
		return null;
	}

	const handleToggle = () => {
		if (collapsed) {
			trackEvent(
				'ai/sidebar/open',
				{ url: location.pathname },
				user,
				workspace
			);
			setCollapsed(false);
			setMode('ai');
		} else if (mode === 'ai') {
			setCollapsed(!entitySideBarStore.collapsed);
		} else {
			setMode('ai');
		}
	};

	const isOpen = !collapsed && mode === 'ai';

	return (
		<IconButton
			tooltip={`${isOpen ? 'Hide' : 'Show'} Secoda AI`}
			onClick={handleToggle}
			highlight={isOpen}
			iconName={isOpen ? 'sparklesFilled' : 'sparkles'}
			variant="tertiary"
		/>
	);
});
