// NOTE: ideally when we revisit this component we avoid Mantine's text and `Button` component.
// eslint-disable-next-line no-restricted-imports
import { Button, createStyles, Text } from '@mantine/core';
import { IconCaretUp } from '@tabler/icons-react';
import type { MouseEvent } from 'react';
import { useState } from 'react';
import type { Question } from '../../../lib/models';

interface IEmbeddedQuestionsButtonProps {
	question: Question;
}

const useStyles = createStyles(() => ({
	upvoteButton: {
		padding: '2px 6px',
		'.mantine-Button-inner': {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
		'.mantine-Button-icon': {
			margin: 0,
		},
	},
}));

function EmbeddedQuestionsButton({ question }: IEmbeddedQuestionsButtonProps) {
	const [votes, setVotes] = useState(question.votes_sum);
	const [voted, setVoted] = useState(question.current_user_vote === 1);

	const { classes } = useStyles();

	const variant = voted ? 'light' : 'subtle';
	const color = voted ? 'blue' : 'gray';

	const handleUpvote = (event: MouseEvent) => {
		event.stopPropagation();

		// eslint-disable-next-line no-underscore-dangle
		let _votes = votes;

		if (voted) {
			_votes -= 1;
		} else {
			_votes += 1;
		}

		// eslint-disable-next-line no-underscore-dangle
		const _voted = !voted;
		setVotes(_votes);
		setVoted(_voted);

		// This is to cache the upvote values in between navigations.
		question.votes_sum = _votes;
		question.current_user_vote = _voted ? 1 : 0;

		question.upvote();
	};

	return (
		<Button
			className={classes.upvoteButton}
			variant={variant}
			color={color}
			leftIcon={<IconCaretUp size={12} />}
			onClick={handleUpvote}
		>
			<Text size="xs">{votes}</Text>
		</Button>
	);
}

export default EmbeddedQuestionsButton;
