import { createStyles, Group, Stack } from '@mantine/core';
import {
	ApiPaths,
	apiQueryKey,
	useGetIntegrationExcludedTitles,
	useSetExcludedExcludedTitles,
} from '@repo/api-codegen';
import {
	FilterDropdownType,
	FilterOperator,
	FilterOption,
} from '@repo/common/components/Filter/types.ts';
import { Text } from '@repo/foundations';
import { useMemo } from 'react';
import { IIntegration, queryClient } from '../../../../../api';
import {
	AddFilter,
	Filter,
	FilterOptionType,
	FilterValue,
} from '../../../../Filter';
import { FILTER_OPTIONS_CONFIG } from '../../../../Filter/constants.tsx';

const CUSTOM_TITLE_FILTER_OPTION: FilterOption = {
	label: 'Title',
	type: FilterOptionType.TITLE,
	field: 'title',
	filterDropdownConfig: {
		dropdownType: FilterDropdownType.String,
		defaultOperator: FilterOperator.Contains,
		inputPlaceholder: 'Enter a title',
		hasIsNotSetOption: false,
	},
};

interface IntegrationExclusionFiltersProps {
	integration: IIntegration;
}

const useStyles = createStyles((theme) => ({
	card: {
		padding: theme.spacing.md,
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.md,
	},
}));

export function IntegrationExclusionFilters({
	integration,
}: IntegrationExclusionFiltersProps) {
	const { classes } = useStyles();

	const { data: excludeFilters } = useGetIntegrationExcludedTitles({
		pathParams: {
			integrationId: integration.id,
		},
	});
	const { mutateAsync: updateExcludedTitles } = useSetExcludedExcludedTitles({
		onSuccess: () => {
			queryClient.invalidateQueries(
				apiQueryKey(
					`integration/integrations/${integration.id}/excluded-titles` as ApiPaths
				)
			);
		},
	});

	const filterValues = useMemo(
		() =>
			excludeFilters?.map((value: string) => {
				const filterValue: FilterValue = {
					value,
					operator: FilterOperator.Contains,
					filterType: FilterOptionType.TITLE,
				};
				return filterValue;
			}),
		[excludeFilters]
	);

	const handleOnChange =
		(previousValue: FilterValue) => (value: Partial<FilterValue>) => {
			const updatedExcludeFilters = excludeFilters?.map((filter) => {
				if (filter === previousValue.value) {
					return value.value as string;
				}
				return filter;
			});

			updateExcludedTitles({
				pathParams: {
					integrationId: integration.id,
				},
				body: updatedExcludeFilters,
			});
		};

	const handleOnClear = (value: FilterValue) => () => {
		const updatedExcludeFilters = excludeFilters?.filter(
			(filter) => filter !== value.value
		);

		updateExcludedTitles({
			pathParams: {
				integrationId: integration.id,
			},
			body: updatedExcludeFilters,
		});
	};

	const handleOnAddFilter = (value: FilterValue) => {
		updateExcludedTitles({
			pathParams: {
				integrationId: integration.id,
			},
			body: [...(excludeFilters || []), value.value as string],
		});

		return {
			value: value,
			changeFilter: handleOnChange(value),
			clearFilter: handleOnClear(value),
		};
	};

	return (
		<Stack spacing="lg">
			<Stack spacing="md" className={classes.card}>
				<Group position="apart">
					<Text size="sm">
						<strong>Exclude </strong>
						resources that match any of the following filters
					</Text>
				</Group>
				<Group spacing="xs">
					{filterValues?.map((value, idx) => (
						<Filter
							/* eslint-disable-next-line react/no-array-index-key */
							key={`filter-${idx}}`}
							filterOption={CUSTOM_TITLE_FILTER_OPTION}
							value={value}
							onChange={handleOnChange(value)}
							onClear={handleOnClear(value)}
							showDetailedLabel
							operatorConfig={{
								getOperators: () => [FilterOperator.Contains],
								getLabel: (operator) => operator.toString(),
							}}
						/>
					))}
					<AddFilter
						options={[FILTER_OPTIONS_CONFIG[FilterOptionType.TITLE]]}
						onAddFilter={handleOnAddFilter}
					/>
				</Group>
			</Stack>
		</Stack>
	);
}
