import { Checkbox, Stack, useMantineTheme } from '@mantine/core';
import { map } from 'lodash-es';
import type { FormFieldValue, FormFieldValues } from '../../../../../api';

interface IFormCheckboxFieldProps {
	initialValue: string[];
	updateValue: (values: FormFieldValues) => void;
	isReadOnly: boolean;
	options?: FormFieldValue[];
}

export default function FormCheckboxField({
	initialValue,
	updateValue,
	isReadOnly,
	options,
}: IFormCheckboxFieldProps) {
	const theme = useMantineTheme();

	const handleCheckboxChange = (values: string[]) => {
		updateValue({
			values: map(values, (v) => ({
				value: v,
			})),
		});
	};

	return (
		<Checkbox.Group value={initialValue} onChange={handleCheckboxChange}>
			<Stack spacing={theme.spacing['2xs']}>
				{map(options, (option: FormFieldValue) => (
					<Checkbox
						label={option.value}
						value={option.value}
						disabled={isReadOnly}
					>
						{option.value}
					</Checkbox>
				))}
			</Stack>
		</Checkbox.Group>
	);
}
