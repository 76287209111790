import { createStyles, Menu } from '@mantine/core';
import {
	Breadcrumbs,
	BreadcrumbsSkeleton,
	Button,
	Icon,
	IconButton,
	type IconNames,
} from '@repo/foundations';
import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import type { ISecodaEntity, Monitor } from '../../../api';
import { useDeleteMonitor, useUpdateMonitor } from '../../../api';
import {
	measurementsQueryKeyFactory,
	monitorsQueryKeyFactory,
} from '../../../api/hooks/monitoring/constants';
import { AIAssistantSidebarToggle } from '../../../components/AIAssistant';
import RunMonitors from '../../../components/BulkTableActions/components/RunMonitors';
import EntityPageAsideCollapse from '../../../components/EntityPageAsideCollapse/EntityPageAsideCollapse';
import EntityPageNavBar from '../../../components/EntityPageLayout/EntityPageNavBar';
import DeleteModal from '../../../components/ModalDelete/DeleteModal';
import { closeAllModals, openModal } from '../../../components/ModalManager';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarSidesheetControlsWrapper,
	NavBarWrapper,
} from '../../../components/SecodaAppShell/SecodaNavBar';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { buildResourceUrl } from '../../../utils/navigationUtils';

const useStyles = createStyles(() => ({
	content: {
		padding: 0,
	},
}));

type IMonitorPageNavbarProps = {
	monitor?: Monitor;
	targetEntity?: ISecodaEntity;
};

function MonitorPageNavbar({ monitor, targetEntity }: IMonitorPageNavbarProps) {
	const { classes } = useStyles();
	const queryClient = useQueryClient();
	const { monitoringV2 } = useFeatureFlags();

	const navigate = useNavigate();
	const canEdit = monitor?.permissions?.edit || false;
	const canRun = monitor?.permissions?.run || false;

	const { mutateAsync: updateMonitor } = useUpdateMonitor({});

	const handleUpdateMonitor = async (data: Partial<Monitor>) => {
		if (!monitor?.id) {
			return;
		}

		await updateMonitor({
			data: {
				id: monitor.id,
				metric_type: monitor.metric_type,
				...data,
			},
		});
	};

	const { mutateAsync: deleteMonitor } = useDeleteMonitor({
		options: {
			onSuccess: async () => {
				await queryClient.invalidateQueries(monitorsQueryKeyFactory.allLists());

				if (!targetEntity) {
					navigate('/monitors');
					return;
				}

				const url = buildResourceUrl(targetEntity);
				navigate(`${url}/monitors`);
			},
		},
	});

	const toggleMonitorEnabled = async () => {
		if (!monitor?.id) {
			return;
		}

		await handleUpdateMonitor({ is_enabled: !monitor.is_enabled });
	};

	const handleMonitorRunSuccess = async () => {
		if (!monitor?.id) {
			return;
		}

		await queryClient.invalidateQueries(
			monitorsQueryKeyFactory.byId(monitor?.id)
		);
		await queryClient.invalidateQueries(monitorsQueryKeyFactory.all());
		await queryClient.invalidateQueries(measurementsQueryKeyFactory.allLists());
	};

	const deleteMonitorFn = async (): Promise<void> => {
		if (monitor?.id) {
			await deleteMonitor({
				id: monitor?.id,
			});
		}

		closeAllModals();
	};

	const handleMonitorDelete = async () => {
		if (monitor?.id) {
			openModal({
				variant: 'default',
				title: 'Delete monitor',
				children: (
					<DeleteModal
						text="This can't be undone."
						onClose={closeAllModals}
						onConfirm={() => deleteMonitorFn()}
					/>
				),
				classNames: {
					body: classes.content,
				},
			});
		}
	};

	const [monitorEnabledLabel] = monitor?.is_enabled
		? ['Turn off', 'playerPause' as IconNames]
		: ['Turn on monitor'];

	const actions = (
		<>
			<Menu position="bottom-end">
				<Menu.Target>
					<IconButton
						tooltip="More controls"
						iconName="dots"
						variant="tertiary"
					/>
				</Menu.Target>
				<Menu.Dropdown>
					{Boolean(monitor?.is_enabled) && (
						<Menu.Item
							icon={<Icon name="playerPause" />}
							onClick={toggleMonitorEnabled}
							disabled={!canEdit}
						>
							{monitorEnabledLabel}
						</Menu.Item>
					)}
					<Menu.Item
						disabled={!canEdit}
						icon={<Icon name="trash" color="icon/critical/default" />}
						onClick={handleMonitorDelete}
					>
						Delete
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>

			{monitor?.is_enabled ? (
				<RunMonitors
					variant="primary"
					monitor={monitor}
					disabled={!canRun}
					onSuccess={handleMonitorRunSuccess}
				/>
			) : (
				<Button
					variant="primary"
					leftIconName="playerPause"
					onClick={toggleMonitorEnabled}
					disabled={!canEdit}
				>
					{monitorEnabledLabel}
				</Button>
			)}
		</>
	);

	const toggles = (
		<>
			<AIAssistantSidebarToggle />
			<EntityPageAsideCollapse />
		</>
	);

	if (monitoringV2) {
		return (
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					{monitor === undefined ? (
						<BreadcrumbsSkeleton />
					) : (
						<Breadcrumbs
							crumbs={[
								{ title: 'Monitors', href: '/monitors' },
								{ title: monitor.name || '', href: `/monitor/${monitor.id}` },
							]}
						/>
					)}
				</NavBarBreadcrumbWrapper>
				<NavBarRightSideWrapper>{actions}</NavBarRightSideWrapper>
				<NavBarSidesheetControlsWrapper>
					{toggles}
				</NavBarSidesheetControlsWrapper>
			</NavBarWrapper>
		);
	} else {
		// Monitoring V1 Navbar
		return (
			<EntityPageNavBar
				entity={targetEntity as never}
				actions={actions}
				toggles={toggles}
			/>
		);
	}
}

export default observer(MonitorPageNavbar);
