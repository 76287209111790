import {
	Box,
	Group,
	Breadcrumbs as MantineBreadcrumbs,
	Menu,
	Stack,
	createStyles,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Buttons';

export const useStyles = createStyles((theme) => ({
	root: {
		width: 'fit-content',
	},
	separator: {
		marginLeft: theme.other.space[0.25],
		marginRight: theme.other.space[0.25],
	},
	button: {
		fontSize: theme.other.typography.text.sm,
		lineHeight: theme.other.typography.lineHeight.text.sm,
	},
	buttonInner: {
		justifyContent: 'left',
	},
}));

export interface BreadcrumbCrumb {
	title: string;
	href: string;
	icon?: string;
	onClick?: () => void;
}

interface BreadcrumbProps {
	crumb: BreadcrumbCrumb;
	onClick?: () => void;
}

function Breadcrumb({ crumb, onClick }: BreadcrumbProps) {
	const { classes } = useStyles();
	return (
		<Button
			size="md"
			className={classes.button}
			classNames={{ inner: classes.buttonInner }}
			component={Link}
			to={crumb.href}
			onClick={onClick}
			variant="tertiary"
		>
			{crumb.icon ? (
				<Group spacing="xs" noWrap>
					<span>{crumb.icon}</span>
					<span>{crumb.title}</span>
				</Group>
			) : (
				<>{crumb.title}</>
			)}
		</Button>
	);
}

interface TruncatedBreadcrumbsProps {
	crumbs: Array<BreadcrumbCrumb>;
	onBreadcrumbClick?: () => void;
}

function TruncatedBreadcrumbs({
	crumbs,
	onBreadcrumbClick,
}: TruncatedBreadcrumbsProps) {
	const { classes } = useStyles();

	return (
		<Menu position="bottom">
			<Menu.Target>
				<Button size="md" className={classes.button} variant="tertiary">
					...
				</Button>
			</Menu.Target>
			<Menu.Dropdown>
				<Stack spacing={0}>
					{crumbs.map((crumb, index) => (
						<Breadcrumb
							key={`${crumb.title}-${index}`}
							crumb={crumb}
							onClick={onBreadcrumbClick}
						/>
					))}
				</Stack>
			</Menu.Dropdown>
		</Menu>
	);
}

interface BreadcrumbsProps {
	crumbs: Array<BreadcrumbCrumb>;
	'data-testid'?: string;
	onBreadcrumbClick?: () => void;
}

export function Breadcrumbs({
	crumbs,
	'data-testid': dataTestID,
	onBreadcrumbClick,
}: BreadcrumbsProps) {
	const { classes } = useStyles();

	if (crumbs.length === 0) {
		return null;
	}

	const breadCrumbLabels = crumbs.map((curr) => curr.title).join('|||');
	const breadCrumbLinks = crumbs.map((curr) => curr.href).join('|||');

	const { ref: containerRef, width: containerWidth } = useElementSize();
	const { ref: breadcrumbRef, width: breadcrumbWidth } = useElementSize();
	const [truncatedItems, setTruncatedItems] = useState<number>(0);
	const [stabilized, setStabilized] = useState<boolean>(false);

	useEffect(() => {
		if (containerWidth === 0) {
			// The `useElementSize` hook needs to initalize before we get a real width
			return;
		}

		if (
			breadcrumbWidth > containerWidth &&
			truncatedItems < crumbs.length - 1
		) {
			// We don't have enough space for all of the breadcrumbs, and still have
			// more breadcrumbs that we could be truncating.
			setTruncatedItems((trncatedItms) => trncatedItms + 1);
		} else {
			setStabilized(true);
		}

		// Note: we don't "untruncate things". If more space becomes available we won't expand.

		// The resizing takes a cycle, if we include the `truncatedItems` in the
		// deps here we will truncate all items in any case where any truncation
		// would take place.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [containerWidth, breadcrumbWidth]);

	return (
		<Box
			ref={containerRef}
			w="100%"
			styles={{ opacity: stabilized ? '1' : '0' }}
		>
			<MantineBreadcrumbs
				ref={breadcrumbRef}
				data-testid={dataTestID}
				data-breadcrumblabels={breadCrumbLabels}
				data-breadcrumblinks={breadCrumbLinks}
				classNames={{
					root: classes.root,
					separator: classes.separator,
				}}
			>
				{crumbs.map((crumb, index) => {
					if (index === 0) {
						return (
							<Breadcrumb
								key={`${crumb.title}-${index}`}
								crumb={crumb}
								onClick={onBreadcrumbClick}
							/>
						);
					} else if (index === truncatedItems) {
						return (
							<TruncatedBreadcrumbs
								key="truncated"
								crumbs={crumbs.slice(1, truncatedItems + 1)}
								onBreadcrumbClick={onBreadcrumbClick}
							/>
						);
					} else if (index > truncatedItems) {
						return (
							<Breadcrumb
								key={`${crumb.title}-${index}`}
								crumb={crumb}
								onClick={onBreadcrumbClick}
							/>
						);
					}
				})}
			</MantineBreadcrumbs>
		</Box>
	);
}
