import { Group, createStyles } from '@mantine/core';

interface NavBarRightSideWrapperProps {
	children: React.ReactNode;
}
export const useStyles = createStyles((theme) => ({
	rightSideWrapper: {
		gap: theme.spacing['3xs'],
		flexWrap: 'nowrap',
	},
}));

export function NavBarRightSideWrapper({
	children,
}: NavBarRightSideWrapperProps) {
	const { classes } = useStyles();
	return <Group className={classes.rightSideWrapper}>{children}</Group>;
}
