import { filter, map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';

import SelectorSearch from '@repo/common/components/MultiSelector/SelectorSearch';
import { FilterValue } from '../FilterCarousel.constants';
import { SearchFilterStoreContext } from '../store';
import FilterOptionItem from './FilterOptionItem';
import { showFilterOption } from './utils';

function FilterOptionsDropdown() {
	const searchFilterStore = useContext(SearchFilterStoreContext);
	const { searchFilters } = searchFilterStore;
	const [searchTerm, setSearchTerm] = useState('');

	const filteredMenuItems = filter(Object.values(FilterValue), (filterValue) =>
		showFilterOption(
			searchTerm,
			searchFilters[FilterValue.NATIVE_TYPE].selectedOptions,
			searchFilters[filterValue]
		)
	);

	return (
		<>
			<SelectorSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
			{map(filteredMenuItems, (option) => (
				<FilterOptionItem key={option} item={searchFilters[option]} />
			))}
		</>
	);
}

export default observer(FilterOptionsDropdown);
