import { Divider, SimpleGrid, Stack, createStyles } from '@mantine/core';
import { Filter } from '@repo/api-codegen';
import { useNavigateHandler } from '@repo/common/hooks/useNavigate';
import { isEmpty } from 'lodash-es';
import type { MouseEvent } from 'react';
import type { ApiCatalogSort, ISecodaEntity } from '../../../../../../../api';
import { useCatalogList } from '../../../../../../../api/hooks/resourceCatalog';
import { SortValue } from '../../../../../../../components/Filter';
import { buildResourceUrl } from '../../../../../../../utils/navigationUtils';
import type { IWidgetItemProps, ResourceWidgetTypes } from '../../../types';
import type { ICollectionSelectorDropdownProps } from './CollectionSelectorDropdown';
import EmptyCollectionWidget from './EmptyCollectionWidget';
import EmptyResourcesWidget from './EmptyResourcesWidget';
import { ResourceWidgetItem } from './ResourcesWidget';

const useStyles = createStyles((theme) => ({
	resource: {
		cursor: 'pointer',
		borderRadius: theme.radius.xs,
		'&:hover': {
			backgroundColor: theme.colors.gray[1],
		},
	},
}));

function CollectionWidget({
	widget,
	onCollectionChange,
}: IWidgetItemProps & ICollectionSelectorDropdownProps) {
	const { classes } = useStyles();

	const navigateHandler = useNavigateHandler();

	const widgetType = widget.type as ResourceWidgetTypes;
	const defaultSort: ApiCatalogSort = {
		field: SortValue.POPULARITY,
		order: 'desc',
	};
	let filters: { filter: Filter; sort: ApiCatalogSort } = {
		filter: { operator: 'and', operands: [] },
		sort: defaultSort,
	};
	if (widget?.linked_entity) {
		filters.filter.operands.push({
			field: 'collections',
			operator: 'exact',
			operands: [],
			value: widget.linked_entity,
		});
	}
	const { data: entities } = useCatalogList({
		filters,
		options: {
			select: ({ results }) => results,
			enabled: true,
			suspense: false,
		},
	});

	const handleNavigateToResource =
		(resource: ISecodaEntity) => (event: MouseEvent) => {
			const url = buildResourceUrl(resource);
			navigateHandler(event)(url);
		};

	if (!widget.linked_entity) {
		return (
			<EmptyCollectionWidget
				color={widget.color}
				onCollectionChange={onCollectionChange}
			/>
		);
	}

	if (isEmpty(entities)) {
		return <EmptyResourcesWidget widgetType={widgetType} />;
	}

	return (
		<SimpleGrid cols={1} spacing={0}>
			{entities?.map((resource) => (
				<Stack
					key={resource?.id}
					pt="sm"
					px="sm"
					spacing="sm"
					className={classes.resource}
					onClick={handleNavigateToResource(resource)}
				>
					<ResourceWidgetItem entity={resource} />
					<Divider />
				</Stack>
			))}
		</SimpleGrid>
	);
}

export default CollectionWidget;
