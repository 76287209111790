/* eslint-disable no-await-in-loop */
import type { Filter } from '@repo/api-codegen';
import { isNil } from 'lodash-es';
import {
	ILineage,
	ILineageEntityChildren,
	ILineageTableQuery,
	ILineageTableTest,
	queryClient,
} from '../../api';
import { apiClient, getEndpoints } from '../../api/common';
import {
	LINEAGE_NAMESPACE,
	lineageQueryKeyFactory,
} from '../../api/hooks/lineage';
import type { LineageDirectionEnum } from '../../components/LineageGraph/types';
import { delay } from '../../components/LineageGraph/utils';
import { LINEAGE_QUERY_OPTIONS } from './constants';

export const getLineage = async (
	id?: string,
	direction?: LineageDirectionEnum,
	filter?: Filter
): Promise<ILineage[]> => {
	if (isNil(id) || isNil(direction)) {
		return [];
	}

	const results = [];
	let page = 1;
	let hasNextPage = true;

	const fetchPage = async (currentPage: number) => {
		const endpoint = getEndpoints(LINEAGE_NAMESPACE).root();
		return queryClient.fetchQuery({
			queryKey: lineageQueryKeyFactory.list(currentPage, {
				id,
				direction,
				filter,
				page: currentPage,
			}),
			queryFn: () =>
				apiClient.get(endpoint, {
					params: { id, direction, filter, page: currentPage },
				}),
		});
	};

	while (hasNextPage) {
		if (page > 10) {
			await delay(100);
		}

		const response = await fetchPage(page);

		results.push(...response.data.results);
		hasNextPage = !!response.data.meta.next_page;
		page += 1;
	}

	return results;
};

const getLineageEntitiesForIds = async (
	ids: string[],
	path: 'children' | 'table-tests' | 'table-creation-queries'
) => {
	const results = [];
	let page = 1;
	let hasNextPage = true;

	const fetchPage = async (currentPage: number) => {
		const endpoint = getEndpoints(LINEAGE_NAMESPACE).byPath([path]);
		return queryClient.fetchQuery({
			queryKey: lineageQueryKeyFactory.byArgs(path, {
				tableIds: ids,
				page: currentPage,
			}),
			queryFn: () =>
				apiClient.post(
					endpoint,
					{
						ids: ids.join(','),
					},
					{
						params: { page: currentPage },
					}
				),
			...LINEAGE_QUERY_OPTIONS,
		});
	};

	while (hasNextPage) {
		if (page > 10) {
			await delay(100);
		}

		const response = await fetchPage(page);

		results.push(...response.data.results);
		hasNextPage = !!response.data.meta.next_page;
		page += 1;
	}

	return results;
};

export const getEntityChildren = async (
	ids: string[]
): Promise<ILineageEntityChildren[]> =>
	getLineageEntitiesForIds(ids, 'children');

export const getTableTests = async (
	ids: string[]
): Promise<ILineageTableTest[]> => getLineageEntitiesForIds(ids, 'table-tests');

export const getTableCreationQueries = async (
	ids: string[]
): Promise<ILineageTableQuery[]> =>
	getLineageEntitiesForIds(ids, 'table-creation-queries');
