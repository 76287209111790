import { Anchor, Divider, Stack } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { Button, Text } from '@repo/foundations';
import { useFormik } from 'formik';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import type { IIntegration } from '../../api';
import {
	useImportJSONLMetadataIntegration,
	useImportMetadataIntegration,
} from '../../api';
import type { Integration } from '../../lib/models';

import { resetAllListCache } from '../../lib/stores/ModelStore.functions';

export const CSVImport = observer(
	(props: { integration: Integration | IIntegration }) => {
		const { integration: i } = props;
		const integration = toJS(i);

		const integrationId = integration.id;

		const { mutateAsync: importMetadata } =
			useImportMetadataIntegration(integrationId);

		const navigate = useNavigate();

		const csvUploadForm = useFormik<{ file: File | null }>({
			initialValues: {
				file: null,
			},
			validationSchema: Yup.object({
				file: Yup.mixed().required('Csv file is equired'),
			}),
			onSubmit: async ({ file }, { resetForm }) => {
				if (file) {
					await importMetadata(file);
					navigate(`/integrations/${integrationId}/syncs`);
					resetForm();
					resetAllListCache();
				}
			},
		});

		return (
			<form onSubmit={csvUploadForm.handleSubmit}>
				<Stack spacing={0}>
					<Text fw={500}>Option 1: Tables CSV</Text>
					<Text size="xs">
						Import tables and columns from a csv. Format can be found
						<Anchor
							inline
							target="_blank"
							href="https://docs.secoda.co/integrations/custom-integration#generate-a-csv-of-your-data"
						>
							{' here.'}
						</Anchor>
					</Text>
					<Stack spacing={8}>
						<Dropzone
							accept={['text/csv']}
							multiple={false}
							onDrop={(files) => {
								csvUploadForm.setFieldValue('file', files[0]);
							}}
						>
							<Stack align="center" spacing={4}>
								<Button>Select file</Button>
								{csvUploadForm.values.file && (
									<Text size="xs" color="text/secondary/default">
										{csvUploadForm.values.file.name}
									</Text>
								)}
							</Stack>
						</Dropzone>

						<Button
							w="fit-content"
							type="submit"
							variant="primary"
							disabled={!csvUploadForm.isValid || !csvUploadForm.dirty}
						>
							Import csv
						</Button>
					</Stack>
				</Stack>
			</form>
		);
	}
);

export const JSONLRecordImport = observer(
	(props: { integration: Integration | IIntegration }) => {
		const { integration: i } = props;
		const integration = toJS(i);

		const integrationId = integration.id;

		const { mutateAsync: importJSONLMetadata } =
			useImportJSONLMetadataIntegration(integrationId);

		const navigate = useNavigate();

		const jsonlUploadForm = useFormik<{
			resourcesFile: File | null;
			lineagesFile: File | null;
		}>({
			initialValues: {
				resourcesFile: null,
				lineagesFile: null,
			},
			validationSchema: Yup.object({
				resourcesFile: Yup.mixed().required('Csv file is required'),
				lineagesFile: Yup.mixed(),
			}),
			onSubmit: async ({ resourcesFile, lineagesFile }, { resetForm }) => {
				if (resourcesFile) {
					await importJSONLMetadata({ resourcesFile, lineagesFile });
					navigate(`/integrations/${integrationId}/syncs`);
					resetForm();
				}
			},
		});

		return (
			<form onSubmit={jsonlUploadForm.handleSubmit}>
				<Stack spacing={0}>
					<Text fw={500}>Option 2: Newline-delimited JSON (JSONL) records</Text>
					<Text size="xs">
						Import any resource and lineage data from JSONL files. Instruction
						and format can be found
						<Anchor
							inline
							target="_blank"
							href="https://docs.secoda.co/integrations/custom-integration/import-your-resources/create-your-jsonl-file"
						>
							{' here.'}
						</Anchor>
					</Text>
					<Stack spacing={8}>
						<Dropzone
							multiple={false}
							onDrop={(files) => {
								jsonlUploadForm.setFieldValue('resourcesFile', files[0]);
							}}
						>
							<Stack align="center" spacing={4}>
								<Button>Select resources file (required)</Button>
								{jsonlUploadForm.values.resourcesFile && (
									<Text size="xs" color="text/secondary/default">
										{jsonlUploadForm.values.resourcesFile.name}
									</Text>
								)}
							</Stack>
						</Dropzone>
						<Dropzone
							multiple={false}
							onDrop={(files) => {
								jsonlUploadForm.setFieldValue('lineagesFile', files[0]);
							}}
						>
							<Stack align="center" spacing={4}>
								<Button>Select lineages file</Button>
								{jsonlUploadForm.values.lineagesFile && (
									<Text size="xs" color="text/secondary/default">
										{jsonlUploadForm.values.lineagesFile.name}
									</Text>
								)}
							</Stack>
						</Dropzone>

						<Button
							w="fit-content"
							variant="primary"
							type="submit"
							disabled={!jsonlUploadForm.isValid || !jsonlUploadForm.dirty}
						>
							Import resources and lineages
						</Button>
					</Stack>
				</Stack>
			</form>
		);
	}
);

export const IntegrationImport = observer(
	(props: { integration: Integration | IIntegration }) => {
		const { integration } = props;

		return (
			<Stack>
				<Divider />
				<CSVImport integration={integration} />
				<Divider />
				<JSONLRecordImport integration={integration} />
			</Stack>
		);
	}
);
