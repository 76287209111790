import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';

import { Stack } from '@mantine/core';
import { useCallback, useEffect, useMemo } from 'react';
import type { IDictionaryTerm } from '../../api';
import { useAuthUser } from '../../api';
import type {
	FetchModelInfiniteListHook,
	FetchModelList,
} from '../../api/factories/types';
import {
	fetchCatalogList,
	useCatalogInfiniteList,
} from '../../api/hooks/resourceCatalog';
import { useTeamEmptyState } from '../../api/hooks/team/emptyStates';
import type { ButtonDetails } from '../../components/EmptyState';
import { EmptyState } from '../../components/EmptyState';
import { entityModalStore } from '../../components/EntityModal/store';
import { TableV2 } from '../../components/TableV2';
import type { OnCellClickHandlerParams } from '../../components/TableV2/types';
import { useEntityBreadcrumbTeam } from '../../hooks/useEntityBreadcrumbs';
import { EntityType } from '../../lib/types';
import { useHomepageTeam } from '../HomePage/hooks/useHomepageTeam';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './TeamDictionaryTermsPage.hooks';

function TeamDictionaryTermsPage() {
	const { teamId } = useParams();
	const { user, isAdminUser, isViewerOrGuestUser } = useAuthUser();
	const { isTeamEditorUser } = useHomepageTeam();

	const { classes } = useStyles();

	const columns = useColumns();
	const actions = useActions();

	const navigate = useNavigate();

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	useEffect(() => {
		// If the user opens a entity page from this context we want the team
		// breadcrumb to match the team of this page.
		if (teamId) {
			setTeamBreadcrumb(teamId);
		}
	}, [teamId]);

	const handleCreateDictionaryTermClick = useCallback(async () => {
		entityModalStore.setEntity('create', EntityType.dictionary_term, teamId);
		entityModalStore.setMetadata('owners', [user.id]);
	}, [teamId, user.id]);

	const emptyState = useTeamEmptyState(teamId as string, 'dictionary_term', {
		enabled: !isViewerOrGuestUser,
	});

	const handleCellClick = useCallback(
		({ column, record }: OnCellClickHandlerParams<IDictionaryTerm>) => {
			if (column.navigate !== false) {
				navigate(`/dictionary/${record.id}`);
			}
		},
		[navigate]
	);

	const buttons: ButtonDetails[] = useMemo(
		() => [
			{
				name: 'Learn more',
				action: () => {
					window.open('https://docs.secoda.co/features/dictionary', '_blank');
				},
				isPrimary: false,
				size: 'md',
				leftIconName: 'externalLink',
			},
			...(isTeamEditorUser || isAdminUser
				? [
						{
							name: 'Create a dictionary term',
							action: handleCreateDictionaryTermClick,
							isPrimary: true,
							size: 'md',
						} as ButtonDetails,
					]
				: []),
		],
		[handleCreateDictionaryTermClick, isAdminUser, isTeamEditorUser]
	);

	if (!isViewerOrGuestUser) {
		if (emptyState?.is_empty) {
			return (
				<EmptyState
					illustrationName="dictionary"
					title="Welcome to Dictionary"
					description="Keep a consistent set of shared data terms across your team with the dictionary."
					includeGoBack={false}
					buttons={buttons}
					stateHeight="80vh"
					size="lg"
				/>
			);
		}
	}

	return (
		<Stack className={classes.wrapper}>
			<TableV2<IDictionaryTerm>
				withCustomProperties={EntityType.dictionary_term}
				pluralTypeString="terms"
				withCsvExport
				withInfiniteScroll
				withQuickActions={[
					'actions::sidebar',
					'actions::ai',
					'actions::verified',
					'actions::delete',
				]}
				usePaginationList={
					useCatalogInfiniteList as FetchModelInfiniteListHook<IDictionaryTerm>
				}
				fetchPaginationList={
					fetchCatalogList as unknown as FetchModelList<IDictionaryTerm>
				}
				withCheckbox
				withInteractiveHeader
				withSearch
				columns={columns}
				withActions={actions}
				onCellClick={handleCellClick}
				columnVisibility={{
					catalogType: EntityType.dictionary_term,
					catalogServerType: EntityType.dictionary_term,
				}}
				nestingFilter="parent_id"
				defaultRequiredSearchParamsNesting={{
					entity_type: EntityType.dictionary_term,
					calculate_children_count: true,
				}}
				// Don't show nested terms at the root level.
				defaultRequiredCatalogFilters={{
					operands: [
						{
							operator: 'not',
							field: 'parent_id',
							operands: [],
						},
					],
				}}
				defaultRequiredSearchParams={{
					team_id: teamId,
					entity_type: EntityType.dictionary_term,
					calculate_children_count: true,
				}}
			/>
		</Stack>
	);
}

export default observer(TeamDictionaryTermsPage);
