import { Group, Stack } from '@mantine/core';
import { Button } from '@repo/foundations';
import { Navigate } from 'react-router';
import { useAuthUser } from '../../api';
import { useAutomationList } from '../../api/hooks/automation';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import AddAutomationButton from '../../components/Automation/AddAutomationButton';
import { EmptyState } from '../../components/EmptyState';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { UpgradeButton } from '../../components/Settings/UpgradeButton';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import AutomationList from './AutomationList';

function AutomationListPage() {
	const { classes, theme } = useStyles();
	const { workspace } = useAuthUser();
	const { automationsAccess, isLoading: isFeatureAccessLoading } =
		useFeatureAccess();

	const { data: automations, isLoading } = useAutomationList({});

	if (!workspace.automation_enabled) {
		return <Navigate to="/" />;
	}

	if (isLoading) {
		return <FullWidthLoadingSpinner />;
	}

	if (!automationsAccess) {
		return (
			<EmptyState
				illustrationName="upgrade"
				title="Upgrade to access Automations"
				description="Automatically set tags, owners, property values, and so much more with automations."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={
					<UpgradeButton
						tooltip="Upgrade to access Automations"
						feature="Automations"
						size="md"
					/>
				}
			/>
		);
	}

	const actions = (
		<Group>
			<Button
				component="a"
				target="_blank"
				rel="noreferrer noopener"
				size="md"
				href="https://docs.secoda.co/features/automations"
				leftIconName="externalLink"
			>
				Learn more
			</Button>
			<AddAutomationButton />
		</Group>
	);

	if (automations && automations.results.length === 0) {
		if (isFeatureAccessLoading) {
			return <FullWidthLoadingSpinner />;
		}

		return (
			<EmptyState
				className={classes.emptySpace}
				imgSrc="/images/empty-state/automation.webp"
				imgWidth={theme.other.space[40]}
				title="Automate your data documentation"
				description="Automatically set tags, owners, property values, and so much more with automations."
				includeGoBack={false}
				stateHeight="80vh"
				size="lg"
				withActions={actions}
			/>
		);
	}

	return (
		<Stack className={classes.wrapper}>
			<AutomationList />
		</Stack>
	);
}

export default AutomationListPage;
