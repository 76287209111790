import { IFilterSelection } from '@repo/common/interfaces/filterSelection';
import { map, mapValues } from 'lodash-es';
import type {
	FilterMenuItem,
	FilterValue,
	SearchFilters,
} from '../FilterCarousel.constants';

/**
 * Converts the search filters to the format used by the view menu
 */
export function parseSearchFiltersToView(
	searchFilters: SearchFilters
): Record<FilterValue, IFilterSelection> {
	const selectedFilters = mapValues(
		searchFilters,
		(filterMenuItem: FilterMenuItem) =>
			({
				isInclude: filterMenuItem.isInclude,
				selectedOptionValues: map(filterMenuItem.selectedOptions, 'value'),
			}) as IFilterSelection
	);
	return selectedFilters;
}
