import type { CSSObject } from '@mantine/core';
import { createStyles } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useDebouncedValue } from '@mantine/hooks';
import { Icon } from '@repo/foundations';
import { getColor } from '@repo/theme/utils';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import type { FormFieldValues } from '../../../../../api';

interface IFormDateField {
	initialValue?: string;
	updateValue: (values: FormFieldValues) => void;
	isReadOnly: boolean;
}

const useStyles = createStyles((theme) => ({
	root: {
		flexGrow: '1 !important' as CSSObject['flexGrow'],
	},
	input: {
		padding: `${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing.sm}`,
		border: `0.5px solid ${getColor('border/secondary/default')}`,
		borderRadius: theme.radius.sm,
		backgroundColor: getColor('surface/input/default'),
		'&:hover': {
			backgroundColor: getColor('surface/input/hover'),
		},
		'&:focus': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
		'&:active': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
	},
}));

export default function FormDateField({
	initialValue,
	updateValue,
	isReadOnly,
}: IFormDateField) {
	const { classes } = useStyles();

	const parseDate = (dateString: string | undefined) => {
		if (!isNil(dateString) && dayjs(dateString).isValid()) {
			return new Date(dateString);
		}
		return null;
	};

	const [date, setDate] = useState<Date | null>(parseDate(initialValue));
	const [debouncedDate] = useDebouncedValue(date, 500);

	useEffect(() => {
		if (!isNil(debouncedDate) && debouncedDate !== parseDate(initialValue)) {
			updateValue({ values: [{ value: debouncedDate?.toISOString() }] });
		}
	}, [debouncedDate]);

	return (
		<DatePickerInput
			classNames={{
				root: classes.root,
				input: classes.input,
			}}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore poorly typed component
			placeholder="Pick a date"
			value={date}
			valueFormat="YYYY-MM-DD"
			onChange={setDate}
			size="md"
			icon={<Icon name="calendar" />}
			clearable
			weekendDays={[]}
			getDayProps={() => ({
				sx: (theme) => ({
					'&:focus, &:active': {
						backgroundColor: theme.other.getColor('fill/brand/selected'),
						color: theme.other.getColor('text/brand-on-fill/default'),
						radius: theme.radius.sm,
					},
				}),
			})}
			popoverProps={{
				position: 'bottom-start',
			}}
			disabled={isReadOnly}
		/>
	);
}
