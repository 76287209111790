import { Box, createStyles, Divider, Group, Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { type ReactNode } from 'react';
import {
	RIGHT_SIDEBAR_TRANSITION_DURATION,
	RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION,
	TOP_NAVBAR_HEIGHT,
} from '../SecodaAppShell/constants';
import ResizeEntityPageSidebar from './ResizeEntitySidebar';
import type { EntitySidebarMode } from './store';
import { entitySideBarStore } from './store';

const useStyles = createStyles(
	(
		theme,
		{ collapsed, withDivider }: { collapsed: boolean; withDivider: boolean }
	) => ({
		asideWrapper: {
			width: `calc(${entitySideBarStore.width}px - ${withDivider ? 1 : 0}px)`,
			minWidth: `calc(${entitySideBarStore.width}px - ${withDivider ? 1 : 0})`,
			height: `calc(100vh - ${TOP_NAVBAR_HEIGHT}px)`,
			scrollbarColor: `${theme.other.getColor(
				'fill/tertiary/default'
			)} transparent`,
			scrollbarWidth: 'thin',
			overflowY: 'auto',
			scrollbarGutter: 'stable',
			transitionProperty: 'width',
			transitionTimingFunction: RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION,
			transitionDuration: RIGHT_SIDEBAR_TRANSITION_DURATION,
		},
		wrapper: {
			height: '100%',
			width: collapsed ? 0 : entitySideBarStore.width,
			opacity: collapsed ? 0 : 1,
			transform: `translateX(${collapsed ? entitySideBarStore.width : 0}px)`,
			transitionProperty: 'width',
			transitionTimingFunction: RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION,
			transitionDuration: RIGHT_SIDEBAR_TRANSITION_DURATION,

			'@media print': {
				display: 'none',
			},
		},
		divider: {
			position: 'absolute',
			height: '100vh',
			transitionDelay: '200ms',
		},
	})
);

export interface IEntityPageSidebarWrapperProps {
	classNames?: Record<string, string>;
	children?: ReactNode;
	mode?: EntitySidebarMode;
}

function EntityPageSidebarWrapper({
	classNames,
	children,
	mode,
}: IEntityPageSidebarWrapperProps) {
	const collapsed = mode
		? entitySideBarStore.mode !== mode || !!entitySideBarStore.collapsed
		: !!entitySideBarStore.collapsed;

	const { classes, cx } = useStyles({
		collapsed,
		withDivider: true,
	});

	return (
		<Group
			className={cx(classes.wrapper, classNames?.wrapper)}
			spacing={0}
			noWrap
			data-testid="entity-sidebar-wrapper"
		>
			<Divider className={classes.divider} orientation="vertical" />
			<Stack spacing={0}>
				<Box h={TOP_NAVBAR_HEIGHT} />
				<Stack
					className={cx(classes.asideWrapper, classNames?.asideWrapper)}
					spacing={0}
				>
					{children}
				</Stack>
			</Stack>
			<ResizeEntityPageSidebar />
		</Group>
	);
}

export default observer(EntityPageSidebarWrapper);
