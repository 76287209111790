import { Group, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { Icon, type IconNames, Text } from '@repo/foundations';

interface IAnchorButtonProps {
	label: string;
	icon?: IconNames;
	onClick: () => void;
}

export function AnchorButton({ label, icon, onClick }: IAnchorButtonProps) {
	const { hovered, ref } = useHover<HTMLButtonElement>();

	const theme = useMantineTheme();

	const color = hovered ? 'text/emphasis/hover' : 'text/emphasis/default';

	return (
		<UnstyledButton ref={ref} w="fit-content" onClick={onClick}>
			<Group spacing={theme.spacing['4xs']}>
				{icon && <Icon name={icon} color={color} />}
				<Text size="sm" weight="semibold" color={color}>
					{label}
				</Text>
			</Group>
		</UnstyledButton>
	);
}
