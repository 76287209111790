import { createStyles, Flex, Grid, Group, Tooltip } from '@mantine/core';
import { AuditItemOut } from '@repo/api-codegen';
import { Icon, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { ResourceLink } from '../../../components/Link';
import { formatShortTimeDifference } from '../../../utils/time';

export interface IncidentAuditItemProps {
	auditItem: AuditItemOut;
	showLine?: boolean;
}

export const useStyles = createStyles((theme) => ({
	icon: {
		maxWidth: theme.other.iconSize.md + theme.other.space[2] * 2,
		display: 'flex',
	},
	content: {
		paddingLeft: 0,
		paddingX: theme.other.space[2],
	},
	line: {
		paddingTop: theme.spacing.sm,
		width: theme.other.space[0.25],
		height: `calc(100% - ${theme.other.space[7]}px)`,
		minHeight: theme.other.space[5],
		backgroundColor: theme.other.getColor('border/secondary/default'),
	},
}));

function IncidentAuditItem({
	auditItem,
	showLine = false,
}: IncidentAuditItemProps) {
	const { classes } = useStyles();

	const formattedTime = `${formatShortTimeDifference(auditItem.created_at)}`;

	let icon: ReactNode = <Icon name="alertCircleFilled" />;
	let text: ReactNode = <Text size="sm" />;

	const actorId = auditItem.actor?.id;
	const currId = auditItem.extra?.current_value;
	const prevId = auditItem.extra?.previous_value;

	switch (auditItem.event_type) {
		case 'incident.new':
			icon = <Icon name="alertCircle" color="icon/critical/default" />;
			text = <Text size="sm">Incident started</Text>;
			break;
		case 'incident.resolved':
			icon = <Icon name="circleCheck" color="icon/success/default" />;
			if (actorId) {
				text = (
					<Text size="sm">
						Incident resolved by <ResourceLink href={`/user/${actorId}`} />
					</Text>
				);
			} else {
				text = <Text size="sm">Incident was automatically resolved</Text>;
			}
			break;
		case 'incident.acknowledged':
			icon = <Icon name="circleCheck" color="icon/caution/default" />;
			text = (
				<Text size="sm">
					Incident acknowledged by <ResourceLink href={`/user/${actorId}`} />
				</Text>
			);
			break;
		case 'incident.expired':
			icon = <Icon name="circleCheck" color="icon/secondary/default" />;
			text = <Text size="sm">Incident expired</Text>;
			break;
		case 'incident.assigned_owner':
			icon = <Icon name="userPlus" />;

			if (currId === actorId) {
				text = (
					<Text size="sm">
						<ResourceLink href={`/user/${actorId}`} /> self-assigned as{' '}
						<b>owner</b>
					</Text>
				);
			} else if (prevId && currId) {
				text = (
					<Text size="sm">
						<ResourceLink href={`/user/${actorId}`} /> re-assigned{' '}
						<ResourceLink href={`/user/${currId}`} /> as <b>owner</b>
					</Text>
				);
			} else if (!prevId && currId) {
				text = (
					<Text size="sm">
						<ResourceLink href={`/user/${actorId}`} /> assigned{' '}
						<ResourceLink href={`/user/${currId}`} /> as <b>owner</b>
					</Text>
				);
			} else if (prevId && !currId) {
				text = (
					<Text size="sm">
						<ResourceLink href={`/user/${actorId}`} /> removed the <b>owner</b>
					</Text>
				);
			}
			break;
		default:
			return null;
	}

	return (
		<Grid pt={0}>
			<Grid.Col span={2} className={classes.icon}>
				<Flex direction="column" align="center" gap="3xs">
					{icon}
					{showLine && <div className={classes.line} />}
				</Flex>
			</Grid.Col>
			<Grid.Col span="auto" className={classes.content}>
				<Group spacing="xs" noWrap>
					{text}
					<Tooltip label={dayjs(auditItem.created_at).format('LLL')}>
						<Text size="xs" color="text/secondary/default" truncate>
							{formattedTime}
						</Text>
					</Tooltip>
				</Group>
			</Grid.Col>
		</Grid>
	);
}

export default IncidentAuditItem;
