import { Group, createStyles } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import type { ReactNode } from 'react';

export const useStyles = createStyles((theme) => ({
	label: {
		fontSize: theme.fontSizes.xs,
		color: theme.other.getColor('text/primary/default'),
		lineHeight: theme.spacing.lg,
	},
}));

interface FilterTargetLabelProps {
	label: string | ReactNode;
	showChevron?: boolean;
	isMenuOpen?: boolean;
	className?: string;
}

export function FilterTargetLabel({
	isMenuOpen,
	label,
	showChevron = false,
	className,
}: FilterTargetLabelProps) {
	const { classes } = useStyles();

	return (
		<Group spacing="3xs" className={className}>
			{typeof label === 'string' ? (
				<Text className={classes.label}>{label}</Text>
			) : (
				label
			)}
			{showChevron && (
				<Icon name={isMenuOpen ? 'chevronUp' : 'chevronDown'} iconPadding={0} />
			)}
		</Group>
	);
}
