import { Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import type { IMetric } from '../../api';
import { useAuthUser } from '../../api';
import type { FetchModelInfiniteListHook } from '../../api/factories/types';
import { useCatalogInfiniteList } from '../../api/hooks/resourceCatalog';
import { useTeamEmptyState } from '../../api/hooks/team/emptyStates';
import type { ButtonDetails } from '../../components/EmptyState';
import { EmptyState } from '../../components/EmptyState';
import { entityModalStore } from '../../components/EntityModal/store';
import { TableV2 } from '../../components/TableV2';
import type { OnCellClickHandlerParams } from '../../components/TableV2/types';
import { useEntityBreadcrumbTeam } from '../../hooks/useEntityBreadcrumbs';
import { EntityType } from '../../lib/types';
import { useHomepageTeam } from '../HomePage/hooks/useHomepageTeam';
import { useStyles } from '../TeamCatalogPage/TeamCatalogPage.styles';
import { useActions, useColumns } from './hooks';

function TeamMetricsPage() {
	const { teamId } = useParams();
	const { user, isAdminUser, isViewerOrGuestUser } = useAuthUser();
	const { isTeamEditorUser } = useHomepageTeam();

	const { classes } = useStyles();

	const columns = useColumns();
	const actions = useActions();

	const { setTeamBreadcrumb } = useEntityBreadcrumbTeam();

	useEffect(() => {
		// If the user opens a entity page from this context we want the team
		// breadcrumb to match the team of this page.
		if (teamId) {
			setTeamBreadcrumb(teamId);
		}
	}, [teamId]);

	const handleCreateMetricClick = useCallback(async () => {
		entityModalStore.setEntity('create', EntityType.metric, teamId);
		entityModalStore.setMetadata('owners', [user.id]);
	}, [teamId, user.id]);

	const emptyState = useTeamEmptyState(teamId as string, 'metric', {
		enabled: !isViewerOrGuestUser,
	});

	const navigate = useNavigate();

	const handleCellClick = useCallback(
		({ column, record }: OnCellClickHandlerParams<IMetric>) => {
			if (column.navigate !== false) {
				navigate(`/metrics/${record.id}`);
			}
		},
		[navigate]
	);

	const buttons: ButtonDetails[] = [
		{
			name: 'Learn more',
			action: () => {
				window.open('https://docs.secoda.co/features/metrics', '_blank');
			},
			isPrimary: false,
			size: 'md',
			leftIconName: 'externalLink',
		},
		...(isTeamEditorUser || isAdminUser
			? [
					{
						name: 'Create a metric',
						action: handleCreateMetricClick,
						isPrimary: true,
						size: 'md',
					} as ButtonDetails,
				]
			: []),
	];

	if (!isViewerOrGuestUser) {
		if (emptyState?.is_empty) {
			return (
				<EmptyState
					illustrationName="metrics"
					title="Welcome to Metrics"
					description="Centralize and share metrics within your team for clear and well informed decision making."
					includeGoBack={false}
					buttons={buttons}
					stateHeight="80vh"
					size="lg"
				/>
			);
		}
	}

	return (
		<Stack className={classes.wrapper}>
			<TableV2<IMetric>
				pluralTypeString="metrics"
				withCsvExport
				withInfiniteScroll
				usePaginationList={
					useCatalogInfiniteList as FetchModelInfiniteListHook<IMetric>
				}
				withCheckbox
				withInteractiveHeader
				withSearch
				columns={columns}
				withActions={actions}
				onCellClick={handleCellClick}
				columnVisibility={{
					catalogType: EntityType.metric,
					catalogServerType: EntityType.metric,
				}}
				defaultRequiredSearchParams={{
					parent_id__isnull: true,
					team_id: teamId,
					entity_type: EntityType.metric,
				}}
			/>
		</Stack>
	);
}

export default observer(TeamMetricsPage);
