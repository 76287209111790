import type { ISecodaEntity } from '../../api';
import type { SecodaEntity } from '../../lib/models';
import { AIAssistantSidebarToggle } from '../AIAssistant';
import CommentCollapse from '../CommentsCollapse/CommentCollapse';
import EmbeddedQuestionsButton from '../EmbeddedQuestions/EmbeddedQuestionsButton';
import EntityPageAsideCollapse from '../EntityPageAsideCollapse/EntityPageAsideCollapse';

interface INavBarEntityButtonsProps {
	entity: SecodaEntity | ISecodaEntity;
	withComment?: boolean;
	withDiscussions?: boolean;
}

function EntityPageToggles({
	entity,
	withComment,
	withDiscussions,
}: INavBarEntityButtonsProps) {
	return (
		<>
			<AIAssistantSidebarToggle />
			{withComment && <CommentCollapse />}
			<EntityPageAsideCollapse />
			{withDiscussions && <EmbeddedQuestionsButton entity={entity} />}
		</>
	);
}

export default EntityPageToggles;
