export function getFontColorFromBackgroundColor(
	backgroundColor: string
): string {
	let hex = backgroundColor;

	if (backgroundColor.startsWith('#')) {
		hex = backgroundColor.slice(1);
	}

	if (hex.length !== 6) {
		throw new Error('Invalid HEX color.');
	}

	const r = parseInt(hex.slice(0, 2), 16);
	const g = parseInt(hex.slice(2, 4), 16);
	const b = parseInt(hex.slice(4, 6), 16);

	// Based on : https://stackoverflow.com/a/946734
	const isLight = r * 0.299 + g * 0.587 + b * 0.114 > 186;

	return isLight ? '#232221' : '#FFFFFF';
}
