import {
	createStyles,
	Group,
	Menu,
	Popover,
	Stack,
	TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Button, Icon } from '@repo/foundations';
import { useCallback, useState } from 'react';

interface IAddCustomPropertyProps {
	onAddPropertyName: (name: string) => void;
}

const useStyles = createStyles({
	formWrapper: {
		width: 300,
		padding: 8,
	},
});

function AddCustomProperty({ onAddPropertyName }: IAddCustomPropertyProps) {
	const { classes } = useStyles();
	const [opened, setOpened] = useState(false);

	const form = useForm({
		initialValues: {
			customProperty: '',
		},
	});

	const handlePopoverOpen = useCallback(() => {
		setOpened(true);
	}, []);

	const handlePopoverClose = useCallback(() => {
		form.reset();
		setOpened(false);
	}, [form]);

	const handleSubmitClick = form.onSubmit(({ customProperty }) => {
		onAddPropertyName(customProperty);
		handlePopoverClose();
	});

	return (
		<Popover opened={opened} onClose={handlePopoverClose} position="bottom-end">
			<Popover.Target>
				<Menu.Item onClick={handlePopoverOpen} icon={<Icon name="plus" />}>
					Add property
				</Menu.Item>
			</Popover.Target>
			<Popover.Dropdown>
				<form onSubmit={handleSubmitClick} autoComplete="off">
					<Stack className={classes.formWrapper} spacing={24}>
						<TextInput
							autoFocus
							label="Property name"
							placeholder="Custom property"
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...form.getInputProps('customProperty')}
						/>
						<Group position="right">
							<Button onClick={handlePopoverClose}>Cancel</Button>
							<Button variant="primary" type="submit">
								Confirm
							</Button>
						</Group>
					</Stack>
				</form>
			</Popover.Dropdown>
		</Popover>
	);
}

export default AddCustomProperty;
