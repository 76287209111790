import { Avatar, Box, Group, Stack } from '@mantine/core';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { pickColorType } from '@repo/common/utils';
import { Text, Title } from '@repo/foundations';
import { isEmpty, map } from 'lodash-es';
import { useMemo } from 'react';
import type { IFormSubmission } from '../../../../../api';
import { useExtendedUserList } from '../../../../../api/hooks/user/useExtendedUserList';
import CollapsableStack from '../../../../CollapsableStack';
import {
	SelectableProperty,
	StaticProperty,
} from '../../../../EntityPageLayout/EntityPropertySidebar';
import SubmissionStatusBadge from '../SubmissionStatusBadge';
import { useStyles } from './ReviewSubmissionModal.styles';

interface IVisitorRightSectionProps {
	submission: IFormSubmission;
}

export default function VisitorRightSection({
	submission,
}: IVisitorRightSectionProps) {
	const { classes, theme } = useStyles();

	const { activeUsers } = useExtendedUserList({ suspense: false });

	const status: SelectablePropertyItem = useMemo(() => {
		switch (submission.status) {
			case 'DRAFT':
				return {
					label: 'Draft',
					value: 'DRAFT',
					color: 'fill/secondary/default',
				};
			case 'PENDING':
				return {
					label: 'Pending',
					value: 'PENDING',
					color: 'fill/warning-secondary/default',
				};
			case 'APPROVED':
				return {
					label: 'Approved',
					value: 'APPROVED',
					color: 'fill/success-secondary/default',
				};
			case 'REJECTED':
				return {
					label: 'Rejected',
					value: 'REJECTED',
					color: 'fill/critical-secondary/default',
				};
			default:
				return {
					label: 'Unknown',
					value: 'UNKNOWN',
					color: 'fill/secondary/default',
				};
		}
	}, [submission.status]);

	const rejectedBy = submission.rejected_by && submission.rejected_by[0];

	return (
		<Stack spacing={0}>
			<Stack p="xl">
				<CollapsableStack
					classNames={classes}
					groupName="Properties"
					withDivider={false}
				>
					<Stack>
						<StaticProperty
							label="Status"
							custom={<SubmissionStatusBadge status={submission.status} />}
						/>
						<SelectableProperty
							selected={map(submission.form?.owners, 'id')}
							type="multi"
							label="Reviewers"
							value="reviewers"
							iconType="avatar"
							isViewerUser
							options={
								map(activeUsers, (user) => ({
									label: user.display_name,
									value: user.id,
									icon: user.profile_picture,
									navigateTo: `/user/${user.id}`,
									strikeThrough: user.disabled,
									hidden: user.disabled,
								})) as SelectablePropertyItem[]
							}
						/>
						<SelectableProperty
							selected={submission.form?.approval_condition || ''}
							type="single"
							label="Approval Condition"
							value="approval_condition"
							iconType="none"
							isViewerUser
							options={
								[
									{
										value: 'ANY',
										label: 'Any Reviewer',
									},
									{
										value: 'ALL',
										label: 'All Reviewers',
									},
									{
										value: null,
										label: 'None',
									},
								] as SelectablePropertyItem[]
							}
						/>
					</Stack>
				</CollapsableStack>
			</Stack>
			{!isEmpty(submission.review_comment) &&
				!isEmpty(submission.rejected_by) && (
					<Stack spacing="xs" px="xl" py="md">
						<Box className={classes.commentsHeaderWrapper}>
							<Title size="sm">Comments</Title>
						</Box>
						<Group className={classes.rejectedByWrapper}>
							<Avatar
								src={rejectedBy?.profile_picture}
								alt={`${rejectedBy?.first_name} ${rejectedBy?.last_name}`}
								color={pickColorType(
									`${rejectedBy?.first_name} ${rejectedBy?.last_name}`
								)}
								size="sm"
							/>
							<Stack spacing={theme.spacing['2xs']}>
								<Title size="sm">{`${rejectedBy?.first_name} ${rejectedBy?.last_name}`}</Title>
								<Text size="sm">{submission.review_comment}</Text>
							</Stack>
						</Group>
					</Stack>
				)}
		</Stack>
	);
}
