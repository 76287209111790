import {
	Box,
	createStyles,
	getDefaultZIndex,
	getStylesRef,
} from '@mantine/core';
import { useDisclosure, useElementSize } from '@mantine/hooks';
import { Button } from '@repo/foundations';
import { ReactNode, useLayoutEffect, useState } from 'react';

const useStyles = createStyles(
	(theme, { opened, maxHeight }: { opened: boolean; maxHeight: number }) => ({
		wrapper: {
			position: 'relative',
			...(!opened
				? {
						maxHeight,
						overflow: 'hidden',
					}
				: {}),

			[`&:hover .${getStylesRef('button')}`]: {
				opacity: 1,
			},
		},
		overlay: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'end',
			pointerEvents: 'none',

			'&::after': !opened
				? {
						position: 'absolute',
						display: 'block',
						content: '""',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						background: `linear-gradient(transparent 60%, ${theme.other.getColor('surface/app/default')} 100%);`,
						cursor: 'pointer',
						zIndex: getDefaultZIndex('overlay'),
						pointerEvents: 'initial',
					}
				: {},
		},
		button: {
			ref: getStylesRef('button'),
			position: 'absolute',
			bottom: theme.spacing.md,
			zIndex: getDefaultZIndex('overlay') + 2,
			opacity: !opened ? 1 : 0,
			pointerEvents: 'initial',
			transition: 'all 100ms ease',
		},
	})
);

interface ExpandCollapseProps {
	maxHeight: number;
	children: ReactNode;
	initialOpen?: boolean;
}

export function ExpandCollapse({
	maxHeight,
	children,
	initialOpen = false,
}: ExpandCollapseProps) {
	const [loaded, setLoaded] = useState(false);
	const [opened, { toggle, open }] = useDisclosure(initialOpen);
	const { classes } = useStyles({ opened, maxHeight });
	const { ref, height } = useElementSize();

	useLayoutEffect(() => {
		if (loaded || height === 0) {
			// not ready yet
			return;
		}

		if (height < maxHeight && !opened) {
			open();
		}

		setLoaded(true);
	}, [height, maxHeight, open, loaded, setLoaded, opened]);

	return (
		<Box className={classes.wrapper}>
			<Box ref={ref}>{children}</Box>
			{(!opened || height > maxHeight) && (
				<Box className={classes.overlay} onClick={!opened ? toggle : undefined}>
					<Button
						className={classes.button}
						rightIconName={opened ? 'chevronUp' : 'chevronDown'}
						onClick={toggle}
					>
						{opened ? 'Show less' : 'Show more'}
					</Button>
				</Box>
			)}
		</Box>
	);
}
