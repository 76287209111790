import type { NodeSpec, Node as ProsemirrorNode } from 'prosemirror-model';

import type { MarkdownSerializerState } from '@repo/secoda-editor/lib/markdown/serializer';
import { ParseSpec } from 'prosemirror-markdown';
import { NodeSelection } from 'prosemirror-state';
import { BlockSkeleton } from '../components/BlockSkeleton';
import { ChartBlockContainer } from '../components/MetricContainer/ChartBlockContainer';
import type { ComponentProps } from '../lib/ComponentView';
import chartBlocksRule from '../rules/chartblocks';
import ReactNode from './ReactNode';

export default class ChartBlock extends ReactNode {
	get name() {
		return 'chart_block';
	}

	get markdownToken() {
		return 'chart_block';
	}

	get schema(): NodeSpec {
		return {
			attrs: {
				id: {
					default: '',
				},
			},
			group: 'block',
			defining: true,
			atom: true,
			draggable: false,
			parseDOM: [
				{
					preserveWhitespace: 'full',
					tag: 'div.chartblock',
					getAttrs: (dom: HTMLElement | string) =>
						typeof dom === 'string'
							? null
							: {
									id: dom.getAttribute('id'),
								},
				},
			],
			toDOM: (node) => [
				'div',
				{
					...node.attrs,
					class: 'chartblock',
					id: node.attrs.id,
				},
				node.attrs.id,
			],
		};
	}

	toMarkdown(state: MarkdownSerializerState, node: ProsemirrorNode) {
		const { id } = node.attrs;
		if (id) {
			state.write(`\n\n:;:${id}:;:\n\n`);
			state.closeBlock(node);
		}
	}

	parseMarkdown(): ParseSpec {
		return {
			block: 'chart_block',
			getAttrs: (tok) => ({
				id: tok.attrs?.[0]?.[1].split(':;:')[1],
			}),
		};
	}

	get rulePlugins() {
		return [chartBlocksRule];
	}

	commands() {
		return () => () => true;
	}

	component = ({ node, getPos, isSelected }: ComponentProps) => {
		if (!node) {
			return <BlockSkeleton height={100} isSelected={isSelected} />;
		}

		const deleteNode = () => {
			const { view } = this.editorState;
			const $pos = view.state.doc.resolve(getPos());
			const tr = view.state.tr.setSelection(new NodeSelection($pos));
			view.dispatch(tr.deleteSelection());
			view.focus();
		};

		return (
			<ChartBlockContainer
				id={node.attrs.id}
				readOnly={this.editorState.readOnly}
				onDelete={() => deleteNode()}
				isSelected={isSelected}
			/>
		);
	};
}
