import { Box, Stack, createStyles } from '@mantine/core';
import { get, set } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { IColumn, useAuthUser, useUpdateSecodaEntity } from '../../../../api';
import {
	FetchModelInfiniteListHook,
	FetchModelList,
} from '../../../../api/factories/types';
import {
	fetchCatalogList,
	useCatalogInfiniteList,
} from '../../../../api/hooks/resourceCatalog';

import { useColumnDefs } from '../../../../components/CatalogView/hooks/useColumnDefs';
import { TableV2 } from '../../../../components/TableV2';
import {
	CapitalizeTextRender,
	ColumnProfileRender,
	DescriptionRender,
} from '../../../../components/TableV2/render';
import { useGenericColumns } from '../../../../components/TableV2/SecodaEntity.hooks';
import type {
	ExtendedDataTableColumn,
	Render,
} from '../../../../components/TableV2/types';

import { FilterOptionType } from '../../../../components/Filter';
import { ICommandListItem } from '../../../../components/Spotlight/components/CommandPalette/constants';
import { CUSTOM_PROPERTY_COLUMN_PREFIX } from '../../../../constants';
import { EntityType } from '../../../../lib/types';
import { useActions as useCatalogActions } from '../../../TeamCatalogPage/TeamCatalogPage.hooks';
import type { DashboardEntityTabsStore } from '../DashboardEntityTabsStore';

export function useColumns(): ExtendedDataTableColumn<IColumn>[] {
	const genericColumns = useGenericColumns({});

	const columns = useMemo(
		() => [
			{
				accessor: 'type',
				title: 'Type',
				width: 160,
				render: (record: IColumn) => (
					<CapitalizeTextRender<IColumn>
						record={record}
						field={'type'}
						field2={'native_type'}
					/>
				),
				filterOptionType: FilterOptionType.NATIVE_TYPE,
			},
			...genericColumns,
			{
				accessor: 'properties._profile.ntiles',
				title: 'Distribution',
				sortable: false,
				filtering: false,
				explicit: true,
				width: 160,
				render: ColumnProfileRender as unknown as Render<IColumn>,
			},
		],
		[genericColumns]
	);

	return columns;
}
export const useActions = () => {
	const actions = useCatalogActions() as unknown as ICommandListItem<IColumn>[];

	return actions;
};

const useStyles = createStyles(() => ({
	gridWrapper: {
		width: '100%',
		minHeight: 350,
		flexGrow: 1,
	},
	tableWrapper: {
		height: '100%',
	},
}));

interface ChartsTabProps {
	id: string;
	store: DashboardEntityTabsStore;
}

function ChartsTab({ id, store }: ChartsTabProps) {
	const columns = useColumns();
	const actions = useActions();

	const { mutateAsync: updateColumn } = useUpdateSecodaEntity({});

	const handleChange = useCallback(
		(field: string) => (id: string) => (value: string | string[] | boolean) => {
			const nested = {};
			// Support field being a `.properties` path and turn into a nested object.
			field.split('.').reduce((acc, key, index, arr) => {
				if (index === arr.length - 1) {
					set(acc, key, value);
				} else {
					set(acc, key, {});
				}
				return get(acc, key);
			}, nested);
			return (
				updateColumn(
					{
						data: {
							id,
							...nested,
						},
					},
					{
						onSuccess: () => {},
					}
				),
				[updateColumn]
			);
		},
		[updateColumn]
	);
	const { classes } = useStyles();
	const { isEditorOrAdminUser } = useAuthUser();

	const { columnDefs } = useColumnDefs({
		defaultColumns: columns,
		catalogType: EntityType.chart,
		catalogServerType: EntityType.chart,
		isEditorOrAdminUser,
	});

	// Modify TableV2 columns with the custom properties.
	const customColumns = useMemo(() => {
		const cols = [...columns];
		columnDefs.forEach((column) => {
			if (column.field?.startsWith(CUSTOM_PROPERTY_COLUMN_PREFIX)) {
				cols.push({
					navigate: false,
					accessor: column.field,
					title: column.field,
					render: (record) => (
						<DescriptionRender
							record={record}
							field={column.field}
							onChange={handleChange(column.field)}
						/>
					),
					width: 350,
				});
			}
		});
		return cols;
	}, [columnDefs, columns, handleChange]);

	return (
		<Stack className={classes.gridWrapper}>
			<Box className={classes.tableWrapper}>
				<TableV2<IColumn>
					key={columnDefs.length}
					withSearch
					withInteractiveHeader
					withCsvExport
					withCheckbox
					withActions={actions}
					columns={customColumns}
					withInfiniteScroll
					usePaginationList={
						useCatalogInfiniteList as FetchModelInfiniteListHook<IColumn>
					}
					fetchPaginationList={
						fetchCatalogList as unknown as FetchModelList<IColumn>
					}
					columnVisibility={{
						catalogType: EntityType.chart,
						catalogServerType: EntityType.chart,
					}}
					defaultRequiredCatalogFilters={{
						operands: [
							{
								operands: [],
								field: 'parent_id',
								operator: 'exact',
								value: id,
							},
						],
					}}
					defaultRequiredSearchParams={{
						entity_type: EntityType.chart,
						calculate_children_count: true,
					}}
					defaultRequiredSearchParamsNesting={{
						entity_type: EntityType.chart,
						calculate_children_count: true,
					}}
					nestingFilter="parent_id"
				/>
			</Box>
		</Stack>
	);
}

export default observer(ChartsTab);
