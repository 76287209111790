/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
import {
	ActionIcon,
	Box,
	createStyles,
	Group,
	Menu,
	Skeleton,
	Stack,
	Tooltip,
	useMantineTheme,
} from '@mantine/core';
import { Badge, Button, Icon, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import { isEmpty } from 'lib0/object';
import { isNil, some, sortBy, uniqBy } from 'lodash-es';
import { useRef, useState } from 'react';
import { IQuestion, IQuestionReply, useAuthUser, useUser } from '../../../api';
import { useQuestionPermissions } from '../../../api/hooks/question/permissions';
import { useToggleQuestionReaction } from '../../../api/hooks/question/reaction';

import { IEmbeddedPrompt } from '../../../api';
import { AISteps } from '../../../components/AIAssistant/Messages/AISteps.tsx';
import { InlineEmoji } from '../../../components/InlineEmojiPicker/InlineEmoji';
import { InlineEmojiPicker } from '../../../components/InlineEmojiPicker/InlineEmojiPicker';
import { RichEditor } from '../../../components/RichEditor';
import { UserAvatar } from '../../../components/UserAvatar';
import { trimMarkdown } from './helpers';
import { useStyles } from './styles';

const useLocalStyles = createStyles((theme) => ({
	rightSection: {
		width: 'calc(100% - 1rem - 32px)',
		gap: 4,
	},
	userSection: {
		minHeight: 32,
		gap: 4,
		justifyContent: 'space-between',
	},
	emojiSection: {
		gap: 4,
	},
	inlineEmojiSection: {
		display: 'flex',
		gap: theme.spacing.xs,
	},
	actionGroup: {
		display: 'flex',
		width: '100%',
		justifyContent: 'flex-end',
		marginBottom: theme.spacing.xs,
	},
}));

export interface IReplyWrapperProps {
	entity: IQuestion | IQuestionReply;
	handleUpdate: (values: Record<string, unknown>) => void;
	handleDelete: VoidFunction;
	accepted?: boolean;
	type?: 'question' | 'reply';
}

export function ReplyWrapper({
	entity,
	handleUpdate,
	handleDelete,
	accepted = false,
	type = 'question',
}: IReplyWrapperProps) {
	const editorRef = useRef<string>();
	const [isEmojiOpen, setIsEmojiOpen] = useState(false);

	const { classes: localClasses } = useLocalStyles();
	const { classes } = useStyles({ accepted });
	const [editing, setEditing] = useState<boolean>(false);
	const [hovered, setHovered] = useState(false);
	const [showSteps, setShowSteps] = useState(false);

	const hasAISteps = 'ai_steps' in entity ? !!entity?.ai_steps?.length : false;
	const stepsInput =
		'ai_steps' in entity
			? ({
					response: {
						steps: entity?.ai_steps,
					},
				} as unknown as IEmbeddedPrompt)
			: undefined;

	const { canDelete, canEdit, canReact, canAccept } = useQuestionPermissions(
		entity.id,
		type
	);

	const { user: authUser } = useAuthUser();

	const { toggleReaction } = useToggleQuestionReaction(
		entity.id,
		authUser.id,
		type
	);

	const handleSave = () => {
		handleUpdate({ definition: editorRef.current });
		setEditing(false);
	};

	const toggleAccept = () => {
		const newAccepted = String(!accepted);
		handleUpdate({
			accepted_answer: newAccepted,
		});
	};

	const handleChangeCallback = (value: string | undefined) => {
		editorRef.current = value;
	};

	const handleSelectEmoji = (emoji: { native: string }) => {
		toggleReaction(emoji.native);
		setIsEmojiOpen(false);
		setHovered(false);
	};

	const theme = useMantineTheme();

	const { data: user, isLoading } = useUser({
		id: entity.owners?.[0],
		options: {
			enabled: !isNil(entity.owners?.[0]),
		},
	});

	if (isLoading && !isNil(entity.owners?.[0])) {
		return <Skeleton />;
	}

	const showName = !isEmpty(user?.first_name) || !isEmpty(user?.last_name);

	return (
		<Group
			className={classes.wrapper}
			data-testid={`${type}-wrapper-${entity.id}`}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			<Box miw={32}>{user && <UserAvatar user={user} size="md" />}</Box>
			<Stack className={localClasses.rightSection}>
				<Group className={localClasses.userSection}>
					<Group spacing={theme.other.space[2]}>
						<Text size="sm" color="text/primary/default" weight="bold">
							{showName
								? `${user?.first_name} ${user?.last_name}`
								: user?.email}
						</Text>
						<Text size="xs" color="text/secondary/default">
							{dayjs(entity.created_at).fromNow(false)}
						</Text>
					</Group>
					<Group>
						{(hovered || isEmojiOpen) && (
							<Group className={localClasses.emojiSection}>
								{hasAISteps && (
									<Tooltip label="Show AI steps">
										<ActionIcon
											onClick={() => setShowSteps((prev) => !prev)}
											size="xs"
											variant={showSteps ? 'filled' : ''}
										>
											<Icon name="listSearch" />
										</ActionIcon>
									</Tooltip>
								)}
								{canReact && (
									<InlineEmojiPicker
										onClick={handleSelectEmoji}
										onOpenChange={setIsEmojiOpen}
									/>
								)}
								{canAccept && type === 'reply' && (
									<Tooltip
										label={accepted ? 'Reopen question' : 'Mark as answer'}
									>
										<ActionIcon
											data-testid={`reply-${accepted ? 'cancel' : 'accept'}-${
												entity.id
											}`}
											onClick={toggleAccept}
											size="xs"
										>
											{accepted ? (
												<Icon name="x" color="icon/primary/default" />
											) : (
												<Icon name="check" color="icon/primary/default" />
											)}
										</ActionIcon>
									</Tooltip>
								)}
								{(canEdit || canDelete) && (
									<ActionIcon size="xs">
										<Menu shadow="md" width={200}>
											<Menu.Target>
												<Icon name="dots" color="icon/primary/default" />
											</Menu.Target>
											<Menu.Dropdown>
												{canEdit && (
													<Menu.Item
														icon={<Icon name="pencil" />}
														onClick={() => setEditing((prev) => !prev)}
													>
														Edit
													</Menu.Item>
												)}
												{canDelete && (
													<Menu.Item
														icon={<Icon name="trash" />}
														onClick={handleDelete}
													>
														Delete
													</Menu.Item>
												)}
											</Menu.Dropdown>
										</Menu>
									</ActionIcon>
								)}
							</Group>
						)}
						{accepted && (
							<Badge
								leftSection={<Icon name="check" color="icon/success/default" />}
								variant="success"
							>
								Answer
							</Badge>
						)}
					</Group>
				</Group>
				<Group className={editing ? classes.editorWrapper : undefined}>
					<Stack
						w="100%"
						className={editing ? classes.editorWhileEditing : undefined}
					>
						<RichEditor
							dataTestId={`${type}-editor-${entity.id}`}
							onChangeCallback={handleChangeCallback}
							placeholder="Edit the content..."
							initialValue={
								trimMarkdown(entity.definition || '').length > 2
									? entity.definition
									: ''
							}
							readOnly={!editing}
							disableTopGap
						/>
						{editing && (
							<Group
								className={localClasses.actionGroup}
								spacing={theme.other.space[2]}
							>
								<Button
									size="sm"
									variant="default"
									onClick={() => setEditing(false)}
								>
									Cancel
								</Button>
								<Button onClick={handleSave} size="sm" variant="primary">
									Save
								</Button>
							</Group>
						)}
					</Stack>
				</Group>
				{!editing && (
					<Box className={localClasses.inlineEmojiSection}>
						{sortBy(uniqBy(entity.reactions ?? [], 'emoji'), 'emoji')
							.filter((f) => f.users.length > 0)
							.map(({ emoji, users }) => (
								<InlineEmoji
									key={emoji}
									emoji={emoji}
									users={users}
									onToggle={handleSelectEmoji}
								/>
							))}
						{canReact &&
							some(entity.reactions ?? [], (r) => r.users.length > 0) && (
								<InlineEmojiPicker onClick={handleSelectEmoji} />
							)}
					</Box>
				)}
				{hasAISteps && showSteps && !!stepsInput && (
					<AISteps prompt={stepsInput} />
				)}
			</Stack>
		</Group>
	);
}
