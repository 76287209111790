/* eslint-disable no-use-before-define */
import { createStyles } from '@mantine/core';
import { getColor } from '@repo/theme/utils';
import { useAuthUser } from '../../api';

export const ROW_HEIGHT = 44;
export const HEADER_HEIGHT = 35;
export const FOOTER_HEIGHT = 40;

export const BREADCRUMB_HEIGHT = 52;
export const BOTTOM_PADDING = 24;
export const TAB_HEIGHT = 47;

export const rowSx = {
	height: ROW_HEIGHT,
	'&:hover': {
		background: getColor('surface/primary/hover'),
		'div[aria-label="placeholder"]': {
			display: 'block',
		},
	},
	'&:has(button[data-expanded="true"])': {
		background: getColor('surface/primary/hover'),
		'div[aria-label="placeholder"]': {
			display: 'block',
		},
	},
};

interface ITableStylesProps {
	hideCheckbox?: boolean;
	stickyColumn?: number;
	hasNestingFilter?: boolean;
	noBorder?: boolean;
	withStickyColumnBorder?: boolean;
}

export const useTableStyles = createStyles(
	(
		theme,
		{
			stickyColumn = 1,
			hideCheckbox = false,
			hasNestingFilter = false,
			noBorder = false,
			withStickyColumnBorder,
		}: ITableStylesProps
	) => {
		const { isViewerOrGuestUser } = useAuthUser();

		const stickyColumnIdentifier = `tr td:nth-of-type(${stickyColumn})`;
		const stickyHdrIdentifier = `tr th:nth-of-type(${stickyColumn})`;

		return {
			table: {},
			root: {
				// Hide hover states for viewer and guest users:
				'td .mantine-UnstyledButton-root': {
					'&:hover': {
						background: isViewerOrGuestUser ? 'none' : undefined,
					},
					'div[aria-label="placeholder"]': {
						display: isViewerOrGuestUser ? 'none' : undefined,
					},
				},
				'tr:has(input[type="checkbox"]:checked)': {
					td: {
						background: `${getColor('surface/primary/selected')} !important`,
					},
				},
				'tr:hover': {
					td: {
						background: `${getColor('surface/primary/hover')} !important`,
					},
					'td button span.mantine-Button-label': {
						visibility: 'visible',
					},
					'td button div.mantine-Text-root': {
						visibility: 'visible',
					},
				},

				// Selectively hide checkbox:
				'.mantine-Checkbox-root': {
					visibility: hideCheckbox ? 'hidden' : 'visible',
				},
				td: {
					// So badges and other React content don't overlap.
					overflow: 'hidden',
					// Let us define padding on the React component level.
					paddingTop: '0 !important',
					paddingBottom: '0 !important',
					borderBottom: noBorder ? 'none !important' : undefined,
				},
				[`${stickyColumnIdentifier}, ${stickyHdrIdentifier}`]: {
					borderRight: `1px solid ${getColor('border/secondary/default')}`,
					paddingLeft: hasNestingFilter ? theme.other.space[8] : undefined,
					borderRightWidth: withStickyColumnBorder ? '1px' : '0',
					position: 'sticky !important' as 'sticky',
					left:
						Math.max(stickyColumn - 1, 0) * theme.other.space[8] +
						// `+ theme.other.space[2]` is to account for the first column
						// left-padding, only on those with checkboxes, otherwise it's 0.
						(stickyColumn > 1 ? theme.other.space[2] : 0),
					zIndex: 1,
				},
				[stickyColumnIdentifier]: {
					background: getColor('fill/primary/default'),
				},
				[stickyHdrIdentifier]: {
					background: getColor('surface/secondary/default'),
				},
				borderRadius: noBorder ? 0 : theme.radius.md,
				borderColor: getColor('border/secondary/default'),
				'input[type="checkbox"]': {
					borderColor: getColor('border/tertiary/default'),
				},
				'div.mantine-Pagination-dots': {
					display: 'none',
				},
				'button.mantine-Pagination-control': {
					display: 'none',
					border: 'none',
				},
				'button.mantine-Pagination-control:has(svg)': {
					display: 'flex',
				},
				'thead > tr > th': {
					lineHeight: theme.spacing.md,
					backgroundColor: getColor('surface/secondary/default'),
					'&:hover': {
						backgroundColor: `${getColor('surface/secondary/hover')} !important`,
					},
					'&:has(div.mantine-Menu-dropdown)': {
						backgroundColor: getColor('surface/secondary/selected'),
					},
				},
			},
			header: {},
			pagination: {
				div: {
					fontSize: theme.fontSizes.xs,
				},
				height: theme.other.space[10],
				color: getColor('text/secondary/default'),
				backgroundColor: getColor('surface/secondary/default'),
				fontWeight: '500 !important' as unknown as number,
				button: {
					backgroundColor: getColor('surface/secondary/default'),
				},
				'button[not-disabled]:hover': {
					backgroundColor: getColor('surface/secondary/hover'),
				},
				'button[disabled]': {
					backgroundColor: getColor('surface/secondary/default'),
				},
				'button[aria-haspopup="menu"]': {
					display: 'none',
					visibility: 'hidden',
				},
			},
		};
	}
);
