import {
	Box,
	Center,
	createStyles,
	Divider,
	Group,
	Loader,
	Menu,
	Tooltip,
} from '@mantine/core';
import { Text } from '@repo/foundations';
import {
	IconCheck,
	IconDatabase,
	IconFolder,
	IconSchema,
	IconTable,
} from '@tabler/icons-react';
import { useDebounceFn } from 'ahooks';
import {
	filter,
	includes,
	isEmpty,
	isNil,
	map,
	size,
	truncate,
} from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { EmptyState } from '../../../../components/EmptyState';
import { SecodaEntityIcon } from '../../../../components/SecodaEntity';
import type { SecodaEntity } from '../../../../lib/models';
import EntityProperty from '../../SearchResults/SearchResultCardView/EntityProperty';
import { getSearchResults } from '../../utils';
import type { FilterMenuOption } from '../FilterCarousel.constants';
import {
	DEFAULT_FILTER_OPTIONS,
	FilterValue,
} from '../FilterCarousel.constants';
import { SearchFilterStoreContext } from '../store';
import RelatedFilterSearch from './RelatedFilterSearch';

const useStyles = createStyles(() => ({
	box: { maxHeight: 256, overflowY: 'auto' },
	item: {
		height: 50,
	},
}));
function RelatedFilterDropdown() {
	const searchFilterStore = useContext(SearchFilterStoreContext);

	const [searchTerm, setSearchTerm] = useState('');
	const [loading, setLoading] = useState(false);
	const [results, setResults] = useState<SecodaEntity[]>([]);

	const { run: search } = useDebounceFn(async (term: string) => {
		setLoading(true);
		const { results: searchResults } = await getSearchResults(term);

		setResults(searchResults);
		setLoading(false);
	});

	const { classes, theme } = useStyles();

	const filterOption = DEFAULT_FILTER_OPTIONS[FilterValue.RELATED];

	const { selectedOptions } = searchFilterStore.searchFilters.related;
	const selectedOptionsIds = map(selectedOptions, 'value');

	const notSelectedResults = filter(
		results,
		(r) => !includes(selectedOptionsIds, r.id)
	);

	const handleSelect = (item: SecodaEntity, shouldAdd: boolean) => {
		if (filterOption) {
			searchFilterStore.setSelectedFilterOptions(
				FilterValue.RELATED,
				{
					label: item.title_full || item.title || 'Untitled',
					value: item.id,
					data: item,
				},
				shouldAdd
			);
		}
	};

	return (
		<>
			<RelatedFilterSearch
				term={searchTerm}
				setTerm={setSearchTerm}
				search={search}
			/>
			<Box className={classes.box}>
				{map(selectedOptions, (option: FilterMenuOption) => {
					const data = option.data as SecodaEntity;

					if (isNil(data)) {
						return null;
					}

					return (
						<Menu.Item
							key={data.id}
							className={classes.item}
							icon={<SecodaEntityIcon entity={data} />}
							rightSection={
								<IconCheck size={20} color={theme.colors.gray[9]} />
							}
							onClick={() => handleSelect(data, false)}
						>
							<Tooltip label={data.title} disabled={size(data.title) <= 36}>
								<Text>{truncate(data.title, { length: 36 })}</Text>
							</Tooltip>
							<Group spacing={12}>
								<EntityProperty
									icon={IconDatabase}
									iconSize={10}
									label={data.search_metadata?.database}
									labelFontSize={10}
									labelTruncateLength={12}
									isHidden={isNil(data.search_metadata?.database)}
								/>
								<EntityProperty
									icon={IconSchema}
									iconSize={10}
									label={data.search_metadata?.schema}
									labelFontSize={10}
									labelTruncateLength={12}
									isHidden={isNil(data.search_metadata?.schema)}
								/>
								<EntityProperty
									icon={IconTable}
									iconSize={10}
									label={data.search_metadata?.table}
									labelFontSize={10}
									labelTruncateLength={12}
									isHidden={isNil(data.search_metadata?.table)}
								/>
								<EntityProperty
									icon={IconFolder}
									iconSize={10}
									label={data.search_metadata?.group}
									labelFontSize={10}
									labelTruncateLength={12}
									isHidden={isNil(data.search_metadata?.group)}
								/>
							</Group>
						</Menu.Item>
					);
				})}
				{loading ? (
					<Center h={50}>
						<Loader />
					</Center>
				) : (
					<>
						{!isEmpty(selectedOptionsIds) && !isEmpty(notSelectedResults) && (
							<Divider my={10} />
						)}
						{map(notSelectedResults, (item) => (
							<Menu.Item
								key={item.id}
								className={classes.item}
								icon={<SecodaEntityIcon entity={item} />}
								onClick={() => handleSelect(item, true)}
							>
								<Tooltip label={item.title} disabled={size(item.title) <= 36}>
									<Text>{truncate(item.title, { length: 36 })}</Text>
								</Tooltip>
								<Group spacing={12}>
									<EntityProperty
										icon={IconDatabase}
										iconSize={10}
										label={item.search_metadata?.database}
										labelFontSize={10}
										labelTruncateLength={12}
										isHidden={isNil(item.search_metadata?.database)}
									/>
									<EntityProperty
										icon={IconSchema}
										iconSize={10}
										label={item.search_metadata?.schema}
										labelFontSize={10}
										labelTruncateLength={12}
										isHidden={isNil(item.search_metadata?.schema)}
									/>
									<EntityProperty
										icon={IconTable}
										iconSize={10}
										label={item.search_metadata?.table}
										labelFontSize={10}
										labelTruncateLength={12}
										isHidden={isNil(item.search_metadata?.table)}
									/>
									<EntityProperty
										icon={IconFolder}
										iconSize={10}
										label={item.search_metadata?.group}
										labelFontSize={10}
										labelTruncateLength={12}
										isHidden={isNil(item.search_metadata?.group)}
									/>
								</Group>
							</Menu.Item>
						))}
					</>
				)}

				{size(results) === 0 && searchTerm !== '' && !loading && (
					<EmptyState
						imgSrc="/images/empty-state/catalog.svg"
						title="No results found"
						description="No resources or invalid search"
						includeGoBack={false}
						stateHeight="40vh"
						size="sm"
					/>
				)}
			</Box>
		</>
	);
}

export default observer(RelatedFilterDropdown);
