import type { DataTableColumn } from '@repo/mantine-datatable';
import { isEmpty } from 'lib0/object';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import type { ISecodaEntity } from '../../api';
import { useCatalogInfiniteList } from '../../api/hooks/resourceCatalog';
import { CATALOG_COLUMN_MAPPING } from '../../components/CatalogView/constants';
import { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import type { ITableV2Props } from '../../components/TableV2';
import { TableV2 } from '../../components/TableV2';
import { useDataQualityScoreColumns } from '../../components/TableV2/SecodaEntity.hooks';
import type { OnCellClickHandlerParams } from '../../components/TableV2/types';
import { buildResourceUrl } from '../../utils/navigationUtils';
import { useActions, useColumns } from './TeamCatalogPage.hooks.tsx';

export function CatalogTable({
	defaultRequiredSearchParams,
	columnVisibility,
	additionalActions,
	...props
}: Partial<ITableV2Props<ISecodaEntity>> & {
	additionalActions?: ICommandListItem<ISecodaEntity>[];
}) {
	const useOverrideColumns = columnVisibility?.catalogServerType
		? (CATALOG_COLUMN_MAPPING[columnVisibility?.catalogServerType]
				.tableV2ColumnsHook ?? (() => []))
		: () => [];

	const genericColumns = useColumns();
	const overrideColumns = useOverrideColumns();
	const dataQualityColumns = useDataQualityScoreColumns({
		catalogType: columnVisibility?.catalogServerType,
	});

	const columns = useMemo(() => {
		const result = (
			!isEmpty(overrideColumns)
				? [...overrideColumns, ...dataQualityColumns]
				: [...genericColumns, ...dataQualityColumns]
		) as DataTableColumn<ISecodaEntity>[];
		return result;
	}, [dataQualityColumns, genericColumns, overrideColumns]);

	const actions = useActions();

	const navigate = useNavigate();

	const handleCellClick = useCallback(
		({ column, record }: OnCellClickHandlerParams<ISecodaEntity>) => {
			if (column.navigate !== false) {
				navigate(buildResourceUrl(record));
			}
		},
		[navigate]
	);

	return (
		<TableV2<ISecodaEntity>
			pluralTypeString="resources"
			withInfiniteScroll
			usePaginationList={useCatalogInfiniteList}
			withCsvExport
			withCheckbox
			withSearch
			withInteractiveHeader
			columns={columns}
			withActions={[...actions, ...(additionalActions ?? [])]}
			withQuickActions={[
				'actions::pii',
				'actions::verified',
				'actions::sidebar',
				'actions::ai',
				'actions::delete',
			]}
			onCellClick={handleCellClick}
			columnVisibility={columnVisibility}
			defaultRequiredSearchParams={defaultRequiredSearchParams}
			additionalFilters={[
				'schema',
				'database',
				'sources',
				'schema',
				'related',
				'slack_channels__contains',
			]}
			{...props}
		/>
	);
}
