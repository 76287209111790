export function parseGitbookMarkdown(markdown: string) {
	const HINT_TEMPLATE_REGEX = /{% hint.+?%}\n((\n|.)*?){% endhint %}/g;

	const REMOVE_TEMPLATE_REGEX = /{% ([^%\s]+)(.+?)%}\n(\n|.)*?{% end\1 %}/g;
	const GITLAB_SHORTCUT_REGEX = /<a href="#h_.*<\/a>/g;
	const GITLAB_DESCRIPTOR_REGEX = /^---(\n|.)*---/g;
	const RELATIVE_LINK = '../.gitbook/assets';
	const FIGURE_REGEX = /<(\/|.)?figure>/g;
	const FIGURE_CAPTION_REGEX = /<figcaption>(.+)<\/figcaption>/g;

	const IMAGE_TAG_REGEX = /<img src="(\.\.\/)+(.+?)" alt="">/g;
	const EMBED_REGEX = /{% embed url="(.*?)" %}/g;

	// Replace hint template with markdown blockquote
	let text = markdown.replaceAll(HINT_TEMPLATE_REGEX, (match, firstGroup) =>
		firstGroup.replaceAll(/^/gm, '>')
	);

	// Remove all other templates
	text = text.replaceAll(REMOVE_TEMPLATE_REGEX, '');
	text = text.replaceAll(GITLAB_SHORTCUT_REGEX, '');
	text = text.replaceAll(GITLAB_DESCRIPTOR_REGEX, '');
	text = text.replaceAll(
		RELATIVE_LINK,
		'https://raw.githubusercontent.com/secoda/gitbook/master/.gitbook/assets'
	);

	// Remove figure and replace with image
	text = text.replaceAll(FIGURE_REGEX, '');
	text = text.replaceAll(FIGURE_CAPTION_REGEX, '');
	text = text.replace(
		IMAGE_TAG_REGEX,
		(match, firstGroup, secondGroup) =>
			`![](${secondGroup.replaceAll(/ /g, '%20')})`
	);

	// Parse embed (videos)
	text = text.replaceAll(
		EMBED_REGEX,
		(match, firstGroup) =>
			`[iframe:embed](${firstGroup}&embedWidth=450&embedHeight=220)`
	);

	return text;
}
