import { ActionIcon, createStyles } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import type { ForwardedRef } from 'react';
import { forwardRef, useContext } from 'react';
import { FilterMenuTarget } from '../../../../components/FilterMenu';
import { SearchFilterStoreContext } from '../store';

interface IViewMenuTargetProps {
	onClick?: () => void;
	setShowEditDropdown: () => void;
	standalone?: boolean;
}

interface IViewMenuTargetStyleProps {
	standalone?: boolean;
}

const useStyles = createStyles(
	(theme, { standalone }: IViewMenuTargetStyleProps) => ({
		actionIcon: {
			variant: standalone ? 'subtle' : 'outline',
			borderRadius: standalone ? theme.radius.sm : theme.radius.lg,
			height: '28px',
			width: '28px',
			borderColor: theme.other.getColor('border/primary/default'),
			color: theme.other.getColor('icon/primary/default'),
		},
	})
);

const ViewMenuTarget = forwardRef(
	(
		{
			standalone,
			onClick,
			setShowEditDropdown,
			...props
		}: IViewMenuTargetProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const { classes } = useStyles({ standalone });
		const searchFilterStore = useContext(SearchFilterStoreContext);

		const { selectedView, isAnyFilterSelected, isViewEdited } =
			searchFilterStore;

		if (!selectedView) {
			// No selected view and some filters are selected
			if (isAnyFilterSelected) {
				return (
					<FilterMenuTarget
						data-testid="view-dropdown"
						ref={ref}
						variant="primary"
						leftIcon={<Icon name="stack" />}
						onClick={() => {
							searchFilterStore.selectedView = undefined;
							setShowEditDropdown();
						}}
						{...props}
					>
						Save filters
					</FilterMenuTarget>
				);
			}
			// No selected view and no filter selected
			return (
				<ActionIcon
					{...props}
					data-testid="view-dropdown"
					ref={ref}
					variant={standalone ? 'subtle' : 'outline'}
					size="xs"
					className={classes.actionIcon}
					onClick={onClick}
				>
					<Icon name="stack" />
				</ActionIcon>
			);
		}

		// Selected view and filters are changed
		if (isViewEdited) {
			return (
				<FilterMenuTarget
					data-testid="view-dropdown"
					ref={ref}
					variant="primary"
					leftIcon={<Icon name="stack" />}
					onClick={() => {
						setShowEditDropdown();
					}}
					{...props}
				>
					{selectedView.label} (unsaved)
				</FilterMenuTarget>
			);
		}

		// Selected view and filters are not changed
		return (
			<FilterMenuTarget
				data-testid="view-dropdown"
				ref={ref}
				variant="primary"
				leftIcon={<Icon name="stack" />}
				onClick={onClick}
				{...props}
			>
				{selectedView.label}
			</FilterMenuTarget>
		);
	}
);

ViewMenuTarget.displayName = 'ViewMenuTarget';

export default observer(ViewMenuTarget);
