import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IGlossaryTerm } from '../../types';

// TODO: move from dictionary terms to glossary terms when backend is ready https://linear.app/secoda/issue/ENG-11072/fix-api-routing-from-dictionary-term-to-glossary-term

export const GLOSSARY_TERMS_NAMESPACE = ['dictionary', 'terms'];

export const glossaryTermsQueryKeyFactory = createQueryKeys(
	GLOSSARY_TERMS_NAMESPACE
);

const { prefetchGlossaryTerm, prefetchGlossaryTermList } =
	prefetchFunctionsFactory('glossaryTerm', glossaryTermsQueryKeyFactory);

const {
	useGlossaryTerm,
	useGlossaryTermInfiniteList,
	useGlossaryTermList,
	useCreateGlossaryTerm,
	useDeleteGlossaryTerm,
	useUpdateGlossaryTerm,
	fetchGlossaryTermList,
	updateGlossaryTerm,
} = baseQueryHooksFactory<IGlossaryTerm, 'glossaryTerm'>(
	'glossaryTerm',
	glossaryTermsQueryKeyFactory
);

export {
	fetchGlossaryTermList,
	prefetchGlossaryTerm,
	prefetchGlossaryTermList,
	updateGlossaryTerm,
	useCreateGlossaryTerm,
	useDeleteGlossaryTerm,
	useGlossaryTerm,
	useGlossaryTermInfiniteList,
	useGlossaryTermList,
	useUpdateGlossaryTerm,
};
