import { Box, Tabs } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { space } from '@repo/theme/primitives';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Groups } from './GroupsTab';
import { Members } from './MembersTab';

const tabs: { name: string; title?: string }[] = [
	{ name: 'Users', title: 'Members' },
	{ name: 'Groups' },
];

export function MemberSettings() {
	const [activeTab, setActiveTab] = useState<string | null>(tabs[0].name);
	const navigate = useNavigate();

	const { subtab } = useParams();
	// Set the selected tab based on the browser url.
	useEffect(() => {
		if (subtab) {
			const found = tabs.findIndex(
				(f) => f.name.toLowerCase() === subtab.toLowerCase()
			);
			if (!isNil(found)) {
				// Use `isNil` because it can be 0, which will resolve to false.
				setActiveTab(tabs[found].name);
			}
		}
	}, [subtab]);

	const onTabChange = useCallback(
		(tabName: string) => {
			setActiveTab(tabName);
			navigate(`/settings/members/${tabName.toLowerCase()}`);
		},
		[navigate]
	);

	return (
		<Box>
			<Tabs
				value={activeTab}
				onTabChange={onTabChange}
				mt={space[2]}
				color="dark"
			>
				<Tabs.List>
					{tabs.map((tab) => (
						<Tabs.Tab key={tab.name + tab.title} value={tab.name}>
							{tab.title ?? tab.name}
						</Tabs.Tab>
					))}
				</Tabs.List>
				{tabs.map((tab) => (
					<Tabs.Panel
						key={tab.name + tab.title}
						value={tab.name}
						id={`panel-${tab.name}`}
					>
						{tab.name === 'Users' && <Members />}
						{tab.name === 'Groups' && <Groups />}
					</Tabs.Panel>
				))}
			</Tabs>
		</Box>
	);
}
