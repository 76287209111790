import {
	Box,
	createStyles,
	Divider,
	Drawer,
	Skeleton,
	Stack,
} from '@mantine/core';
import { ISecodaEntity, useIncident } from '../../../api';
import { CommentStoreProvider } from '../../DocumentPage/context';
import IncidentActionBar from './IncidentDrawer/IncidentActionBar';
import IncidentActivity from './IncidentDrawer/IncidentActivity';
import IncidentDownstreamResources from './IncidentDrawer/IncidentDownstreamResources';
import IncidentMeasurementChart from './IncidentDrawer/IncidentMeasurmentChart';
import IncidentSummary from './IncidentDrawer/IncidentSummary';
import { useIncidentDrawer } from './IncidentDrawerContext';

const useStyles = createStyles((theme) => ({
	drawerBody: {
		width: '100%',
		height: '100%',
		overflowY: 'auto',
		display: 'flex',
		padding: 0,
	},
	wrapper: {
		width: '100%',
		padding: `${theme.spacing.lg} 0 0 ${theme.spacing.lg}`,
	},
	content: {
		overflowY: 'auto',
		scrollbarGutter: 'stable',
		paddingLeft: 0,
		paddingRight: theme.spacing.lg,
		paddingBottom: theme.spacing.lg,
	},
}));

interface IncidentDrawerProps {
	targetEntity?: ISecodaEntity;
}

function IncidentDrawer({ targetEntity }: IncidentDrawerProps) {
	const { incidentId, opened, close } = useIncidentDrawer();
	const { classes, theme } = useStyles();
	const { data: incident } = useIncident({ id: incidentId || '' });

	return (
		<Drawer
			classNames={{
				body: classes.drawerBody,
			}}
			size={theme.other.space[160]}
			withinPortal
			position="right"
			opened={opened}
			onClose={close}
			withCloseButton={false}
		>
			{!incident && (
				<Stack pr="md" className={classes.wrapper}>
					<Skeleton height={100} />
					{Array.from({ length: 5 }).map((i) => (
						<Skeleton key={`skeleton-${i}`} height={20} />
					))}
					<Skeleton height={400} />
					<Skeleton height={100} />
				</Stack>
			)}
			{incident && (
				<Stack className={classes.wrapper}>
					<IncidentActionBar incident={incident} />
					<Box className={classes.content}>
						<IncidentSummary
							incident={incident}
							targetEntity={targetEntity}
							withTargetDisplay
							withJiraIssue
						/>
						<Divider my="md" />
						<IncidentMeasurementChart incident={incident} />
						<Divider my="md" />
						<IncidentDownstreamResources incident={incident} />
						<Divider my="md" />
						<CommentStoreProvider>
							<IncidentActivity incident={incident} />
						</CommentStoreProvider>
					</Box>
				</Stack>
			)}
		</Drawer>
	);
}

export default IncidentDrawer;
