import { Avatar, Badge, Box, Group, createStyles } from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';
import {
	Button,
	type ButtonVariants,
	Icon as IconComponent,
	Text,
} from '@repo/foundations';
import type { SecodaThemeShades } from '@repo/theme/types';
import { type Icon } from '@tabler/icons-react';
import { isEmpty, isNil, slice } from 'lodash-es';
import type { ForwardedRef, MouseEvent, ReactNode } from 'react';
import { forwardRef, useCallback, useMemo } from 'react';
import { useNavigate } from '../../hooks/useNavigate';
import { getUserInitialsFromName, pickColor } from '../../utils';
import { ItemIconType } from '../ItemIcon';
import TagIcon from '../TagIcon/TagIcon';
import type { SelectablePropertyItem } from './types';

const ICON_HEIGHT = 16;

interface ISingleSelectorTargetProps {
	placeholder?: string;
	placeholderIcon?: Icon;
	hideOnEmpty?: boolean;
	variant?: ButtonVariants | 'outline';
	selected: SelectablePropertyItem | undefined;
	iconType: ItemIconType;
	isViewerUser: boolean;
	readOnly?: boolean;
	marginLeft?: string;
}

interface IStyleParams {
	variant?: ButtonVariants | 'outline';
	readOnly?: boolean;
	empty: boolean;
	hideOnEmpty?: boolean;
	textColor: SecodaThemeShades;
	badgeColor: SecodaThemeShades;
}

const useStyles = createStyles(
	(
		theme,
		{
			textColor,
			badgeColor,
			variant,
			empty,
			hideOnEmpty,
			readOnly = false,
		}: IStyleParams
	) => {
		const isOutline = variant === 'outline';

		const readOnlyBg = theme.other.getColor('surface/primary/disabled');

		return {
			buttonRoot: {
				cursor: readOnly ? 'default' : 'pointer',
				borderWidth: 1,
				borderColor: isOutline
					? theme.other.getColor('border/primary/default')
					: 'transparent',
				display: 'flex',
				height: theme.spacing['2xl'],
				paddingLeft: 8,
				paddingRight: 8,
				paddingTop: 4,
				paddingBottom: 4,
				overflow: 'clip',
				backgroundColor: readOnly ? readOnlyBg : 'transparent',
				'div[aria-label="placeholder"]': {
					display: 'none',
				},
				'&:hover': {
					'div[aria-label="placeholder"]': {
						display: 'block',
					},
					backgroundColor: readOnly
						? readOnlyBg
						: theme.other.getColor('surface/primary/hover'),
					'.mantine-Avatar-root': {
						outlineColor: readOnly ? 'none' : theme.colors.gray[1],
					},
				},
				'&:active, &:focus': {
					backgroundColor: readOnly
						? readOnlyBg
						: theme.other.getColor('surface/primary/active'),
					'.mantine-Avatar-root': {
						outlineColor: readOnly ? 'none' : theme.colors.gray[2],
					},
				},
			},
			buttonRightIcon: {
				marginLeft: theme.spacing.xs,
			},
			buttonLabel: {
				visibility: hideOnEmpty && empty ? 'hidden' : 'visible',
				color: empty
					? theme.other.getColor('text/secondary/default')
					: theme.other.getColor('text/primary/default'),
				fontSize: theme.fontSizes.sm,
				fontWeight: theme.other.typography.weight.semibold,
				lineHeight: `${ICON_HEIGHT}px`,
				display: 'flex',
				justifyContent: 'space-between',
			},
			buttonInner: {
				paddingLeft: theme.spacing['2xs'],
			},
			badgeInner: {
				color: theme.colors.gray[9],
				fontSize: theme.fontSizes.sm,
				fontWeight: theme.other.typography.weight.regular,
				lineHeight: `${ICON_HEIGHT}px`,
			},
			icon: {
				width: `${ICON_HEIGHT}px`,
				height: `${ICON_HEIGHT}px`,
				color: theme.other.getColor('icon/primary/default'),
				flexShrink: 0,
			},
			contentGroup: {
				overflow: 'hidden',
				whiteSpace: 'nowrap',
			},
			content: {
				color: textColor
					? theme.other.getColor(textColor)
					: theme.other.getColor('text/primary/default'),
				textOverflow: 'ellipsis',
			},
			placeholder: {
				textOverflow: 'ellipsis',
				color: theme.other.getColor('text/secondary/default'),
			},
			noShrink: {
				flexShrink: 0,
			},
			wrapper: {
				borderRadius: theme.radius.sm,
				padding: `${theme.spacing['4xs']} ${theme.spacing['2xs']}`,
				fontSize: theme.fontSizes.sm,
				backgroundColor: theme.other.getColor(badgeColor),
				color: theme.other.getColor(textColor),
			},
		};
	}
);

const SingleSelectorTarget = forwardRef(
	(
		{
			selected,
			iconType,
			isViewerUser,
			hideOnEmpty,
			variant = 'tertiary',
			placeholder = 'Select',
			placeholderIcon,
			readOnly = false,
			marginLeft,
			...others
		}: ISingleSelectorTargetProps,
		forwardedRef: ForwardedRef<HTMLButtonElement | HTMLDivElement>
	) => {
		const empty = isEmpty(selected) || isNil(selected);

		const { classes, theme } = useStyles({
			hideOnEmpty,
			textColor: selected?.textColor
				? selected.textColor
				: 'text/primary/default',
			badgeColor: selected?.color ? selected.color : 'fill/primary/default',
			readOnly,
			variant,
			empty,
		});

		const navigate = useNavigate();
		const ref = useMergedRef(forwardedRef);

		const color = selected?.color?.includes('/')
			? theme.other.getColor(selected.color)
			: selected?.color;

		const LeftSection = useMemo(() => {
			if (isNil(selected)) {
				if (placeholderIcon) {
					const Icon = placeholderIcon;
					return <Icon className={classes.icon} />;
				}
				return null;
			}

			if (selected.iconName) {
				return (
					<IconComponent
						name={selected.iconName}
						style={{
							color: color || theme.other.getColor('icon/primary/default'),
						}}
					/>
				);
			}

			if (iconType === 'none') {
				return null;
			}

			if (iconType === 'badge') {
				return null;
			}

			if (iconType === 'avatar') {
				return (
					<Avatar
						miw={`${ICON_HEIGHT}px`}
						mih={`${ICON_HEIGHT}px`}
						h={`${ICON_HEIGHT}px`}
						w={`${ICON_HEIGHT}px`}
						size="sm"
						src={selected.icon as string}
						sx={{ backgroundColor: pickColor(selected.label) }}
					>
						{(selected.icon as ReactNode) ||
							slice(getUserInitialsFromName(selected.label), 0, 1)}
					</Avatar>
				);
			}

			if (iconType === 'tag') {
				return (
					<TagIcon
						color={color || theme.other.getColor('icon/brand/default')}
					/>
				);
			}

			if (iconType === 'tabler') {
				const Icon = selected.icon as Icon;
				return (
					<Icon
						height={`${ICON_HEIGHT}px`}
						width={`${ICON_HEIGHT}px`}
						size={ICON_HEIGHT}
						color={color}
					/>
				);
			}

			return (
				<Text style={{ lineHeight: `${ICON_HEIGHT}px` }}>
					{selected.icon as ReactNode}
				</Text>
			);
		}, [selected, iconType, placeholderIcon, classes.icon, color, theme.other]);

		const circleTarget = useMemo(() => {
			if (isNil(selected)) {
				return null;
			}
			if (!selected.icon) {
				return null;
			}

			if (iconType !== 'circle') {
				return null;
			}

			if (selected?.shouldHideIcon) {
				return selected.label;
			}

			const Icon = selected.icon as Icon;

			return (
				<Badge
					classNames={{
						inner: classes.buttonLabel,
					}}
					color={color}
					leftSection={
						<Icon
							size={selected.size ? selected.size : ICON_HEIGHT}
							color={color}
							{...(selected.fill ? { fill: selected.fill } : {})}
						/>
					}
					my={0}
				>
					{selected.label}
				</Badge>
			);
		}, [classes.buttonLabel, color, iconType, selected]);

		const handleOnClick = useCallback(
			(e: MouseEvent<HTMLButtonElement>) => {
				// Prevent the key press from being propagated to the rest of the app.
				e.preventDefault();
				e.stopPropagation();

				if (!isEmpty(selected)) {
					if ((readOnly || isViewerUser) && !isNil(selected.navigateTo)) {
						navigate(selected.navigateTo);
					}
				}
			},
			[isViewerUser, navigate, readOnly, selected]
		);

		const Wrapper = iconType === 'badge' ? Badge : Box;

		return (
			<Button
				size="md"
				ml={marginLeft}
				py={theme.other.space['1']}
				ref={ref as ForwardedRef<HTMLButtonElement>}
				variant={variant === 'outline' ? 'default' : variant}
				onClick={handleOnClick}
				{...others}
				classNames={{
					root: classes.buttonRoot,
					label: classes.buttonLabel,
					inner: classes.buttonInner,
				}}
			>
				{iconType === 'circle' && circleTarget}
				{iconType !== 'circle' && (
					<Wrapper
						h={20}
						{...(iconType === 'badge'
							? { className: classes.wrapper }
							: { color: color })}
					>
						<Group spacing="3xs" className={classes.contentGroup} noWrap>
							{!isNil(LeftSection) && (
								<Box className={classes.noShrink}>{LeftSection}</Box>
							)}
							{selected ? (
								<Text
									className={classes.content}
									td={selected?.strikeThrough ? 'line-through' : undefined}
									size="sm"
								>
									{selected?.label}
								</Text>
							) : (
								<Text
									aria-label="placeholder"
									className={classes.placeholder}
									size="sm"
								>
									{placeholder}
								</Text>
							)}
						</Group>
					</Wrapper>
				)}
			</Button>
		);
	}
);

SingleSelectorTarget.displayName = 'SingleSelectorTarget';

export default SingleSelectorTarget;
