import {
	Box,
	Group,
	Loader,
	Menu,
	ScrollArea,
	Stack,
	TextInput,
} from '@mantine/core';
import { useDebouncedState, useInputState } from '@mantine/hooks';
import { Text } from '@repo/foundations';
import { map } from 'lodash-es';
import { useMemo } from 'react';
import type { ICollection } from '../../../../../../../api';
import { useCollectionList } from '../../../../../../../api';
import { SecodaEntityIcon } from '../../../../../../../components/SecodaEntity';
import { useHomepageTeam } from '../../../../../hooks/useHomepageTeam';

export interface ICollectionSelectorDropdownProps {
	onCollectionChange: (value: [string, string]) => void;
}

function CollectionSelectorDropdown({
	onCollectionChange,
}: ICollectionSelectorDropdownProps) {
	const { isTeamsPage, team } = useHomepageTeam();
	const [searchTerm, setSearchTerm] = useInputState('');
	const [debouncedSearchTerm] = useDebouncedState(searchTerm, 500);

	const filters = useMemo(() => {
		const filtersToUse: Record<string, string> = {};

		if (isTeamsPage) {
			filtersToUse.teams = team.id;
		}

		if (debouncedSearchTerm) {
			filtersToUse.title__trigram_word_similar = debouncedSearchTerm;
		}

		return filtersToUse;
	}, [debouncedSearchTerm, isTeamsPage, team?.id]);

	const { isLoading, data } = useCollectionList({
		filters: {
			...(filters as Record<string, string>),
		},
		options: {
			select: ({ results }) => results,
			suspense: false,
			keepPreviousData: true,
			refetchOnMount: 'always',
		},
	});

	const handleClick = (id: string, title: string) => () => {
		onCollectionChange([id, title]);
	};

	const getMenuItem = (collection: ICollection) => (
		<Menu.Item
			key={collection.id}
			onClick={handleClick(collection.id, collection.title || 'Untitled')}
		>
			<Group spacing={8}>
				<SecodaEntityIcon entity={collection} size={16} />
				<Text lineClamp={1}>{collection.title}</Text>
			</Group>
		</Menu.Item>
	);

	return (
		<Menu.Dropdown>
			<Stack spacing={0}>
				<TextInput
					p={0}
					mb="xs"
					placeholder="Search collections..."
					value={searchTerm}
					onChange={setSearchTerm}
					rightSection={isLoading && <Loader size={12} />}
					autoFocus
				/>
				<Box>
					{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
					{/* @ts-ignore poorly typed component by Mantine */}
					<ScrollArea.Autosize mah={200}>
						{map(data, getMenuItem)}
					</ScrollArea.Autosize>
				</Box>
			</Stack>
		</Menu.Dropdown>
	);
}

export default CollectionSelectorDropdown;
