import { Text } from '@repo/foundations';
import { find, get, isEmpty, isNil } from 'lodash-es';
import { useMemo } from 'react';
import type {
	FormFieldValues,
	IFormField,
	IFormSubmission,
} from '../../../../../api';
import { useUpdateFormSubmissionValues } from '../../../../../api/hooks/forms';
import FormInputWrapper from '../../../FormInputWrapper';
import FormCheckboxField from './FormCheckboxField';
import FormDateField from './FormDateField';
import FormResourceField from './FormResourceField/FormResourceField';
import FormSelectField from './FormSelectField';
import FormTextField from './FormTextField';
import FormUserField from './FormUserField';

interface IFormFieldProps {
	field: IFormField;
	submission?: IFormSubmission;
	isReadOnly?: boolean;
}

export function FormField({
	field,
	submission,
	isReadOnly: inheritedIsReadOnly = false,
}: IFormFieldProps) {
	const { input_type: type, label, helper_text: helperText } = field;
	const isReadOnly = inheritedIsReadOnly || isNil(submission);

	const hasHelperText = !isEmpty(helperText);

	const { mutate: updateFormSubmissionValue } = useUpdateFormSubmissionValues();

	const handleUpdateFormSubmissionValue = (
		formFieldValues: FormFieldValues
	) => {
		if (isReadOnly) {
			return;
		}
		updateFormSubmissionValue({
			submissionId: submission.id as string,
			formFieldValues,
			fieldId: field.id as string,
		});
	};

	const firstFormFieldValue = useMemo<string | undefined>(() => {
		if (isNil(submission)) {
			return undefined;
		}
		const fieldValues = find(submission.field_values, { field_id: field.id });

		if (isNil(fieldValues)) {
			return undefined;
		}
		return get(fieldValues, 'values.values[0].value');
	}, [field.id, submission?.field_values]);

	const allFormFieldValues = useMemo<string[]>(() => {
		if (isNil(submission)) {
			return [];
		}
		const fieldValues = find(submission.field_values, { field_id: field.id });

		if (isNil(fieldValues)) {
			return [];
		}

		const values = get(fieldValues, 'values.values', []);
		return values.map((value) => value.value);
	}, [field.id, submission?.field_values]);

	return (
		<FormInputWrapper label={label ?? ''}>
			{type === 'text' && (
				<FormTextField
					initialValue={firstFormFieldValue}
					type="text"
					updateValue={handleUpdateFormSubmissionValue}
					isReadOnly={isReadOnly}
				/>
			)}
			{type === 'multiline-text' && (
				<FormTextField
					initialValue={firstFormFieldValue}
					type="multiline-text"
					updateValue={handleUpdateFormSubmissionValue}
					isReadOnly={isReadOnly}
				/>
			)}
			{type === 'number' && (
				<FormTextField
					initialValue={firstFormFieldValue}
					type="number"
					updateValue={handleUpdateFormSubmissionValue}
					isReadOnly={isReadOnly}
				/>
			)}
			{type === 'date' && (
				<FormDateField
					initialValue={firstFormFieldValue}
					updateValue={handleUpdateFormSubmissionValue}
					isReadOnly={isReadOnly}
				/>
			)}
			{type === 'checkbox' && (
				<FormCheckboxField
					initialValue={allFormFieldValues}
					updateValue={handleUpdateFormSubmissionValue}
					isReadOnly={isReadOnly}
					options={field.options?.values}
				/>
			)}
			{type === 'select' && (
				<FormSelectField
					type="select"
					initialValue={firstFormFieldValue}
					updateValue={handleUpdateFormSubmissionValue}
					options={field.options?.values}
					isReadOnly={isReadOnly}
				/>
			)}
			{type === 'multi-select' && (
				<FormSelectField
					type="multi-select"
					initialValue={allFormFieldValues}
					updateValue={handleUpdateFormSubmissionValue}
					options={field.options?.values}
					isReadOnly={isReadOnly}
				/>
			)}
			{type === 'user' && (
				<FormUserField
					initialValue={allFormFieldValues}
					updateValue={handleUpdateFormSubmissionValue}
					isReadOnly={isReadOnly}
				/>
			)}
			{type === 'resource' && (
				<FormResourceField
					initialSelected={allFormFieldValues}
					updateValue={handleUpdateFormSubmissionValue}
					isReadOnly={isReadOnly}
				/>
			)}
			{hasHelperText && (
				<Text size="sm" color="text/secondary/default">
					{helperText}
				</Text>
			)}
		</FormInputWrapper>
	);
}
