/* eslint-disable @typescript-eslint/no-explicit-any */
import { find, includes } from 'lodash-es';
import type { SecodaEntity } from '../lib/models';
import { EntityType } from '../lib/types';

export const ENTITY_TO_URL_PREFIX_MAP: Partial<Record<EntityType, string>> = {
	[EntityType.table]: 'table',
	[EntityType.column]: 'column',
	[EntityType.dashboard]: 'dashboard',
	[EntityType.chart]: 'chart',
	[EntityType.job]: 'job',
	[EntityType.event]: 'event',
	[EntityType.collection]: 'collections',
	[EntityType.dictionary_term]: 'dictionary',
	[EntityType.metric]: 'metrics',
	[EntityType.document]: 'docs',
	[EntityType.question]: 'questions',
};

// Support for building URLs from neo4j resources as well as elasticsearch resources, `ESResource`.
export const buildResourceUrl = (resource: {
	id: string;
	entity_type?: string | null;
	url?: string | null;
	to_entity?: SecodaEntity;
	from_entity?: SecodaEntity;
	properties?: {
		[key: string]: any;
	};
	parent?: any;
	parentId?: string;
}): string => {
	let url = '#';
	if (resource.entity_type === EntityType.table) {
		url = `/table/${resource.id}`;
	} else if (resource.entity_type === EntityType.database) {
		url = `/database/${resource.id}`;
	} else if (resource.entity_type === EntityType.schema) {
		url = `/schema/${resource.id}`;
	} else if (resource.entity_type === EntityType.column) {
		url = `/column/${resource.id}`;
	} else if (resource.entity_type === EntityType.metric) {
		url = `/metrics/${resource.id}`;
	} else if (resource.entity_type === EntityType.dictionary_term) {
		url = `/dictionary/${resource.id}`;
	} else if (resource.entity_type === EntityType.document) {
		url = `/docs/${resource.id}`;
	} else if (resource.entity_type === EntityType.request) {
		url = `/questions/${resource.id}`;
	} else if (resource.entity_type === EntityType.job) {
		url = `/job/${encodeURIComponent(resource.id || '')}`;
	} else if (resource.entity_type === EntityType.event) {
		url = `/event/${encodeURIComponent(resource.id || '')}`;
	} else if (resource.entity_type === EntityType.dashboard) {
		url = `/dashboard/${encodeURIComponent(resource.id || '')}`;
	} else if (resource.entity_type === EntityType.chart) {
		url = `/chart/${encodeURIComponent(resource.id)}`;
	} else if (resource.entity_type === EntityType.user) {
		// Some properties are formatted https://linear.app/secoda/issue/ENG-760/non-nested-properties-are-moved-to-custom-upon-requested
		if (resource.properties) {
			if (resource.properties.custom) {
				const customIDField = find(
					resource.properties.custom,
					(p) => p.name === 'id'
				);
				url = `/user/${encodeURIComponent(customIDField.value || '')}`;
			} else {
				url = `/user/${encodeURIComponent(resource.properties.id || '')}`;
			}
		} else {
			url = `/user/${encodeURIComponent(resource.id || '')}`;
		}
	} else if (resource.entity_type === EntityType.collection) {
		url = `/collections/${encodeURIComponent(resource.id || '')}`;
	} else if (resource.entity_type === EntityType.integration) {
		url = `/integrations/${encodeURIComponent(resource.id || '')}/syncs`;
	} else if (resource.entity_type === EntityType.user_group) {
		url = '/settings/members/groups/';
	} else if (resource.entity_type === EntityType.template) {
		url = `/templates/${encodeURIComponent(resource.id || '')}`;
	}
	// DEPRECATED: This logic should not be used because path partially includes title
	// Write a new function to parse LineageRelation URLs
	// If lineage or relationship, then we need to build the url from the to or from entities.
	else if (resource.to_entity) {
		const currentId = window.location.pathname.split('/').pop();
		if (resource.from_entity?.id === currentId) {
			return buildResourceUrl(resource.to_entity);
		}
		if (resource.to_entity.id === currentId && resource.from_entity) {
			return buildResourceUrl(resource.from_entity);
		}
	}

	return url;
};

export const entityUrl = (
	entity: Pick<SecodaEntity, 'id' | 'entity_type'>
): string =>
	buildResourceUrl({ id: entity.id, entity_type: entity.entity_type });

interface IntegrationURLParams {
	entityType?: EntityType | string;
	level: number;
	title: string;
	id?: string;
	teamId?: string;
}

export function getIntegrationURL({
	entityType,
	level,
	title,
	id,
	teamId,
}: IntegrationURLParams): {
	url: string;
	leaf: boolean;
	catalogFilterParams: Record<string, string | string[]>;
} {
	let key: string | undefined;
	let value: string | string[] | undefined;

	// Level represents how deep the node is in the tree.
	// 0 is at the root, 1 is the first level of children, etc.

	if (level === 0) {
		value = title;

		if (
			entityType === EntityType.dashboard ||
			entityType === EntityType.job ||
			entityType === EntityType.chart ||
			entityType === EntityType.event
		) {
			key = 'integration';
			value = id ? [id] : [];
		} else if (entityType === EntityType.table) {
			// At level 0, table type is mapped to the "cluster" filter.
			key = 'integration';
			value = id ? [id] : [];
		}
	} else if (entityType === EntityType.cluster) {
		key = 'integration';
		value = id ? [id] : [];
	} else if (entityType === EntityType.database) {
		key = 'database';
		value = title;
	} else if (entityType === EntityType.schema) {
		key = 'schema';
		value = title;
	} else if (entityType === EntityType.dashboard_group) {
		key = 'group';
		value = title;
	} else if (entityType === EntityType.event) {
		key = 'event';
		value = title;
	} // If a leaf node, build a url directly there.
	else if (entityType === EntityType.job) {
		// TODO: This is a hack to get the job url. We should have a better way to get the job url.
		// buildResourceUrl is being used in too many places to check if updating it for the job is safe
		// This is a temporary fix to get the job url.
		const url = `/job/${id}`;
		return { url, leaf: false, catalogFilterParams: {} };
	} else if (
		level > 1 &&
		includes(
			[EntityType.dashboard, EntityType.chart, EntityType.table],
			entityType
		)
	) {
		const url = buildResourceUrl({
			entity_type: entityType as EntityType,
			id: id!,
		});

		return { url, leaf: true, catalogFilterParams: {} };
	} else {
		return { url: '#', leaf: true, catalogFilterParams: {} };
	}

	let catalogFilterParams: Record<string, string | string[]> = {};
	if (key) {
		catalogFilterParams = { [key]: value };
	}

	const url = `/teams/${teamId}/catalog`;

	return {
		url,
		leaf: false,
		catalogFilterParams,
	};
}

export const resolveBaseEntityPath = (entityType: EntityType) => {
	if (entityType === EntityType.metric) {
		return '/metrics';
	}
	if (entityType === EntityType.dictionary_term) {
		return '/dictionary';
	}
	if (entityType === EntityType.question) {
		return '/questions';
	}
	if (entityType === EntityType.document) {
		return '/docs';
	}
	if (entityType === EntityType.collection) {
		return '/collections';
	}
	return '/catalog';
};
