import { createStyles, Input, Stack, TextInput } from '@mantine/core';
import { useUnmount } from 'ahooks';
import { isNumber } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type React from 'react';

import MultiSelector from '@repo/common/components/MultiSelector/MultiSelector';
import { Text } from '@repo/foundations';
import { useEffect, useMemo } from 'react';
import { useAuthUser } from '../../api';
import { useExtendedUserList } from '../../api/hooks/user/useExtendedUserList.ts';
import ThresholdSelector from '../../pages/MonitorPage/components/ThresholdSelector';
import { SCHEDULE_OPTIONS } from '../../pages/MonitorPage/constants';
import { SelectableProperty } from '../EntityPageLayout/EntityPropertySidebar';
import { getOwnerOptions } from '../EntityPageLayout/utils.ts';
import SqlEditor from '../Sql/SqlEditor/SqlEditor';
import IntegrationSelector from './IntegrationSelector';
import MultipleItemsSelector from './MultipleItemsSelector';
import TableSelector from './TableSelector';
import { useAddMonitorStoreContext } from './context';

const useStyles = createStyles((theme) => ({
	sqlEditor: {
		border: 'thin solid',
		borderColor: theme.other.getColor('border/primary/default'),
		marginTop: theme.spacing.xs,
		padding: theme.spacing['3xs'],
		paddingTop: theme.spacing.md,
		backgroundColor: theme.colors.gray[0],
	},
}));

function AddMonitorForm() {
	const { classes } = useStyles();
	const store = useAddMonitorStoreContext();

	useUnmount(() => {
		store.resetForm();
	});

	const { activeUsers } = useExtendedUserList({ suspense: false });
	const { user: currentUser } = useAuthUser();

	useEffect(() => {
		store.setFormFields('owners', [currentUser.id]);
	}, [currentUser.id, store]);

	const owners = useMemo(
		() => getOwnerOptions(activeUsers || [], () => {}, true),
		[activeUsers]
	);

	const integrationSelector = (
		<Stack spacing="3xs">
			<Text size="xs" weight="semibold">
				Integration
			</Text>
			<IntegrationSelector />
		</Stack>
	);

	const tableSelector = store.getFormValue('integration') && (
		<Stack spacing="3xs">
			<Text size="xs" weight="semibold">
				Tables
			</Text>
			<MultipleItemsSelector type="tables" />
		</Stack>
	);

	const columnSelector = store.getFormValue('table') && (
		<Stack spacing="3xs">
			<Text size="xs" weight="semibold">
				Columns
			</Text>
			<MultipleItemsSelector type="columns" />
		</Stack>
	);

	const singleTableSelector = store.getFormValue('integration') && (
		<Stack spacing="3xs">
			<Text size="xs" weight="semibold">
				Tables
			</Text>
			<TableSelector
				integrationId={store.getFormValue('integration') as string}
			/>
		</Stack>
	);

	const metricTypeDescription = (
		<Text size="sm" color="text/secondary/default">
			{store.metric.description}
		</Text>
	);

	const handleMinimumValueChange = async (value: number | null | undefined) => {
		store.setFormFields('condition_manual_min', value);
	};

	const handleMaximumValueChange = async (value: number | null | undefined) => {
		store.setFormFields('condition_manual_max', value);
	};

	const handleSensitivityChange = async (value: number | string) => {
		let sensitivity = typeof value === 'string' ? parseInt(value) : value;

		if (!isNumber(sensitivity)) {
			store.setFormFields('condition_auto_sensitivity', sensitivity);
		}
	};

	const handleThresholdChange = (
		value: string | number | boolean | string[]
	) => {
		if (value === 'automatic') {
			store.setFormFields('condition_auto_sensitivity', 5);
			return;
		}

		store.setFormFields('condition_auto_sensitivity', null);
	};

	// A flag to indicate whether we're ready to select owners/threshold etc
	const resourceSelected =
		store.getFormValue('metricType') === 'custom_sql'
			? !!store.getFormValue('integration')
			: store.getFormValue('selected').length > 0;

	const ownerSelector = resourceSelected && (
		<Stack spacing="3xs">
			<Text size="xs" weight="semibold">
				Owners
			</Text>
			<MultiSelector
				variant="default"
				initialSelected={store.form.owners}
				onChange={(newOwners) =>
					store.setFormFields('owners', newOwners as string[])
				}
				inheritedValues={[]}
				property={'owners'}
				iconType={'avatar'}
				options={owners}
				isViewerUser={false}
				readOnly={false}
				isMenuItemBadge={false}
				permittedId={''}
			/>
		</Stack>
	);

	const thresholdSelector = resourceSelected && (
		<Stack spacing="3xs">
			<Text size="xs" weight="semibold">
				Threshold
			</Text>
			<ThresholdSelector
				variant="default"
				value={store.thresholdType}
				onChange={handleThresholdChange}
				threshold={store.thresholdType}
				data={{
					condition_manual_max: store.getFormValue('condition_manual_max'),
					condition_manual_min: store.getFormValue('condition_manual_min'),
					condition_auto_sensitivity: store.getFormValue(
						'condition_auto_sensitivity'
					),
					metric_type: store.getFormValue('metricType'),
				}}
				onMinimumValueChange={handleMinimumValueChange}
				onMaximumValueChange={handleMaximumValueChange}
				onSensitivityChange={handleSensitivityChange}
			/>
		</Stack>
	);

	const scheduleSelector = resourceSelected && (
		<Stack spacing="3xs">
			<Text size="xs" weight="semibold">
				Schedule
			</Text>
			<SelectableProperty
				variant="default"
				selected={store.getFormValue('frequency').toString()}
				type="single"
				value="schedule"
				iconType="tabler"
				isViewerUser={false}
				options={SCHEDULE_OPTIONS}
				onChange={(value) => {
					const frequency = Number(value);
					if (!isNumber(frequency)) {
						return;
					}

					store.setFormFields('frequency', frequency);
				}}
			/>
		</Stack>
	);

	if (store.metric.group === 'table') {
		return (
			<Stack spacing="xl">
				{metricTypeDescription}
				{integrationSelector}
				{tableSelector}
				{ownerSelector}
				{thresholdSelector}
				{scheduleSelector}
			</Stack>
		);
	}

	if (store.metric.group === 'column') {
		return (
			<Stack>
				{metricTypeDescription}
				{integrationSelector}
				{singleTableSelector}
				{columnSelector}
				{ownerSelector}
				{thresholdSelector}
				{scheduleSelector}
			</Stack>
		);
	}

	const handleMonitorNameChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		store.setFormFields('name', event.target.value);
	};

	if (store.metric.value === 'custom_sql') {
		return (
			<Stack spacing="xl">
				{metricTypeDescription}
				{integrationSelector}
				{store.getFormValue('integration') && (
					<>
						<TextInput
							size="xs"
							label="Monitor name"
							placeholder="Custom name"
							value={store.getFormValue('name')}
							onChange={handleMonitorNameChange}
						/>
						<Stack spacing="3xs">
							<Input.Label>SQL query</Input.Label>
							<Input.Description>
								This SQL query must return a single scalar value or GROUP BY
								results.
							</Input.Description>
							<SqlEditor
								className={classes.sqlEditor}
								onChange={store.setQuery}
								lineNumbers
							/>
						</Stack>
					</>
				)}
				{ownerSelector}
				{thresholdSelector}
				{scheduleSelector}
			</Stack>
		);
	}

	return <Text>Unsupported metric type: {store.metric.label}</Text>;
}

export default observer(AddMonitorForm);
