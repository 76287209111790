import { Stack } from '@mantine/core';
import { useCallback, type RefObject } from 'react';
import type { IEmbeddedPrompt } from '../../api';
import { DelayedRender } from '../DelayedRender/DelayedRender';
import { MessageGroup } from './Messages/MessageGroup';
import { MessageGroupSkeleton } from './Messages/MessageGroupSkeleton';

type AIChatConversationProps = {
	isLoading?: boolean;
	messages?: IEmbeddedPrompt[];
	scrollAreaRef: RefObject<HTMLDivElement>;
	onRetry?: (messageId: string) => void;
};

export function AIChatConversation({
	isLoading = false,
	messages = [],
	scrollAreaRef,
	onRetry,
}: AIChatConversationProps) {
	const handleOnRetry = useCallback(
		(messageId: string) => {
			onRetry?.(messageId);
		},
		[onRetry]
	);

	return (
		<Stack spacing="xl">
			{isLoading ? (
				<DelayedRender>
					<MessageGroupSkeleton />
				</DelayedRender>
			) : (
				messages.map((message) => (
					<MessageGroup
						key={message.id}
						message={message}
						scrollAreaRef={scrollAreaRef}
						onRetry={onRetry ? () => handleOnRetry(message.id) : undefined}
					/>
				))
			)}
		</Stack>
	);
}
