import { Checkbox, createStyles, Group } from '@mantine/core';
import { ChangeEventHandler } from 'react';
import type { IGroupItem } from '../../../../../api';
import { EntityType } from '../../../../../lib/types.ts';
import { getEntityTypeDisplayInfo } from '../../../../../utils/entityDisplayUtils.ts';

export enum VisibilityState {
	VISIBLE = 'visible',
	INDETERMINATE = 'indeterminate',
	HIDDEN = 'hidden',
}

interface SelectPanelTableRowProps {
	item: IGroupItem;
	isVisible: (item: IGroupItem) => VisibilityState;
	handleVisibilityChange: (item: IGroupItem, visible: boolean) => void;
	level?: number;
}

interface SelectPanelTableRowStylesProp {
	level: number;
}

const useStyles = createStyles(
	(theme, { level }: SelectPanelTableRowStylesProp) => ({
		checkbox: {
			paddingLeft: `calc(${theme.spacing['2xl']} * ${level})`,
		},
	})
);

export function SelectPanelTableRow({
	item,
	isVisible,
	handleVisibilityChange,
	level = 0,
}: SelectPanelTableRowProps) {
	const { classes, theme } = useStyles({ level });

	const hasChildren = item.children?.length > 0;

	const EntityIcon = getEntityTypeDisplayInfo(
		item.entity_type as EntityType
	).icon;

	const toggleVisibility: ChangeEventHandler<HTMLInputElement> = (event) => {
		handleVisibilityChange(item, event.target.checked);
	};

	return (
		<>
			<tr key={item.databuilder_id}>
				<td>
					<Group>
						<Checkbox
							className={classes.checkbox}
							checked={[
								VisibilityState.VISIBLE,
								VisibilityState.INDETERMINATE,
							].includes(isVisible(item))}
							indeterminate={isVisible(item) === VisibilityState.INDETERMINATE}
							onChange={toggleVisibility}
						/>
						<Group spacing="xs">
							<EntityIcon size={theme.other.iconSize.sm} />
							{item.title}
						</Group>
					</Group>
				</td>
			</tr>
			{hasChildren &&
				item.children.map((child) => (
					<SelectPanelTableRow
						key={child.databuilder_id}
						item={child}
						isVisible={isVisible}
						handleVisibilityChange={handleVisibilityChange}
						level={level + 1}
					/>
				))}
		</>
	);
}
