import axios from 'axios';
import { cloneDeep } from 'lodash-es';

import { authHeaders } from '../../api/common';
import type { WithOnlyIdRequired } from '../typescript';
import { BaseModel } from './base';

export type FeatureFlags = 'ai-suggestions' | 'access-request';

export interface WorkspaceNotificationPreference {
	notification_preferences: boolean;
	notify_access_request: boolean;
	notify_announcement: boolean;
	notify_applied_access_request: boolean;
	notify_applied_change_request: boolean;
	notify_change_request: boolean;
	notify_comment_mention: boolean;
	notify_comment_new_thread: boolean;
	notify_comment_reaction: boolean;
	notify_comment_reply: boolean;
	notify_comment_resolved: boolean;
	notify_extraction_column_added: boolean;
	notify_extraction_column_removed: boolean;
	notify_extraction_job_completed: boolean;
	notify_extraction_job_failed: boolean;
	notify_joined_team: boolean;
	notify_mentions: boolean;
	notify_metadata_push_job_completed: boolean;
	notify_metadata_push_job_failed: boolean;
	notify_monitor_incident_created: boolean;
	notify_monitor_incident_resolved: boolean;
	notify_queryblock_job_completed: boolean;
	notify_queryblock_job_failed: boolean;
	notify_question_answer: boolean;
	notify_question_new: boolean;
	notify_question_owner_assigned: boolean;
	notify_question_reply: boolean;
	notify_question_reply_edited: boolean;
	notify_question_reply_removed: boolean;
	notify_resource_archived: boolean;
	notify_resource_collection_added: boolean;
	notify_resource_collection_removed: boolean;
	notify_resource_definition_updated: boolean;
	notify_resource_description_updated: boolean;
	notify_resource_owner_assigned: boolean;
	notify_resource_published: boolean;
	notify_resource_tag_added: boolean;
	notify_resource_tag_removed: boolean;
	notify_resource_unpublished: boolean;
	notify_table_schema_changed: boolean;

	[key: string]: boolean;
}

export class Workspace extends BaseModel {
	// @ts-expect-error TS(2564): Property 'name' has no initializer and is not defi... Remove this comment to see the full error message
	members_count: number;

	// @ts-expect-error TS(2564): Property 'name' has no initializer and is not defi... Remove this comment to see the full error message
	name: string;

	// @ts-expect-error TS(2564): Property 'user_id' has no initializer and is not d... Remove this comment to see the full error message
	user_id: string;

	// @ts-expect-error TS(2564): Property 'is_active' has no initializer and is not... Remove this comment to see the full error message
	is_active: boolean;

	// @ts-expect-error TS(2564): Property 'disabled' has no initializer and is not... Remove this comment to see the full error message
	disabled: boolean;

	// @ts-expect-error TS(2564): Property 'scheduled_for_deletion' has no initializer and is not... Remove this comment to see the full error message
	scheduled_for_deletion: boolean;

	enforce_sso: string | null = null;

	lax_cookie_security: boolean | null = null;

	// @ts-expect-error TS(2564): Property 'allowed_domains' has no initializer and ... Remove this comment to see the full error message
	allowed_domains: string[];

	// @ts-expect-error TS(2564): Property 'customer_id' has no initializer and is n... Remove this comment to see the full error message
	customer_id: string | null;

	// @ts-expect-error TS(2564): Property 'customer_plan_id' has no initializer and... Remove this comment to see the full error message
	customer_plan_id: string | null;

	// @ts-expect-error TS(2564): Property 'anonymous_user_id' has no initializer and is n... Remove this comment to see the full error message
	anonymous_user_id: string | null;

	// @ts-expect-error TS(2564): Property 'demo_data_added' has no initializer and ... Remove this comment to see the full error message
	demo_data_added: boolean;

	// @ts-expect-error TS(2564): Property 'disable_analytics' has no initializer an... Remove this comment to see the full error message
	disable_analytics: boolean;

	// @ts-expect-error TS(2564): Property 'hide_feedback_for_viewers' has no initia... Remove this comment to see the full error message
	hide_feedback_for_viewers: boolean;

	// @ts-expect-error TS(2564): Property 'hide_dictionary_term_query_section' has ... Remove this comment to see the full error message
	hide_dictionary_term_query_section: boolean;

	// @ts-expect-error TS(2564): Property 'hide_sensitive_integration_params' has n... Remove this comment to see the full error message
	hide_sensitive_integration_params: boolean;

	// @ts-expect-error TS(2564): Property 'onboarding_completed' has no initializer... Remove this comment to see the full error message
	onboarding_completed: boolean;

	// @ts-expect-error TS(2564): Property 'publicly_available' has no initializer a... Remove this comment to see the full error message
	publicly_available: boolean;

	// @ts-expect-error TS(2564): Property 'logo' has no initializer and is not defi... Remove this comment to see the full error message
	logo: string;

	// @ts-expect-error TS(2564): Property 'background_image' has no initializer and... Remove this comment to see the full error message
	background_image: string | null;

	// @ts-expect-error TS(2564): Property 'free_trial_started_at' has no initialize... Remove this comment to see the full error message
	free_trial_started_at: string;

	// @ts-expect-error TS(2564): Property 'notification_preferences' has no initial... Remove this comment to see the full error message
	notification_preferences: WorkspaceNotificationPreference;

	inherit_parent_document_properties: boolean | null = null;

	default_dictionary_term_form: string | null = null;

	default_document_form: string | null = null;

	default_question_form: string | null = null;

	ai_search: boolean | null = null;

	monitoring_enabled: boolean | null = null;

	integration_analytics_enabled: boolean | null = null;

	automation_enabled: boolean | null = null;

	default_full_width_pages: boolean | null = null;

	default_publishing_enabled: boolean | null = null;

	table_v2_enabled: boolean | null = null;

	langchain_enabled: boolean | null = null;

	// @ts-expect-error TS(2739)
	send_notifications_to_service_account: boolean;

	marketplace_enabled?: boolean;

	has_connected_integration: boolean = false;

	forms_enabled?: boolean;

	quality_enabled: boolean | null = null;

	constructor(obj: WithOnlyIdRequired<Workspace>) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get fields() {
		return BaseModel.fields.concat([
			'name',
			'allowed_domains',
			'enforce_sso',
			'customer_id',
			'customer_plan_id',
			'demo_data_added',
			'disable_analytics',
			'hide_feedback_for_viewers',
			'hide_sensitive_integration_params',
			'onboarding_completed',
			'publicly_available',
			'logo',
			'background_image',
			'free_trial_started_at',
			'notification_preferences',
			'inherit_parent_document_properties',
			'default_question_form',
			'default_document_form',
			'default_dictionary_term_form',
			'ai_search',
			'monitoring_enabled',
			'integration_analytics_enabled',
			'send_notifications_to_service_account',
			'marketplace_enabled',
			'table_v2_enabled',
			'langchain_enabled',
			'forms_enabled',
		]);
	}

	static get namespace() {
		return ['workspace'];
	}

	join() {
		return axios.post(`${this.apiPath}join/`, {}, authHeaders());
	}

	async publish(entityIds: string[] = [], sendPublishAnnouncement = false) {
		await axios.post(
			`${this.apiPath}publish/`,
			{
				entity_ids: entityIds,
				send_publish_announcement: sendPublishAnnouncement,
			},
			authHeaders()
		);
	}

	async discard(entityIds: string[] = []) {
		await axios.post(
			`${this.apiPath}discard/`,
			{
				entity_ids: entityIds,
			},
			authHeaders()
		);
	}
}
