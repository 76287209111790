import {
	Box,
	Flex,
	Image,
	UnstyledButton,
	createStyles,
	rem,
} from '@mantine/core';
import { Button, Text, Title } from '@repo/foundations';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuthUser, useThisUser } from '../../api/hooks';
import { FullWidthLoadingSpinner } from '../../components/LoadingSpinner';
import { SecodaMinimalLogo } from '../../components/SecodaMinimalLogo';
import { useUserOnboarding } from '../../hooks/useUserOnboarding';
import { trackEvent } from '../../utils/analytics';

interface Step0CopyProps {
	firstName: string;
	workspaceName: string;
}

function Step0Copy({ firstName, workspaceName }: Step0CopyProps) {
	return (
		<>
			<Title order={1} size="xl">
				Hi {firstName},<br /> welcome to Secoda
			</Title>
			<Text>
				Your Google search for {workspaceName}’s data, improving transparency
				for your cross-functional team.
			</Text>
		</>
	);
}

function Step1Copy() {
	return (
		<>
			<Title order={1} size="xl">
				No more repetitive questions
			</Title>
			<Text>
				Bridge the gap of data insights by asking your data team questions or
				reviewing past questions. Your data team can provide links to relevant
				tables, queries, or additional questions for clearer insights.
			</Text>
		</>
	);
}

function Step2Copy() {
	return (
		<>
			<Title order={1} size="xl">
				Gain more context about your data
			</Title>
			<Text>
				Understand your data better through Secoda by discovering information
				like descriptions, owners, related resources, and more.
			</Text>
		</>
	);
}
const useStyles = createStyles((theme) => ({
	page: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		alignItems: 'center',
		minHeight: '100vh',
		minWidth: theme.other.space[160],
		padding: theme.spacing['3xl'],
	},
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		maxWidth: theme.other.space[320],
		width: '100%',
	},
	rightPanelContainer: {
		display: 'flex',
		alignItems: 'center',
		width: theme.other.space[120],
		[`@media (max-width: ${rem(theme.other.space[200])})`]: {
			display: 'none',
		},
	},
	rightPanel: {
		position: 'relative',
		overflow: 'hidden',
	},
	backgroundImage: {},
	stepIndicator: {
		height: theme.other.space[2],
		width: theme.other.space[16],
		backgroundColor: theme.other.getColor('fill/tertiary/hover'),
		borderRadius: theme.other.space[3],
		opacity: 0.5,
		':hover': {
			backgroundColor: theme.other.getColor('fill/tertiary/active'),
		},
	},
	selectedStepIndicator: {
		backgroundColor: theme.other.getColor('fill/brand/default'),
		':hover': {
			backgroundColor: theme.other.getColor('fill/brand/hover'),
		},
	},
	educationImage: {
		position: 'absolute',
		top: '25%',
		left: '5%',
		width: '90%',
	},
	contents: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
		justifyContent: 'center',
		gap: theme.spacing['3xl'],
		height: theme.other.space[160] - theme.other.space[8],
		width: theme.other.space[120],
		paddingRight: theme.other.space[20],
	},
	logo: {
		width: theme.other.space[8],
	},
}));

interface OnboardingViewerWelcomePageProps {
	startStep?: 0 | 1 | 2;
}

export default function OnboardingViewerWelcomePage({
	startStep,
}: OnboardingViewerWelcomePageProps) {
	const { classes, cx } = useStyles();
	const [step, setStep] = useState<number>(startStep || 0);
	const navigate = useNavigate();
	const { isLoading: isUserLoading, data: currentUserData } = useThisUser();
	const { user, workspace } = useAuthUser();
	const { dismissViewerWelcomePage } = useUserOnboarding();

	const markAsCompleteAndNavigateAway = useCallback(() => {
		trackEvent(
			'viewer_onboarding/post_login_education_page_skip/click',
			{},
			user,
			workspace
		);
		dismissViewerWelcomePage();
		navigate('/');
	}, [navigate, dismissViewerWelcomePage, user, workspace]);

	const nextStep = useCallback(() => {
		if (step === 2) {
			trackEvent(
				'viewer_onboarding/post_login_education_page_final_continue/click',
				{},
				user,
				workspace
			);
			dismissViewerWelcomePage();
			navigate('/');
		} else {
			setStep((curr) => curr + 1);
		}
	}, [setStep, step, navigate, dismissViewerWelcomePage, user, workspace]);

	if (isUserLoading) {
		return <FullWidthLoadingSpinner />;
	}

	return (
		<Box className={classes.page} data-testid="page">
			<Box className={classes.container} data-testid="container">
				<Flex direction="column" justify="space-between">
					<Box className={classes.contents}>
						<SecodaMinimalLogo className={classes.logo} />
						<Flex gap="xl" direction="column" h="200px">
							{step === 0 && (
								<Step0Copy
									firstName={currentUserData?.first_name || ''}
									workspaceName={currentUserData?.workspace.name || ''}
								/>
							)}
							{step === 1 && <Step1Copy />}
							{step === 2 && <Step2Copy />}
						</Flex>
						<Flex direction="row" gap="sm">
							<Button variant="primary" onClick={nextStep}>
								{step !== 2 ? 'Continue' : "Let's go"}
							</Button>
							{step !== 2 && (
								<Button
									onClick={() => markAsCompleteAndNavigateAway()}
									variant="tertiary"
								>
									Skip
								</Button>
							)}
						</Flex>
					</Box>
					<Box>
						<Flex direction="row" gap="md">
							<UnstyledButton
								onClick={() => setStep(0)}
								className={
									step === 0
										? cx(classes.stepIndicator, classes.selectedStepIndicator)
										: classes.stepIndicator
								}
							/>
							<UnstyledButton
								onClick={() => setStep(1)}
								className={
									step === 1
										? cx(classes.stepIndicator, classes.selectedStepIndicator)
										: classes.stepIndicator
								}
							/>
							<UnstyledButton
								onClick={() => setStep(2)}
								className={
									step === 2
										? cx(classes.stepIndicator, classes.selectedStepIndicator)
										: classes.stepIndicator
								}
							/>
						</Flex>
					</Box>
				</Flex>
				<Box className={classes.rightPanelContainer}>
					<Box className={classes.rightPanel}>
						<Image
							className={classes.backgroundImage}
							src="/images/onboarding/gradient_2x.webp"
						/>
						{step === 0 && (
							<Box
								style={{
									position: 'absolute',
									top: '5%',
									right: '0',
									width: '90%',
								}}
							>
								<Image
									src="/images/onboarding/education_1_4x.webp"
									alt="An example of Secoda's search UI"
								/>
							</Box>
						)}
						{step === 1 && (
							<Box
								style={{
									position: 'absolute',
									top: '15%',
									left: '5%',
									width: '90%',
								}}
							>
								<Image
									src="/images/onboarding/education_2_4x.webp"
									alt="An example of Secoda's AI Assistant"
								/>
							</Box>
						)}
						{step === 2 && (
							<Box
								style={{
									position: 'absolute',
									top: '2%',
									left: '5%',
									width: '90%',
								}}
							>
								<Image
									src="/images/onboarding/education_3_4x.webp"
									alt="An example of your company's data within Secoda"
								/>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
