/* eslint-disable no-bitwise */
import {
	Box,
	// NOTE: we would like to avoid using `@mantine/core/Button` in favour of `@repo/foundations/Button`
	// eslint-disable-next-line no-restricted-imports
	Button,
	Center,
	createStyles,
	Group,
	useMantineTheme,
} from '@mantine/core';
import { Icon } from '@repo/foundations';
import { useUpdateEffect } from 'ahooks';
import { countBy } from 'lodash-es';
import * as monaco from 'monaco-editor';
import { LanguageIdEnum, setupLanguageFeatures } from 'monaco-sql-languages';
import { useEffect, useRef, useState } from 'react';
import { hashCode } from '../../../utils/utils';
import { DataDisplayTable } from '../../DataDisplayTable';
import { EmptyState } from '../../EmptyState';
import './languageSetup.ts';
import { completionService } from './languageSetup.ts';
import { getEditorOptions } from './SqlEditor.helpers';
import {
	MonacoThemeDark,
	MonacoThemeLight,
} from './SqlEditorTheme/monacoTheme.ts';

export interface ISqlEditorProps {
	results?: unknown[][];
	className?: string;
	autoCompleteIntegrationId?: string;
	autoCompleteIntegrationType?: string;
	minHeight?: number;
	classNames?: {
		root?: string;
		editor?: string;
	};
	defaultValue?: string;
	onChange?: (value: string) => void;
	onExecute?: () => void;
	withActions?: JSX.Element;
	lineNumbers?: boolean;
	readOnly?: boolean;
}

const useStyles = createStyles(
	(theme, { minHeight }: { minHeight: number }) => ({
		wrapper: {
			position: 'relative',
			padding: 8,
			minHeight: minHeight + 26,
		},
		editor: {
			minHeight: minHeight,
		},
	})
);

export default function SqlEditor({
	results = [],
	className,
	classNames,
	defaultValue = '',
	autoCompleteIntegrationId,
	autoCompleteIntegrationType,
	lineNumbers = false,
	readOnly = false,
	withActions,
	minHeight = 274,
	onChange,
	onExecute,
}: ISqlEditorProps) {
	const hostRef = useRef<HTMLDivElement>(null);
	const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

	const { classes, cx, theme } = useStyles({ minHeight });
	const { colorScheme } = useMantineTheme();

	const [query, setQuery] = useState(defaultValue);
	const [tab, setTab] = useState<'query' | 'preview'>(
		results.length > 0 ? 'preview' : 'query'
	);
	useEffect(() => {
		if (results.length > 0) {
			setTab('preview');
		}
	}, [results.length]);

	useUpdateEffect(() => {
		onChange?.(query);
	}, [query]);

	useEffect(
		() => () => {
			editorRef.current?.dispose();
		},
		[]
	);

	useEffect(() => {
		if (hostRef.current && !editorRef.current) {
			setupLanguageFeatures(LanguageIdEnum.MYSQL, {
				completionItems: {
					enable: true,
					completionService: completionService(
						autoCompleteIntegrationId,
						autoCompleteIntegrationType
					),
				},
			});

			monaco.editor.defineTheme(
				'sqlTheme',
				colorScheme === 'dark' ? MonacoThemeDark : MonacoThemeLight
			);
			editorRef.current = monaco.editor.create(hostRef.current, {
				language: LanguageIdEnum.MYSQL,
				theme: 'sqlTheme',
				...getEditorOptions(lineNumbers, readOnly, false),
				glyphMargin: countBy(query)?.['\n'] > 20,
				value: defaultValue,
			});
			editorRef.current.onDidChangeModelContent(() => {
				setQuery(editorRef?.current?.getValue() ?? '');
			});
			editorRef.current.addAction({
				id: 'runQuery',
				label: 'Run Query',
				precondition: 'editorTextFocus',
				keybindings: [
					monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
					monaco.KeyMod.WinCtrl | monaco.KeyCode.Enter,
					monaco.KeyMod.Shift | monaco.KeyCode.Enter,
				],
				run: () => {
					if (onExecute) {
						onExecute();
					}
				},
			});
		}
	}, [
		autoCompleteIntegrationId,
		colorScheme,
		defaultValue,
		lineNumbers,
		onExecute,
		query,
		readOnly,
	]);

	// If the editor is read-only and the default value changes, update the editor.
	useEffect(() => {
		if (editorRef.current && readOnly) {
			editorRef.current.setValue(defaultValue);
		}
	}, [defaultValue, readOnly]);

	const key = hashCode(JSON.stringify(results));

	return (
		<Box className={cx(classes.wrapper, className, classNames?.root)}>
			{withActions && (
				<Group sx={{ justifyContent: 'space-between' }} mb={16}>
					<Group spacing={2}>
						<Button
							data-testid="preview-sql-editor-button"
							onClick={() => setTab('preview')}
							bg={
								tab === 'preview'
									? theme.other.getColor('fill/transparent/active')
									: theme.other.getColor('fill/transparent/default')
							}
							leftIcon={<Icon name="table" />}
							variant="subtle"
							size="md"
						>
							Preview
						</Button>
						<Button
							data-testid="query-sql-editor-button"
							onClick={() => setTab('query')}
							bg={
								tab === 'query'
									? theme.other.getColor('fill/transparent/active')
									: theme.other.getColor('fill/transparent/default')
							}
							leftIcon={<Icon name="code" />}
							variant="subtle"
							size="md"
						>
							Query
						</Button>
					</Group>
					{withActions}
				</Group>
			)}
			<Box
				data-testid="query-sql-editor"
				display={tab === 'query' ? 'block' : 'none'}
			>
				<div
					ref={hostRef}
					className={cx(classes.editor, classNames?.editor)}
					style={{ height: '100%', width: '100%' }}
				/>
			</Box>
			<Box key={key} display={tab === 'preview' ? 'block' : 'none'}>
				{/* We check for results.length <= 1 because the first row is the column names. */}
				{results.length <= 1 && (
					<Center data-testid="sql-editor-preview-empty-state" py={60}>
						<EmptyState
							size="lg"
							iconName="tableRow"
							title="No data found"
							description="This query returned no rows. Try adjusting the query"
							includeGoBack={false}
						/>
					</Center>
				)}
				{results.length > 0 && <DataDisplayTable results={results} />}
			</Box>
		</Box>
	);
}
