import {
	Anchor,
	Box,
	createStyles,
	Divider,
	Flex,
	Group,
	Tabs,
} from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Icon, Text, Title } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import { capitalize } from 'lodash-es';
import type { CSSProperties } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthUser } from '../../api';
import { usePlan } from '../../hooks/usePlans';
import { UpgradeButton } from './UpgradeButton';
import { useSettingsTabs } from './useSettingsTabs';

const useStyles = createStyles((theme) => ({
	settingsPanel: {
		maxWidth: theme.other.space[160],
		margin: '0 auto',
	},
}));

function TabGroupTitle(props: {
	title: string;
	icon: JSX.Element;
	style?: CSSProperties;
}) {
	const { title, style, icon } = props;
	return (
		<Flex style={style ?? {}} mb="10px" align="center" gap="5px">
			{icon}
			<Text size="sm" weight="semibold">
				{title}
			</Text>
		</Flex>
	);
}

export default function SettingsPage() {
	const navigate = useNavigate();
	const { classes } = useStyles();
	const { tab: browserPath } = useParams();
	const { isEditorOrAdminUser } = useAuthUser();
	const { plan } = usePlan();

	const { profileTabs, workspaceTabs } = useSettingsTabs();

	const tabs = [...profileTabs, ...workspaceTabs];
	const currentTab = browserPath
		? tabs.find((tab) => tab.id === browserPath)
		: profileTabs[0];

	if (!currentTab) {
		navigate('/403');
		return null;
	}

	return (
		<Box p="lg">
			<Tabs
				value={currentTab.id}
				onTabChange={(tabId) => navigate(`/settings/${tabId}`)}
				orientation="vertical"
				variant="pills"
			>
				<Tabs.List mr={space[4]} px={space[2]} w={space[60]}>
					<TabGroupTitle icon={<Icon name="userCircle" />} title="Account" />
					{profileTabs.map((tab) => (
						<Tabs.Tab key={tab.id} value={tab.id}>
							<Text size="sm" weight="semibold">
								{tab.sidebarTitle ?? tab.name}
							</Text>
						</Tabs.Tab>
					))}
					{isEditorOrAdminUser && (
						<>
							<TabGroupTitle
								icon={<Icon name="building" />}
								style={{ marginTop: '30px' }}
								title="Workspace"
							/>
							{workspaceTabs.map((tab) => (
								<Tabs.Tab key={tab.id} id={tab.id} value={tab.id}>
									<Text size="sm" weight="semibold">
										{tab.sidebarTitle ?? tab.name}
									</Text>
								</Tabs.Tab>
							))}
						</>
					)}
				</Tabs.List>

				{tabs.map((tab) => {
					const title = tab.title ?? capitalize(tab.name);
					const TabElement = tab.component;

					return (
						<Tabs.Panel
							key={tab.id}
							id={`panel-${tab.name}`}
							value={tab.id}
							className={classes.settingsPanel}
						>
							<Box>
								<Title size="xl" mb="15px">
									{title}
								</Title>
								<Text mb="35px" size="sm">
									{tab.description}
									{tab.documentationLink && (
										<Anchor
											target="_blank"
											href={tab.documentationLink}
											align="center"
										>
											{' '}
											<Group spacing="xxs" noWrap>
												Go to documentation
												<Icon
													name="arrowRight"
													style={{
														display: 'inline-block',
														verticalAlign: 'text-top',
													}}
												/>
											</Group>
										</Anchor>
									)}
								</Text>
								{plan && tab.upgradeRequired?.(plan) && (
									<UpgradeButton feature={title} />
								)}
								<Divider size="sm" my="2rem" />
							</Box>
							<TabElement />
						</Tabs.Panel>
					);
				})}
			</Tabs>
		</Box>
	);
}
