import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	stackContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	stack: {
		width: '100%',
		maxWidth: theme.other.space[160],
		gap: theme.spacing.xs,
		paddingTop: theme.spacing.md,
		paddingBottom: theme.spacing.md,
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginTop: theme.spacing.md,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		padding: `${theme.other.space[4]}px ${theme.other.space[6]}px`,
	},
	backButtonGroup: {
		gap: 0,
	},
	backButtonIcon: {
		borderRadius: theme.radius.sm,
		background: theme.other.getColor('fill/transparent/default'),
	},
	verticalLine: {
		position: 'relative',
		borderLeft: `2px dotted ${theme.other.getColor(
			'border/secondary/default'
		)}`,
		height: theme.spacing.md,
	},
}));
