import { Box, Grid, Stack, createStyles } from '@mantine/core';
import { Button } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useAuthUser, type IComment } from '../../../api';
import { COMMENT_PLACEHOLDER_ID } from '../../../components/Editor/outline/src/marks/Comment';
import { entitySideBarStore } from '../../../components/EntityPageLayout/store';
import { UserAvatar } from '../../../components/UserAvatar';
import type { WithOnlyIdRequired } from '../../../lib/typescript';

import { isCommandMenuType } from '../../../components/Editor/outline/src/components/Toolbar/utils';
import { removeTrailingWhitespaces } from '../../../components/Editor/outline/src/utils/removeTrailingWhitespaces';
import Comment from '../components/Comment';
import CommentForm from '../components/CommentForm';
import { useCommentStyles } from '../components/styles';
import { useCommentStoreContext } from '../context';

export const FLOATING_COMMENT_WIDTH = 280;

export type ICommentStyleProps = {
	top: number;
	isFocused: boolean;
};

const useStyles = createStyles(
	(theme, { top, isFocused }: ICommentStyleProps) => ({
		commentThreadWrapper: {
			// Positioning and animation CSS
			position: 'absolute',
			transform: `translate(${isFocused ? '10px' : '30px'}, ${String(top)}px)`,
			transition: 'all 300ms cubic-bezier(0.7, 0.2, 0.3, 0.9)',
			border: `1px solid ${theme.other.getColor('border/primary/default')}`,
			backgroundColor: theme.other.getColor('surface/primary/default'),
			padding: theme.spacing.md,
			width: FLOATING_COMMENT_WIDTH,
			borderRadius: theme.radius.md,

			'&:hover': {
				transform: `translate(10px, ${String(top)}px)`,
				transition: 'all 300ms cubic-bezier(0.7, 0.2, 0.3, 0.9)',
				boxShadow: `3px 3px 3px ${theme.other.getColor(
					'surface/primary/active'
				)}, -1px -1px 3px ${theme.other.getColor('surface/primary/active')}`,
				cursor: 'pointer',
			},

			'&:focus-within, &:focus-within &:hover': {
				boxShadow: `3px 3px 3px ${theme.other.getColor(
					'surface/primary/active'
				)}, -1px -1px 3px ${theme.other.getColor('surface/primary/active')}`,
			},
		},
		placeholderWrapper: {
			// Positioning and animation CSS
			position: 'absolute',
			transform: `translate(${isFocused ? '10px' : '30px'}, ${String(top)}px)`,
			transition: 'all 300ms cubic-bezier(0.7, 0.2, 0.3, 0.9)',

			padding: 0,
			border: 'none',
			width: FLOATING_COMMENT_WIDTH,
		},
	})
);

export type IFloatingCommentThreadProps = {
	comments?: IComment[];
	rootCommentID: string;
	onCreateComment: (data: Partial<Omit<IComment, 'id'>>) => Promise<IComment>;
	onUpdateComment: (data: WithOnlyIdRequired<IComment>) => Promise<void>;
	onDeleteComment: (id: string) => Promise<void>;
};

function FloatingCommentThread({
	comments,
	rootCommentID,
	onCreateComment,
	onUpdateComment,
	onDeleteComment,
}: IFloatingCommentThreadProps) {
	const {
		placeholderVisible,
		focusedCommentID,
		commentTopOffsetMap,
		selectedText,
		setSelectedText,
		setPlaceholderVisible,
		setFocusedCommentId,
		setCommentDOMHeight,
		handleProseMirrorRemoveComment,
		handleProseMirrorReplacePlaceholder,
	} = useCommentStoreContext();
	const isFocused = focusedCommentID === rootCommentID;
	const hasPosition = commentTopOffsetMap[rootCommentID] !== undefined;

	const { theme, classes } = useStyles({
		top: commentTopOffsetMap[rootCommentID],
		isFocused,
	});
	const { classes: commentClasses } = useCommentStyles();

	const { user } = useAuthUser();
	const isPlaceholder = rootCommentID === COMMENT_PLACEHOLDER_ID;
	const [commentsCondensed, setCommentsCondensed] = useState(true);

	// === COMMENT UPDATE HANDLERS ===
	const handleSubmitComment = async (commentDefinition: string) => {
		const newComment = await onCreateComment({
			root: isPlaceholder ? undefined : rootCommentID,
			quoted_text: isPlaceholder ? selectedText : '',
			definition: removeTrailingWhitespaces(commentDefinition),
		});

		if (isPlaceholder) {
			setSelectedText('');
			handleProseMirrorReplacePlaceholder(newComment.id);
		}
	};

	const handleCancelComment = () => {
		setFocusedCommentId(undefined);
		if (isPlaceholder) {
			setPlaceholderVisible(false);
			handleProseMirrorRemoveComment(COMMENT_PLACEHOLDER_ID);
		}
	};
	// ========================

	// === TRICKY REF IMPLEMENTATION ===
	const [ref, setRef] = useState<HTMLDivElement | null>(null);
	const refCallback = useCallback((node: HTMLDivElement | null) => {
		if (node !== null) {
			setRef(node);
			setCommentDOMHeight(rootCommentID, node.offsetHeight);

			const resizeObserver = new ResizeObserver((entries) => {
				entries.forEach((entry) => {
					const { offsetHeight } = entry.target as HTMLElement;
					// Update the height if it's not null or 0
					if (offsetHeight) {
						setCommentDOMHeight(rootCommentID, offsetHeight);
					}
				});
			});
			resizeObserver.observe(node);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* Handle losing focus when clicking outside the comment box.
		For a placeholder comment, this remove the placeholder. */
	const handleOutsideClick: EventListener = (e) => {
		const { target } = e;

		// Don't close if not clicking on HTML
		if (!(target instanceof HTMLElement)) {
			return;
		}

		// Don't close if clicking inside the comment box
		if (!ref || ref.contains(target)) {
			return;
		}

		// Don't close if clicking on popups menu inside the comment form
		let parent = target.parentElement;
		while (parent) {
			if (isCommandMenuType(parent.getAttribute('menu-id') || '')) {
				return;
			}
			parent = parent.parentElement;
		}

		// Don't close if user is clicking on the selected text inside the document editor
		if (
			focusedCommentID === rootCommentID &&
			target.getAttribute('commentID') !== rootCommentID
		) {
			setFocusedCommentId(undefined);
			if (isPlaceholder) {
				setPlaceholderVisible(false);
				handleProseMirrorRemoveComment(COMMENT_PLACEHOLDER_ID);
			}
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	});
	// =======================

	const handleCommentClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setFocusedCommentId(rootCommentID);
	};
	// ====================

	// If we can't find a proper position for the comment, don't render it
	if (!hasPosition || !comments || (isPlaceholder && !placeholderVisible)) {
		return null;
	}

	if (isPlaceholder) {
		return (
			<Box
				ref={refCallback}
				className={classes.placeholderWrapper}
				onClick={handleCommentClick}
			>
				<CommentForm
					dataTestId="new-comment-thread-0e8e669"
					autoFocus={!!entitySideBarStore.collapsed}
					onSubmit={handleSubmitComment}
					onCancel={handleCancelComment}
				/>
			</Box>
		);
	}

	// Condensed display if there are more than 4 comments
	const commentContent =
		comments.length < 5 || !commentsCondensed ? (
			comments?.map((comment) => (
				<Comment
					key={comment.id}
					comment={comment}
					onUpdateComment={onUpdateComment}
					onDeleteComment={onDeleteComment}
				/>
			))
		) : (
			<>
				<Comment
					key={`floating_comment_${comments[0].id}`}
					comment={comments[0]}
					onUpdateComment={onUpdateComment}
					onDeleteComment={onDeleteComment}
				/>
				<Button
					variant="tertiary"
					size="sm"
					onClick={() => setCommentsCondensed(false)}
				>
					Show {comments.length - 2} more replies...
				</Button>
				<Comment
					key={`floating_comment_${comments[comments.length - 1].id}`}
					comment={comments[comments.length - 1]}
					onUpdateComment={onUpdateComment}
					onDeleteComment={onDeleteComment}
				/>
			</>
		);

	return (
		<Box
			key={rootCommentID}
			data-testid={`comment-thread-0a9ebfe-${rootCommentID}`}
			ref={refCallback}
			className={classes.commentThreadWrapper}
			onClick={handleCommentClick}
		>
			<Stack spacing="sm">{commentContent}</Stack>
			{isFocused && (
				<Grid pt={theme.spacing.md}>
					<Grid.Col span={2} className={commentClasses.commentAvatar}>
						{user && <UserAvatar user={user} size="xxs" />}
					</Grid.Col>
					<Grid.Col span="auto" className={commentClasses.commentContent}>
						<CommentForm
							key={rootCommentID}
							dataTestId={`comment-reply-in-thread-ab0aac8-${rootCommentID}`}
							autoFocus={!!entitySideBarStore.collapsed}
							placeholder="Leave a reply..."
							onSubmit={handleSubmitComment}
							onCancel={handleCancelComment}
						/>
					</Grid.Col>
				</Grid>
			)}
		</Box>
	);
}

export default observer(FloatingCommentThread);
