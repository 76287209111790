import { Group } from '@mantine/core';
import { IconFolders, IconTags } from '@tabler/icons-react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../lib/types';
import { entityModalStore } from '../../store';
import { CollectionSelector } from '../CollectionSelector';
import { OwnerSelector } from '../OwnerSelector';
import { TagSelector } from '../TagSelector';

function MetricMetadata() {
	if (entityModalStore.type !== EntityType.metric) {
		return null;
	}

	const handleChange = (key: string) => (value: string[]) => {
		entityModalStore.setMetadata(key, value);
	};

	return (
		<Group key={entityModalStore?.template?.id}>
			<OwnerSelector
				initialValue={entityModalStore?.template?.owners}
				onChangeUserOwners={handleChange('owners')}
			/>
			<CollectionSelector
				initialValue={entityModalStore?.template?.collections}
				placeholderIcon={IconFolders}
				onChange={handleChange('collections')}
			/>
			<TagSelector
				initialValue={entityModalStore?.template?.tags}
				placeholderIcon={IconTags}
				onChange={handleChange('tags')}
			/>
		</Group>
	);
}

export default observer(MetricMetadata);
