import {
	DEFAULT_FILTER_OPTIONS,
	DEFAULT_FILTER_OPTIONS_WITH_DQS,
	FILTER_OPTIONS_DIVIDER,
} from '@repo/common/components/Filter/constants';
import { FilterOptionType } from '@repo/common/components/Filter/types';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useWorkspace } from '../../api';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import { useFeatureFlags } from '../../utils/featureFlags';
import { SearchFilterV2Store, SearchFilterV2StoreContext } from './store';

export function SearchFiltersV2Provider({ children }: { children: ReactNode }) {
	const { workspace } = useWorkspace();
	const { aiFilters } = useFeatureFlags();
	const { enableAi } = useAiEnabled();

	const defaultSearchFilterV2Store = useMemo(() => {
		let options = workspace.quality_enabled
			? DEFAULT_FILTER_OPTIONS_WITH_DQS
			: DEFAULT_FILTER_OPTIONS;

		if (aiFilters && enableAi) {
			options = [FilterOptionType.AI, FILTER_OPTIONS_DIVIDER, ...options];
		}

		return new SearchFilterV2Store(options);
	}, [aiFilters, enableAi, workspace]);

	return (
		<SearchFilterV2StoreContext.Provider value={defaultSearchFilterV2Store}>
			{children}
		</SearchFilterV2StoreContext.Provider>
	);
}
