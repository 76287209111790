import { Button } from '@repo/foundations';
import BackgroundJobProgress from '../../../../components/BackgroundJobProgress';
import type { BackgroundJob } from '../../../../lib/models';

interface IColumnProfiler {
	backgroundJob?: BackgroundJob | null;
	onRunProfilerClick: VoidFunction;
	onBackgroundJobCompleted: VoidFunction;
}

function ColumnProfiler({
	onRunProfilerClick,
	backgroundJob,
	onBackgroundJobCompleted,
}: IColumnProfiler) {
	return (
		<>
			{!!backgroundJob && (
				<BackgroundJobProgress
					key={backgroundJob.id}
					job={backgroundJob}
					onCompleted={onBackgroundJobCompleted}
				/>
			)}

			<Button
				size="sm"
				variant="default"
				onClick={onRunProfilerClick}
				loading={!!backgroundJob}
				leftIconName="activityHeartbeat"
			>
				Run profiler
			</Button>
		</>
	);
}

export default ColumnProfiler;
