import { Menu } from '@mantine/core';
import { Icon, Text } from '@repo/foundations';
import { useNavigate } from 'react-router';
import {
	ISecodaEntity,
	queryClient,
	secodaEntitiesQueryKeyFactory,
	useAuthUser,
	useUpdateSecodaEntity,
} from '../../../../api';
import { trackEvent } from '../../../../utils/analytics';
import { openConfirmModal } from '../../../ModalManager';

import { resourceCatalogQueryKeyFactory } from '../../../../api/hooks/resourceCatalog/constants';
import { useCurrentTeam } from '../../../../api/hooks/team/myMemberships';
import { useEntityBreadcrumbs } from '../../../../hooks/useEntityBreadcrumbs';
import { CATALOG_COLUMN_MAPPING } from '../../../CatalogView/constants';

interface DeleteActionProps {
	entity: ISecodaEntity;
}

export function DeleteAction({ entity }: DeleteActionProps) {
	const { currentTeam } = useCurrentTeam();
	const { breadcrumbs } = useEntityBreadcrumbs(entity, { includeTeam: true });
	const { user, workspace } = useAuthUser();

	const { mutateAsync: updateSecodaEntity } = useUpdateSecodaEntity({
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries(
					secodaEntitiesQueryKeyFactory.byId(entity.id)
				);
				// should be the same key as in action::delete in TableV2
				// https://github.com/secoda/secoda/blob/8def9a5f50b930955900342ed0918c0df16d6647/apps/frontend/src/components/TableV2/SecodaEntity.hooks.tsx#L898
				queryClient.invalidateQueries(
					resourceCatalogQueryKeyFactory.allLists()
				);
			},
		},
	});

	const navigate = useNavigate();

	const handleDeleteClick = async () => {
		const entitytitle =
			entity.title_cased || entity.title || `Untitled ${entity.entity_type}`;
		openConfirmModal({
			variant: 'default',
			title: `Delete ${entitytitle}`,
			children: (
				<Text pt="md">Are you sure you want to delete {entitytitle}?</Text>
			),
			labels: {
				cancel: 'Cancel',
				confirm: 'Delete',
			},
			cancelProps: { size: 'md' },
			confirmProps: { variant: 'primary', tone: 'critical', size: 'md' },
			onConfirm: async () => {
				await updateSecodaEntity({
					data: {
						id: entity.id,
						archived: true,
					},
				});

				trackEvent(
					`${entity.entity_type}/delete`,
					{
						id: entity.id,
					},
					user,
					workspace
				);

				CATALOG_COLUMN_MAPPING?.[entity.entity_type]?.server?.invalidateCache();
				if (currentTeam && breadcrumbs) {
					navigate(breadcrumbs[0].href);
				}
			},
		});
	};

	return (
		<Menu.Item
			icon={<Icon name="trash" />}
			onClick={handleDeleteClick}
			color="red"
		>
			Delete
		</Menu.Item>
	);
}
