import { ActionIcon, Menu, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Icon } from '@repo/foundations';
import {
	MetricName,
	isIntegrationMetricWidget,
	useWorkspace,
} from '../../../api';

import type { IMetricWidget } from '../../../api';
import { WidgetSize } from '../../../interfaces';
import { AddAnalyticsMonitorModal } from './AddAnalyticsMonitorModal';

export type IEditMenuProps = {
	metricWidget: IMetricWidget;
	onEditingTitle: VoidFunction;
	updateWidgetSize: (
		size: WidgetSize,
		disableInvalidateReport?: boolean
	) => void;
	deleteWidget: VoidFunction;
};

export function EditMenu({
	metricWidget,
	onEditingTitle,
	updateWidgetSize,
	deleteWidget,
}: IEditMenuProps) {
	const theme = useMantineTheme();
	const { workspace } = useWorkspace();

	const [opened, { open, close }] = useDisclosure();

	const widgetSizeName: Record<WidgetSize, string> = {
		[WidgetSize.SMALL]: 'Small',
		[WidgetSize.MEDIUM]: 'Half',
		[WidgetSize.LARGE]: 'Large',
		[WidgetSize.FULL]: 'Full',
	};

	const widgetSizeItems = Object.values(WidgetSize).map((size) => (
		<Menu.Item
			key={size}
			disabled={metricWidget.size === size}
			icon={<Icon name="layoutSidebar" />}
			onClick={() => updateWidgetSize(size, true)}
			rightSection={metricWidget.size === size && <Icon name="check" />}
		>
			{widgetSizeName[size]}
		</Menu.Item>
	));

	return (
		<>
			<Menu position="bottom-end" withinPortal>
				<Menu.Target>
					<ActionIcon
						size={32}
						sx={{
							borderColor: theme.colors.gray[4],
						}}
					>
						<Icon name="dots" />
					</ActionIcon>
				</Menu.Target>
				<Menu.Dropdown>
					<Menu.Label>Widget size</Menu.Label>
					{widgetSizeItems}
					<Menu.Divider />
					<Menu.Item icon={<Icon name="pencil" />} onClick={onEditingTitle}>
						Edit title
					</Menu.Item>
					<Menu.Divider />
					{isIntegrationMetricWidget(metricWidget) &&
					metricWidget.metric_metadata.metric_name !==
						MetricName.DBT_TABLE_TEST_RESULTS && // TODO[tan]: Remove after fixing DBT_TABLE_TEST_RESULTS (https://linear.app/secoda/issue/SEC-4442/add-more-dbt-widgets)
					workspace.integration_analytics_enabled ? (
						<>
							<Menu.Item icon={<Icon name="activity" />} onClick={open}>
								Add monitor
							</Menu.Item>
							<Menu.Divider />
						</>
					) : null}
					<Menu.Item
						color="red"
						icon={<Icon name="trash" />}
						onClick={deleteWidget}
					>
						Remove
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
			<AddAnalyticsMonitorModal
				opened={opened}
				onClose={close}
				metricWidget={metricWidget}
			/>
		</>
	);
}
