import type { TooltipProps, UnstyledButtonProps } from '@mantine/core';
import {
	Center,
	Loader,
	Tooltip,
	UnstyledButton,
	createStyles,
} from '@mantine/core';
import { rem } from '@mantine/styles';
import type { ColorNames } from '@repo/theme/utils';
import { forwardRef } from 'react';
import { Icon, type IconNames } from '../Icon';

export type IconButtonVariants = 'default' | 'primary' | 'tertiary';
export type IconButtonTones = 'default' | 'critical';
export type IconButtonSizes = 'sm' | 'md' | 'lg';

interface IconButtonProps extends UnstyledButtonProps {
	/** The icon at the center of the button */
	iconName: IconNames;
	/** Controls button appearance  */
	variant?: IconButtonVariants;
	/** Controls button appearance  */
	tone?: IconButtonTones;
	/** Predefined button size */
	size?: IconButtonSizes;
	/** Callback for when it's clicked */
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;

	/** Text to show on hover */
	tooltip?: string;
	tooltipProps?: Omit<TooltipProps, 'children' | 'label'>;

	/** highlight the button visually */
	highlight?: boolean;

	/** fill the icon with the same color as the stroke */
	fillIcon?: boolean;

	/** Indicate loading state */
	loading?: boolean;
	/** Disabled state */
	disabled?: boolean;
	id?: string;
}

interface IconButtonStyleProps {
	loading: boolean;
	disabled: boolean;
	size: IconButtonSizes;
	variant: IconButtonVariants;
	tone: IconButtonTones;
	fillIcon: boolean;
}

const useStyles = createStyles(
	(
		theme,
		{ size, loading, disabled, variant, tone, fillIcon }: IconButtonStyleProps
	) => {
		let height: number = theme.other.space[7];
		let width: number = theme.other.space[7];
		let paddingY: number = theme.other.space[1.5];
		let fillColor: ColorNames = 'fill/primary/default';
		let iconColor: ColorNames = 'icon/primary/default';
		let hoverFillColor: ColorNames = 'fill/primary/hover';
		let hoverIconColor: ColorNames = 'icon/primary/hover';
		let activeFillColor: ColorNames = 'fill/primary/active';
		let activeIconColor: ColorNames = 'icon/primary/active';
		let disabledFillColor: ColorNames = 'fill/primary/disabled';
		let disabledIconColor: ColorNames = 'icon/primary/disabled';
		let boxShadow = '';
		let boxShadowOnHover = '';
		let boxShadowOnActive = '';

		if (size === 'lg') {
			// eslint-disable-next-line prefer-destructuring
			height = theme.other.space[8];
			// eslint-disable-next-line prefer-destructuring
			width = theme.other.space[8];
			paddingY = theme.other.space[1.5];
		} else if (size === 'sm') {
			// eslint-disable-next-line prefer-destructuring
			height = theme.other.space[6];
			// eslint-disable-next-line prefer-destructuring
			width = theme.other.space[6];
			// eslint-disable-next-line prefer-destructuring
			paddingY = theme.other.space[1];
		}
		if (variant === 'default') {
			fillColor = 'fill/primary/default';
			iconColor = 'icon/primary/default';
			hoverFillColor = 'fill/primary/hover';
			activeFillColor = 'fill/primary/active';
			disabledFillColor = 'fill/primary/disabled';
			disabledIconColor = 'icon/primary/disabled';
			boxShadow =
				'0px 1px 0px 0px #DDD inset, 1px 0px 0px 0px #DDD inset, -1px 0px 0px 0px #DDD inset, 0px -1px 0px 0px #CCC inset';
			boxShadowOnHover =
				'0px 1px 0px 0px #EBEBEB inset, 1px 0px 0px 0px #EBEBEB inset, -1px 0px 0px 0px #EBEBEB inset, 0px -1px 0px 0px #CCC inset';
			boxShadowOnActive =
				'0px 2px 1px 0px rgba(0, 0, 0, 0.12) inset, 1px 1px 1px 0px rgba(0, 0, 0, 0.12) inset, -1px 0px 1px 0px rgba(0, 0, 0, 0.12) inset';
			if (tone === 'critical') {
				iconColor = 'icon/critical/default';
				hoverIconColor = 'icon/critical/default';
				activeIconColor = 'icon/critical/default';
			}
			if (loading) {
				fillColor = 'fill/primary/disabled';
			}
		} else if (variant === 'primary') {
			fillColor = 'fill/brand/default';
			hoverFillColor = 'fill/brand/hover';
			activeFillColor = 'fill/brand/active';
			iconColor = 'text/brand-on-fill/default';
			hoverIconColor = 'text/brand-on-fill/hover';
			activeIconColor = 'text/brand-on-fill/active';
			disabledFillColor = 'fill/brand/disabled';
			disabledIconColor = 'text/brand-on-fill/disabled';
			boxShadow =
				'0px 1px 0px 0px #000 inset, 0px -1px 0px 1px #000 inset, -2px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 2px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 0px 0px rgba(255, 255, 255, 0.20) inset';
			boxShadowOnActive = '0px 3px 0px 0px #000 inset';
			if (tone === 'critical') {
				fillColor = 'fill/critical/default';
				hoverFillColor = 'fill/critical/hover';
				activeFillColor = 'fill/critical/active';
				iconColor = 'text/critical-on-fill/default';
				hoverIconColor = 'text/critical-on-fill/hover';
				activeIconColor = 'text/critical-on-fill/active';
				boxShadow =
					'0px 1px 0px 0px rgba(255, 255, 255, 0.48) inset, -1px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 0px 0px 0px rgba(255, 255, 255, 0.20) inset, 0px -1.5px 0px 0px rgba(0, 0, 0, 0.25) inset';
				boxShadowOnActive = `0px 3px 0px 0px ${theme.other.getColor(
					'fill/critical/active'
				)} inset`;
			}
			if (loading) {
				fillColor = 'fill/brand/disabled';
			}
		} else if (variant === 'tertiary') {
			fillColor = 'fill/transparent/default';
			iconColor = 'icon/primary/default';
			hoverFillColor = 'fill/transparent/hover';
			activeFillColor = 'fill/transparent/active';
			disabledFillColor = 'fill/transparent/default';
			disabledIconColor = 'icon/primary/disabled';
		}

		return {
			container: {
				height,
				width,
				flexShrink: 0,
				padding: `${paddingY} 0`,
				position: 'relative',

				borderRadius: theme.other.space[2],

				color: theme.other.getColor(iconColor),
				fill: fillIcon ? theme.other.getColor(iconColor) : undefined,
				backgroundColor: theme.other.getColor(fillColor),

				boxShadow: loading || disabled ? 'none' : boxShadow,
				transition: 'box-shadow 0.075s ease-in-out 0s',

				'&:hover': {
					color: theme.other.getColor(hoverIconColor),
					backgroundColor: theme.other.getColor(hoverFillColor),
					boxShadow: boxShadowOnHover,
				},
				'&:active': {
					color: theme.other.getColor(activeIconColor),
					backgroundColor: theme.other.getColor(activeFillColor),
					boxShadow: boxShadowOnActive,
					paddingTop:
						variant !== 'tertiary' ? `calc(${paddingY}px + 1px)` : undefined,
					paddingBottom:
						variant !== 'tertiary' ? `calc(${paddingY}px - 1px)` : undefined,
				},
				'&[data-active]': {
					color: theme.other.getColor(activeIconColor),
					fill: fillIcon ? theme.other.getColor(activeIconColor) : undefined,
					backgroundColor: theme.other.getColor(activeFillColor),
					boxShadow: boxShadowOnActive,
					paddingTop:
						variant !== 'tertiary' ? `calc(${paddingY}px + 1px)` : undefined,
					paddingBottom:
						variant !== 'tertiary' ? `calc(${paddingY}px - 1px)` : undefined,
					'&:hover': {
						backgroundColor: theme.other.getColor(hoverFillColor),
						boxShadow: boxShadowOnHover,
						paddingTop: `${paddingY}px`,
						paddingBottom: `${paddingY}px`,
					},
				},
				'&:focus': {
					outline: `solid ${theme.other.getColor(
						'border/emphasis/default'
					)} 2px`,
					outlineOffset: rem(theme.other.space[0.25]),
				},
				'&:disabled': {
					borderColor: 'transparent',
					backgroundColor: theme.other.getColor(disabledFillColor),
					color: theme.other.getColor(disabledIconColor),
					cursor: 'not-allowed',
					pointerEvents: 'none',
				},
			},
			inner: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100%',
				overflow: 'visible',
				background:
					variant === 'primary'
						? 'linear-gradient(180deg, rgba(255, 255, 255, 0.04) 87%, rgba(255, 255, 255, 0.16) 100%)'
						: undefined,
			},
			loader: {
				position: 'absolute',
				left: '50%',
				top: `calc(50% - ${theme.other.space[2]}px)`,
				height: '100%',
				transform: 'translateX(-50%)',
			},
			icon: {
				color: disabled ? theme.other.getColor(disabledIconColor) : 'inherit',
				// eslint-disable-next-line no-nested-ternary
				fill: fillIcon
					? disabled
						? theme.other.getColor(disabledIconColor)
						: 'inherit'
					: undefined,
				opacity: loading ? 0 : 1,
			},
		};
	}
);

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
	(
		{
			id,
			onClick,
			variant = 'default',
			tone = 'default',
			iconName,
			size = 'md',
			loading = false,
			disabled = false,
			tooltip = undefined,
			tooltipProps = undefined,
			highlight = false,
			fillIcon = false,
			className,
			classNames,
			styles,
			...other
		}: IconButtonProps,
		ref
	) => {
		const { classes, cx, theme } = useStyles(
			{ size, loading, disabled, variant, tone, fillIcon },
			{
				name: 'IconButton',
				classNames,
				styles,
			}
		);

		return (
			<UnstyledButton
				id={id}
				ref={ref}
				aria-label={tooltip || ''}
				className={cx(classes.container, className)}
				disabled={disabled || loading}
				data-active={highlight || undefined}
				onClick={onClick}
				{...other}
			>
				<Center className={classes.inner}>
					{loading === true && (
						<span className={classes.loader}>
							<Loader
								size="xs"
								color={theme.other.getColor(
									variant === 'primary'
										? 'text/brand-on-fill/disabled'
										: 'icon/primary/disabled'
								)}
							/>
						</span>
					)}
					{tooltip === undefined ? (
						<Icon name={iconName} className={classes.icon} />
					) : (
						<Tooltip label={tooltip} {...(tooltipProps || {})}>
							<Icon name={iconName} className={classes.icon} />
						</Tooltip>
					)}
				</Center>
			</UnstyledButton>
		);
	}
);
IconButton.displayName = 'IconButton';

export default IconButton;
