import { Box, Grid, Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useAuthUser, type IComment } from '../../../api';
import { COMMENT_PLACEHOLDER_ID } from '../../../components/Editor/outline/src/marks/Comment';
import { removeTrailingWhitespaces } from '../../../components/Editor/outline/src/utils/removeTrailingWhitespaces';
import { entitySideBarStore } from '../../../components/EntityPageLayout/store';
import { UserAvatar } from '../../../components/UserAvatar';
import type { WithOnlyIdRequired } from '../../../lib/typescript';
import Comment from '../components/Comment';
import CommentForm from '../components/CommentForm';
import { useCommentStyles } from '../components/styles';
import { useCommentStoreContext } from '../context';

export type IFixedCommentThreadProps = {
	comments?: IComment[];
	rootCommentID: string;
	onCreateComment: (data: Partial<Omit<IComment, 'id'>>) => Promise<IComment>;
	onUpdateComment: (data: WithOnlyIdRequired<IComment>) => Promise<void>;
	onDeleteComment: (id: string) => Promise<void>;
};

function FixedCommentThread({
	comments,
	rootCommentID,
	onCreateComment,
	onUpdateComment,
	onDeleteComment,
}: IFixedCommentThreadProps) {
	const { user } = useAuthUser();
	const { theme, classes } = useCommentStyles();

	const {
		focusedCommentID,
		selectedText,
		setPlaceholderVisible,
		setSelectedText,
		setFocusedCommentId,
		handleProseMirrorRemoveComment,
		handleProseMirrorReplacePlaceholder,
	} = useCommentStoreContext();
	const isFocused = focusedCommentID === rootCommentID;
	const isPlaceholder = rootCommentID === COMMENT_PLACEHOLDER_ID;

	const handleOnClick = () => {
		setFocusedCommentId(rootCommentID);
	};

	const handleSubmitComment = async (commentDefinition: string) => {
		const newComment = await onCreateComment({
			root: isPlaceholder ? undefined : rootCommentID,
			quoted_text: isPlaceholder ? selectedText : '',
			definition: removeTrailingWhitespaces(commentDefinition),
		});

		if (isPlaceholder) {
			setSelectedText('');
			handleProseMirrorReplacePlaceholder(newComment.id);
		}
	};

	const handleCancelComment = () => {
		setFocusedCommentId(undefined);
		if (isPlaceholder) {
			setPlaceholderVisible(false);
			handleProseMirrorRemoveComment(COMMENT_PLACEHOLDER_ID);
		}
	};

	return (
		<Box onClick={handleOnClick}>
			<Stack spacing={0}>
				{comments?.map((comment, idx) => (
					<Comment
						key={`${comment.id}`}
						comment={comment}
						showQuotedText={idx === 0}
						onUpdateComment={onUpdateComment}
						onDeleteComment={onDeleteComment}
					/>
				))}
			</Stack>
			<Grid pt={theme.spacing.xs}>
				<Grid.Col span={2} className={classes.commentAvatar}>
					{user && <UserAvatar user={user} size="xxs" />}
				</Grid.Col>
				<Grid.Col span="auto" className={classes.commentContent}>
					<CommentForm
						key="reply-box-fixed"
						placeholder="Leave a reply..."
						autoFocus={isFocused && !entitySideBarStore.collapsed}
						onSubmit={handleSubmitComment}
						onCancel={handleCancelComment}
					/>
				</Grid.Col>
			</Grid>
		</Box>
	);
}

export default observer(FixedCommentThread);
