import { createStyles, Menu, Stack, TextInput } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { getColor } from '@repo/theme/utils';
import { includes, map, size } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import type { FormFieldValues, ISecodaEntity } from '../../../../../../api';
import { useSecodaEntityList } from '../../../../../../api';
import useCatalogSearch from '../../../../../../hooks/useCatalogSearch';
import { SecodaEntityIcon } from '../../../../../SecodaEntity';
import FormResourceFieldItem from './FormResourceFieldItem';
import FormResourceFieldTarget from './FormResourceFieldTarget';

interface IFormResourceFieldProps {
	initialSelected?: string[];
	updateValue: (values: FormFieldValues) => void;
	isReadOnly: boolean;
}

interface IResourceFieldItemProps {
	label: string;
	subLabel?: string;
	value: string;
	icon: React.ReactNode;
	onAdd?: () => void;
	onRemove?: () => void;
}

const useStyles = createStyles((theme) => ({
	input: {
		padding: `${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing.sm}`,
		border: `0.5px solid ${getColor('border/secondary/default')}`,
		borderRadius: theme.radius.sm,
		backgroundColor: getColor('surface/input/default'),
		'&:hover': {
			backgroundColor: getColor('surface/input/hover'),
		},
		'&:focus': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
		'&:active': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
	},
}));

export default function FormResourceField({
	initialSelected,
	updateValue,
	isReadOnly,
}: IFormResourceFieldProps) {
	const { classes, theme } = useStyles();

	const [searchQuery, setSearchQuery] = useInputState('');

	const [currentSelected, setCurrentSelected] =
		useState<IResourceFieldItemProps[]>();

	const { data: selectedResources } = useSecodaEntityList({
		filters: {
			id__in: (initialSelected ?? [])?.join(','),
		},
		options: {
			select: ({ results }) => results,
		},
	});

	useEffect(() => {
		setCurrentSelected(
			map(selectedResources, (entity) => ({
				label: entity?.title ?? `Untitled ${entity.entity_type}`,
				value: entity.id,
				icon: <SecodaEntityIcon entity={entity} size={12} />,
				onRemove: () => {
					updateValue({
						values: map(currentSelected, (v) => ({
							value: v.value,
						})).filter((v) => v.value !== entity.id),
					});
				},
			}))
		);
	}, [currentSelected, initialSelected, selectedResources, updateValue]);

	const handleOnItemClick = (entity: ISecodaEntity) => {
		const values = [...map(currentSelected, 'value'), entity.id];

		updateValue({
			values: map(values, (v) => ({
				value: v,
			})),
		});
	};

	const { data: searchResults } = useCatalogSearch({
		term: searchQuery,
		select: (results) =>
			results
				.filter((entity) => !includes(map(currentSelected, 'value'), entity.id))
				.map((entity) => ({
					label: entity?.title ?? `Untitled ${entity.entity_type}`,
					value: entity.id,
					icon: <SecodaEntityIcon entity={entity} size={20} />,
					onAdd: () => handleOnItemClick(entity),
				})),
	});

	const itemContent = (index: number, item: IResourceFieldItemProps) => (
		<FormResourceFieldItem key={item.value} item={item} />
	);

	if (isReadOnly) {
		return <FormResourceFieldTarget selected={currentSelected ?? []} />;
	}

	return (
		<Menu position="bottom-start" width={640}>
			<Menu.Target>
				<FormResourceFieldTarget selected={currentSelected ?? []} />
			</Menu.Target>
			<Menu.Dropdown>
				<Stack>
					<TextInput
						classNames={{ input: classes.input }}
						placeholder="Search..."
						size="xs"
						value={searchQuery}
						onChange={setSearchQuery}
					/>
					{size(searchResults) > 0 && (
						<Stack spacing={theme.spacing['2xs']}>
							<Virtuoso
								style={{
									height:
										size(searchResults) > 6 ? 288 : size(searchResults) * 48,
								}}
								data={searchResults ?? []}
								totalCount={size(searchResults)}
								itemContent={itemContent}
							/>
						</Stack>
					)}
				</Stack>
			</Menu.Dropdown>
		</Menu>
	);
	return null;
}
