import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { useIntegrationList } from '../../../api';
import IntegrationLogo from '../../IntegrationLogo';

interface IntegrationSelectorProps {
	onChange: (id: string) => void;
	initialValue?: string;
	allowEmptyOption?: boolean;
}

export function IntegrationSelector({
	onChange,
	initialValue,
	allowEmptyOption = false,
}: IntegrationSelectorProps) {
	const { data: integrations } = useIntegrationList({
		options: {
			select: (data) => data.results,
		},
	});

	const integrationOptions = [
		...(allowEmptyOption
			? [
					{
						label: 'All integrations',
						value: '',
						iconName: 'plugConnected',
					},
				]
			: []),
		...(integrations || []).map((integration) => ({
			label: integration.name,
			value: integration.id,
			icon: <IntegrationLogo integrationType={integration.type} size={16} />,
		})),
	] as SelectablePropertyItem[];

	return (
		<SingleSelector
			variant="default"
			iconType="emoji"
			isViewerUser={false}
			searchable
			options={integrationOptions}
			onChange={(id) => onChange(id)}
			initialSelected={initialValue}
			property="integration"
		/>
	);
}
