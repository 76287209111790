import type { CSSObject } from '@mantine/core';
import { ActionIcon, createStyles, Group, TextInput } from '@mantine/core';
import { Icon, type IconNames } from '@repo/foundations';
import { getColor } from '@repo/theme/utils';
import { map } from 'lodash-es';
import type { ChangeEvent } from 'react';
import { isTrue } from '../../../../../utils/utils';

interface ITextInputWithActionsProps {
	value?: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	actions: {
		icon: IconNames;
		disabled?: boolean;
		onClick: () => void;
	}[];
}

const useStyles = createStyles((theme) => ({
	root: {
		flexGrow: '1 !important' as CSSObject['flexGrow'],
	},
	input: {
		padding: `${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing.sm}`,
		border: `0.5px solid ${getColor('border/secondary/default')}`,
		borderRadius: theme.radius.sm,
		backgroundColor: getColor('surface/input/default'),
		'&:hover': {
			backgroundColor: getColor('surface/input/hover'),
		},
		'&:focus': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
		'&:active': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
	},
}));

export function TextInputWithActions({
	value = '',
	onChange,
	actions,
}: ITextInputWithActionsProps) {
	const { classes, theme } = useStyles();

	return (
		<Group w="100%" position="apart" spacing="sm">
			<TextInput
				classNames={{
					root: classes.root,
					input: classes.input,
				}}
				value={value}
				onChange={onChange}
			/>
			<Group spacing={theme.spacing['3xs']}>
				{map(actions, (action) => (
					<ActionIcon
						size="sm"
						disabled={isTrue(action.disabled)}
						onClick={action.onClick}
					>
						<Icon name={action.icon} />
					</ActionIcon>
				))}
			</Group>
		</Group>
	);
}
