import { Carousel } from '@mantine/carousel';
import type { MantineTheme } from '@mantine/core';
import { createStyles, Menu } from '@mantine/core';
import { Icon, IconButton } from '@repo/foundations';
import { typography } from '@repo/theme/primitives';
import { isNil } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { FilterMenuTarget } from '../../../../components/FilterMenu';
import { SearchFilterStoreContext } from '../store';
import AddFilterDropdown from './AddFilterDropdown';
import FilterOptionsDropdown from './FilterOptionsDropdown';

const useStyles = createStyles((theme: MantineTheme) => ({
	slide: {
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 'auto',
	},
	label: {
		color: theme.colors.gray[7],
		fontSize: theme.fontSizes.sm,
		fontWeight: typography.weight.semibold,
	},
	dropdown: {
		maxHeight: 500,
		overflowY: 'auto',
	},
}));

interface AddFilterMenuProps {
	standalone?: boolean;
}

function AddFilterMenu({ standalone }: AddFilterMenuProps) {
	const searchFilterStore = useContext(SearchFilterStoreContext);

	const [opened, setOpened] = useState(false);

	const { classes } = useStyles();

	const handleResetFilterOption = () => {
		searchFilterStore.setFocusedFilter(undefined);
		// TODO[tan]: Hack until all calls move to react-query
		if (!searchFilterStore.init) {
			searchFilterStore.populateFilterOptions();
			searchFilterStore.init = true;
		}
		setOpened(!opened);
	};

	const handleMenuOnChange = (o: boolean) => {
		if (!searchFilterStore.filterDropdownHeaderUI.isMenuOpen) {
			setOpened(o);
		}
	};

	const handleCloseMenu = () => {
		setOpened(false);
	};

	const children = (
		<Menu
			classNames={{ dropdown: classes.dropdown }}
			width={300}
			position="bottom"
			opened={opened}
			onChange={handleMenuOnChange}
			closeOnItemClick={false}
			withinPortal
		>
			<Menu.Target>
				{standalone ? (
					<IconButton
						data-testid="filter-button"
						variant="tertiary"
						onClick={handleResetFilterOption}
						iconName="filter"
					/>
				) : (
					<FilterMenuTarget
						variant="secondary"
						leftIcon={<Icon name="plus" />}
						borderStyle="dashed"
						onClick={handleResetFilterOption}
					>
						Add filter
					</FilterMenuTarget>
				)}
			</Menu.Target>
			<Menu.Dropdown>
				{isNil(searchFilterStore.focusedFilter) ? (
					<FilterOptionsDropdown />
				) : (
					<AddFilterDropdown onClose={handleCloseMenu} />
				)}
			</Menu.Dropdown>
		</Menu>
	);

	if (standalone) {
		return children;
	}

	return <Carousel.Slide className={classes.slide}>{children}</Carousel.Slide>;
}

export default observer(AddFilterMenu);
