import { UserRole } from '@repo/common/enums/UserRole';
import { useAuthUser } from '../../api';
import { Plan } from '../../interfaces/Plan';
import { CreateTab } from '../../pages/IntegrationsBrowsePage/CreateTab';
import { FeatureFlags, useFeatureFlags } from '../../utils/featureFlags';
import WorkspaceActivityLog from '../ActivityLog/WorkspaceActivityLog';
import { AIToolsSettings } from './AIToolsSettings';
import { APISettings } from './APISettings/APISettings';
import AppearanceSettings from './AppearanceSettings';
import { BillingSettings } from './BillingSettings';
import CatalogDefaultsSettings from './CatalogDefaultsSettings';
import { DataSettings } from './DataSettings';
import { ImportSettings } from './ImportSettings';
import { MemberSettings } from './MemberSettings/MemberSettings';
import { NotificationSettings } from './NotificationSettings';
import { ProfileSettings } from './ProfileSettings';
import { PublishSettings } from './PublishSettings';
import { QualityScoreSettings } from './QualityScoreSettings';
import { SecuritySettings } from './SecuritySettings';
import { TagSettings } from './TagSettings/TagSettings';
import { WorkspaceSettings } from './WorkspaceSettings';

interface TabType {
	name: string;
	id: string;
	title?: string;
	sidebarTitle?: string;
	description?: string;
	upgradeRequired?: (plan: Plan) => boolean;
	documentationLink?: string;
	allowedRoles?: UserRole[];
	featureFlag?: keyof FeatureFlags;
	component: React.JSXElementConstructor<unknown>;
}

const PROFILE_TABS: Array<TabType> = [
	{
		name: 'Profile',
		id: 'profile',
		description: 'Manage your Secoda profile.',
		component: ProfileSettings,
	},
	{
		name: 'Notifications',
		id: 'notifications',
		description: 'Select which notifications you would like to receive.',
		component: NotificationSettings,
	},
];

const WORKSPACE_TABS: Array<TabType> = [
	{
		name: 'Workspace',
		id: 'workspace',
		sidebarTitle: 'General',
		title: 'Workspace Settings',
		description: "Manage your team's workspace settings.",
		allowedRoles: [UserRole.ADMIN],
		component: WorkspaceSettings,
	},
	{
		name: 'Catalog',
		id: 'catalog',
		description: 'Set default catalog display settings.',
		allowedRoles: [UserRole.ADMIN],
		component: CatalogDefaultsSettings,
	},
	{
		name: 'Security',
		id: 'security',
		title: 'Security',
		sidebarTitle: 'Security',
		description:
			'Security settings for your workspace. SAML is only available on the Enterprise plan.',
		documentationLink: 'https://docs.secoda.co/saml',
		allowedRoles: [UserRole.ADMIN],
		component: SecuritySettings,
	},
	{
		name: 'Appearance',
		id: 'appearance',
		description: 'Customize the appearance of your workspace.',
		allowedRoles: [UserRole.ADMIN],
		component: AppearanceSettings,
	},
	{
		name: 'Members',
		id: 'members',
		description: 'Manage your workspace members and groups.',
		allowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		component: MemberSettings,
	},
	{
		name: 'Data',
		id: 'data',
		description: 'Manage your workspace data.',
		allowedRoles: [UserRole.ADMIN],
		component: DataSettings,
	},
	{
		name: 'AI',
		id: 'ai',
		title: 'AI',
		description: 'Manage Secoda AI preferences for your workspace.',
		documentationLink: 'https://docs.secoda.co/features/ai-assistant',
		allowedRoles: [UserRole.ADMIN],
		component: AIToolsSettings,
	},
	{
		name: 'Quality score',
		id: 'quality',
		title: 'Quality score',
		description: 'Understand and improve your data quality.',
		documentationLink: 'https://docs.secoda.co/features/data-quality-score',
		allowedRoles: [UserRole.ADMIN],
		component: QualityScoreSettings,
	},
	{
		name: 'Import',
		id: 'import',
		title: 'Import and export',
		sidebarTitle: 'Import and export',
		description: 'Bulk import and export your workspace data.',
		documentationLink:
			'https://docs.secoda.co/features/data-management/import-and-export-data',
		allowedRoles: [UserRole.ADMIN],
		component: ImportSettings,
	},
	{
		name: 'Publishing',
		id: 'publish',
		description: 'Publish resources from your workspace',
		allowedRoles: [UserRole.ADMIN],
		component: PublishSettings,
	},
	{
		name: 'Billing',
		id: 'billing',
		description:
			'Workspace billing history and additional details will show up here.',
		allowedRoles: [UserRole.ADMIN],
		component: BillingSettings,
	},
	{
		name: 'API',
		id: 'api',
		title: 'API access',
		description:
			'Use the API to interact with Secoda services programmatically.',
		upgradeRequired: (plan: Plan) => !plan?.api_support,
		documentationLink: 'https://docs.secoda.co/secoda-api',
		allowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		component: APISettings,
	},
	{
		name: 'Tags',
		id: 'tags',
		description: 'Use tags to help organize your resources.',
		allowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		component: TagSettings,
	},
	{
		name: 'Audit log',
		id: 'audit-log',
		description: 'View the recent activity in your workspace.',
		allowedRoles: [UserRole.ADMIN],
		upgradeRequired: (plan: Plan) => !plan?.audit_log_support,
		component: WorkspaceActivityLog,
	},
	{
		name: 'Marketplace',
		id: 'marketplace-integrations',
		description:
			"Build your own integration with Secoda's SDK and list it on the marktplace",
		documentationLink:
			'https://docs.secoda.co/integrations/custom-integrations-and-marketplace/secoda-sdk-custom-integration',
		allowedRoles: [UserRole.ADMIN],
		component: CreateTab,
	},
];

export function useSettingsTabs() {
	const { user } = useAuthUser();
	const featureFlags = useFeatureFlags();

	function filterTabs(tabs: TabType[]) {
		return tabs.filter((tab) => {
			if (tab.allowedRoles) {
				return user ? tab.allowedRoles.includes(user.role) : false;
			}
			if (tab.featureFlag) {
				return featureFlags[tab.featureFlag];
			}
			return true;
		});
	}

	return {
		profileTabs: filterTabs(PROFILE_TABS),
		workspaceTabs: filterTabs(WORKSPACE_TABS),
	};
}
