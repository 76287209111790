import { ActionIcon, Box, Group, Stack } from '@mantine/core';
import { useDisclosure, useHover } from '@mantine/hooks';
import { Badge, Button, Icon, Text } from '@repo/foundations';
import { isNil, lowerCase, map, size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import type { ILineage } from '../../../../api';
import { useLineageInfiniteList } from '../../../../api/hooks/lineage/useLineage';
import IntegrationLogo from '../../../IntegrationLogo';
import { LineageDirectionEnum } from '../../types';
import { useStyles } from './ImpactAnalysisCard.styles';
import { ImpactAnalysisCardMetadata } from './ImpactAnalysisCardMetadata';
import { impactAnalysisStore } from './store';

interface IImpactedAnalysisCardProps {
	entity: ILineage;
	depth?: number;
	isLast?: boolean;
}

const ImpactAnalysisCard = observer(
	({ entity, depth = 1, isLast = true }: IImpactedAnalysisCardProps) => {
		const [opened, { toggle }] = useDisclosure(false);
		const { hovered, ref: hoveredRef } = useHover();

		const { data, hasNextPage, fetchNextPage } = useLineageInfiniteList({
			filters: { id: entity.id || '', ...impactAnalysisStore.queryParams },
			options: {
				enabled: false,
				keepPreviousData: true,
			},
		});

		const hasImpactedEntities =
			impactAnalysisStore.filterMenuItems.direction.selectedOptions[0].value ===
			LineageDirectionEnum.DOWNSTREAM
				? size(entity.downstream_entities) > 0
				: size(entity.upstream_entities) > 0;

		const showCard = useMemo(
			() =>
				lowerCase(entity.title).includes(
					lowerCase(impactAnalysisStore.searchTerm)
				),
			[entity.title, impactAnalysisStore.searchTerm]
		);

		const { classes, theme } = useStyles({
			isHovered: hovered,
			isFirst: depth === 1,
			isLast,
			hasImpactedEntities,
			hasNextPage: Boolean(hasNextPage),
			opened,
		});

		if (!showCard && !opened && !hasImpactedEntities) {
			return null;
		}

		const handleToggle = async () => {
			if (isNil(data) || hasNextPage) {
				fetchNextPage();
			}
			toggle();
		};

		const handleFetchMore = async () => {
			if (isNil(data) || hasNextPage) {
				fetchNextPage();
			}
		};

		return (
			<Stack key={entity.id} className={classes.wrapper} spacing={0}>
				{showCard && (
					<Group
						ref={hoveredRef}
						className={classes.cardWrapper}
						spacing="xs"
						p="xs"
						noWrap
					>
						<Box className={classes.chevronWrapper} onClick={handleToggle}>
							{hasImpactedEntities && (
								<ActionIcon size="xs">
									{opened ? (
										<Icon name="chevronDown" color="icon/secondary/default" />
									) : (
										<Icon name="chevronRight" color="icon/secondary/default" />
									)}
								</ActionIcon>
							)}
						</Box>
						<Group spacing="xs" w="100%" noWrap>
							<IntegrationLogo
								entityType={entity.entity_type}
								integrationId={entity.integration_id}
								integrationType={entity.integration_type}
								width={theme.other.iconSize.lg}
								height={theme.other.iconSize.lg}
							/>
							<Stack className={classes.titleWrapper} spacing={0}>
								<Text size="sm" truncate>
									{entity.title}
								</Text>
								<ImpactAnalysisCardMetadata entity={entity} />
							</Stack>
							<Badge>{`Level ${depth}`}</Badge>
						</Group>
					</Group>
				)}
				{opened && hasImpactedEntities && (
					<Stack spacing={0}>
						{map(data?.pages, (child, index) => (
							<ImpactAnalysisCard
								key={child.id}
								entity={child}
								depth={depth + 1}
								isLast={
									(depth === 1 || isLast) && index === size(data?.pages) - 1
								}
							/>
						))}
						{hasNextPage && (
							<Button variant="tertiary" size="md" onClick={handleFetchMore}>
								Load more
							</Button>
						)}
					</Stack>
				)}
			</Stack>
		);
	}
);

export default ImpactAnalysisCard;
