import { createStyles } from '@mantine/core';
import { motion } from 'framer-motion';
import type { IMetricWidgetSample, IWidgetSample } from '../../../../api';
import AddWidgetCard from '../../../HomePage/components/EditHomeScreenDrawer/components/cards/AddWidgetCard';

const useStyles = createStyles((theme) => ({
	addWidgetCard: {
		marginLeft: theme.spacing.xs,
		width: '330px',
	},
}));

export type IWidgetRendererProps = {
	widgetSample: IMetricWidgetSample;
	addWidget: (widget: IWidgetSample) => void;
	isTeamsPage: boolean;
};

export function WidgetRenderer({
	widgetSample,
	addWidget,
	isTeamsPage,
}: IWidgetRendererProps) {
	const { classes } = useStyles();

	const handleClick: VoidFunction = () => {
		addWidget(widgetSample);
	};
	return (
		<motion.div
			key={widgetSample.id}
			className={classes.addWidgetCard}
			whileHover={{
				scale: 1.03,
			}}
			whileTap={{ scale: 0.95 }}
		>
			<AddWidgetCard
				key={`${widgetSample.type} - ${JSON.stringify(
					widgetSample.metric_metadata
				)}`}
				widgetSample={widgetSample}
				onClick={handleClick}
				isTeamsPage={isTeamsPage}
			/>
		</motion.div>
	);
}
