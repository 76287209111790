import { Box, Group, Menu, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { pluralize } from '@repo/common/utils';
import { Icon, Text } from '@repo/foundations';
import { lowerCase } from 'lodash-es';
import { useNavigate } from 'react-router';
import type { ITemplate } from '../../api';
import { queryClient, useAuthUser } from '../../api';
import {
	templatesQueryKeyFactory,
	useDeleteTemplate,
	useTemplateList,
	useUpdateTemplate,
} from '../../api/hooks/templates';
import type { EntityType } from '../../lib/types';

import { useIsTeamsPage } from '../../api/hooks/team';
import { TemplateDotsButton } from './TemplateDotsButton';
import { TemplateNewButton } from './TemplateNewButton';

interface TemplatesButtonProps {
	type: EntityType;
	withForm?: boolean;
	withSetDefault?: boolean;
	children: JSX.Element;
	handleUseTemplate: (template: ITemplate) => void;
}

export function TemplatesButton({
	type,
	withForm,
	withSetDefault,
	children,
	handleUseTemplate,
}: TemplatesButtonProps) {
	const theme = useMantineTheme();
	const navigate = useNavigate();
	const { isEditorUser, isAdminUser } = useAuthUser();
	const [isOpen, { toggle: onToggle }] = useDisclosure();
	const { teamId } = useIsTeamsPage();

	const { data: templates } = useTemplateList({
		filters: {
			entity_type_template: type,
		},
		options: {
			suspense: false,
			select: (data) => data?.results || [],
		},
	});

	// === Templates
	const { mutateAsync: updateTemplate } = useUpdateTemplate({
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries(templatesQueryKeyFactory.list(1));
			},
		},
	});

	const handleToggleTemplateDefault = async (template: ITemplate) => {
		updateTemplate({
			data: { id: template.id, is_default: !template.is_default },
		});
	};

	const handleEditTemplate = async (template: ITemplate) => {
		const urlParams = teamId ? `?teamId=${teamId}` : '';
		navigate(`/templates/${template.id}${urlParams}`);
	};

	const { mutateAsync: handleDeleteTemplate } = useDeleteTemplate({
		options: {
			onSuccess: () => {
				queryClient.invalidateQueries(templatesQueryKeyFactory.list(1));
			},
		},
	});

	return (
		<Menu opened={isOpen} onChange={onToggle}>
			<Menu.Target>
				<Box data-testid="template-chevron-down">{children}</Box>
			</Menu.Target>
			<Menu.Dropdown>
				{templates?.length === 0 ? (
					<Menu.Label>
						<Text size="sm" color="text/secondary/default">
							Create templates to speedup your
							<br /> documentation workflow
						</Text>
					</Menu.Label>
				) : (
					<Menu.Label>Templates for {pluralize(lowerCase(type))}</Menu.Label>
				)}
				{templates?.map((t) => (
					<Menu.Item
						onClick={() => handleUseTemplate(t)}
						key={t.id}
						data-testid={`template-${t.id}`}
						rightSection={
							<Group display="flex" spacing={0} ml={theme.spacing.sm}>
								{t.is_default && (
									<Icon name="flag" color="icon/secondary/default" />
								)}
								<TemplateDotsButton
									item={t}
									displayName="template"
									onSetAsDefault={
										withSetDefault ? handleToggleTemplateDefault : undefined
									}
									defaultDisplayText={
										t?.is_default ? 'Unset as default' : 'Set as default'
									}
									onUseItem={handleUseTemplate}
									onDeleteItem={handleDeleteTemplate}
									onEditItem={handleEditTemplate}
									visible={isEditorUser || isAdminUser}
								/>
							</Group>
						}
						icon={<Icon name="fileText" />}
					>
						{t.title}
					</Menu.Item>
				))}
				<Menu.Divider />

				{(isEditorUser || isAdminUser) && (
					<TemplateNewButton type={type} withForm={withForm} teamId={teamId} />
				)}
			</Menu.Dropdown>
		</Menu>
	);
}
