import { Group } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import CollectionMetadata from './Collection/CollectionMetadata';
import DictionaryTermMetadata from './DictionaryTerm/DictionaryTermMetadata';
import DocumentMetadata from './Document/DocumentMetadata';
import GlossaryTermMetadata from './GlossaryTerm/GlossaryTermMetadata';
import MetricMetadata from './Metric/MetricMetadata';
import QuestionMetadata from './Question/QuestionMetadata';

function EntityModalMetadata() {
	return (
		<Group px="xl" mb="sm">
			<CollectionMetadata />
			<DictionaryTermMetadata />
			<GlossaryTermMetadata />
			<MetricMetadata />
			<DocumentMetadata />
			<QuestionMetadata />
		</Group>
	);
}

export default observer(EntityModalMetadata);
