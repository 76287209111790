import { createStyles, getStylesRef, Paper } from '@mantine/core';
import { getColor } from '@repo/theme/utils';
import type { IFormField } from '../../../../../api';
import FormQuestionContent from './FormQuestionContent';
import FormQuestionHeader from './FormQuestionHeader';

interface IFormQuestionProps {
	field: IFormField;
	numFields: number;
	handleUpdateField: (field: IFormField) => void;
	handleMoveField: (fieldID: string, direction: 'UP' | 'DOWN') => void;
	handleDeleteField: (fieldID: string) => void;
}

const useStyles = createStyles((theme) => ({
	container: {
		overflow: 'hidden',
		'&:hover': {
			[`& .${getStylesRef('formQuestionHeaderActionIcons')}`]: {
				visibility: 'visible',
			},
		},
	},
	content: {
		padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.md}`,
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottom: `1px solid ${getColor('border/secondary/default')}`,
		background: getColor('surface/secondary/default'),
	},
}));

export function FormQuestion({
	field,
	numFields,
	handleUpdateField,
	handleMoveField,
	handleDeleteField,
}: IFormQuestionProps) {
	const { classes } = useStyles();

	const moveField = (direction: 'UP' | 'DOWN') => {
		handleMoveField(field.id as string, direction);
	};

	const deleteField = () => {
		handleDeleteField(field.id as string);
	};

	return (
		<Paper
			className={classes.container}
			shadow="box-shadow"
			withBorder
			radius="md"
		>
			<FormQuestionHeader
				field={field}
				numFields={numFields}
				moveField={moveField}
				deleteField={deleteField}
			/>
			<FormQuestionContent field={field} updateField={handleUpdateField} />
		</Paper>
	);
}
