import { useMantineTheme } from '@mantine/core';
import type {
	IMarketplaceIntegrationSpec,
	IMarketplaceIntegrationSpecVersion,
} from '@repo/common/models/marketplace';
import { Text } from '@repo/foundations';
import { useMutation, useQuery } from '@tanstack/react-query';
import { openConfirmModal } from '../../../components/ModalManager';
import { apiClient, getEndpoints } from '../../common';
import { baseQueryHooksFactory, createQueryKeys } from '../../factories';
import type { UseQueryOptionsArgs } from '../../factories/types';
import { queryClient } from '../../index';

export const MARKETPLACE_NAMESPACE = ['integration', 'marketplace'];

export const MARKETPLACE_SPEC_NAMESPACE = [
	'integration',
	'marketplace',
	'specs',
];

export const marketplaceSpecQueryKey = createQueryKeys(
	MARKETPLACE_SPEC_NAMESPACE
);

const {
	useCreateMarketplaceIntegrationSpec,
	useMarketplaceIntegrationSpecList,
	useDeleteMarketplaceIntegrationSpec,
	useMarketplaceIntegrationSpec,
} = baseQueryHooksFactory<
	IMarketplaceIntegrationSpec,
	'marketplaceIntegrationSpec'
>('marketplaceIntegrationSpec', marketplaceSpecQueryKey);

export const useDeleteMarketplaceIntegrationSpecWithConfirmation = () => {
	const theme = useMantineTheme();
	const { mutateAsync, ...rest } = useDeleteMarketplaceIntegrationSpec({
		invalidationKeys: [marketplaceSpecQueryKey.all()],
	});
	const apiDeleteSpec = (specId: string, onDeleted: VoidFunction) => {
		openConfirmModal({
			sx: {
				paddingTop: `${theme.spacing.md} !important`,
				paddingBottom: `${theme.spacing.md} !important`,
			},
			title: (
				<Text size="md" weight="bold">
					Delete integration?
				</Text>
			),
			children: (
				<Text size="sm">
					Once deleted, you will not be able to recover this integration.
					Existing connections will no longer able to use this integration.
				</Text>
			),
			labels: { confirm: 'Delete', cancel: 'Cancel' },
			cancelProps: {
				size: 'md',
			},
			confirmProps: {
				size: 'md',
				variant: 'primary',
				tone: 'critical',
			},

			onConfirm: async () => {
				await mutateAsync({ id: specId });
				onDeleted();
			},
		});
	};

	return { apiDeleteSpec, ...rest };
};

export function useMarketplaceIntegrationSpecVersion(
	specId: string,
	versionId: string,
	options: UseQueryOptionsArgs = {}
) {
	const queryKey = [
		...MARKETPLACE_SPEC_NAMESPACE,
		specId,
		'versions',
		versionId,
	];
	const queryFn = async () => {
		const url = getEndpoints(MARKETPLACE_SPEC_NAMESPACE).byPath([
			specId,
			'versions',
			versionId,
		]);

		const { data } = await apiClient.get<IMarketplaceIntegrationSpecVersion>(
			url,
			{}
		);
		return data;
	};

	return useQuery(queryKey, queryFn, options);
}

export function useMarketplaceIntegrationSpecVersionCode(
	specId: string,
	versionId: string,
	options?: UseQueryOptionsArgs
) {
	const queryKey = [
		...MARKETPLACE_SPEC_NAMESPACE,
		specId,
		'versions',
		versionId,
		'code',
	];
	const queryFn = async () => {
		const url = getEndpoints(MARKETPLACE_SPEC_NAMESPACE).byPath([
			specId,
			'versions',
			versionId,
			'code',
		]);

		const { data } = await apiClient.get<string>(url, {});
		return data;
	};

	return useQuery(queryKey, queryFn, options);
}

export type CreateMarketplaceIntegrationSpecVersionArgs = {
	detail: Pick<
		IMarketplaceIntegrationSpecVersion,
		| 'form_spec'
		| 'name'
		| 'description'
		| 'category'
		| 'allowed_endpoints'
		| 'icon_url'
	>;
	codeFile: File;
	specId: string;
};

export function useCreateMarketplaceIntegrationSpecVersion() {
	const mutationFn = async ({
		specId,
		detail,
		codeFile,
	}: CreateMarketplaceIntegrationSpecVersionArgs) => {
		const url = getEndpoints(MARKETPLACE_SPEC_NAMESPACE).byPath([
			specId,
			'versions',
		]);

		const form = new FormData();
		form.append('code_file', codeFile);
		form.append('detail', JSON.stringify(detail));

		await apiClient.post<IMarketplaceIntegrationSpecVersion>(url, form);
		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(marketplaceSpecQueryKey.all());
		},
	});
}

export type UpdateMarketplaceIntegrationSpecVersionArgs = {
	detail: Pick<
		IMarketplaceIntegrationSpecVersion,
		| 'form_spec'
		| 'name'
		| 'description'
		| 'category'
		| 'allowed_endpoints'
		| 'icon_url'
	>;
	codeFile?: File | null;
	specId: string;
	versionId: string;
};

export function useEditMarketplaceIntegrationSpecVersion() {
	const mutationFn = async ({
		specId,
		versionId,
		detail,
		codeFile,
	}: UpdateMarketplaceIntegrationSpecVersionArgs) => {
		const url = getEndpoints(MARKETPLACE_SPEC_NAMESPACE).byPath([
			specId,
			'versions',
			versionId,
			'edit',
		]);

		const form = new FormData();
		form.set('detail', JSON.stringify(detail));
		if (codeFile) {
			form.append('code_file', codeFile);
		}

		await apiClient.post<IMarketplaceIntegrationSpecVersion>(url, form, {});
		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(marketplaceSpecQueryKey.all());
		},
	});
}

export function useChangeSubmissionStateMarketplaceIntegrationSpecVersion(
	version: IMarketplaceIntegrationSpecVersion
) {
	const mutationFn = async (action: 'submit' | 'withdraw') => {
		const url = getEndpoints(MARKETPLACE_SPEC_NAMESPACE).byPath([
			version.spec_id,
			'versions',
			version.id,
			'submission',
			action,
		]);

		await apiClient.post<IMarketplaceIntegrationSpecVersion>(url);
		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(marketplaceSpecQueryKey.all());
		},
	});
}

export function useInternalMarketplaceIntegrationSpecVersionList() {
	const queryKey = [...MARKETPLACE_NAMESPACE, 'versions_for_review'];
	const queryFn = async () => {
		const url = getEndpoints(MARKETPLACE_NAMESPACE).byPath([
			'versions_for_review',
		]);

		const { data } = await apiClient.get<IMarketplaceIntegrationSpecVersion[]>(
			url,
			{}
		);
		return data;
	};

	return useQuery(queryKey, queryFn);
}

export function useReviewMarketplaceVersion() {
	const mutationFn = async ({
		action,
		comment,
		specId,
		versionId,
		isTrusted,
	}: {
		specId: string;
		versionId: string;
		action: 'approve' | 'reject' | null;
		comment: string;
		isTrusted: boolean;
	}) => {
		const url = getEndpoints(MARKETPLACE_SPEC_NAMESPACE).byPath([
			specId,
			'versions',
			versionId,
			'review',
		]);

		const body: Record<string, any> = {
			comment,
			is_trusted: isTrusted,
		};

		if (action) {
			body.approved = action === 'approve';
		}

		await apiClient.post<IMarketplaceIntegrationSpecVersion>(url, body);
		return true;
	};

	return useMutation({
		mutationFn,
		onSettled: () => {
			queryClient.invalidateQueries(marketplaceSpecQueryKey.all());
			queryClient.invalidateQueries(MARKETPLACE_NAMESPACE);
		},
	});
}

export {
	useCreateMarketplaceIntegrationSpec,
	useDeleteMarketplaceIntegrationSpec,
	useMarketplaceIntegrationSpec,
	useMarketplaceIntegrationSpecList,
};
