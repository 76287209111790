import { Box, Group, Stack, createStyles } from '@mantine/core';

import { Icon, Text, Title } from '@repo/foundations';
import { capitalize } from 'lodash-es';
import moment from 'moment';
import { UserAvatar } from '../../../components/UserAvatar';
import type { User } from '../../../lib/models';

const useStyles = createStyles(() => ({
	headerWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: '20px',
		marginBottom: 40,
	},
}));

interface IProfileHeaderProps {
	user: User;
}

function ProfileHeader({ user }: IProfileHeaderProps) {
	const { classes } = useStyles();

	return (
		<Box className={classes.headerWrapper}>
			<UserAvatar size="md" user={user} />
			<Stack spacing="xs">
				<Title order={2}>{user.display_name}</Title>
				<Group>
					<Group spacing="xs">
						<Icon name="circleCheck" color="icon/success/default" />
						<Text>Active</Text>
					</Group>
					{user.last_login && (
						<Group spacing="xs">
							<Icon name="calendarTime" color="icon/success/default" />
							<Text>
								Last active {moment(user.last_login).format('DD MMM YYYY')}
							</Text>
						</Group>
					)}
					<Group spacing="xs">
						<Icon name="userCheck" />
						<Text>{capitalize(user.role)}</Text>
					</Group>
					<Group spacing="xs">
						<Icon name="calendar" />
						<Text>Joined {moment(user.created_at).format('MMM YYYY')}</Text>
					</Group>
				</Group>
			</Stack>
		</Box>
	);
}

export default ProfileHeader;
