import { Menu } from '@mantine/core';

interface IResourceFieldItemProps {
	label: string;
	subLabel?: string;
	value: string;
	icon: React.ReactNode;
	onAdd?: () => void;
	onRemove?: () => void;
}

interface IFormResourceFieldItemProps {
	item: IResourceFieldItemProps;
}

// eslint-disable-next-line react/display-name
function FormResourceFieldItem({ item }: IFormResourceFieldItemProps) {
	const { label, icon, onAdd } = item;
	return (
		<Menu.Item icon={icon} onClick={onAdd}>
			{label}
		</Menu.Item>
	);
}

export default FormResourceFieldItem;
