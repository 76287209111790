import { Drawer, Stack } from '@mantine/core';
import { Badge, BadgeVariant, Text } from '@repo/foundations';
import { AutomationJob } from '../../api/types/models/automation/automationJob';
import { useFeatureFlags } from '../../utils/featureFlags';
import { ErrorLogs } from '../ErrorLogs/ErrorLogs';
import { AutomationHistoryDetailsTable } from './AutomationHistoryDetailTable';

interface IAutomationJobDrawerProps {
	automationJob: AutomationJob;
	opened: boolean;
	onClose: () => void;
}

export function AutomationJobDrawer({
	automationJob,
	opened,
	onClose,
}: IAutomationJobDrawerProps) {
	const { automationHistory } = useFeatureFlags();

	let variant: BadgeVariant = 'info';
	if (automationJob.status === 'success') {
		variant = 'success';
	}
	if (automationJob.status === 'error') {
		variant = 'critical';
	}

	return (
		<Drawer
			opened={opened}
			onClose={onClose}
			position="right"
			title={
				<Text size="md" weight="bold">
					Status
				</Text>
			}
		>
			<Stack w="100%">
				<Text size="sm" weight="regular">
					<Badge variant={variant} radius="sm">
						{automationJob.status}
					</Badge>
				</Text>
				{automationJob.error_message && (
					<ErrorLogs title="Error" logs={[automationJob.error_message]} />
				)}
				{!automationJob.error_message && automationHistory && (
					<AutomationHistoryDetailsTable automationJobId={automationJob.id} />
				)}
			</Stack>
		</Drawer>
	);
}
