import { Badge } from '@repo/foundations';
interface IPublishStatusBadgeProps {
	value?: boolean;
}

export default function PublishStatusBadge({
	value,
}: IPublishStatusBadgeProps) {
	if (value) {
		return <Badge variant="success">Published</Badge>;
	}

	return <Badge variant="default">Draft</Badge>;
}
