import type { MantineTheme } from '@mantine/core';
import { Avatar, createStyles } from '@mantine/core';
import { type ButtonVariants, Text } from '@repo/foundations';
import type { TablerIconsProps } from '@tabler/icons-react';
import { isNil, map, omitBy, size, slice, upperFirst } from 'lodash-es';
import { ReactNode } from 'react';
import { pickColorType, pluralize, singularize } from '../../utils';
import { ItemIconType } from '../ItemIcon';
import { SelectablePropertyType } from '../SelectableProperty/types';
import type { SelectablePropertyItem } from '../SingleSelector/types';
import TagIcon from '../TagIcon/TagIcon';
import { colorGroupToFillMap } from '../UserAvatar/helpers';

interface IMultiTargetButtonProps {
	selected: SelectablePropertyItem[];
	property: SelectablePropertyType;
	iconType: ItemIconType;
	variant?: ButtonVariants;
}

const useStyles = createStyles((theme: MantineTheme) => ({
	root: {
		outlineWidth: 2,
		outlineStyle: 'solid',
	},
	avatarLabel: {
		fontSize: '0.6rem',
		lineHeight: theme.fontSizes.xs,
	},
}));

function MultiTargetButton({
	selected,
	property,
	iconType,
	variant = 'default',
}: IMultiTargetButtonProps) {
	const { classes, theme } = useStyles();
	const iconHeight =
		iconType === 'tag' ? theme.other.space[3] : theme.other.space[4];

	return (
		<Avatar.Group ml={iconType !== 'tag' ? 2 : 0} spacing="xs" my="auto">
			{map(slice(selected, 0, 3), (item) => {
				const sx: Record<string, string | null | undefined> = {
					backgroundColor: theme.other.getColor('surface/primary/active'),
				};

				if (iconType === 'avatar') {
					const colorGroup = pickColorType(item.label);
					const { fillStart, fillEnd, textColor } =
						colorGroupToFillMap(colorGroup);
					sx.backgroundImage = theme.fn.gradient({
						from: fillStart,
						to: fillEnd,
						deg: 180,
					});
					sx.color = textColor;
				} else if (iconType === 'tabler') {
					sx.backgroundColor = item.color as string;
				} else if (iconType === 'tag') {
					sx.backgroundColor = item.color as string;
				}

				let icon: ReactNode;
				if (iconType === 'avatar') {
					icon =
						(item.icon as ReactNode) || upperFirst(item.label?.slice(0, 1));
				} else if (iconType === 'tag') {
					icon = <TagIcon color={item.color as string} />;
				} else if (iconType === 'tabler') {
					const Icon = item.icon as (props: TablerIconsProps) => JSX.Element;
					icon = (
						<Icon
							height={`${iconHeight}px`}
							width={`${iconHeight}px`}
							size={iconHeight}
							color={sx?.color ?? item.color}
						/>
					);
				} else {
					icon = item.icon as ReactNode | undefined;
				}

				return (
					<Avatar
						key={`${item.value}`}
						classNames={{
							root: classes.root,
							placeholder: classes.avatarLabel,
						}}
						src={iconType === 'avatar' ? (item.icon as string) : undefined}
						miw={`${iconHeight}px`}
						mih={`${iconHeight}px`}
						h={`${iconHeight}px`}
						w={`${iconHeight}px`}
						size="sm"
						sx={omitBy(sx, isNil)}
					>
						{icon}
					</Avatar>
				);
			})}
			<Text
				ml={4}
				size="sm"
				style={{ lineHeight: `${iconHeight}px` }}
				color={
					variant === 'primary'
						? 'text/brand-on-fill/default'
						: 'text/primary/default'
				}
			>
				{`${size(selected)} ${
					size(selected) > 1 ? pluralize(property) : singularize(property)
				}`}
			</Text>
		</Avatar.Group>
	);
}

export default MultiTargetButton;
