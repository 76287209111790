import { createStyles, TextInput } from '@mantine/core';
import { useDebouncedValue, useInputState } from '@mantine/hooks';
import { getColor } from '@repo/theme/utils';
import { useEffect } from 'react';
import type { IFormField } from '../../../../../api';
import FormInputWrapper from '../../../FormInputWrapper';

interface IFormFieldLabelProps {
	field: IFormField;
	updateField: (field: IFormField) => void;
}

const useStyles = createStyles((theme) => ({
	input: {
		padding: `${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing['2xs']} ${theme.spacing.sm}`,
		border: `0.5px solid ${getColor('border/secondary/default')}`,
		borderRadius: theme.radius.sm,
		backgroundColor: getColor('surface/input/default'),
		'&:hover': {
			backgroundColor: getColor('surface/input/hover'),
		},
		'&:focus': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
		'&:active': {
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.colors.blue[5]}`,
			backgroundColor: getColor('surface/input/active'),
		},
	},
}));

export default function FormFieldLabel({
	field,
	updateField,
}: IFormFieldLabelProps) {
	const [label, setLabel] = useInputState(field.label);
	const [debouncedLabel] = useDebouncedValue(label, 500);

	useEffect(() => {
		if (label !== field.label) {
			setLabel(field.label);
		}
	}, [field.id]);

	useEffect(() => {
		if (debouncedLabel !== field.label) {
			updateField({ ...field, label });
		}
	}, [debouncedLabel]);

	const { classes } = useStyles();

	return (
		<FormInputWrapper label="Label">
			<TextInput
				classNames={{
					input: classes.input,
				}}
				value={label}
				onChange={setLabel}
			/>
		</FormInputWrapper>
	);
}
