import { Group, Stack } from '@mantine/core';
import { OPERATORS_CONFIG } from '@repo/common/components/Filter/constants.ts';
import { IconButton } from '@repo/foundations';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import type { ISecodaEntity } from '../../../api';
import { useAuthUser } from '../../../api';
import { isViewerOfEntity } from '../../../utils/authorization/roles';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { AddFilter, Filter, SearchFilterV2StoreContext } from '../../Filter';
import { FILTER_OPTIONS_CONFIG } from '../../Filter/constants.tsx';
import { lineageStore } from '../store';
import { DownloadMenuButton } from './DownloadMenuButton';
import LineageSearch from './LineageSearch';

interface IFilterBarProps {
	toggleFullscreen: () => void;
	entity?: ISecodaEntity;
}

export const FilterBar = observer(
	({ toggleFullscreen, entity }: IFilterBarProps) => {
		const { lineageFiltering } = useFeatureFlags();
		const store = useContext(SearchFilterV2StoreContext);

		const { user, isEditorOrAdminUser } = useAuthUser();
		const writeAccess = entity
			? !isViewerOfEntity(user, entity)
			: isEditorOrAdminUser;

		const handleAddNode = () => {
			lineageStore.addTemporaryNode();
		};

		const handleToggleFullscreen = () => {
			toggleFullscreen();
		};

		return (
			<Stack spacing="sm">
				<Group position="apart">
					<LineageSearch />
					<Group spacing={8}>
						{writeAccess && (
							<IconButton
								variant="tertiary"
								tooltip="Add node"
								iconName="plus"
								onClick={handleAddNode}
								data-testid="lineage-filter-bar-add-node-button-buQst4rN"
							/>
						)}
						<IconButton
							variant="tertiary"
							tooltip="Auto Position"
							iconName="layout"
							onClick={lineageStore.autoPosition}
						/>
						<IconButton
							variant="tertiary"
							tooltip="Reinitialize Lineage"
							iconName="refresh"
							onClick={lineageStore.reinitialize}
						/>
						<IconButton
							variant="tertiary"
							tooltip={
								lineageStore.isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'
							}
							iconName={
								lineageStore.isFullscreen
									? 'arrowsDiagonalMinimize'
									: 'arrowsDiagonal'
							}
							onClick={handleToggleFullscreen}
							data-testid="lineage-controls-fullscreen-70b077616"
						/>
						<DownloadMenuButton />
					</Group>
				</Group>
				{lineageFiltering && (
					<Group spacing="xs">
						{store.values.map((value, idx) => (
							<Filter
								// eslint-disable-next-line react/no-array-index-key
								key={`filter-${idx}}`}
								value={toJS(value)}
								filterOption={FILTER_OPTIONS_CONFIG[value.filterType]}
								onChange={store.onChangeValue(idx)}
								onClear={store.onClearValue(idx)}
								showDetailedLabel
								operatorConfig={
									OPERATORS_CONFIG[
										FILTER_OPTIONS_CONFIG[value.filterType].filterDropdownConfig
											.dropdownType
									]
								}
							/>
						))}
						<AddFilter
							options={toJS(store.filterOptions)}
							onAddFilter={store.onAddValue}
						/>
					</Group>
				)}
			</Stack>
		);
	}
);
