import { createStyles, Stack } from '@mantine/core';
import { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { map } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import type { IForm } from '../../../api';
import { queryClient, useAuthUser } from '../../../api';
import { formQueryKeys, useUpdateForm } from '../../../api/hooks/forms';
import { useExtendedUserList } from '../../../api/hooks/user/useExtendedUserList';
import { isTrue } from '../../../utils/utils';
import CollapsableStack from '../../CollapsableStack';
import {
	SelectableProperty,
	StaticProperty,
} from '../../EntityPageLayout/EntityPropertySidebar';
import EntitySidebarWrapper from '../../EntityPageLayout/EntitySidebarWrapper';
import { getOwnerOptions } from '../../EntityPageLayout/utils';

interface IFormPageSidebarProps {
	form?: IForm;
}

const useStyles = createStyles((theme) => ({
	collapsableWrapper: {
		padding: theme.spacing.xl,
	},
}));

export function FormPageSideBar({ form }: IFormPageSidebarProps) {
	const { classes } = useStyles();

	const { isAdminUser } = useAuthUser();

	const { activeUsers } = useExtendedUserList({ suspense: false });

	const { mutateAsync: updateForm } = useUpdateForm();

	const handleFormUpdate = useCallback(
		async (data: Partial<IForm>) => {
			if (form?.id) {
				await updateForm({
					form: {
						...form,
						...data,
					},
				});
			}

			queryClient.invalidateQueries(formQueryKeys.all());
		},
		[form, updateForm]
	);

	const handleFormOwnerChange = useCallback(
		(owners: string[]) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			handleFormUpdate({ owners });
		},
		[handleFormUpdate]
	);

	const handleFormApprovalConditionChange = useCallback(
		(condition: 'ALL' | 'ANY' | null) => {
			handleFormUpdate({
				approval_condition: condition,
			});
		},
		[handleFormUpdate]
	);

	const status: SelectablePropertyItem = isTrue(form?.published)
		? {
				label: 'Published',
				value: true,
				fill: 'fill/success-secondary/default',
			}
		: {
				label: 'Draft',
				value: false,
				fill: 'fill/secondary/default',
			};

	const owners = useMemo(
		() => getOwnerOptions(activeUsers ?? [], handleFormOwnerChange, false),
		[activeUsers, handleFormOwnerChange]
	);

	const approvalConditions = useMemo(
		() =>
			[
				{
					value: 'ANY',
					label: 'Any Reviewer',
					onClick: handleFormApprovalConditionChange,
				},
				{
					value: 'ALL',
					label: 'All Reviewers',
					onClick: handleFormApprovalConditionChange,
				},
				{
					value: null,
					label: 'None',
					onClick: handleFormApprovalConditionChange,
				},
			] as SelectablePropertyItem[],
		[]
	);

	return (
		<EntitySidebarWrapper>
			<Stack>
				<CollapsableStack
					classNames={classes}
					groupName="Properties"
					withDivider={false}
				>
					<Stack>
						{isAdminUser && (
							<StaticProperty type="badge" label="Status" value={status} />
						)}
						<SelectableProperty
							selected={map(form?.owners, 'id')}
							type="multi"
							label="Reviewers"
							value="reviewers"
							iconType="avatar"
							isViewerUser={!isAdminUser}
							options={owners}
						/>
						<SelectableProperty
							selected={form?.approval_condition || ''}
							type="single"
							label="Approval Condition"
							value="approval_condition"
							iconType="none"
							isViewerUser={!isAdminUser}
							options={approvalConditions}
						/>
					</Stack>
				</CollapsableStack>
			</Stack>
		</EntitySidebarWrapper>
	);
}
