import { IconButton } from '@repo/foundations';
import { useCallback } from 'react';
import { ISecodaEntity, useAuthUser } from '../../api';
import type { SecodaEntity } from '../../lib/models';
import { trackEvent } from '../../utils/analytics';

import EmbeddedQuestionsDrawer from './EmbeddedQuestionsDrawer';
import { embeddedQuestionsStore } from './store';

export interface IEmbeddedQuestionsButtonProps {
	entity: SecodaEntity | ISecodaEntity;
}

function EmbeddedQuestionsButton({ entity }: IEmbeddedQuestionsButtonProps) {
	const { user, workspace } = useAuthUser();

	const onClick = useCallback(() => {
		embeddedQuestionsStore.setOpen(true);
		trackEvent(
			`${entity.entity_type}/activity_button/click`,
			{},
			user,
			workspace!
		);
	}, []);

	return (
		<>
			<IconButton
				tooltip="Show questions"
				onClick={onClick}
				iconName="messageCircleQuestion"
				variant="tertiary"
			/>
			<EmbeddedQuestionsDrawer entity={entity} />
		</>
	);
}

export default EmbeddedQuestionsButton;
