import type { ISecodaEntity, MetricType } from '../../../api';
import AddMonitorButton from '../../AddMonitor/AddMonitorButton';

interface CreateMonitorActionProps {
	entity: ISecodaEntity;
	metricType: MetricType;
}

export function CreateMonitorAction({
	entity,
	metricType,
}: CreateMonitorActionProps) {
	const isUniqueOrNullMonitor =
		metricType === 'unique_percentage' || metricType === 'null_percentage';

	return (
		<AddMonitorButton
			initialIntegration={entity.integration}
			initialTable={entity.id}
			initialMetricType={metricType}
			initialSearchTerm={isUniqueOrNullMonitor ? undefined : entity.title}
		/>
	);
}
