// NOTE we should avoid using `@mantine/core/Button` in favour of `@repo/core/Button`
// eslint-disable-next-line no-restricted-imports
import { Button, useMantineTheme } from '@mantine/core';
import type { Incident, IncidentStatus, ISecodaEntity } from '../../../api';
import { useIncidentStatusUpdate } from '../../../api';
import { AIAssistantSidebarToggle } from '../../../components/AIAssistant';
import EntityPageAsideCollapse from '../../../components/EntityPageAsideCollapse/EntityPageAsideCollapse';
import EntityPageNavBar from '../../../components/EntityPageLayout/EntityPageNavBar';
import {
	getIncidentStatusIcon,
	isAcknowledgeEnabled,
	isResolveEnabled,
} from '../utils';

type IIncidentPageNavbarProps = {
	targetEntity?: ISecodaEntity;
	incident?: Incident;
};

function IncidentPageNavbar({
	targetEntity,
	incident,
}: IIncidentPageNavbarProps) {
	const theme = useMantineTheme();
	const { mutateAsync: updateIncident } = useIncidentStatusUpdate();

	const handleIncidentStatusUpdate = (status: IncidentStatus) => async () => {
		if (!incident?.id) {
			return;
		}

		if (status === 'active' || status === 'expired') {
			return;
		}

		await updateIncident({
			data: {
				id: incident.id,
				monitorId: incident.monitor,
				status,
			},
		});
	};

	const acknowledgeIcon = getIncidentStatusIcon('acknowledged', { size: 20 });
	const resolveIcon = getIncidentStatusIcon('resolved', { size: 20 });

	const showAcknowledgeButton =
		isAcknowledgeEnabled(incident?.status) && incident?.user_can_resolve;
	const showResolveButton =
		isResolveEnabled(incident?.status) && incident?.user_can_resolve;

	return (
		<EntityPageNavBar
			entity={targetEntity as never}
			actions={
				<>
					{showAcknowledgeButton && (
						<Button
							h={theme.other.space[8]}
							variant="default"
							leftIcon={acknowledgeIcon}
							onClick={handleIncidentStatusUpdate('acknowledged')}
						>
							Acknowledge
						</Button>
					)}
					{showResolveButton && (
						<Button
							h={theme.other.space[8]}
							variant="default"
							leftIcon={resolveIcon}
							onClick={handleIncidentStatusUpdate('resolved')}
						>
							Resolve
						</Button>
					)}
				</>
			}
			toggles={
				<>
					<AIAssistantSidebarToggle />
					<EntityPageAsideCollapse />
				</>
			}
		/>
	);
}

export default IncidentPageNavbar;
