import { Skeleton } from '@mantine/core';
import { Suspense } from 'react';

import { MetricContainer } from './MetricContainer';

export interface ChartBlockContainerProps {
	id: string;
	readOnly: boolean;
	onDelete: VoidFunction;
	isSelected?: boolean;
}

export function ChartBlockContainer({
	id,
	readOnly,
	onDelete,
	isSelected,
}: ChartBlockContainerProps) {
	return (
		<Suspense fallback={<Skeleton height={300} />}>
			<MetricContainer
				id={id}
				readOnly={readOnly}
				withQuery={false}
				withChart
				onDelete={onDelete}
				isSelected={isSelected}
			/>
		</Suspense>
	);
}
