import { Skeleton, Stack } from '@mantine/core';
import { Title } from '@repo/foundations';
import {
	Incident,
	useMeasurementsChartData,
	useMonitor,
} from '../../../../api';
import { metricTypeLabel } from '../../utils';
import MonitorTimeseriesChart from '../MonitorTimeseriesChart';

interface IncidentMeasurementChartProps {
	incident: Incident;
}

function IncidentMeasurementChart({ incident }: IncidentMeasurementChartProps) {
	const { data: measurements } = useMeasurementsChartData({
		incidentId: incident.id,
	});
	const { data: monitor } = useMonitor({
		id: incident.monitor,
		options: {
			enabled: incident && Boolean(incident.id),
		},
	});
	if (!monitor || !measurements || measurements.length === 0) {
		// Return null or some fallback UI if conditions are not met
		return <Skeleton height={400} width="100%" />;
	}
	return (
		<Stack spacing={0}>
			<Title size={'md'} pb={'md'}>
				{metricTypeLabel(monitor.metric_type)}
			</Title>
			<MonitorTimeseriesChart
				data={measurements}
				metricType={monitor.metric_type}
				configuredLowerThreshold={monitor.condition_manual_min ?? undefined}
				configuredUpperThreshold={monitor.condition_manual_max ?? undefined}
				width="100%"
			/>
		</Stack>
	);
}

export default IncidentMeasurementChart;
