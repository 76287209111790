import { Stack } from '@mantine/core';
import { useFormSubmissionsList } from '../../../../api/hooks/forms';
import { TableV2 } from '../../../TableV2';

import type { IBaseModel, IFormSubmission } from '../../../../api';
import { useAuthUser } from '../../../../api';
import type { FetchModelListHook } from '../../../../api/factories/types';
import {
	LastUpdatedRender,
	ReadOnlyFormSubmissionStatusRender,
	ReadOnlyOwnerRender,
} from '../../../TableV2/render';
import type { Render } from '../../../TableV2/types';
import { useFormStore } from '../../context';
import ReviewSubmissionModal from './ReviewSubmissionModal/ReviewSubmissionModal';

interface ISubmissionsTabProps {
	formId: string;
}

export default function SubmissionsTab({ formId }: ISubmissionsTabProps) {
	const { isAdminUser } = useAuthUser();
	const { setSubmissionUnderReview } = useFormStore();

	const handleRowClick = (row: { id: string | undefined }) => {
		if (isAdminUser) {
			setSubmissionUnderReview(row.id);
		}
	};

	return (
		<Stack>
			<TableV2
				pluralTypeString="submissions"
				withCheckbox={false}
				withInteractiveHeader={false}
				onRowClick={handleRowClick}
				columns={[
					{
						accessor: 'user',
						title: 'Owner',
						render: (record) => (
							<ReadOnlyOwnerRender record={record as IFormSubmission} />
						),
					},
					{
						accessor: 'status',
						title: 'Status',
						render:
							ReadOnlyFormSubmissionStatusRender as unknown as Render<IFormSubmission>,
						width: 200,
					},
					{
						accessor: 'last_updated',
						title: 'Last updated',
						render: (record) => (
							<LastUpdatedRender record={record} field={'updated_at'} />
						),
					},
				]}
				usePaginationList={
					useFormSubmissionsList(
						formId,
						false
					) as FetchModelListHook<IBaseModel>
				}
			/>
			<ReviewSubmissionModal />
		</Stack>
	);
}
