import { createStyles } from '@mantine/core';
import { useNavigate } from 'react-router';
import { closeModal } from '../../ModalManager';
import WidgetSelector from '../../WidgetSelector';
import type { WidgetData } from '../../WidgetSelector/types';
import { MONITOR_SPECS } from '../monitors';
import { MonitorSpecCard } from '../MonitorSpecCard/MonitorSpecCard';
import { MonitorSpec } from '../types';
import { ADD_MONITOR_MODAL_ID } from './constants';

export const useStyles = createStyles((theme) => ({
	header: {
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		padding: `${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.sm} ${theme.spacing.lg}`,
	},
	body: {
		padding: 0,
	},
	widgetItemsWrapper: {
		columnGap: theme.spacing.lg,
		rowGap: theme.spacing.md,
	},
	widgetWrapper: {
		paddingBottom: 0,
	},
}));

function AddMonitorModal() {
	const { theme, classes } = useStyles();
	const navigate = useNavigate();

	const data: WidgetData<MonitorSpec>[] = [
		...MONITOR_SPECS.map((spec) => ({
			data: spec,
			title: spec.title,
			description: spec.description,
			group: spec.group,
		})),
	];

	const handleMonitorSelect = async (widgetData: WidgetData<MonitorSpec>) => {
		navigate(
			`/monitors/new?${new URLSearchParams(widgetData.data.type).toString()}`
		);
		closeModal(ADD_MONITOR_MODAL_ID);
	};

	return (
		<WidgetSelector
			data={data}
			type="filter"
			onWidgetSelect={handleMonitorSelect}
			widgetGroupProps={{
				classNames: {
					widgetItemsWrapper: classes.widgetItemsWrapper,
					widgetWrapper: classes.widgetWrapper,
				},
			}}
			scrollAreaHeight={theme.other.space[160]}
			cols={3}
			renderer={MonitorSpecCard}
		/>
	);
}

export default AddMonitorModal;
