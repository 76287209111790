import { Box, Divider, Group, createStyles } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';
import {
	RIGHT_SIDEBAR_TRANSITION_DURATION,
	RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION,
	TOP_NAVBAR_HEIGHT,
} from '../constants';
import { entitySideBarStore } from './../../EntityPageLayout/store';

export const useStyles = createStyles((theme) => ({
	sidesheetControlsWrapper: {
		position: 'fixed',
		top: 0,
		right: theme.spacing.xl,
		zIndex: 10,

		height: `${TOP_NAVBAR_HEIGHT}px`,
		gap: theme.spacing.sm,
		flexWrap: 'nowrap',
		paddingLeft: theme.spacing.sm,
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	toggles: {
		display: 'flex',
		gap: theme.spacing['3xs'],
		flexWrap: 'nowrap',
	},
	divider: {
		transition: `opacity 100ms ${RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION}`,
	},
	sidesheetControlsPhantomElement: {
		height: `${TOP_NAVBAR_HEIGHT}px`,
		gap: theme.spacing.sm,
		flexWrap: 'nowrap',
		paddingLeft: theme.spacing.sm,
		justifyContent: 'flex-end',
		alignItems: 'center',

		transition: `width ${RIGHT_SIDEBAR_TRANSITION_DURATION} ${RIGHT_SIDEBAR_TRANSITION_TIMING_FUNCTION}`,
		visibility: 'hidden',
	},
}));

function ActionsTogglesDivider({ collapsed }: { collapsed: boolean }) {
	const { classes, theme } = useStyles();
	return (
		<Box
			h="md"
			className={classes.divider}
			style={{ opacity: collapsed ? '1' : '0' }}
		>
			<Divider
				orientation="vertical"
				size="xs"
				h="md"
				color={theme.other.getColor('border/primary/default')}
			/>
		</Box>
	);
}

interface NavBarSidesheetControlsWrapperProps {
	children: React.ReactNode;
}

export const NavBarSidesheetControlsWrapper = observer(
	({ children }: NavBarSidesheetControlsWrapperProps) => {
		const { classes } = useStyles();

		// We're unable to animate from "width: unset" to "width: 0". This was
		// causing some jank.
		const [delayedCollapse] = useDebouncedValue(
			entitySideBarStore.collapsed,
			50
		);

		return (
			<>
				<Group
					className={classes.sidesheetControlsPhantomElement}
					w={delayedCollapse ? 'unset' : 0}
					pl={delayedCollapse ? 'sm' : 0}
				>
					<ActionsTogglesDivider collapsed={delayedCollapse || false} />
					<Group className={classes.toggles}>{children}</Group>
				</Group>
				<Group className={classes.sidesheetControlsWrapper}>
					<ActionsTogglesDivider collapsed={delayedCollapse || false} />
					<Group className={classes.toggles}>{children}</Group>
				</Group>
			</>
		);
	}
);
