import { Box, Group } from '@mantine/core';
import { useApiListTeams } from '@repo/api-codegen';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { Button, Icon, Text } from '@repo/foundations';
import { IconUsers } from '@tabler/icons-react';
import React, { ReactNode } from 'react';
import { useUserGroupList } from '../../../../api';

interface TeamAndGroupSelectorProps {
	onTeamSelected: (teamId: string) => void;
	onGroupSelected: (groupId: string) => void;
}

export function TeamAndGroupSelector({
	onTeamSelected,
	onGroupSelected,
}: TeamAndGroupSelectorProps) {
	const { data: teams } = useApiListTeams(
		{
			queryParams: {
				only_write: false,
			},
		},
		{
			suspense: false,
			select: (res) => res.results,
		}
	);

	const { data: groups } = useUserGroupList({
		options: {
			select: (res) => res.results,
		},
	});

	const [selected, setSelected] = React.useState<
		SelectablePropertyItem | undefined
	>();

	const options: SelectablePropertyItem[] = [
		...(teams?.map((t) => ({
			label: t.name,
			value: t.id,
			icon: t.icon,
			group: 'teams',
		})) ?? []),
		...(groups?.map((g) => ({
			label: g.name,
			value: g.id,
			icon: g.icon,
			group: 'groups',
		})) ?? []),
	];

	const handleChange = (value: string) => {
		const selectedOption = options.find((o) => o.value === value);
		if (selectedOption) {
			setSelected(selectedOption);
		}

		if (selectedOption?.group === 'teams') {
			onTeamSelected(value);
			onGroupSelected('');
		} else {
			onGroupSelected(value);
			onTeamSelected('');
		}
	};

	return (
		<Box maw={512}>
			<SingleSelector
				iconType="emoji"
				isViewerUser={false}
				searchable
				options={options}
				placeholder="All teams"
				placeholderIcon={IconUsers}
				onChange={handleChange}
				property="team"
				target={
					<Button size="md">
						<Group spacing="3xs" noWrap>
							{selected ? (
								<Text>{selected?.icon || ('💼' as ReactNode)}</Text>
							) : (
								<Icon name="users" />
							)}
							<Text size="sm">{selected?.label || 'All teams'}</Text>
						</Group>
					</Button>
				}
			/>
		</Box>
	);
}
